import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { extractTableHeaders } from 'utils/hooks/reportTableUtils';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  arrangement_reference_number: string;
  lei_entity_signing_contractual_arrangement: string;
  // name_of_entity: string;
}

interface B0301Props {
  entityName: string | null | undefined;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Contractual arrangement reference number - <span>0010</span>
        </p>
        <AdditionalInformation
          description="As reported in B_02.02.0010
Identify the contractual arrangement reference number signed by the ICT third-party service provider
(alphanumerical)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'arrangement_reference_number',
    key: 'arrangement_reference_number',
    fixed: 'left',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          LEI of the entity signing the contractual arrangement -{' '}
          <span>0020</span>
        </p>
        <AdditionalInformation
          description="Identify the undertaking signing the contractual arrangement using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard or the EUID.
(alphanumerical)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'lei_entity_signing_contractual_arrangement',
    key: 'lei_entity_signing_contractual_arrangement',
    fixed: 'left',
  },
  //   {
  //     title: (
  //       <Styled.AddttionalInfoContainer>
  //         Name of the financial entity
  //         <AdditionalInformation description="Legal name of the financial entity maintaining and updating the register of information
  // (alphanumerical)" />
  //       </Styled.AddttionalInfoContainer>
  //     ),
  //     width: 100,
  //     dataIndex: 'entity_name',
  //     key: 'entity_name',
  //   },
];
const B0301: React.FC<B0301Props> = ({ entityName }) => {
  const { date, is_displayed, headers } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData =
        await reportingOp.fetchContractualArrangementsLei(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          arrangement_reference_number: item.arrangement_reference_number,
          lei_entity_signing_contractual_arrangement:
            item.lei_entity_signing_contractual_arrangement,
          // name_of_entity: item.name_of_entity,
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  useEffect(() => {
    extractTableHeaders(memoizedColumns, dispatch);
  }, []);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0301;
