import { Dispatch } from '@reduxjs/toolkit';
import globalSlice from './globalSlice';
import { notification } from 'antd';
import { IChangePass, IFilters, IResetPass, ThemeList } from '../globalTypes';
import { ITableFilters } from './types';
import ApiService from 'utils/ApiCalls';
import Helpers from 'utils/Helpers';
import axios from 'axios';

const {
  set_theme,
  set_loading,
  set_applied_filters_result,
  set_table_filters,
  set_filters_data,
} = globalSlice.actions;

const setTheme = (theme: ThemeList) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_theme(theme));
  } catch (error) {
    console.error(error);
  }
};

const setAppliedFilersResult = (data: any[]) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_applied_filters_result(data));
  } catch (error) {
    console.error(error);
  }
};

const setTableFilter = (filter: ITableFilters) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_table_filters(filter));
  } catch (error) {
    console.error(error);
  }
};

const setFilters = () => async (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    const response = await ApiService.getFilters();
    let fetchedFilters: IFilters[] = [];

    if (response.data && !Helpers.isObjEmpty(response.data)) {
      fetchedFilters = Object.values(
        response.data as { id: number; name_of_entity: string }[],
      ).map((entity) => {
        return {
          filter: entity.name_of_entity,
          options: [
            {
              id: entity.id,
              name_of_entity: entity.name_of_entity,
              label: entity.name_of_entity,
              value: entity.id.toString(),
            },
          ],
        };
      });
    }

    dispatch(set_filters_data(fetchedFilters));
  } catch (error) {
    console.error('Error fetching the Entities:', error);
  }
};

const login = async (email: string, password: string) => {
  try {
    const response = await ApiService.login({
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

const changePass = async (
  formData: IChangePass,
  linkToken: string | qs.ParsedQs | string[] | qs.ParsedQs[],
) => {
  try {
    const response = await ApiService.changePass(formData, linkToken);

    notification.success({
      message: response.status,
      description: 'Your password has been updated.',
      duration: 20,
    });
  } catch (error) {
    console.error(error);
  }
};

const logout = async () => {
  try {
    const response = await ApiService.logout();
    return response.data;
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
};

const signup = async (data: any) => {
  try {
    const response = await ApiService.signup(data);
    return response.data;
  } catch (error) {
    console.error('Signup failed:', error);
    throw error;
  }
};

const forgotPassword = async (email: string) => {
  try {
    const response = await ApiService.forgotPassword(email);
    return response.data;
  } catch (error) {
    console.error('Forgot password request failed:', error);
    throw error;
  }
};

const resetPassword = async (formData: IResetPass) => {
  try {
    const response = await ApiService.resetPassword(formData);

    notification.success({
      message: response.status,
      description: 'Your password has been reseted successfully.',
      duration: 20,
    });
  } catch (error) {
    console.error(error);
  }
};

const userActivityStatus = async (username: string) => {
  try {
    const response = await ApiService.fetchUserActivityStatus(username);
    return response.data;
  } catch (error) {
    console.error('Activity status request failed:', error);
    throw error;
  }
};

const fetchSensitivenessOfData = async () => {
  try {
    const response = await ApiService.getSensitivenessOfData();
    return response;
  } catch (error) {
    console.error('Error fetching the sensitiveness of data', error);
  }
};

const fetchSubstitutability = async () => {
  try {
    const response = await ApiService.getICTServiceSubstitutability();
    return response;
  } catch (error) {
    console.error('Error fetching the substitutability', error);
  }
};

const inactivateICTSystem = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateICTSystem(dataList);

    const { errors, deleted_ict_system, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach((error: { ict_system_id: number; error: string }) => {
        if (!error.error.includes('ICT System entry not found.')) {
          notification.error({
            message: `Error for ICT System ID: ${error.ict_system_id}`,
            description: error.error,
            duration: 10,
          });
        }
      });
    } else if (deleted_ict_system && deleted_ict_system.length > 0) {
      notification.success({
        message: 'ICT System Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate ICT System. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate ICT System');
  }
};

const inactivateData = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateData(dataList);

    const { errors, deleted_data, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach((error: { data_id: number; error: string }) => {
        if (!error.error.includes('Data entry not found.')) {
          notification.error({
            message: `Error for Data ID: ${error.data_id}`,
            description: error.error,
            duration: 10,
          });
        }
      });
    } else if (deleted_data && deleted_data.length > 0) {
      notification.success({
        message: 'Data Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate Data. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate Data');
  }
};

const fetchICTSystemsbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postICTSystemsByEntity(entityTypeData);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching ICt Sytsems:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

export default {
  setTheme,
  setAppliedFilersResult,
  setTableFilter,
  setFilters,
  login,
  changePass,
  logout,
  signup,
  forgotPassword,
  resetPassword,
  userActivityStatus,
  fetchSensitivenessOfData,
  fetchSubstitutability,
  inactivateICTSystem,
  inactivateData,
  fetchICTSystemsbyEntityName,
};
