import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { extractTableHeaders } from 'utils/hooks/reportTableUtils';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  function_identifier: string;
  licenced_identifier: string;
  function_name: string;
  lei_of_financial_entity: string;
  criticality_assessment: string;
  reasons_for_criticality: string;
  date_of_last_assessment: string;
  recovery_time_objective: string;
  recovery_point_objective: string;
  impact_of_discontinuing_function: string;
}

interface B0601Props {
  entityName: string | null | undefined;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          {' '}
          Function Identifier - <span>0010</span>
        </p>
        <AdditionalInformation
          description="The function identifier shall be composed by the letter F (capital letter) followed by a natural number (e.g. “F1” for the 1st function identifier and “Fn” for the nth function identifier with “n” being a natural number).
Each combination between ‘LEI of the financial entity making use of the ICT service(s)’ (B_06.01.0040), ‘Function name’ (B_06.01.0030) and ‘Licenced activity’ (B_06.01.0020) shall have a unique function identifier.
Example: a financial entity which operates under two licensed activities (‘activity A’ and ‘activity B’) will be given two unique ‘function identifiers’ for the same function X (e.g. Sales) performed for activity A and activity B.
(pattern)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'function_identifier',
    key: 'function_identifier',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          {' '}
          Licenced activity - <span>0020</span>
        </p>
        <AdditionalInformation description="One of the licenced activities referred to in the underlying legal acts listed in Annex II for the different types of financial entities. Where the function is not linked to a registered or licenced activity, 'support functions' shall be reported." />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'licenced_identifier',
    key: 'licenced_identifier',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Function name - <span>0030</span>
        </p>
        <AdditionalInformation
          description="Function name according to the financial entity’s internal organisation.
(alphanumerical)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'function_name',
    key: 'function_name',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          {' '}
          LEI of the financial entity - <span>0040</span>
        </p>
        <AdditionalInformation
          description="As reported in B_04.01.0020
Identify the financial entity using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard
(alphanumerical)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'lei_of_financial_entity',
    key: 'lei_of_financial_entity',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          {' '}
          Criticality or importance assessment - <span>0050</span>
        </p>
        <AdditionalInformation
          description="Use this column to indicate whether the function is critical or important according to the financial entity’s assessment. One of the options in the following closed list shall be used:
1.	Yes;
2.	No;
3.	Assessment not performed.
(closed set of options)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'criticality_assessment',
    key: 'criticality_assessment',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Reasons for criticality or importance - <span>0060</span>
        </p>
        <AdditionalInformation
          description="Brief explanation on the reasons for classifying the function as critical or important (300 characters maximum)
(alphanumerical)
(optional)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'reasons_for_criticality',
    key: 'reasons_for_criticality',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Date of the last assessment of criticality or importance -{' '}
          <span>0070</span>
        </p>
        <AdditionalInformation
          description="Identify the date using ISO 8601 (yyyy– mm–dd) code of the date of the last assessment of criticality or importance in case the function is supported by ICT services provided by ICT third-party service providers.
Where the assessment of the function’s criticality or importance is not performed, it shall be filled in with ‘9999-12-31’
(date)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'date_of_last_assessment',
    key: 'date_of_last_assessment',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          {' '}
          Recovery time objective of the function - <span>0080</span>
        </p>
        <AdditionalInformation
          description="In number of hours. Where the recovery time objective is less than 1 hour, ‘1’ shall be reported. Where the recovery time objective of the function is not defined, ‘0’ shall be reported.
(natural number)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'recovery_time_objective',
    key: 'recovery_time_objective',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Recovery point objective of the function - <span>090</span>
        </p>
        <AdditionalInformation
          description="In number of hours. Where the recovery point objective is less than 1 hour, ‘1’ shall be reported. Where the recovery point objective of the function is not defined, ‘0’ shall be reported.
(natural number)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'recovery_point_objective',
    key: 'recovery_point_objective',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          {' '}
          Impact of discontinuing the function - <span>0100</span>
        </p>
        <AdditionalInformation
          description="Use this column to indicate the impact of discontinuing the function according to the financial entity’s assessment. One of the options in the following closed list shall be used:
1.	Low
2.	Medium;
3.	High;
4.	Assessment not performed.
(closed set of options)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'impact_of_discontinuing_function',
    key: 'impact_of_discontinuing_function',
  },
];

const B0601: React.FC<B0601Props> = ({ entityName }) => {
  const { date, is_displayed, headers } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData = await reportingOp.fetchRT0601(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          function_identifier: item['Function identifier'],
          licenced_identifier: item['Licenced activity'],
          function_name: item['Function name'],
          lei_of_financial_entity: item['LEI of the financial entity'],
          criticality_assessment: item['Criticality assessment'],
          reasons_for_criticality: item['Reasons for criticality'],
          date_of_last_assessment:
            item['Date of the last assessment of criticality'],
          recovery_time_objective:
            item['Recovery time objective of the function'],
          recovery_point_objective:
            item['Recovery point objective of the function'],
          impact_of_discontinuing_function:
            item['Impact of discontinuing the function'],
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  useEffect(() => {
    extractTableHeaders(memoizedColumns, dispatch);
  }, []);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0601;
