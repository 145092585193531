import React from 'react';
import * as Styled from './CustomTitle.styled';

interface ITitleProps {
  title: string;
  level?: 5 | 1 | 2 | 3 | 4;
}

const CustomTitle: React.FC<ITitleProps> = ({ title, level }) => (
  <Styled.StyledTitle level={level}>{title}</Styled.StyledTitle>
);

export default CustomTitle;
