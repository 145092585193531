import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import useFilters from 'utils/hooks/useFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import { RootState } from 'store/configureStore';
import { setSelectedTPP } from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import { INonSubstReason } from 'store/ducks/DataEntry/ThirdPartyProviders/types';
import * as Styled from '../../DataEntry.styled';

const NonSubstReason: React.FC = () => {
  const dispatch = useDispatch();

  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<INonSubstReason[]>([]);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const { selectedTPP } = useSelector((state: RootState) => state.tpp);
  const [selectedTPPID, setSelectedTPPID] = useState<string>('');
  const [tppName, setTppName] = useState<string>('');

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const getNonSubstReason = async () => {
    try {
      const response = await tppOp.fetchNonSubstReason(tppName, selectedTPPID);
      const data = response.non_subst_reason;
      const mappedData = data.map((non_subst_reason: any, index: number) => ({
        key: 0,
        id: non_subst_reason.id,
        name: non_subst_reason.name,
        description: non_subst_reason.description,
        isNew: false,
        isEdited: false,
        tpp_name: response.tpp_name,
      }));
      setDataSource(mappedData);
      setCount(mappedData.length);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const fetchTPPID = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;

      const dataTPP = data.map((item: any, index: number) => ({
        id: item.id,
        third_party_service_provider_name:
          item.third_party_service_provider_name,
      }));
      const matchedTPP = dataTPP.find(
        (item: INonSubstReason) =>
          item.id.toString() === selectedTPP.toString(),
      );
      if (matchedTPP) {
        setSelectedTPPID(matchedTPP.id);
        setTppName(matchedTPP.third_party_service_provider_name);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      if (selectedTPP === '') {
        fetchTPPID();
      } else if (selectedTPP) {
        dispatch(setSelectedTPP(selectedTPP));
      } else if (!selectedTPP) {
        dispatch(setSelectedTPP(''));
        setDataSource([]);
      }
    }
  }, [query.entity]);

  useEffect(() => {
    if (selectedTPP === '' && dataSource.length > 0 && query.entity) {
      setDataSource([]);
      setSelectedTPPID('');
    }
  }, [selectedTPP, dataSource, query.entity]);

  useEffect(() => {
    if (selectedTPP && query.entity) {
      fetchTPPID();
    }
  }, [selectedTPP, query.entity]);

  useEffect(() => {
    if (selectedTPPID && query.entity) {
      getNonSubstReason();
    }
  }, [selectedTPPID, query.entity]);

  const handleAddRow = (record?: any, index?: number) => {
    let uniqueIdCounter = 0;
    const generateUniqueId = (): number => {
      uniqueIdCounter += 1;
      const timestampPart = Date.now();
      const randomPart = Math.floor(Math.random() * 1000);

      const uniqueId = Number(
        `${timestampPart}${uniqueIdCounter}${randomPart}`,
      );
      return uniqueId;
    };

    const newData: INonSubstReason = {
      key: generateUniqueId(),
      id: generateUniqueId(),
      name: '',
      description: '',
      tpp_name: tppName,
      isNew: true,
    };

    const insertIndex = dataSource.findIndex(
      (item) => item.key === record?.key,
    );

    const updatedDataSource = [
      ...dataSource.slice(0, insertIndex + 1),
      newData,
      ...dataSource.slice(insertIndex + 1),
    ];

    setDataSource(updatedDataSource);
    setCount(count + 1);
  };

  const handleSave = (row: INonSubstReason) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const handleSubmit = async () => {
    const filteredData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );

    if (filteredData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited NonSubstReasons to submit.',
        placement: 'topRight',
      });
      return;
    }
    try {
      const response = await tppOp.insertNonSubstReason({
        tpp_name: tppName,
        entity_name: query.entity,
        data_list: filteredData,
      });
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: (
        <>
          Name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'name',
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: 'TPP name',
      dataIndex: 'tpp_name',
      editable: false,
      fixed: 'right',
    },
  ];

  return (
    dataSource && (
      <Styled.StyledWrapper hasEntity={!!query.entity}>
        <CustomTable
          columns={defaultColumns}
          dataSource={dataSource}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        {query.entity && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
        )}
      </Styled.StyledWrapper>
    )
  );
};

export default NonSubstReason;
