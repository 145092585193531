import React from 'react';
import StepComponent from 'components/StepComponent/CustomSteps';
import EntityType from 'components/Tables/DataEntry/Entity/EntityType/EntityType';
import HierarchyInGroup from 'components/Tables/DataEntry/Entity/HierarchyInGroup/HierarchyInGroup';
import NatureOfEntity from 'components/Tables/DataEntry/Entity/NatureOfEntity/NatureOfEntity';
import Entity from 'components/Tables/DataEntry/Entity/Entity/Entity';
import CompetentAuthority from 'components/Tables/DataEntry/Entity/CompetentAuthority/CompetentAuthority';
import CountryComponent from 'components/Tables/DataEntry/Entity/Country/Country';

const EntitySteps: React.FC = () => {
  const steps = [
    { title: 'Entity', content: <Entity /> },
    { title: 'Type of financial entity', content: <EntityType /> },
    {
      title: 'Hierarchy of the financial entity within the group',
      content: <HierarchyInGroup />,
    },
    { title: 'Nature Of Entity', content: <NatureOfEntity /> },
    { title: 'Competent authority', content: <CompetentAuthority /> },
    { title: 'Country of the financial entity', content: <CountryComponent /> },
  ];

  return (
    <div>
      <StepComponent steps={steps} title="Entity Steps" />
    </div>
  );
};

export default EntitySteps;
