import { notification } from 'antd';
import ApiService from 'utils/ApiCalls';

const fetchEntity = async (entityData: any, linkToken: string) => {
  try {
    const response = await ApiService.postEntity(entityData, linkToken);
    notification.success({
      message: response.status,
      description:
        'The entity has been successfully saved. You can now go to the filters section and select the updated or newly created entity.',
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchEntityNames = async () => {
  try {
    const response = await ApiService.getFilters();
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchEntityType = async (entityData: any) => {
  try {
    const response = await ApiService.postEntityType(entityData);
    notification.success({
      message: response.status,
      description: 'Entity Type - successfuly saved',
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchHierarchyGroup = async (hierarchyGroupData: any) => {
  try {
    const response = await ApiService.postHierarchyGroup(hierarchyGroupData);
    notification.success({
      message: response.status,
      description: 'Hierarchy Group - successfuly saved',
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const setAllEntities = async () => {
  try {
    const response = await ApiService.getAllEntities();
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchEntityTypeByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postEntityTypeByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Entity Type:', error);
  }
};

const fetchHierarchyGroupByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postHierarchyGroupByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Hierarchy Group:', error);
  }
};

const fetchNatureOfEntityByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postNatureOfEntityByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Nature of Entity:', error);
  }
};

const fetchCompetentAuthorityByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postCompetentAuthorityByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Competent Authority:', error);
  }
};

const fetchCountryByEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postCountryByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Country:', error);
  }
};

const fetchValueOfTotalAssetsByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postValueOfTotalAssetsByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Value of Total Assets:', error);
  }
};

const fetchActivitiesByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postActivitiesByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Activities:', error);
  }
};

const fetchEntitiesByEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postEntitiesByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Entities:', error);
  }
};

const fetchNatureOfEntity = async (natureOfEntity: any) => {
  try {
    const response = await ApiService.postNatureOfEntity(natureOfEntity);
    notification.success({
      message: response.status,
      description: 'Nature of Entity - successfuly saved',
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchCompetentAuthority = async (competentAuthority: any) => {
  try {
    const response =
      await ApiService.postCompetentAuthority(competentAuthority);
    notification.success({
      message: response.status,
      description: 'Competent authority - successfuly saved',
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchCountry = async (country: any) => {
  try {
    const response = await ApiService.postCountry(country);
    notification.success({
      message: response.status,
      description: 'Country - successfuly saved',
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchValueOfTotalAssets = async (valueOfTotalAssets: any) => {
  try {
    const response =
      await ApiService.postValueOfTotalAssets(valueOfTotalAssets);
    notification.success({
      message: response.status,
      description: 'Value of Total Assets - successfuly saved',
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchActivitiesByTypeEntity = async (activitiesByTypeEntity: any) => {
  try {
    const response = await ApiService.postActivitiesByTypeEntity(
      activitiesByTypeEntity,
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchEntityTypes = async () => {
  try {
    const response = await ApiService.getEntityTypes();
    return response;
  } catch (error) {
    console.error('Error fetching the entity types:', error);
  }
};

const fetchHierarchyInGroup = async () => {
  try {
    const response = await ApiService.getHierarchyInGroups();
    return response;
  } catch (error) {
    console.error('Error fetching the Hierarchy in groups api:', error);
  }
};

const fetchNatureID = async () => {
  try {
    const response = await ApiService.getNatureID();
    return response;
  } catch (error) {
    console.error('Error fetching the nature id api:', error);
  }
};

const fetchCompetentAuthorities = async () => {
  try {
    const response = await ApiService.getCompetentAuthorities();
    return response;
  } catch (error) {
    console.error('Error fetching the competent authorities api:', error);
  }
};

const fetchCurrencies = async () => {
  try {
    const response = await ApiService.getCurrencies();
    return response;
  } catch (error) {
    console.error('Error fetching currencies api:', error);
  }
};

const fetchCountries = async () => {
  try {
    const response = await ApiService.getCountries();
    return response;
  } catch (error) {
    console.error('Error fetching countries api:', error);
  }
};

const fetchBranches = async () => {
  try {
    const response = await ApiService.getListOfBranches();
    return response;
  } catch (error) {
    console.error('Error fetching branches api:', error);
  }
};

const inactivateEntityType = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateEntityType(dataList);
    notification.success({
      message: response.status,
      description: response.statusText,
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to inactivate entity type(s)');
  }
};

const inactivateEntity = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateEntity(dataList);

    const { errors, deleted_entities, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach((error: { entity_id: number; error: string }) => {
        notification.error({
          message: `Error for Entity ID: ${error.entity_id}`,
          description: error.error,
          duration: 10,
        });
      });
    } else if (deleted_entities && deleted_entities.length > 0) {
      notification.success({
        message: 'Entities Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate entity. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate entity');
  }
};
const inactivateLicencedActivity = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateLicencedActivity(dataList);

    const { errors, deleted_selection_licenced_acts, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach(
        (error: { selection_licenced_act_id: number; error: string }) => {
          notification.error({
            message: `Error for Licensed Activity ID: ${error.selection_licenced_act_id}`,
            description: error.error,
            duration: 10,
          });
        },
      );
    } else if (
      deleted_selection_licenced_acts &&
      deleted_selection_licenced_acts.length > 0
    ) {
      notification.success({
        message: 'Licensed Activity Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate Licensed Activity. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate Licensed Activity');
  }
};

const fetchAllFinancialEntities = async () => {
  try {
    const response = await ApiService.getFinancialEntities();
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchFinancialEntitiesById = async (
  financial_entity_id: number | null,
) => {
  try {
    const response =
      await ApiService.getFinancialEntityById(financial_entity_id);
    return response;
  } catch (error) {
    console.error('Error fetching the Financial Entities:', error);
  }
};

const fetchFinancialEntity = async (entityData: any) => {
  try {
    const response = await ApiService.postFinancialEntity(entityData);
    notification.success({
      message: response.status,
      description:
        'The financial entity has been successfully saved. You can now go to the filters section and select the updated or newly created entity.',
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export default {
  fetchEntity,
  fetchEntityType,
  fetchHierarchyGroup,
  setAllEntities,
  fetchEntityTypeByEntityName,
  fetchHierarchyGroupByEntityName,
  fetchNatureOfEntityByEntityName,
  fetchCompetentAuthorityByEntityName,
  fetchCountryByEntityName,
  fetchValueOfTotalAssetsByEntityName,
  fetchActivitiesByEntityName,
  fetchEntitiesByEntityName,
  fetchNatureOfEntity,
  fetchCompetentAuthority,
  fetchCountry,
  fetchValueOfTotalAssets,
  fetchActivitiesByTypeEntity,
  fetchEntityTypes,
  fetchHierarchyInGroup,
  fetchNatureID,
  fetchCompetentAuthorities,
  fetchCurrencies,
  fetchCountries,
  fetchEntityNames,
  fetchBranches,
  inactivateEntityType,
  inactivateEntity,
  inactivateLicencedActivity,
  fetchAllFinancialEntities,
  fetchFinancialEntitiesById,
  fetchFinancialEntity,
};
