import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialGlobalState from './initialState';

const functionSlice = createSlice({
  name: 'function',
  initialState: initialGlobalState(),
  reducers: {},
});

export const {} = functionSlice.actions;
export default functionSlice;
