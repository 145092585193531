import ApiService from 'utils/ApiCalls';
import { useSelector } from 'react-redux';

const useFetchICTServices = () => {
  const {
    selectedContractForICT,
    selectedEntityForICT,
    selectedFunctionForICT,
    selectedTPPForICT,
  } = useSelector((state: any) => state.ictServices);

  const fetchICTServices = async () => {
    try {
      const response = await ApiService.fetchICTServices(
        selectedContractForICT,
        selectedEntityForICT,
        selectedFunctionForICT,
        selectedTPPForICT,
      );
      return response;
    } catch (error) {
      console.error('Error fetching ICT Services:', error);
      throw error;
    }
  };

  return fetchICTServices;
};

const postICTServices = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postICTServices(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the ICT Services:', error);
  }
};

const fetchFunctionsbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postFunctionsbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Functions:', error);
  }
};

const fetchContractualArrangementsbyEntityName = async (
  entityTypeData: any,
) => {
  try {
    const response =
      await ApiService.postContractualArrangementsbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Contractual Arrangements:', error);
  }
};

export default {
  useFetchICTServices,
  fetchFunctionsbyEntityName,
  fetchContractualArrangementsbyEntityName,
  postICTServices,
};
