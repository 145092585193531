import { IICTServiceState } from './types';

const initialICTServiceState = (): IICTServiceState => ({
  loading: false,
  selectedContractForICT: null,
  selectedFunctionForICT: null,
  selectedTPPForICT: null,
  selectedEntityForICT: null,
});

export default initialICTServiceState;
