import React from 'react';
import { Layout } from 'antd';
import CardComponent from 'components/CardComponent';
import workingAreas from 'constants/WorkingAreas';
import TitleBar from 'components/TitleBar';
import * as Styled from './WorkingAreas.styled';
import { TitleList } from 'store/ducks/globalTypes';

const WorkingAreasPage = () => {
  const areas = workingAreas();

  return (
    <Layout>
      <TitleBar title={TitleList.WORKING_AREAS} />
      <Styled.Container>
        <Styled.GridContainer>
          {areas.map((item: any) => (
            <CardComponent
              key={item.area}
              area={item.area}
              infoText={item.infoText}
              imgSrc={item.imgSrc}
              href={item.href}
            />
          ))}
        </Styled.GridContainer>
      </Styled.Container>
    </Layout>
  );
};

export default WorkingAreasPage;
