import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Form, theme, Col } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { globalOp } from 'store/ducks/Global';
import AuthLayout from 'components/Layout/AuthLayout/AuthLayout';
import { logoSrc } from 'components/TitleBar/TitleBar.styled';
import * as Styled from './Login.styled';

const { useToken } = theme;

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (values: { username: string; password: string }) => {
    setLoading(true);
    const { username, password } = values;

    globalOp
      .login(username, password)
      .then((response) => {
        const token = response.token;
        localStorage.setItem('authToken', token);
        navigate('/working_area');
      })
      .catch((error) => {
        console.error(error);
        form.setFields([
          { name: 'username', errors: ['Invalid username or password'] },
          { name: 'password', errors: ['Invalid username or password'] },
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Styled.LoginContainer>
    <Styled.NavbarLogin>
      <Col span={8}>
        <img src={logoSrc} alt="logo" />
      </Col>
      <Col span={8}>
        <h1>ProgRisCom</h1>
        <p><span>Progress -</span> <span>Risk -</span> <span>Compliance</span></p>
      </Col>
  </Styled.NavbarLogin>
    <Styled.FormContainer>
      <Form form={form} name="login" onFinish={handleSubmit}>
        <Styled.ItemsContainer>
          <Styled.StyledFormItem
            token={token}
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              type="text"
            />
          </Styled.StyledFormItem>
          <Styled.StyledFormItem
            token={token}
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Styled.StyledFormItem>
        </Styled.ItemsContainer>

        <Styled.BottomSection>
          <Styled.StyledButton
            type="primary"
            loading={loading}
            htmlType="submit"
          >
            Sign In
          </Styled.StyledButton>

          <Styled.StyledLink to="/forgot_password">
            <span>Forgot your password?</span>
            <span>Click here</span>
          </Styled.StyledLink>
        </Styled.BottomSection>
      </Form>
    </Styled.FormContainer>
    </Styled.LoginContainer>
  );
};

export default LoginForm;
