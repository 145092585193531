import ApiService from 'utils/ApiCalls';
import axios from 'axios';

const fetchFunctionbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postFunctionByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Function:', error);
  }
};

const insertFunctions = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postFunctions(entityTypeData);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching Functions:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

export default { fetchFunctionbyEntityName, insertFunctions };
