import styled from 'styled-components';
import Background from 'assets/img/login_build.png';

export const StyledContainer = styled('div')`
  overflow-y: hidden;
  min-height: 100vh;
  background-image: ${() => `url(${Background})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const FormContainer = styled('div')`
  margin-left: 200px;
  margin-top: 60px;
  width: 315px;
  display: flex;
  flex-direction: column;
`;
