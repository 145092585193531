import { ThemeList } from 'store/ducks/globalTypes';

function ColorPalettes() {
  const colorPalettes = [
    { label: 'mainTheme', value: ThemeList.MAIN_THEME },
    {
      label: 'firstTheme',
      value: ThemeList.FIRST_THEME,
    },
    {
      label: 'secondTheme',
      value: ThemeList.SECOND_THEME,
    },
    {
      label: 'thirdTheme',
      value: ThemeList.THIRD_THEME,
    },
    {
      label: 'fourthTheme',
      value: ThemeList.FOURTH_THEME,
    },
    {
      label: 'fifthTheme',
      value: ThemeList.FIFTH_THEME,
    },
    {
      label: 'sixthTheme',
      value: ThemeList.SIXTH_THEME,
    },
    {
      label: 'seventhTheme',
      value: ThemeList.SEVENTH_THEME,
    },
    {
      label: 'eighthTheme',
      value: ThemeList.EIGHTH_THEME,
    },
  ];

  return colorPalettes;
}

export default ColorPalettes;
