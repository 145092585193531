import styled from 'styled-components';
import { Typography } from 'antd';

const { Title } = Typography;

export const StyledTitle = styled(Title)`
  text-align: center;
  line-height: 2 !important;
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  margin: 0;
  letter-spacing: 1.5px;
`;
