import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ISelectedValueContract,
  ISelectedValue,
  ISelectedEntityForICT,
} from './types';
import initialICTServiceState from './initialState';

const ictServiceSlice = createSlice({
  name: 'ict_service',
  initialState: initialICTServiceState(),
  reducers: {
    setSelectedContract_ICT: (
      state,
      action: PayloadAction<ISelectedValueContract | null>,
    ) => {
      state.selectedContractForICT = action.payload;
    },
    setSelectedFunction_ICT: (
      state,
      action: PayloadAction<ISelectedValue | null>,
    ) => {
      state.selectedFunctionForICT = action.payload;
    },
    setSelectedTPP_ICT: (
      state,
      action: PayloadAction<ISelectedValue | null>,
    ) => {
      state.selectedTPPForICT = action.payload;
    },
    setSelecedEntity_ICT: (
      state,
      action: PayloadAction<ISelectedEntityForICT | null>,
    ) => {
      state.selectedEntityForICT = action.payload;
    },
  },
});

export const {
  setSelectedContract_ICT,
  setSelectedFunction_ICT,
  setSelectedTPP_ICT,
  setSelecedEntity_ICT,
} = ictServiceSlice.actions;

export default ictServiceSlice;
