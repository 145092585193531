import React, { useEffect, useState } from 'react';
import { Button, Space, Table } from 'antd';
import type { TableProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import { reportingOp } from 'store/ducks/Reportings';
import { DownloadOutlined } from '@ant-design/icons';
import { columnData } from 'constants/report99Data';
import { parse } from 'json2csv';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

interface RecordType {
  id: number;
  option: string;
  dataTxt: string;
  dataDesc: string;
  dataPoint: string;
  rowSpan?: number;
}

interface B9901Props {
  entityName: string | null | undefined;
}

const getData = (length: number, dataReports: any) => {
  const columnRowCounts: Record<string, number> = {
    'Type of contractual arrangements': 5,
    'Impact of discontinuing the function': 6,
    'Impact of discontinuing the ICT services': 6,
    'Possibility of reintegration of the contracted ICT service': 3,
    'Sensitiveness of the data stored by the ICT third-party service provider': 7,
    'Substitutability of the ICT third-party service provider': 5,
  };

  const columnNames = Object.keys(columnRowCounts);
  const rows: RecordType[] = [];
  let index = 0;

  columnNames.forEach((columnName) => {
    const rowCount = columnRowCounts[columnName];
    let dataKey: keyof typeof dataReports;

    switch (columnName) {
      case 'Type of contractual arrangements':
        dataKey = 'type_of_contractual_arrangement';
        break;
      case 'Impact of discontinuing the function':
        dataKey = 'impact_of_discontinuing_function';
        break;
      case 'Impact of discontinuing the ICT services':
        dataKey = 'impact_of_discontinuing_ict_services';
        break;
      case 'Possibility of reintegration of the contracted ICT service':
        dataKey = 'possibility_of_reintegration';
        break;
      case 'Sensitiveness of the data stored by the ICT third-party service provider':
        dataKey = 'sensitiveness_of_data_stored_by_ict_tpsp';
        break;
      case 'Substitutability of the ICT third-party service provider':
        dataKey = 'substitutability_of_ict_provider';
        break;
      default:
        return;
    }

    const data = dataReports[dataKey];

    if (!data || data.length < rowCount) {
      return;
    }

    let previousCode = '';
    let rowspan = 1;

    for (let i = 0; i < rowCount; i++) {
      const column = columnData[index % columnData.length];
      const currentCode = column.code;

      if (currentCode === previousCode) {
        rowspan = 0;
      } else {
        rowspan = rowCount;
      }

      rows.push({
        id: index++,
        option: '1',
        dataTxt: data[i]?.int_options_def ?? '',
        dataDesc: data[i]?.description ?? '',
        dataPoint: data[i]?.data_point ?? '',
        rowSpan: rowspan,
      });

      previousCode = currentCode;
    }
  });

  return rows;
};
const fixedColumns: TableProps<RecordType>['columns'] = [
  {
    title: '',
    children: [
      {
        title: '',
        dataIndex: 'data_point',
        render: (_, record) => {
          if (!record || !record.dataPoint) return '';
          return record.dataPoint;
        },
      },
    ],
  },
  {
    title: 'B_99.01.C0010',
    children: [
      {
        title: 'Column Code',
        width: 150,
        render: (_, record) => {
          const column = columnData[record.id] || {};
          return column.code || '';
        },
        onCell: (record) => {
          if (!record) return {};
          return {
            rowSpan: record.rowSpan,
          };
        },
      },
    ],
  },
  {
    title: 'B_99.01.C0020',
    children: [
      {
        title: 'Column Name',
        width: 150,
        render: (_, record) => {
          const column = columnData[record.id] || {};
          return column.name || '';
        },
        onCell: (record) => {
          if (!record) return {};
          return {
            rowSpan: record.rowSpan,
          };
        },
      },
    ],
  },
  {
    title: 'B_99.01.C0030',
    children: [
      {
        title: 'Options',
        dataIndex: 'int_options_def',
        render: (_, record) => {
          if (!record || !record.dataDesc) return '';
          return record.dataDesc;
        },
      },
    ],
  },
  {
    title: 'B_99.01.C0040',
    children: [
      {
        title: 'Description/Internal definition of the option',
        dataIndex: 'int_options_def',
        render: (_, record) => {
          if (!record || !record.dataTxt) return '';
          return record.dataTxt;
        },
      },
    ],
  },
];

const B9901: React.FC<B9901Props> = ({ entityName }) => {
  const [fixed, setFixed] = React.useState(true);
  const [bordered, setBordered] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [empty, setEmpty] = React.useState(false);
  const [count, setCount] = React.useState(10000);

  const tblRef: Parameters<typeof Table>[0]['ref'] = React.useRef(null);

  const { date, is_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [dataReports, setDataReports] = useState<any>({});
  const dispatch = useDispatch();

  const exportToExcel = () => {
    const data = {
      'B_99.01': {
        columnPoints: [
          ...dataReports.type_of_contractual_arrangement.map(
            (item: { data_point: any }) => item.data_point,
          ),
          ...dataReports.impact_of_discontinuing_function.map(
            (item: { data_point: any }) => item.data_point,
          ),
          ...dataReports.impact_of_discontinuing_ict_services.map(
            (item: { data_point: any }) => item.data_point,
          ),
          ...dataReports.possibility_of_reintegration.map(
            (item: { data_point: any }) => item.data_point,
          ),
          ...dataReports.sensitiveness_of_data_stored_by_ict_tpsp.map(
            (item: { data_point: any }) => item.data_point,
          ),
          ...dataReports.substitutability_of_ict_provider.map(
            (item: { data_point: any }) => item.data_point,
          ),
        ],
        columnCode: columnData.map((item) => item.code),
        columnName: columnData.map((item) => item.name),
        options: [
          ...dataReports.type_of_contractual_arrangement.map(
            (item: { description: any }) => item.description,
          ),
          ...dataReports.impact_of_discontinuing_function.map(
            (item: { description: any }) => item.description,
          ),
          ...dataReports.impact_of_discontinuing_ict_services.map(
            (item: { description: any }) => item.description,
          ),
          ...dataReports.possibility_of_reintegration.map(
            (item: { description: any }) => item.description,
          ),
          ...dataReports.sensitiveness_of_data_stored_by_ict_tpsp.map(
            (item: { description: any }) => item.description,
          ),
          ...dataReports.substitutability_of_ict_provider.map(
            (item: { description: any }) => item.description,
          ),
        ],
        description: [
          ...dataReports.type_of_contractual_arrangement.map(
            (item: { int_options_def: any }) => item.int_options_def,
          ),
          ...dataReports.impact_of_discontinuing_function.map(
            (item: { int_options_def: any }) => item.int_options_def,
          ),
          ...dataReports.impact_of_discontinuing_ict_services.map(
            (item: { int_options_def: any }) => item.int_options_def,
          ),
          ...dataReports.possibility_of_reintegration.map(
            (item: { int_options_def: any }) => item.int_options_def,
          ),
          ...dataReports.sensitiveness_of_data_stored_by_ict_tpsp.map(
            (item: { int_options_def: any }) => item.int_options_def,
          ),
          ...dataReports.substitutability_of_ict_provider.map(
            (item: { int_options_def: any }) => item.int_options_def,
          ),
        ],
      },
    };

    type Data = typeof data;
    const headers = [
      '',
      'B_99.01.C0010',
      'B_99.01.C0020',
      'B_99.01.C0030',
      'B_99.01.C0040',
    ];
    const subHeaders = [
      '',
      'Column Code',
      'Column Name',
      'Options',
      'Description/Internal definition of the option',
    ];
    const rowData: string[][] = [];

    const mergeSizes = [5, 6, 6, 3, 7, 5];
    const startRow = 2;

    let rowIndex = 0;
    for (let i = 0; i < mergeSizes.length; i++) {
      const size = mergeSizes[i];

      for (let j = 0; j < size; j++) {
        const row: string[] = [];

        row.push(data['B_99.01']?.columnPoints[rowIndex] || '');

        if (j === 0) {
          row.push(data['B_99.01']?.columnCode[i] || '');
          row.push(data['B_99.01']?.columnName[i] || '');
        } else {
          row.push('');
          row.push('');
        }

        row.push(data['B_99.01']?.options[rowIndex] || '');
        row.push(data['B_99.01']?.description[rowIndex] || '');
        rowData.push(row);

        rowIndex++;
      }
    }

    const sheetData = [headers, subHeaders, ...rowData];
    const ws = XLSX.utils.aoa_to_sheet(sheetData);

    const merges: XLSX.Range[] = [];
    let currentRow = 2;

    mergeSizes.forEach((size, index) => {
      if (size > 1) {
        merges.push({
          s: { r: currentRow, c: 1 },
          e: { r: currentRow + size - 1, c: 1 },
        });

        merges.push({
          s: { r: currentRow, c: 2 },
          e: { r: currentRow + size - 1, c: 2 },
        });
      }
      currentRow += size;
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'B_99.01');
    ws['!merges'] = merges;

    XLSX.writeFile(wb, 'table_data.xlsx');
    console.log('Export triggered!');
  };

  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    if (!entityName) {
      setDataReports({});
      return;
    }
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData = await reportingOp.fetchB9901(params);
      setDataReports(fetchedData);
      console.log(dataReports);
    } catch (error) {
      console.error('Error fetching data:', error);
      setDataReports({});
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  const data = React.useMemo<RecordType[]>(
    () => getData(count, dataReports),
    [count, dataReports],
  );

  const mergedColumns = React.useMemo<typeof fixedColumns>(() => {
    if (!fixed) {
      return fixedColumns;
    }

    if (!expanded) {
      return fixedColumns;
    }

    return fixedColumns.map((col) => ({ ...col, onCell: undefined }));
  }, [expanded, fixed]);

  const expandableProps = React.useMemo<
    TableProps<RecordType>['expandable']
  >(() => {
    if (!expanded) {
      return undefined;
    }

    return {
      columnWidth: 48,
      expandedRowRender: (record) => {
        if (!record) return null;
        return <p style={{ margin: 0 }}>🎉 Expanded {record.option}</p>;
      },
      rowExpandable: (record) => record.id % 2 === 0,
    };
  }, [expanded]);

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%', textAlign: 'end' }}>
        <Table<RecordType>
          id="your-table-id"
          bordered={bordered}
          columns={mergedColumns}
          rowKey="id"
          dataSource={empty ? [] : data}
          pagination={false}
          ref={tblRef}
          expandable={expandableProps}
          scroll={{ x: 1000, y: 430 }}
        />
        {entityName && (
          <Button key="submit" type="primary" onClick={exportToExcel} disabled>
            <DownloadOutlined />
            Download
          </Button>
        )}
      </Space>
    </div>
  );
};

export default B9901;
