import { jwtDecode } from 'jwt-decode';
import { ISelectedOptions } from 'store/ducks/Global/types';
import { filterNames } from 'constants/filterNames';

const Helpers = {
  isObjEmpty: (obj: any) => Object.keys(obj).length === 0,

  getToken: () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken;
      } catch (e) {
        console.error('Failed to decode token:', e);
        return null;
      }
    }
    return null;
  },
  removeToken: () => localStorage.removeItem('authToken'),

  isAuthenticated: () => !!Helpers.getToken(),
};

export default Helpers;
