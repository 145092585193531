import React, { useState, useEffect } from 'react';
import i18nIsoCountries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { useDispatch, useSelector } from 'react-redux';
import { notification, Table, Input, Button, Modal } from 'antd';
import {
  PlusOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { contractual_informationOp } from 'store/ducks/DataEntry/ContractualInformation';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import {
  IContractulInformation,
  ICosts,
  EditedData,
} from 'store/ducks/DataEntry/ContractualInformation/types';
import useCountries from 'utils/hooks/fetchHooks/useCountries';
import useFilters from 'utils/hooks/useFilters';
import DynamicField from 'components/DynamicCalendarField/DynamicField';
import CustomSelect from 'components/CustomSelect';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { generateUniqueId } from 'utils/hooks/generateUniqueId';
import { setSelecedEntity_Contractual_Information } from 'store/ducks/DataEntry/ContractualInformation/contractualInformationSlice';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import * as Styled from '../../DataEntry.styled';

const ContractualInformation: React.FC = () => {
  const [data, setData] = useState<IContractulInformation[]>([]);
  const [editedData, setEditedData] = useState<EditedData>({});
  const [changedData, setChangedData] = useState<Record<string, any>[]>([]);
  const [terminatReasons, setTerminatReasons] = useState([]);
  const [contractualArrangementTypes, setContractualArrangementTypes] =
    useState([]);
  const { countries } = useCountries();
  const { confirm } = Modal;
  const [isTooltipVisible, setIsTooltipVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const { query } = useFilters();
  const [currencies, setCurrencies] = useState<
    { label: string; value: string }[]
  >([]);

  const intraGroup = [
    { label: 'yes', value: true },
    { label: 'no', value: false },
  ];

  const {
    selectedBranchForContractualInformation,
    selectedICTForContractualInformation,
    selectedTPPForContractualInformation,
    selectedEntityForContractualInformation,
  } = useSelector((state: any) => state.contractualInformation);
  const isLoggedIn = localStorage.getItem('authToken');
  i18nIsoCountries.registerLocale(enLocale);
  const dispatch = useDispatch();

  const fetchTerminatReasons = async () => {
    try {
      const response = await contractual_informationOp.fetchTerminatReasons();
      if (response?.data.terminat_reasons) {
        const formattedOptions = response.data.terminat_reasons.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setTerminatReasons(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContractualArrangementTypes = async () => {
    try {
      const response =
        await contractual_informationOp.fetchContractualArrangementTypes();
      if (response?.data.contractual_arrangement_types) {
        const formattedOptions =
          response.data.contractual_arrangement_types.map(
            (reasons: { name: any; id: any }) => ({
              label: reasons.name,
              value: reasons.id,
            }),
          );
        setContractualArrangementTypes(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContractualInformation =
    contractual_informationOp.useFetchContractualInformation();

  useEffect(() => {
    if (!selectedEntityForContractualInformation) {
      const selectedEntity = {
        entityId: query.entityId ? Number(query.entityId) : 0,
        entity: query.entity ? String(query.entity) : '',
      };
      dispatch(setSelecedEntity_Contractual_Information(selectedEntity));
    }
  }, [query]);

  const fetchContracts = async () => {
    try {
      const response = await fetchContractualInformation();
      const data = response?.data.arrangements;
      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        contract_name: item.contract_name,
        contract_description: item.contract_description,
        terminatReason_id: item.terminat_reason.id,
        terminatReason_name: item.terminat_reason.name,
        terminatReason_description: item.terminat_reason.description,
        typeofContractualArrangement_id: item.contract_type_arrangement.id,
        typeofContractualArrangement_name: item.contract_type_arrangement.name,
        typeofContractualArrangement_description:
          item.contract_type_arrangement.description,
        costs: item.costs
          ? item.costs.map((cost: any) => ({
              costs_id: cost.id,
              costs_currency_amount_reported: cost.currency_amount_reported,
              costs_annual_expense: cost.annual_expense,
              costs_total_annual_expense: cost.total_annual_expense,
              costs_description: cost.description,
              costs_currency_id: cost?.currency?.id,
              costs_contractual_arrangement_id: cost.contractual_arrangement_id,
              costs_start_date: cost.start_date,
              costs_end_date: cost.end_date,
              currency_name: cost?.currency?.currency_name,
              currency_description: cost?.currency?.description,
              currency_id: cost?.currency?.id,
            }))
          : [],
        country_id: item.governing_law_country.id,
        country_country_name: item.governing_law_country.country_name,
        country_iso_code: item.governing_law_country.iso_code,
        country_description: item.governing_law_country.description,
        contractualArrangement_id: item.id,
        contractualArrangement_arrangement_reference_number:
          item.arrangement_reference_number,
        contractualArrangement_start_date: item.start_date,
        contractualArrangement_end_date: item.end_date,
        contractualArrangement_terminat_reason_id: item.terminat_reason.id,
        contractualArrangement_contract_type_id: item.contract_type?.id,
        contractualArrangement_intra_group: item.intra_group,
        contractualArrangement_contract_type_arrangement_id:
          item.contract_type_arrangement.id,
        contractualArrangement_overarching_reference_number:
          item.overarching_reference_number,
        contractualArrangement_linked_contractual_arrangement:
          item.linked_contractual_arrangement,
        contractualArrangement_description: item.description,
        contractualArrangement_notice_period: item.notice_period,
        contractualArrangement_governing_law_country_id:
          item.governing_law_country.id,
        contractualArrangement_entity_id: item.entity.id,
        contractualArrangement_third_party_service_provider_id:
          item.third_party_service_provider.id,
      }));
      setData(mappedData);
    } catch (error) {
      console.log(error, 'error');
      setData([]);
    }
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = dataWithKey.filter(
          (item) => item.key !== key,
        );
        fetchContracts();
        setData(updatedDataSource);
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: IContractulInformation) => {
    if (record.contractualArrangement_id === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The Contractual Arrangement ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the Contractual Arrangement with ID ${record.contractualArrangement_id}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response =
            await contractual_informationOp.inactivateContractualArrangement([
              record.contractualArrangement_id,
            ]);
          fetchContracts();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };

  const fetchCurrencies = async () => {
    try {
      const response = await entityOp.fetchCurrencies();
      if (response?.data.currencies) {
        const formattedOptions = response.data.currencies.map(
          (reasons: { currency_name: any; id: any }) => ({
            label: reasons.currency_name,
            value: reasons.id,
          }),
        );
        setCurrencies(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const shouldFetch = selectedEntityForContractualInformation && selectedTPPForContractualInformation;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchContractualInformation();
        // TODO
        await Promise.all([
          fetchContracts(),
          fetchTerminatReasons(),
          fetchCurrencies(),
          fetchContractualArrangementTypes(),
        ]);
      } catch (error) {
        console.error('Error during data fetching:', error);
      }
    };
    if (shouldFetch) {
      fetchData();
    }
  }, [
    selectedEntityForContractualInformation,
    selectedTPPForContractualInformation,
    query,
  ]);

  const addNewCost = (rowKey: string | number) => {
    const newCost: ICosts = {
      costs_id: generateUniqueId(),
      costs_currency_amount_reported: '',
      costs_annual_expense: '',
      costs_total_annual_expense: '',
      costs_description: '',
      costs_currency_id: '',
      costs_contractual_arrangement_id: rowKey,
      costs_third_party_service_provider_id:
        selectedTPPForContractualInformation?.value,
      costs_start_date: '',
      costs_end_date: '9999-01-01',
      currency_name: '',
      currency_description: '',
      currency_id: '',
    };

    setData((prevData) =>
      prevData.map((record) => {
        if (record.contractualArrangement_id === rowKey) {
          return {
            ...record,
            costs: [...record.costs, { ...newCost }],
          };
        }
        return record;
      }),
    );
  };

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.contractualArrangement_id,
  }));

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  const addNewRow = async () => {
    const terminatReasons = await fetchTerminatReasons();
    const currencies = await fetchCurrencies();
    const arrangementTypes = await fetchContractualArrangementTypes();

    const contractualArrangement_id = generateUniqueId();
    const newRowTemplate: IContractulInformation = {
      key: generateUniqueId(),
      isNew: true,
      contract_name: '',
      contract_description: '',
      terminatReason_id: '',
      terminatReason_name: '',
      terminatReason_description: '',
      typeofContractualArrangement_id: '',
      typeofContractualArrangement_name: '',
      typeofContractualArrangement_description: '',
      country_id: '',
      country_country_name: '',
      country_iso_code: '',
      country_description: '',
      contractualArrangement_id: contractualArrangement_id,
      contractualArrangement_arrangement_reference_number: '',
      contractualArrangement_start_date: '',
      contractualArrangement_end_date: '9999-01-01',
      contractualArrangement_terminat_reason_id: '',
      contractualArrangement_contract_type_id: '',
      contractualArrangement_contract_type_arrangement_id: '',
      contractualArrangement_overarching_reference_number: '',
      contractualArrangement_linked_contractual_arrangement: '',
      contractualArrangement_description: '',
      contractualArrangement_notice_period: 0,
      contractualArrangement_governing_law_country_id: '',
      contractualArrangement_entity_id: query.entityId,
      contractualArrangement_third_party_service_provider_id:
        selectedTPPForContractualInformation?.value,
      contractualArrangement_intra_group: false,
      costs: [
        {
          costs_id: generateUniqueId(),
          costs_currency_amount_reported: '',
          costs_annual_expense: '',
          costs_total_annual_expense: '',
          costs_description: '',
          costs_currency_id: '',
          costs_contractual_arrangement_id: contractualArrangement_id,
          costs_third_party_service_provider_id:
            selectedTPPForContractualInformation?.value,
          costs_start_date: '',
          costs_end_date: '9999-01-01',
          currency_name: '',
          currency_description: '',
          currency_id: '',
        },
      ],
    };

    const newRow = structuredClone(newRowTemplate);

    setData((prevData) => [...prevData, newRow]);
    setChangedData((prevChangedData) => [...prevChangedData, newRow]);
  };
  const handleOpenTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: true }));
  };

  const handleCloseTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: false }));
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    rowKey: string,
  ) => {
    const { value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    const updatedRow = data.find(
      (row) => row.contractualArrangement_id.toString() == rowKey,
    );

    if (updatedRow) {
      const updatedRowWithChanges: Record<string, any> = {
        ...updatedRow,
        [fieldName]: value,
      };
      if (fieldName === 'costs_id' || 'contract_id') {
        if (updatedRow.costs) {
          const match = fieldName.match(/^(\d+)_c_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];
            const targetIndex = updatedRow.costs.findIndex(
              (cost) => cost.costs_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.costs[targetIndex] = {
                ...updatedRow.costs[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching cost found for idFromFieldName (${idFromFieldName}) in cost`,
              );
            }
          }
        }
      }

      const cleanedUpRow = Object.keys(updatedRowWithChanges).reduce(
        (acc: Record<string, any>, key) => {
          const newKey = key.replace(/^[^a-zA-Z]+/, '');
          acc[newKey] = updatedRowWithChanges[key];
          return acc;
        },
        {},
      );

      setChangedData((prevChangedData) => {
        const newChangedData = [...prevChangedData];
        const existingRowIndex = newChangedData.findIndex(
          (row) =>
            row.contractualArrangement_id ===
            cleanedUpRow.contractualArrangement_id,
        );

        if (existingRowIndex !== -1) {
          const existingRow = newChangedData[existingRowIndex];
          const mergedRow = { ...existingRow, ...cleanedUpRow };
          if (JSON.stringify(existingRow) !== JSON.stringify(mergedRow)) {
            newChangedData[existingRowIndex] = mergedRow;
          }
        } else {
          newChangedData.push(cleanedUpRow);
        }

        return newChangedData;
      });
    } else {
      console.log('No match found for contractualArrangement_id:', rowKey);
    }
  };
  const updateChangedData = (editedData: any, changedData: any) => {
    if (editedData && typeof editedData === 'object') {
      Object.keys(editedData).forEach((key: string) => {
        const keyFromEditedData = parseInt(key.split('_')[0]);
        const matchingChangedRow = changedData.find((dataItem: any) => {
          return dataItem.contractualArrangement_id === keyFromEditedData;
        });

        if (matchingChangedRow) {
          const fieldName = key.replace(/^\d+_/, '');
          if (matchingChangedRow.hasOwnProperty(fieldName)) {
            matchingChangedRow[fieldName] = editedData[key];
          }
        }
      });
    } else {
      console.error('editedData is not an object:', editedData);
    }

    setChangedData([...changedData]);
  };

  useEffect(() => {
    updateChangedData(editedData, changedData);
  }, [editedData]);

  const handleSubmit = async () => {
    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description:
          'There are no new or edited Contractual Informations to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    const formattedData = {
      tpp_name: selectedTPPForContractualInformation?.label,
      branch_name: selectedBranchForContractualInformation?.label,
      ict_service_id: selectedICTForContractualInformation?.value,
      entity_name: query.entity,
      contractual_arrangement: changedData.map((item) => {
        return {
          id: item?.contractualArrangement_id,
          arrangement_reference_number:
            item?.contractualArrangement_arrangement_reference_number,
          start_date: item?.contractualArrangement_start_date,
          end_date: item?.contractualArrangement_end_date,
          terminat_reason_id: item?.contractualArrangement_terminat_reason_id,
          // contract_type_id: item?.contractualArrangement_contract_type_id,
          contract_type_arrangement_id:
            item?.contractualArrangement_contract_type_arrangement_id,
          overarching_reference_number:
            item?.contractualArrangement_overarching_reference_number,
          linked_contractual_arrangement:
            item?.contractualArrangement_linked_contractual_arrangement,
          description: item?.contractualArrangement_description,
          notice_period: Number(item?.contractualArrangement_notice_period),
          governing_law_country_id:
            item?.contractualArrangement_governing_law_country_id,
          entity_id: query.entityId,
          third_party_service_provider_id: selectedTPPForContractualInformation
            ? selectedTPPForContractualInformation.value
            : item?.contractualArrangement_third_party_service_provider_id,
          intra_group: item?.contractualArrangement_intra_group,
          contract_name: item?.contract_name,
          contract_description: item?.contract_description,
        };
      }),

      costs: changedData.flatMap(
        (item) =>
          item.costs?.map((cost: ICosts) => ({
            id: cost.costs_id,
            currency_amount_reported: cost.costs_currency_amount_reported,
            annual_expense: Number(cost.costs_annual_expense),
            description: cost.costs_description,
            currency_id: cost.costs_currency_id,
            contractual_arrangement_id: item.contractualArrangement_id,
            start_date: cost.costs_start_date,
            end_date: cost.costs_end_date,
          })) || [],
      ),
      token: isLoggedIn,
    };
    try {
      const response =
        await contractual_informationOp.postContractualInformation(
          formattedData,
        );
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns: EditableColumnType<IContractulInformation>[] = [
    {
      title: (
        <Styled.TitleComponent>
          ICT third-party service provider
          <Styled.Asterisx>*</Styled.Asterisx>
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_third_party_service_provider_id',
      editable: true,
      fixed: 'left',
      render: () => (
        <Input value={selectedTPPForContractualInformation?.label} disabled />
      ),
    },
    {
      title: <Styled.TitleComponent>Contract name</Styled.TitleComponent>,
      dataIndex: 'contract_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contract_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contract_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>Contract description</Styled.TitleComponent>
      ),
      dataIndex: 'contract_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contract_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contract_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Contractual arrangement reference number</p>
            <p>
              B_02.01.0010, B_02.03.0010, B_03.01.0010, B_03.02.0010,
              B_03.03.0100, B_04.01.0010
            </p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the contractual arrangement between the financial entity or, in case of a group, the group subsidiary and the direct ICT third-party service provider.
The contractual arrangement reference number is the internal reference number of the contractual arrangement assigned by the financial entity.
The contractual arrangement reference number shall be unique and consistent over time at entity, sub-consolidated and consolidated level, where applicable.
The contractual arrangement reference number shall be used consistently across all templates of the register of information when referring to the same contractual arrangement.
For the case where an entity is acting on behalf of a financial entity for all the activities of the financial entity including the ICT services (refer to recital 7) the contractual arrangement reference number can be the contractual arrangement between the entity and its direct ICT third-party service provider.
(alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Führen Sie die vertragliche Vereinbarung zwischen dem 
            Finanzunternehmen oder – im Falle einer Gruppe – dem 
            Tochterunternehmen der Gruppe und dem direkten 
            IKT-Drittdienstleister auf.
            Die Kennnummer der vertraglichen Vereinbarung muss auf 
            Unternehmensebene bzw. auf teilkonsolidierter und auf 
            konsolidierter Ebene eindeutig sein und ist im Zeitverlauf 
            unverändert beizubehalten.
            Die Kennnummer der vertraglichen Vereinbarung ist in allen 
            Vorlagen des Informationsregisters einheitlich zu verwenden, 
            wenn auf dieselbe vertragliche Vereinbarung Bezug 
            genommen wird.
            Wenn ein Unternehmen für alle Tätigkeiten (einschließlich 
            der IKT-Dienstleistungen) im Namen eines 
            Finanzunternehmens handelt (siehe Erwägungsgrund 7), 
            kann die Kennnummer der vertraglichen Vereinbarung die 
            vertragliche Vereinbarung zwischen dem Unternehmen und 
            seinem direkten IKT-Drittdienstleister sein."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_arrangement_reference_number',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_arrangement_reference_number`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_arrangement_reference_number
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Start date of the contractual arrangement</p>
            <p>B_02.02.0070</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the date of entry into force of the contractual arrangement as stipulated in the contractual arrangement using the ISO 8601 (yyyy–mm–dd) code
(date)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie unter
            Verwendung des ISO 8601-Codes (JJJJ-MM-TT) das Datum des
            Inkrafttretens der vertraglichen
            Vereinbarung an, das in der vertraglichen
            Vereinbarung
            festgelegt ist."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_start_date',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_start_date`;
        const rowKey = record.key;
        return (
          <DynamicField
            value={
              editedData.hasOwnProperty(fieldName)
                ? String(editedData[fieldName] ?? '')
                : String(record.contractualArrangement_start_date ?? '')
            }
            fieldName={fieldName}
            rowKey={record.key}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEditChange(e, fieldName, record.key)
            }
            type="date"
            useDefaultDate={false}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>End date of the contractual arrangement</p>
            <p>B_02.02.0080</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the end date as stipulated in the contractual arrangement using the ISO 8601 (yyyy–mm–dd) code. Where the contractual arrangement is indefinite, it shall be filled in with ‘9999-12-31’. Where the contractual arrangement has been terminated on a date different than the end date, this shall be filled in with the termination date.
Where the contractual arrangement foresees a renewal, this shall be filled in with the date of the contract renewal as stipulated in the contractual arrangement.
(date)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie unter
            Verwendung des ISO 8601-Codes (JJJJ-MM-TT) das in der vertraglichen
            Vereinbarung festgelegte Enddatum an. Ist die vertragliche
            Vereinbarung unbefristet, ist
            an dieser Stelle „9999-12-31“ anzugeben.
            Wurde die vertragliche Vereinbarung zu
            einem anderen Zeitpunkt als dem Enddatum gekündigt, so ist in diesem Fall das
            Kündigungsdatum anzugeben.
            Ist in der vertraglichen
            Vereinbarung eine
            Verlängerung vorgesehen, so ist diese mit
            dem in der vertraglichen
            Vereinbarung festgelegten Datum der
            Vertragsverlängerung
            anzugeben."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_end_date',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_end_date`;
        const rowKey = record.key;
        return (
          <DynamicField
            value={
              editedData.hasOwnProperty(fieldName)
                ? String(editedData[fieldName] ?? '9999-01-01')
                : String(record.contractualArrangement_end_date ?? '9999-01-01')
            }
            fieldName={fieldName}
            rowKey={record.key}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEditChange(e, fieldName, record.key)
            }
            type="date"
            useDefaultDate={true}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Overarching contractual arrangement reference number</p>
            <p>B_02.01.0030</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Not applicable if the contractual arrangement is the ‘overarching contractual arrangement’ or a ‘standalone arrangement’. In the other cases, report the contractual arrangement reference number of the overarching arrangement, which shall be equal to the value as reported in column B_02.01.0010 when reporting the overarching contractual arrangement.
(alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Entfällt, wenn es sich bei der vertraglichen
            Vereinbarung um
            die „übergeordnete vertragliche
            Vereinbarung“ oder um eine
            „eigenständige Vereinbarung“ handelt. In den anderen Fällen
            ist die Kennnummer der übergeordneten vertraglichen
            Vereinbarung anzugeben. Diese muss dem
            Wert entsprechen,
            der in Spalte B_02.01.0010 bei der Meldung der
            übergeordneten vertraglichen
            Vereinbarung angegeben
            wurde."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_overarching_reference_number',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_overarching_reference_number`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_overarching_reference_number
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Contractual arrangement linked to the contractual arrangement referred
          in B_02.03.0010
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Contractual arrangement reference number of the contractual arrangement between the ICT intra-group service provider of the contractual arrangement in B_02.03.0010 and its direct ICT third-party service provider.
(alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Kennnummer der vertraglichen
            Vereinbarung zwischen
            dem
            Unternehmen, das die bereitgestellten
            IK
            T-Dienstleistung(en) in Anspruch nimmt, und dem
            gruppeninternen IK
            T-Dienstleister.
            Die Kennnummer der vertraglichen
            Vereinbarung muss
            eindeutig sein und ist im Zeitverlauf und über die gesamte
            Gruppe hinweg unverändert beizubehalten."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_linked_contractual_arrangement',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_linked_contractual_arrangement`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_linked_contractual_arrangement
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'contractualArrangement_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Notice period for the financial entity making use of the ICT
              service(s)
            </p>
            <p>B_02.02.0100</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the notice period for terminating the contractual arrangement by the financial entity in a business-as-usual case. The notice period shall be expressed as number of calendar days from the counterparty’s receipt of the request to terminate the ICT service.
(natural number)
(mandatory if the ICT service is supporting a critical or important function)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie die Kündigungsfrist für die Kündigung der vertraglichen
            Vereinbarung durch
            das Finanzunternehmen in einem Szenario mit unveränderten
            Rahmenbedingungen
            an. Die Kündigungsfrist wird als Anzahl von Kalendertagen ab dem Eingang des
            Antrags auf Kündigung der IK
            T-Dienstleistung bei der Gegenpartei ausgedrückt."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_notice_period',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_notice_period`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_notice_period
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },

    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Reason of the termination or ending of the contractual arrangement
            </p>
            <p>B_02.02.0090</p>
          </div>
          <AdditionalInformation
            color="red"
            description="Where the contractual arrangement has been terminated or ended, identify the reason of the termination or ending of the contractual arrangements using one of the options in the following closed list:
1.	Termination not for cause: The contractual arrangement has expired/ended and has not been renewed by any of the parties;
2.	Termination for cause: The contractual arrangement has been terminated, the ICT third-party service provider being in a breach of applicable law, regulations or contractual provisions;
3.	Termination for cause: The contractual arrangement has been terminated, due to the fact that impediments of the ICT third-party service provider capable of altering the supported function have been identified;
4.	Termination for cause: The contractual arrangement has been terminated due to weaknesses of the ICT third-party service provider regarding the management and security of sensitive data or information of any of the counterparties;
5.	Termination following a request by a competent authority: The contractual arrangement has been terminated following a request by a competent Authority.
6.	Other: The contractual arrangement has been terminated by any of the parties for any other reason than the reasons referred to in points 1 to 5.
(closed set of options)
(mandatory if the contractual arrangement is terminated)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Wenn die vertragliche Vereinbarung gekündigt oder beendet wurde, geben Sie anhand
            einer der Optionen aus der folgenden erschöpfenden Liste den Grund für die Kündigung
            oder Beendigung der vertraglichen
            Vereinbarungen an:
            1. Beendigung ohne besonderen Anlass: Die vertragliche
            Vereinbarung ist abgelaufen/
            beendet und wurde von keiner der Parteien verlängert.
            2. Beendigung aus gegebenem Anlass: Die vertragliche Vereinbarung wurde
            gekündigt, da der IK
            T-Drittdienstleister gegen anwendbares Recht, Regelungen
            oder
            Vertragsbestimmungen verstoßen hat.
            3. Beendigung aus gegebenem Anlass: Die vertragliche
            Vereinbarung wurde aufgrund
            der
            Tatsache gekündigt, dass beim IK
            T-Drittdienstleister Schwierigkeiten
            festgestellt wurden, die die unterstützte Funktion beeinträchtigen könnten.
            4. Beendigung aus gegebenem Anlass: Die vertragliche
            Vereinbarung wurde aufgrund
            von Defiziten des IK
            T-Drittdienstleisters in Bezug auf die
            Verwaltung und
            Sicherheit sensibler Daten oder Informationen einer der Gegenparteien gekündigt.
            5. Kündigung auf Ersuchen einer zuständigen Behörde: Die vertragliche
            Vereinbarung
            wurde auf Ersuchen einer zuständigen Behörde gekündigt.
            6. Sonstige: Die vertragliche
            Vereinbarung wurde von einer der Parteien aus einem
            anderen als den unter den Nummern 1 bis 5 genannten Gründen gekündigt."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'terminatReason_name',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_terminatReason_name`;
        const fieldNameId = `${record.contractualArrangement_id}_terminatReason_id`;
        const fieldNameIdContract = `${record.contractualArrangement_id}_contractualArrangement_terminat_reason_id`;

        const rowKey = record.key;
        return (
          <CustomSelect
            options={terminatReasons}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.terminatReason_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldNameId, rowKey);
              handleEditChange(idEvent, fieldNameIdContract, rowKey);
              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: 'Terminate reason description',
      dataIndex: 'terminatReason_description',
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: (
        <Styled.TitleComponent>
          Intra group contract <Styled.Asterisx>*</Styled.Asterisx>
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_intra_group',
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_intra_group`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={intraGroup}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_intra_group
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const nameEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Type of contractual arrangement</p>
            <p>B_02.01.0020</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the type of contractual arrangement by using one of the options in the following closed list:
1.	standalone arrangement;
2.	overarching / master contractual arrangement;
3.	subsequent or associated arrangement.
(closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Benennen Sie anhand einer der Optionen aus der folgenden
            erschöpfenden Liste die Art der vertraglichen
            Vereinbarung:
            1. eigenständige Vereinbarung,
            2. übergeordnete Vereinbarung / Globalvereinbarung,
            3. Folge- oder Nebenvereinbarung."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'typeofContractualArrangement_name',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_typeofContractualArrangement_name`;
        const fieldNameId = `${record.contractualArrangement_id}_typeofContractualArrangement_id`;
        const fieldNameIdArrangement = `${record.contractualArrangement_id}_contractualArrangement_contract_type_arrangement_id`;
        const fieldNameId2 = `${record.contractualArrangement_id}_contractualArrangement_contract_type_id`;

        const rowKey = record.key;
        return (
          <CustomSelect
            options={contractualArrangementTypes}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.typeofContractualArrangement_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldNameId, rowKey);
              handleEditChange(idEvent, fieldNameIdArrangement, rowKey);
              handleEditChange(idEvent, fieldNameId2, rowKey);

              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Type of contractual arrangement description
        </Styled.TitleComponent>
      ),
      dataIndex: 'typeofContractualArrangement_description',
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: (
        <Styled.TitleComponent>
          Costs
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Annual expenses or estimated costs (or intragroup transfer) of the ICT services contractual arrangement for the past year. Monetary value shall be reported in units. The annual expense or estimated cost shall be expressed in the currency reported in B_01.02.0040. In case of an overarching arrangement with subsequent or associated arrangements, the sum of the annual expenses or estimated costs reported for the overarching arrangement and the subsequent or associated arrangements shall be equal to the total expenses or estimated costs for the overall contractual arrangement. There shall be no repetition or duplication of annual expenses or estimated costs. The following cases should be reflected: (a) where the annual expenses or estimate costs are not determined at the level of the overarching arrangement (i.e. they are 0), the annual expenses or estimated costs shall be reported at the level of each subsequent or associated arrangements."
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Jährliche Ausgaben oder voraussichtliche Kosten (oder gruppeninterner Transfer) der vertraglichen Vereinbarung für IKT-Dienstleistungen für das zurückliegende Jahr. Der monetäre Wert ist in Einheiten anzugeben.
            Die jährlichen Ausgaben oder voraussichtlichen Kosten sind in der in B_01.02.0040 angegebenen Währung auszuweisen. Im Falle einer übergeordneten Vereinbarung mit Folge- oder Nebenvereinbarungen muss die Summe der jährlichen Ausgaben oder voraussichtlichen Kosten, die für die übergeordnete Vereinbarung und die Folge- oder Nebenvereinbarungen gemeldet werden, den Gesamtausgaben oder den gesamten voraussichtlichen Kosten für die vertragliche Gesamtvereinbarung entsprechen. Es darf keine Wiederholung oder Doppelerfassung der jährlichen Ausgaben oder voraussichtlichen Kosten geben. Die folgenden Fälle sind zu berücksichtigen:
            a)	Werden die jährlichen Ausgaben oder voraussichtlichen Kosten nicht auf der Ebene der übergeordneten Vereinbarung bestimmt (also auf der Ebene 0), so sind die jährlichen Ausgaben oder voraussichtlichen Kosten auf der Ebene der einzelnen Folge- oder Nebenvereinbarungen anzugeben.
            b)	Können die jährlichen Ausgaben oder voraussichtlichen Kosten nicht für die einzelnen Folge- oder Nebenvereinbarungen gemeldet werden, so werden die jährlichen Gesamtausgaben oder voraussichtlichen Kosten auf der Ebene der übergeordneten Vereinbarung gemeldet.
            c)	Gibt es jährliche Ausgaben oder voraussichtliche Kosten für jede Ebene der Vereinbarung, d. h. für die übergeordnete Vereinbarung und die Folge- oder Nebenvereinbarung, und sind diese Informationen verfügbar, so sind die jährlichen Ausgaben oder voraussichtlichen Kosten ohne Doppelerfassung für jede Ebene der vertraglichen Vereinbarung anzugeben.
            (monetär)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'costs',
      key: 'costs',
      className: 'highlighted-column',
      render: (_, record) => {
        return (
          <div>
            {(record.costs as ICosts[]).map((cost: ICosts) => {
              const idFieldName = `${cost.costs_id}_c_costs_id`;
              const currencyId = `${cost.costs_id}_c_costs_currency_id`;
              const currencyAmountReportedFieldName = `${cost.costs_id}_c_costs_currency_amount_reported`;
              const annualExpenseFieldName = `${cost.costs_id}_c_costs_annual_expense`;
              const descriptionFieldName = `${cost.costs_id}_c_costs_description`;
              const startDateFieldName = `${cost.costs_id}_c_costs_start_date`;
              const endDateFieldName = `${cost.costs_id}_c_costs_end_date`;
              const rowKey = record.key;
              const handleRemoveCost = (costKey: number, costID: number) => {
                Modal.confirm({
                  title: 'Are you sure you want to remove this Cost?',
                  content: 'This action cannot be undone.',
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk: async () => {
                    try {
                      const response =
                        await contractual_informationOp.inactivateCosts([
                          costID,
                        ]);
                      const updatedContracts = data.map((data) => {
                        if (data.key === costKey) {
                          const updatedData = data.datas
                            ? data.datas.filter(
                                (cost: ICosts) => cost.costs_id !== costID,
                              )
                            : [];

                          return {
                            ...data,
                            datas: updatedData,
                          };
                        }
                        return data;
                      });
                      setData(updatedContracts);
                      if (record.isNew) {
                        const updatedContracts = data.map((item) => {
                          const filteredCosts =
                            item.costs?.filter(
                              (cost: ICosts) => cost.costs_id !== costID,
                            ) || [];

                          return {
                            ...item,
                            costs: filteredCosts,
                          };
                        });

                        setData(updatedContracts);
                      } else {
                        fetchContracts();
                      }
                    } catch (error) {
                      console.error('Failed to remove the Cost:', error);
                    }
                  },
                  onCancel: () => {
                    notification.info({
                      message: 'Action Canceled',
                      description: 'The Cost removal has been canceled.',
                      duration: 10,
                    });
                  },
                });
              };
              return (
                <Styled.TooltipContainer
                  key={cost.costs_id}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                        <Button
                          size="small"
                          type="text"
                          danger
                          onClick={() =>
                            handleRemoveCost(record.key, cost.costs_id)
                          }
                        >
                          <DeleteOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Currency of the amount reported in B_02.01.0050
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              color="red"
                              description="Identify the ISO 4217 alphabetic code of the currency used to express the amount in B_02.01.0050.(currency)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <CustomSelect
                          value={
                            editedData.hasOwnProperty(
                              currencyAmountReportedFieldName,
                            )
                              ? editedData[currencyAmountReportedFieldName]
                              : cost.costs_currency_amount_reported
                          }
                          options={currencies}
                          onChange={(
                            value: string,
                            option: { label: string; value: string },
                          ) => {
                            const idEvent = {
                              target: {
                                value: option.value,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;
                            handleEditChange(idEvent, currencyId, rowKey);

                            const nameEvent = {
                              target: {
                                value: option.label,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;
                            handleEditChange(
                              nameEvent,
                              currencyAmountReportedFieldName,
                              rowKey,
                            );
                          }}
                          filterOption={(
                            input: string,
                            option: { label: string },
                          ) =>
                            option?.label
                              ?.toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Annual expense or estimated cost of the contractual
                            arrangement for the past year
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              color="red"
                              description="Annual expenses or estimated costs (or intragroup transfer) of the ICT services contractual arrangement for the past year. Monetary value shall be reported in units.
The annual expense or estimated cost shall be expressed in the currency reported in B_01.02.0040.
In case of an overarching arrangement with subsequent or associated arrangements, the sum of the annual expenses or estimated costs reported for the overarching arrangement and the subsequent or associated arrangements shall be equal to the total expenses or estimated costs for the overall contractual arrangement. There shall be no repetition or duplication of annual expenses or estimated costs. The following cases should be reflected:
(a) where the annual expenses or estimate costs are not determined at the level of the overarching arrangement (i.e. they are 0), the annual expenses or estimated costs shall be reported at the level of each subsequent or associated arrangements.
(b)	where the annual expenses or estimated costs cannot be reported for each of the subsequent or associated arrangements, the total annual expenses or estimated costs shall be reported at the level of the overarching arrangement.
(c)	where there are annual expenses or estimated costs related to each level of the arrangement, i.e. overarching and subsequent or associated, and that information is available, the annual expenses or estimated costs shall be reported without duplication at each level of the contractual arrangement.
(monetary)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(annualExpenseFieldName)
                              ? typeof editedData[annualExpenseFieldName] ===
                                'boolean'
                                ? editedData[annualExpenseFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[annualExpenseFieldName])
                              : String(cost.costs_annual_expense)
                          }
                          onChange={(e) =>
                            handleEditChange(e, annualExpenseFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>

                      <Styled.InputField>
                        <strong>
                          Start date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={(() => {
                            const resolvedValue = editedData.hasOwnProperty(
                              startDateFieldName,
                            )
                              ? String(editedData[startDateFieldName])
                              : String(cost.costs_start_date);
                            return resolvedValue;
                          })()}
                          fieldName={startDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, startDateFieldName, record.key)
                          }
                          type="date"
                          useDefaultDate={false}
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          End date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(endDateFieldName)
                              ? String(
                                  editedData[endDateFieldName] ?? '9999-01-01',
                                )
                              : String(cost.costs_end_date ?? '9999-01-01')
                          }
                          fieldName={endDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, endDateFieldName, record.key)
                          }
                          type="date"
                          useDefaultDate={true}
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(cost.costs_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(annualExpenseFieldName)
                          ? typeof editedData[annualExpenseFieldName] ===
                            'boolean'
                            ? editedData[annualExpenseFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[annualExpenseFieldName])
                          : String(cost.costs_annual_expense)
                      }
                      readOnly
                      addonAfter={<PlusCircleOutlined />}
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewCost(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Country of the governing law of the contractual arrangement</p>
            <p>B_02.02.0120</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the country of the governing law of the contractual arrangement using the ISO 3166–1 alpha–2 code.
    (country)
    (mandatory if the ICT service is supporting a critical or important function)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie den ISO 3166-1-Alpha-2-Code des Landes an, dessen Recht für die
            vertragliche Vereinbarung maßgebend ist."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'country_country_name',
      editable: true,

      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_country_country_name`;
        const fieldNameId = `${record.contractualArrangement_id}_country_id`;
        const fieldNameIdGoverningLaw = `${record.contractualArrangement_id}_contractualArrangement_governing_law_country_id`;
        const rowKey = record.key;

        return (
          <>
            <CustomSelect
              value={
                editedData.hasOwnProperty(fieldName)
                  ? editedData[fieldName]
                  : record.country_country_name
              }
              options={countries}
              onChange={(
                value: string,
                option: { label: string; value: string },
              ) => {
                const idEvent = {
                  target: {
                    value: option.value,
                  },
                } as React.ChangeEvent<HTMLInputElement>;
                handleEditChange(idEvent, fieldNameId, rowKey);
                handleEditChange(idEvent, fieldNameIdGoverningLaw, rowKey);

                const nameEvent = {
                  target: {
                    value: option.label,
                  },
                } as React.ChangeEvent<HTMLInputElement>;
                handleEditChange(nameEvent, fieldName, rowKey);
                const selectedCountry = countries.find(
                  (country) => country.value === value,
                );
                if (selectedCountry) {
                  const newIsoCode = i18nIsoCountries.getAlpha2Code(
                    selectedCountry.label,
                    'en',
                  );
                  const isoEvent = {
                    target: { value: newIsoCode || '' },
                  } as React.ChangeEvent<HTMLInputElement>;
                  handleEditChange(
                    isoEvent,
                    `${record.contractualArrangement_id}_country_iso_code`,
                    rowKey,
                  );
                }
              }}
              filterOption={(input: string, option: { label: string }) =>
                option?.label?.toLowerCase().includes(input.toLowerCase())
              }
            />
          </>
        );
      },
    },
    {
      title: <>Country iso code</>,
      dataIndex: 'country_iso_code',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_country_iso_code`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.country_iso_code
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
            disabled
          />
        );
      },
    },
    {
      title: 'Country description',
      dataIndex: 'country_description',
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record) => (
        <>
          {record.isNew ? (
            <Button danger onClick={() => handleRemoveRow(record.key)}>
              Remove
            </Button>
          ) : (
            <Button
              danger
              onClick={() => handleInactivate(record as IContractulInformation)}
            >
              Soft delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Styled.ButtonDiv isEmpty={dataWithKey.length === 0}>
        {query.entity &&
          selectedICTForContractualInformation === null &&
          selectedTPPForContractualInformation && (
            <Styled.AreaButton type="primary" onClick={addNewRow}>
              Add Row
            </Styled.AreaButton>
          )}
      </Styled.ButtonDiv>
      <Styled.TooltipTableContainer>
        <Table
          dataSource={dataWithKey}
          rowKey="key"
          scroll={{ x: 'max-content' }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePaginationChange,
            showSizeChanger: pagination.showSizeChanger,
            pageSizeOptions: pagination.pageSizeOptions,
            position: pagination.position,
          }}
          columns={columns}
        />
      </Styled.TooltipTableContainer>
      {dataWithKey.length > 0 && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      )}
    </>
  );
};

export default ContractualInformation;
