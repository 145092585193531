import React from 'react';
import { theme } from 'antd';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomTitle from '../CustomTitle';
import CustomSelect from '../CustomSelect';
import { ThemeList, TitleList } from 'store/ducks/globalTypes';
import { globalOp } from 'store/ducks/Global';
import ColorPalettes from 'constants/colorPalettes';
import * as Styled from './TitleBar.styled';

const { useToken } = theme;

interface ITitleBarProps {
  title: TitleList;
}

const TitleBar: React.FC<ITitleBarProps> = ({ title }) => {
  const { token } = useToken();
  const dispatch = useDispatch();
  const colorPalettes = ColorPalettes();

  const handleThemeChange = (value: ThemeList) => {
    localStorage.setItem('theme', value);
    dispatch(globalOp.setTheme(value) as any);
  };

  return (
    <Styled.StyledNav token={token}>
      <Link to="/working-area">
        <Styled.StyledLogo src={Styled.logoSrc} alt="logo" />
      </Link>
      <CustomTitle title={title} level={2} />
      <Styled.DropdownContainer>
        <CustomSelect
          size="lg"
          options={colorPalettes}
          defaultValue={localStorage.getItem('theme')}
          onChange={handleThemeChange}
        />
      </Styled.DropdownContainer>
    </Styled.StyledNav>
  );
};

export default TitleBar;
