import { Dispatch } from '@reduxjs/toolkit';
import globalSlice from './globalSlice';
import { notification } from 'antd';
import { IFilters, ThemeList } from '../globalTypes';
import { ITableFilters } from './types';
import ApiService from 'utils/ApiCalls';
import Helpers from 'utils/Helpers';

const {
  set_theme,
  // set_search_params,
  set_loading,
  set_applied_filters_result,
  set_table_filters,
  set_filters_data,
} = globalSlice.actions;

const setTheme = (theme: ThemeList) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_theme(theme));
  } catch (error) {
    console.error(error);
  }
};

// const setSearchParams = (params: string) => (dispatch: Dispatch) => {
//   try {
//     dispatch(set_loading());
//     dispatch(set_search_params(params));
//   } catch (error) {
//     console.error(error);
//   }
// };

const setAppliedFilersResult = (data: any[]) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_applied_filters_result(data));
  } catch (error) {
    console.error(error);
  }
};

const setTableFilter = (filter: ITableFilters) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_table_filters(filter));
  } catch (error) {
    console.error(error);
  }
};

const setFilters = () => async (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    const response = await ApiService.getFilters();
    let fetchedFilters: IFilters[] = [];

    if (response.data && !Helpers.isObjEmpty(response.data)) {
      fetchedFilters = Object.values(
        response.data as { id: number; name_of_entity: string }[],
      ).map((entity) => {
        return {
          filter: entity.name_of_entity,
          options: [
            {
              id: entity.id,
              name_of_entity: entity.name_of_entity,
              label: entity.name_of_entity,
              value: entity.id.toString(),
            },
          ],
        };
      });
    }

    dispatch(set_filters_data(fetchedFilters));
  } catch (error) {
    console.error('Error fetching the Entities:', error);
  }
};

export default {
  setTheme,
  // setSearchParams,
  setAppliedFilersResult,
  setTableFilter,
  setFilters,
};
