// import jwt_decode from 'jwt-decode';
// import { arrayMove } from '@dnd-kit/sortable';
import { ISelectedOptions } from 'store/ducks/Global/types';
import { filterNames } from 'constants/filterNames';

const Helpers = {
  isObjEmpty: (obj: any) => Object.keys(obj).length === 0,
  formatDate: (date: any) => {
    const dt = new Date(date);
    return dt.toLocaleString('de', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  },

  getToken: () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user?.token;
  },
  getUserInfo: () => {
    // const token = Helpers.getToken();
    // return token && jwt_decode(token);
  },

  getFilterParams: () => {
    const searchParams = new URLSearchParams(document.location.search);

    const filtersParams: ISelectedOptions = filterNames.reduce(
      (acc: ISelectedOptions, filter: string) => {
        const lowercaseFilter = filter.toLowerCase();
        if (searchParams.has(lowercaseFilter)) {
          acc[lowercaseFilter] = searchParams.getAll(lowercaseFilter);
        }
        return acc;
      },
      {},
    );

    return filtersParams;
  },

  removeAtIndex: (array: any[], index: number) => [
    ...array.slice(0, index),
    ...array.slice(index + 1),
  ],
  insertAtIndex: (array: any[], index: number, item: unknown) => [
    ...array.slice(0, index),
    item,
    ...array.slice(index),
  ],
};

export default Helpers;
