import React, { useState, useEffect } from 'react';
import useBasicFilters from 'utils/hooks/useBasicFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import { notification, Tooltip, Tag, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { functionOp } from 'store/ducks/DataEntry/Functions';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import * as Styled from '../../DataEntry.styled';

interface IFunction {
  key: number;
  critically_assessment_id: number;
  critically_assessment_name: string;
  critically_assessment_description: string;
  auswahl_function_discont_id: number;
  auswahl_function_discont_name: string;
  auswahl_function_discont_description: string;
  selection_licenced_act_id: number;
  selection_licenced_act_name: string;
  selection_licenced_act_description: string;
  f_function_id: number;
  f_function_identifier: string;
  f_function_name: string;
  f_criticality_assessment_id: number;
  f_reasons_for_criticality: string;
  f_date_of_last_assessment: string;
  f_recovery_time_objective: string;
  f_recovery_point_objective: string;
  f_impact_of_discontinuing_id: number;
  f_licensed_activity_id: number;
  f_entity_id: number;
  f_possibility_of_reintegration_id: number;
  f_function_level_id: number;
  f_function_description: string;
  sensitiveness_of_data_stored_by_ICTTPSP_id: number;
  sensitiveness_of_data_stored_by_ICTTPSP_name: string;
  sensitiveness_of_data_stored_by_ICTTPSP_description: string;
  data_sensitiveness_id: number;
  data_sensitiveness_name: string;
  data_sensitiveness_sensitiveness_of_data_stored_by_ict_tpsp_id: number;
  data_sensitiveness_description: string;
  data_id: number;
  data_location_of_data_at_rest: string;
  data_location_of_data_management: string;
  sensitiveness_id: number; //data
  data_description: string;
  data_ict_system_id: number;
  ict_systems_id: number;
  ict_systems_name: string;
  ict_systems_description: string;
  certificates_id: number;
  certificates_description: string;
  certificates_validity: boolean;
  certificates_date_of_issuance: string;
  certificates_certificate_registry: string;
  certificates_date_of_certificate_register: string;
  certificates_third_party_service_provider_id: number;
  certificates_function_id: number;
  certificates_ict_service_id: number;
  function_level_id: number;
  function_level_name: string;
  function_level_description: string;
  isNew?: boolean;
  isEdited?: boolean;
}
const Function: React.FC = () => {
  const { query } = useBasicFilters();
  const [dataSource, setDataSource] = useState<IFunction[]>([]);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  // const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState<{ [key: string]: boolean }>({});

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const fetchTPPbyEntity = async () => {
    try {
      const response = await functionOp.fetchFunctionbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.functions;
      console.log(data, 'data for functions');

      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        critically_assessment_id: item.criticality_assessment.id,
        critically_assessment_name: item.criticality_assessment.name,
        critically_assessment_description:
          item.criticality_assessment.description,
        auswahl_function_discont_id: item.impact_of_discontinuing.id,
        auswahl_function_discont_name: item.impact_of_discontinuing.name,
        auswahl_function_discont_description:
          item.impact_of_discontinuing.description,
        selection_licenced_act_id: item.licensed_activity.id,
        selection_licenced_act_name: item.licensed_activity.name,
        selection_licenced_act_description: item.licensed_activity.description,
        ict_systems: item.ict_systems
          ? item.ict_systems.map((ict_system: any) => ({
              ict_systems_id: ict_system.id,
              ict_systems_name: ict_system.name,
              ict_systems_description: ict_system.description,
            }))
          : [],
        certificates: item.certificates
          ? item.certificates.map((certificate: any) => ({
              certificates_id: certificate.id,
              certificates_description: certificate.description,
              certificates_validity: certificate.validity,
              certificates_date_of_issuance: certificate.date_of_issuance,
              certificates_certificate_registry:
                certificate.certificate_registry,
              certificates_date_of_certificate_register:
                certificate.date_of_certificate_register,
              certificates_third_party_service_provider_id:
                certificate.third_party_service_provider_id,
              certificates_function_id: certificate.function_id,
              certificates_ict_service_id: certificate.ict_service_id,
            }))
          : [],
        f_function_id: item.function_id,
        f_function_identifier: item.function_identifier,
        f_function_name: item.function_name,
        f_criticality_assessment_id: item.criticality_assessment_id,
        f_reasons_for_criticality: item.reasons_for_criticality,
        f_date_of_last_assessment: item.date_of_last_assessment,
        f_recovery_time_objective: item.recovery_time_objective,
        f_recovery_point_objective: item.recovery_point_objective,
        f_impact_of_discontinuing_id: item.impact_of_discontinuing_id,
        f_licensed_activity_id: item.licensed_activity_id,
        f_entity_id: item.entity_id,
        f_possibility_of_reintegration_id: item.possibility_of_reintegration_id,
        f_function_level_id: item.function_level_id,
        f_function_description: item.description,

        //     function_level_id: data.function_level.id,
        //     function_level_name: data.function_level.name,
        //     function_level_description: data.function_level.description,

        data_description: item.data
          ? item.data.map((iData: any) => ({
              data_id: iData.id,
              data_location_of_data_at_rest: iData.location_of_data_at_rest,
              data_location_of_data_management:
                iData.location_of_data_management,
              sensitiveness_id: iData.sensitiveness_id,
              data_description: iData.description,
              data_ict_system_id: iData.ict_system_id,
              sensitiveness_of_data_stored_by_ICTTPSP_id:
                iData.sensitiveness.data_stored_by_ict_tpsp.id,
              sensitiveness_of_data_stored_by_ICTTPSP_name:
                iData.sensitiveness.data_stored_by_ict_tpsp.name,
              sensitiveness_of_data_stored_by_ICTTPSP_description:
                iData.sensitiveness.data_stored_by_ict_tpsp.description,
              data_sensitiveness_id: iData.sensitiveness.id,
              data_sensitiveness_name: iData.sensitiveness.name,
              data_sensitiveness_description: iData.sensitiveness.description,
            }))
          : [],
        isNew: false,
        isEdited: false,
      }));
      console.log(mappedData, 'mappedData');

      setDataSource(mappedData);
      setCount(mappedData.length);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchTPPbyEntity();
    }
  }, [query]);

  const handleOpenTooltip = (id: string) => {
    setTooltipVisible((prevState) => ({ ...prevState, [id]: true }));
  };

  const handleCloseTooltip = (id: string) => {
    setTooltipVisible((prevState) => ({ ...prevState, [id]: false }));
  };

  const handleAddRow = (record?: any, index?: number) => {
    const maxId = dataSource.reduce(
      (max, item) => (item.f_function_id > max ? item.f_function_id : max),
      0,
    );
    const newId = maxId + 1;

    const newData: IFunction = {
      key: newId,
      critically_assessment_id: 0,
      critically_assessment_name: '',
      critically_assessment_description: '',
      auswahl_function_discont_id: 0,
      auswahl_function_discont_name: '',
      auswahl_function_discont_description: '',
      selection_licenced_act_id: 0,
      selection_licenced_act_name: '',
      selection_licenced_act_description: '',
      f_function_id: 0,
      f_function_identifier: '',
      f_function_name: '',
      f_criticality_assessment_id: 0,
      f_reasons_for_criticality: '',
      f_date_of_last_assessment: '',
      f_recovery_time_objective: '',
      f_recovery_point_objective: '',
      f_impact_of_discontinuing_id: 0,
      f_licensed_activity_id: 0,
      f_entity_id: 0,
      f_possibility_of_reintegration_id: 0,
      f_function_level_id: 0,
      f_function_description: '',
      sensitiveness_of_data_stored_by_ICTTPSP_id: 0,
      sensitiveness_of_data_stored_by_ICTTPSP_name: '',
      sensitiveness_of_data_stored_by_ICTTPSP_description: '',
      data_sensitiveness_id: 0,
      data_sensitiveness_name: '',
      data_sensitiveness_sensitiveness_of_data_stored_by_ict_tpsp_id: 0,
      data_sensitiveness_description: '',
      data_id: 0,
      data_location_of_data_at_rest: '',
      data_location_of_data_management: '',
      sensitiveness_id: 0,
      data_description: '',
      data_ict_system_id: 0,
      ict_systems_id: 0,
      ict_systems_name: '',
      ict_systems_description: '',
      certificates_id: 0,
      certificates_description: '',
      certificates_validity: false,
      certificates_date_of_issuance: '',
      certificates_certificate_registry: '',
      certificates_date_of_certificate_register: '',
      certificates_third_party_service_provider_id: 0,
      certificates_function_id: 0,
      certificates_ict_service_id: 0,
      function_level_id: 0,
      function_level_name: '',
      function_level_description: '',
      isNew: true,
    };

    const insertIndex = dataSource.findIndex(
      (item) => item.key === record?.key,
    );

    const updatedDataSource = [
      ...dataSource.slice(0, insertIndex + 1),
      newData,
      ...dataSource.slice(insertIndex + 1),
    ];

    setDataSource(updatedDataSource);
    setCount(count + 1);
  };

  const handleSave = (row: IFunction) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const handleSubmit = async () => {
    const changedData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );

    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited Branches to submit.',
        placement: 'topRight',
      });
      return;
    }

    const formattedData = {
      entity_name: query.entity,
      data_list: [
        {
          CriticalityAssessment: changedData.map((item) => ({
            id: item.critically_assessment_id,
            name: item.critically_assessment_name,
            description: item.critically_assessment_description,
          })),
        },
        {
          AuswahlFunctionDiscont: changedData.map((item) => ({
            id: item.auswahl_function_discont_id,
            name: item.auswahl_function_discont_name,
            description: item.auswahl_function_discont_description,
          })),
        },
        {
          SelectionLicencedAct: changedData.map((item) => ({
            id: item.selection_licenced_act_id,
            name: item.selection_licenced_act_name,
            description: item.selection_licenced_act_description,
          })),
        },
        {
          Function: changedData.map((item) => ({
            id: item.f_function_id,
            function_identifier: item.f_function_identifier,
            name: item.f_function_name,
            assessment_id: item.f_criticality_assessment_id,
            reasons_for_criticality: item.f_reasons_for_criticality,
            date_of_last_assessment: item.f_date_of_last_assessment,
            recovery_time_objective: item.f_recovery_time_objective,
            recovery_point_objective: item.f_recovery_point_objective,
            impact_of_discontinuing_id: item.f_impact_of_discontinuing_id,
            licensed_activity_id: item.f_licensed_activity_id,
            entity_id: item.f_entity_id,
            possibility_of_reintegration_id:
              item.f_possibility_of_reintegration_id,
            function_level_id: item.f_function_level_id,
            description: item.f_function_description,
          })),
        },
        {
          SensetevenessOfData: changedData.map((item) => ({
            //TODO: check the titles
            id: item.sensitiveness_of_data_stored_by_ICTTPSP_id,
            name: item.sensitiveness_of_data_stored_by_ICTTPSP_name,
            description:
              item.sensitiveness_of_data_stored_by_ICTTPSP_description,
          })),
        },
        {
          DataSensitiveness: changedData.map((item) => ({
            //TODO: check the titles
            id: item.data_sensitiveness_id,
            name: item.data_sensitiveness_name,
            sensitiveness_of_data_stored_by_ict_tpsp_id:
              item.data_sensitiveness_sensitiveness_of_data_stored_by_ict_tpsp_id,
            description: item.data_sensitiveness_description,
          })),
        },
        {
          Data: changedData.map((item) => ({
            id: item.data_id,
            location_of_data_at_rest: item.data_location_of_data_at_rest,
            location_of_data_management: item.data_location_of_data_management,
            sensitiveness_id: item.sensitiveness_id,
            description: item.data_description,
            ict_system_id: item.data_ict_system_id,
          })),
        },
        {
          ICTSystems: changedData.map((item) => ({
            id: item.ict_systems_id,
            name: item.ict_systems_name,
            description: item.ict_systems_description,
          })),
        },
        {
          Certificates: changedData.map((item) => ({
            id: item.certificates_id,
            description: item.certificates_description,
            validity: item.certificates_validity,
            date_of_issuance: item.certificates_date_of_issuance,
            certificate_registry: item.certificates_certificate_registry,
            date_of_certificate_register:
              item.certificates_date_of_certificate_register,
            third_party_service_provider_id:
              item.certificates_third_party_service_provider_id,
            function_id: item.certificates_function_id,
            ict_service_id: item.certificates_ict_service_id,
          })),
        },
        {
          FunctionLevel: changedData.map((item) => ({
            id: item.function_level_id,
            name: item.function_level_name,
            description: item.function_level_description,
          })),
        },
      ],
    };

    try {
      const response = await functionOp.insertFunctions(formattedData);
      console.log(response, 'response');
      notification.success({
        message: 'Submission Successful',
        description: 'The data was successfully submitted.',
        placement: 'topRight',
      });
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: 'Critically assessment id',
      dataIndex: 'critically_assessment_id',
      editable: false,
    },
    {
      title: 'Critically assessment name',
      dataIndex: 'critically_assessment_name',
      editable: true,
    },
    {
      title: 'Critically assessment description',
      dataIndex: 'critically_assessment_description',
      editable: true,
    },
    {
      title: 'Auswahl function discont id',
      dataIndex: 'auswahl_function_discont_id',
      editable: false,
    },
    {
      title: 'Auswahl function discont name',
      dataIndex: 'auswahl_function_discont_name',
      editable: true,
    },
    {
      title: 'Auswahl function discont description',
      dataIndex: 'auswahl_function_discont_description',
      editable: true,
    },
    {
      title: 'Selection licenced act id',
      dataIndex: 'selection_licenced_act_id',
      editable: false,
    },
    {
      title: 'Selection licenced act name',
      dataIndex: 'selection_licenced_act_name',
      editable: true,
    },
    {
      title: 'Selection licenced act description',
      dataIndex: 'selection_licenced_act_description',
      editable: true,
    },
    {
      title: 'Function id',
      dataIndex: 'f_function_id',
      editable: false,
    },
    {
      title: 'Function identifier',
      dataIndex: 'f_function_identifier',
      editable: true,
    },
    {
      title: 'Function name',
      dataIndex: 'f_function_name',
      editable: true,
    },
    {
      title: 'Critically assessment id',
      dataIndex: 'f_criticality_assessment_id',
      editable: false,
    },
    {
      title: 'Reasons for criticality',
      dataIndex: 'f_reasons_for_criticality',
      editable: true,
    },
    {
      title: 'Date of last assessment',
      dataIndex: 'f_date_of_last_assessment',
      editable: true,
    },
    {
      title: 'Recovery time objective',
      dataIndex: 'f_recovery_time_objective',
      editable: true,
    },
    {
      title: 'Recovery point objective',
      dataIndex: 'f_recovery_point_objective',
      editable: true,
    },
    {
      title: 'Impact of discontinuing id',
      dataIndex: 'f_impact_of_discontinuing_id',
      editable: false,
    },
    {
      title: 'Licensed activity id',
      dataIndex: 'f_licensed_activity_id',
      editable: false,
    },
    {
      title: 'Entity id',
      dataIndex: 'f_entity_id',
      editable: false,
    },
    {
      title: 'Possibility of reintegration id',
      dataIndex: 'f_possibility_of_reintegration_id',
      editable: false,
    },
    {
      title: 'Function level id',
      dataIndex: 'f_function_level_id',
      editable: false,
    },
    {
      title: 'Function description',
      dataIndex: 'f_function_description',
      editable: true,
    },
    {
      title: 'Data description',
      dataIndex: 'data_description',
      key: 'data_description',
      render: (data: IFunction[]) => (
        <>
          {data.map((iData) => (
            <Tooltip
              key={iData.data_id}
              title={
                <div>
                  <div>
                    <Button size="small" type="text" onClick={() => handleCloseTooltip(iData.data_id.toString())}>
                      Close
                    </Button>
                  </div>
                  <div>
                    <strong>id:</strong>
                    <span>{iData.data_id}</span>
                  </div>
                  <div>
                    <strong>
                      <strong>Location of data at rest:</strong>
                      <span>{iData.data_location_of_data_at_rest}</span>
                    </strong>
                  </div>
                  <div>
                    <strong>Location of data management:</strong>
                    <span>{iData.data_location_of_data_management}</span>
                  </div>
                  <div>
                    <strong>Sensitiveness id:</strong>
                    <span> {iData.data_sensitiveness_id}</span>
                  </div>
                  <div>
                    <strong>ICT system id:</strong>
                    <span>{iData.data_ict_system_id}</span>
                  </div>
                  <div>
                    <strong>
                      Sensitiveness of data stored by ICTTPSP - id:
                    </strong>
                    <span>
                      {iData.sensitiveness_of_data_stored_by_ICTTPSP_id}
                    </span>
                  </div>
                  <div>
                    <strong>
                      Sensitiveness of data stored by ICTTPSP - name:
                    </strong>
                    <span>
                      {iData.sensitiveness_of_data_stored_by_ICTTPSP_name}
                    </span>
                  </div>
                  <div>
                    <strong>
                      Sensitiveness of data stored by ICTTPSP - description:
                    </strong>
                    <span>
                      {
                        iData.sensitiveness_of_data_stored_by_ICTTPSP_description
                      }
                    </span>
                  </div>
                  <div>
                    <strong>Data sensitiveness id:</strong>
                    <span>{iData.data_sensitiveness_id}</span>
                  </div>
                  <div>
                    <strong>Data sensitiveness name:</strong>
                    <span>{iData.data_sensitiveness_name}</span>
                  </div>
                  <div>
                    <strong>Data sensitiveness description:</strong>
                    <span>{iData.data_sensitiveness_description}</span>
                  </div>
                </div>
              }
              open={isTooltipVisible[iData.data_id] || false}
              trigger={[]}
            >
              {/* <Tag color="blue">{iData.data_description}</Tag> */}
              <Tag color="blue" onClick={() => handleOpenTooltip(iData.data_id.toString())}>
                {iData.data_description}
             </Tag>
            </Tooltip>
          ))}
        </>
      ),
    },
    {
      title: 'ICT systems name',
      dataIndex: 'ict_systems',
      key: 'ict_systems',
      render: (ict_systems: IFunction[]) => (
        <>
          {ict_systems.map((ict_system) => (
            <Tooltip
              key={ict_system.ict_systems_id}
              title={
                <div>
                  <div>
                    <strong>id: </strong>
                    <span>{ict_system.ict_systems_id}</span>
                  </div>
                  <div>
                    <strong>description:</strong>
                    <span>{ict_system.ict_systems_description}</span>
                  </div>
                </div>
              }
            >
              <Tag color="blue">{ict_system.ict_systems_name}</Tag>
            </Tooltip>
          ))}
        </>
      ),
    },
    {
      title: 'Certificate Registry',
      dataIndex: 'certificates',
      key: 'certificates',
      render: (certificates: IFunction[]) => (
        <>
          {certificates.map((certificate) => (
            <Tooltip
              key={certificate.certificates_id}
              title={
                <div>
                  <div>
                    <strong>id: </strong>
                    <span>{certificate.certificates_id}</span>
                  </div>
                  <div>
                    <strong>description:</strong>
                    <span>{certificate.certificates_description}</span>
                  </div>
                  <div>
                    <strong>validity:</strong>
                    <span>{certificate.certificates_validity}</span>
                  </div>
                  <div>
                    <strong>date of issuance:</strong>
                    <span> {certificate.certificates_date_of_issuance}</span>
                  </div>
                  <div>
                    <strong>date of certificate register:</strong>
                    <span>
                      {certificate.certificates_date_of_certificate_register}
                    </span>
                  </div>
                  <div>
                    <strong>TPP id: </strong>
                    <span>
                      {' '}
                      {certificate.certificates_third_party_service_provider_id}
                    </span>
                  </div>
                  <div>
                    <strong>function id:</strong>
                    <span>{certificate.certificates_function_id}</span>
                  </div>
                  <div>
                    <strong>ICT service id:</strong>
                    <span>{certificate.certificates_ict_service_id}</span>
                  </div>
                </div>
              }
            >
              <Tag color="blue">
                {certificate.certificates_certificate_registry}
              </Tag>
              <Styled.GlobalStyle />
            </Tooltip>
          ))}
        </>
      ),
    },
    {
      title: 'Function level id',
      dataIndex: 'function_level_id',
      editable: false,
    },
    {
      title: 'Function level name',
      dataIndex: 'function_level_name',
      editable: true,
    },
    {
      title: 'Function level description',
      dataIndex: 'function_level_description',
      editable: true,
    },
    {
      title: 'Create',
      dataIndex: 'add_row',
      key: 'add_row',
      width: '10px',
      fixed: 'right',
      render: (_: any, record: { key: React.Key }, index: number) => (
        <Styled.OperationsRow onClick={() => handleAddRow(record, index)}>
          <PlusOutlined />
        </Styled.OperationsRow>
      ),
    },
  ];

  return (
    dataSource && (
      <div>
        <Styled.AreaButton onClick={handleAddRow} type="primary">
          Add a row
        </Styled.AreaButton>
        <CustomTable
          columns={defaultColumns}
          dataSource={dataSource}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      </div>
    )
  );
};

export default Function;
