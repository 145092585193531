import styled from 'styled-components';
import { ICustomSelectProps } from './CustomSelect';

export const SelectWrapper = styled('div')<ICustomSelectProps>`
  padding: unset !important;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    max-width: ${(props) =>
      props.size === 'lg' ? '250px' : props.size === 'md' ? '220px' : '162px'};
    min-width: ${(props) =>
      props.size === 'lg' ? '250px' : props.size === 'md' ? '220px' : '162px'};
  }
`;

export const FiltersWrapper = styled('div')`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 45px;
    width: 130px;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .ant-select-selection-placeholder {
    text-align: initial;
    color: #5d5a5a;
  }
`;
