import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  contractual_arrangement_ref_number: string;
  type_of_ICT: string;
  identification_code_of_ICT_provider: string;
  type_of_code_to_identify_provider: string;
  rank: string;
  identification_code_of_recipient: string;
  type_of_code_to_identify_recipient: string;
}

interface B0502Props {
  entityName: string | null | undefined;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Contractual arrangement reference number - <span>0010</span>
        </p>
        <AdditionalInformation
          description="As reported in B_02.01.0010
(alphanumerical)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'contractual_arrangement_ref_number',
    key: 'contractual_arrangement_ref_number',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Type of ICT services - <span>0020</span>
        </p>
        <AdditionalInformation
          description="One of the types of ICT services referred to in Annex III
(closed set of options)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'type_of_ICT',
    key: 'type_of_ICT',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Identification code of the ICT third-party service provider -
          <span>0030</span>
        </p>
        <AdditionalInformation
          description="As reported in B_05.01.0010 for that ICT third-party service provider.
Examples:
—	The identification code of the direct ICT third- party service provider providing ICT service to the financial entity making use of it;
—	The identification code of the subcontractor at rank 2 providing service to the direct ICT third- party service provider.
(alphanumerical)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'identification_code_of_ICT_provider',
    key: 'identification_code_of_ICT_provider',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Type of code to identify the ICT third-party service provider -
          <span>0040</span>
        </p>
        <AdditionalInformation
          description="Type of code to identify the ICT third-party service provider reported in B_05.01.0010
1.	‘LEI’ for LEI
2.	‘EUID’ for EUID
3.	‘Country Code’+Underscore+’Type of Code’ for non LEI and non EUID code
Country Code: Identify the ISO 3166–1 alpha–2 code of the country of issuance of the other code to identify the ICT third- party service provider
Type of Code:
1.	CRN for Corporate registration number
2.	VAT for VAT number
3.	PNR for Passport Number
4.	NIN for National Identity Number
Only LEI or EUID shall be used for legal persons, as identified in B_05.01.0070, whereas alternative code may be used only for an individual acting in a business capacity.
Only LEI shall be used for legal persons that are not established in the Union.
(pattern)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'type_of_code_to_identify_provider',
    key: 'type_of_code_to_identify_provider',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Rank - <span>0050</span>
        </p>
        <AdditionalInformation
          description="Where the ICT third-party service provider is signing the contractual arrangement with the financial entity, it is considered as a direct ICT third-party service provider and the ‘rank’ to be reported shall be 1;
Where the ICT third-party service provider is signing the contract with the direct ICT third-party service provider, it is considered as a subcontractor and the ‘rank’ to be reported shall be 2;
The same logic apply to all the following subcontractors by incrementing the ‘rank’.
Where multiple ICT third-party service providers have the same ‘rank’ in the ICT service supply chain, financial entities shall report the same ‘rank’ for all those ICT third-party service providers.
(natural number)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'rank',
    key: 'rank',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Identification code of the recipient of sub-contracted ICT services -{' '}
          <span>0060</span>
        </p>
        <AdditionalInformation
          description="To be left blank if the ICT third-party service provider (template B_05.02.0030) is a direct ICT third-party service provider i.e. at ‘rank’ r = 1 (template B_05.02.0050);
Where the ICT third-party service provider is at ‘rank’ r = n where n>1, indicate the ‘Identification code of the recipient of the sub-contracted services’ at ‘rank’ r=n-1 that subcontracted the ICT service (even partially) to the ICT third-party service provider at ‘rank’ r=n.
Examples:
—	The identification code of the direct ICT third- party service provider receiving the service from the subcontractor at rank 2;
—	The identification code of the subcontractor at rank 2 receiving the service from the subcontractor at rank 3.
The code used to identify the recipient of sub- contracted ICT services shall match the identification code provided in B_05.01.0010 for that provider.
(alphanumerical)
(mandatory Not applicable for rank 1)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'identification_code_of_recipient',
    key: 'identification_code_of_recipient',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Type of code to identify the recipient of sub-contracted ICT services
          - <span>0070</span>
        </p>
        <AdditionalInformation
          description="To be left blank where the ICT third-party service provider template B_05.02.0030) is at rank r = 1 (template B_05.02.0050);
Where the ICT third-party service provider is at ‘rank’ r = n where n>1, indicate the ‘Type of code to identify the recipient of the sub-contracted service’ at ‘rank’ r=n-1 that subcontracted the ICT service (even partially) to the ICT third-party service provider at ‘rank’ r=n.
1.	‘LEI’ for LEI
2.	‘EUID’ for EUID
3.	CRN for Corporate registration number
4.	VAT for VAT number
5.	PNR for Passport Number
6.	NIN for National Identity Number
The type of code used to identify the recipient of sub- contracted ICT services shall match the identification code provided in B_05.01.0020 for that provider.
(pattern)
(mandatory Not applicable for rank 1)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'type_of_code_to_identify_recipient',
    key: 'type_of_code_to_identify_recipient',
  },
];

const B0502: React.FC<B0502Props> = ({ entityName }) => {
  const { date, is_displayed, headers } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };

    try {
      const fetchedData = await reportingOp.fetchRT0502(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          contractual_arrangement_ref_number:
            item['Contractual arrangement ref. number'],
          type_of_ICT: item['Type of ICT services'],
          identification_code_of_ICT_provider:
            item['ICT service provider code'],
          type_of_code_to_identify_provider:
            item['Type of ICT service provider code'],
          rank: item['Rank'],
          identification_code_of_recipient: item['Recipient ID code'],
          type_of_code_to_identify_recipient: item['Recipient ID type'],
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0502;
