import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import CustomTable from 'components/CustomTable/CustomTable';
import useBasicFilters from 'utils/hooks/useBasicFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import * as Styled from '../../DataEntry.styled';

interface ICountryComponentDataType {
  key: number;
  id: number;
  country_name: string;
  iso_code: string;
  description: string;
  entity_name: string;
}

const CountryComponent: React.FC = () => {
  const { query } = useBasicFilters();
  const [dataSource, setDataSource] = useState<ICountryComponentDataType[]>([]);
  const [initialData, setInitialData] = useState<ICountryComponentDataType[]>(
    [],
  );

  const handleSave = (row: ICountryComponentDataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const fetchCountry = async () => {
    try {
      const response = await entityOp.fetchCountryByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.country;

      const mappedData = [
        {
          key: 0,
          id: data?.id,
          country_name: data?.country_name || '',
          iso_code: data?.iso_code || '',
          description: data?.description || '',
          entity_name: response?.data?.entity_name,
        },
      ];

      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchCountry();
    }
  }, [query]);

  const handleSubmit = async () => {
    if (JSON.stringify(dataSource) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
      });
      return;
    }
    try {
      const payload = {
        entity_name: query.entity,
        data_list: [...dataSource],
      };
      const response = await entityOp.fetchCountry(payload);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', width: '10%', editable: false },
    { title: 'Name', dataIndex: 'country_name', width: '30%', editable: true },
    { title: 'ISO code', dataIndex: 'iso_code', editable: true },
    { title: 'Description', dataIndex: 'description', editable: true },
    { title: 'Entity name', dataIndex: 'entity_name', editable: false },
  ];

  return (
    <>
      <Styled.AreaButton type="primary" disabled>
        Add a row
      </Styled.AreaButton>
      <CustomTable
        columns={columns}
        dataSource={dataSource as ICountryComponentDataType[]}
        handleSave={handleSave}
      />
      <Styled.AreaButton type="primary" onClick={handleSubmit}>
        Submit
      </Styled.AreaButton>
    </>
  );
};

export default CountryComponent;
