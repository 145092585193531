import React from 'react';
import StepComponent from 'components/StepComponent/CustomSteps';
import ThirdPartyServiceProvider from 'components/Tables/DataEntry/TPP/ThirdPartyServiceProvider/ThirdPartyServiceProvider';
import NonSubstReason from 'components/Tables/DataEntry/TPP/NonSubstReason/NonSubstReason';
import PersonType from 'components/Tables/DataEntry/TPP/PersonType/PersonType';
import CurrencyAndCosts from 'components/Tables/DataEntry/TPP/CurencyAndCosts/CurencyAndCosts';
import Certificates from 'components/Tables/DataEntry/TPP/Certificates/Certificates';

const steps = [
  {
    title: 'ThirdPartyServiceProvider',
    content: <ThirdPartyServiceProvider />,
  },
  { title: 'NonSubstReason', content: <NonSubstReason /> },
  { title: 'PersonType', content: <PersonType /> },
  { title: 'Currency and Costs', content: <CurrencyAndCosts /> },
  { title: 'Certificates', content: <Certificates /> },
];

const TPPSteps: React.FC = () => {
  return <StepComponent steps={steps} title="TPP Steps" />;
};

export default TPPSteps;
