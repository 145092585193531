import AxiosInstance from './AxiosInterceptor';
import Helpers from './Helpers';

interface FilterIctServicesParams {
  entity_id: string;
  entity_name: string;
  tpp_id: string;
  tpp_name: string;
  functions_id: string;
  functions_name: string;
  contractual_arrangement_id: string;
  contractual_arrangement_description: string;
}

const ApiService = {
  getFilters: () =>
    AxiosInstance({
      url: '/api/entities',
      method: 'get',
    }),

  getEntityContractualArrangements: (data: any) => {
    const filtersParams = Helpers.getFilterParams();
    return AxiosInstance({
      url: '/api/entity-contractual-arrangements',
      method: 'get',
      params: {
        entity_id: filtersParams.entity_name?.[0],
      },
    });
  },

  postEntity: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-entity',
      method: 'post',
      data: entityData,
    }),

  getBranches: (data: any) => {
    const filtersParams = Helpers.getFilterParams();
    return AxiosInstance({
      url: '/api/entity-branches',
      method: 'get',
      params: {
        entity_id: filtersParams.entity_name?.[0],
      },
    });
  },

  getContractualArrangementsLei: (data: any) => {
    const filtersParams = Helpers.getFilterParams();
    return AxiosInstance({
      url: '/api/contractual-arrangements-lei',
      method: 'get',
      params: {
        entity_id: filtersParams.entity_name?.[0],
      },
    });
  },
  getContractualArrangementsICT: (data: any) => {
    const filtersParams = Helpers.getFilterParams();
    return AxiosInstance({
      url: '/api/contractual-arrangements-ict',
      method: 'get',
      params: {
        entity_id: filtersParams.entity_name?.[0],
      },
    });
  },

  getContractualArrangementsICTUse: (data: any) => {
    const filtersParams = Helpers.getFilterParams();
    return AxiosInstance({
      url: '/api/contractual-arrangements-ict-use',
      method: 'get',
      params: {
        entity_id: filtersParams.entity_name?.[0],
      },
    });
  },

  postEntityType: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-entity-type',
      method: 'post',
      data: entityData,
    }),

  postHierarchyGroup: (hierarchyGroupData: any) =>
    AxiosInstance({
      url: '/api/insert-hierarchy-group',
      method: 'post',
      data: hierarchyGroupData,
    }),

  getAllEntities: () => {
    return AxiosInstance({
      url: '/api/get-all-entities',
      method: 'get',
    });
  },

  postEntityTypeByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-type',
      method: 'post',
      data: entityData,
    }),

  postHierarchyGroupByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-hierarchy',
      method: 'post',
      data: entityData,
    }),

  postNatureOfEntityByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-nature',
      method: 'post',
      data: entityData,
    }),

  postCompetentAuthorityByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-competent-authority',
      method: 'post',
      data: entityData,
    }),

  postCountryByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-country',
      method: 'post',
      data: entityData,
    }),

  postValueOfTotalAssetsByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-value-of-total-assets',
      method: 'post',
      data: entityData,
    }),

  postActivitiesByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-activities-by-entity-type',
      method: 'post',
      data: entityData,
    }),

  postEntitiesByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-by-id',
      method: 'post',
      data: entityData,
    }),

  postTPPbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-third-party-service-provider',
      method: 'post',
      data: entityData,
    }),

  postNatureOfEntity: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-nature-of-entity',
      method: 'post',
      data: entityData,
    }),

  postCompetentAuthority: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-competent-authority',
      method: 'post',
      data: entityData,
    }),

  postCountry: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-country',
      method: 'post',
      data: entityData,
    }),

  postValueOfTotalAssets: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-value-of-total-assets',
      method: 'post',
      data: entityData,
    }),

  postActivitiesByTypeEntity: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-activities-by-type-entity',
      method: 'post',
      data: entityData,
    }),

  getNonSubstReason: () => {
    return AxiosInstance({
      url: '/api/list-non-subst-reasons',
      method: 'get',
    });
  },

  fetchNonSubstReason: (tpp_name: any, tpp_id: string) =>
    AxiosInstance({
      url: '/api/get-non-subst-reason-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),

  fetchPersonType: (tpp_name: any, tpp_id: string) =>
    AxiosInstance({
      url: '/api/get-person-type-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),

  fetchCurrencyAndCosts: (tpp_name: any, tpp_id: string) =>
    AxiosInstance({
      url: '/api/get-costs-and-currency-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),

  fetchCertificates: (tpp_name: string, tpp_id: string) =>
    AxiosInstance({
      url: '/api/filter-certificates-by-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),

  postTPP: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-third-party-service-provider',
      method: 'post',
      data: entityData,
    }),

  postNonSubstReason: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-non-subst-reason',
      method: 'post',
      data: entityData,
    }),

  postPersonTypes: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-person-type',
      method: 'post',
      data: entityData,
    }),

  postCurrencyAndCosts: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-currency-costs',
      method: 'post',
      data: entityData,
    }),

  postCertificates: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-certificates',
      method: 'post',
      data: entityData,
    }),

  postBranchByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-branch-country-assets-by-entity',
      method: 'post',
      data: entityData,
    }),

  postBranches: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-branch-country-assets',
      method: 'post',
      data: entityData,
    }),
  postFunctionByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/filter-function-details',
      method: 'post',
      data: entityData,
    }),

  postFunctions: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-function-criticallity-auswahl-licenced', //
      method: 'post',
      data: entityData,
    }),

  // fetchICTServices: (entity_id: number, entity_name: string, tpp_id: number, tpp_name: string, function_id:number, function_name: string, contactractual_arrangements_id: number, contractual_arrangements_name: string) => {
  //   const filtersParams = Helpers.getFilterParams();
  //   console.log(filtersParams, 'filtersParams');
  //   return AxiosInstance({
  //     url: '/api/filter-ict-services',
  //     method: 'post',
  //     params: {
  //       entity_id: filtersParams.entity_name?.[0],
  //     },
  //   });
  // },
  fetchICTServices: () => {
    // const filtersParams = Helpers.getFilterParams();
    return AxiosInstance({
      url: '/api/filter-ict-services',
      method: 'post',
      data: {
        // entity_id: filtersParams.entity_name?.[0],
        // entity_name: filtersParams.entity_name?.[0],
        // tpp_id: filtersParams.tpp_id,
        // tpp_name: filtersParams.tpp_name,
        // function_id: filtersParams.function_id,
        // function_name: filtersParams.function_name,
        // contractual_arrangements_id: filtersParams.contractual_arrangements_id,
        // contractual_arrangements_name: filtersParams.contractual_arrangements_name,
      },
    });
  },

  postFunctionsbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-functions',
      method: 'post',
      data: entityData,
    }),
  postContractualArrangementsbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-contractual-arrangements-entity',
      method: 'post',
      data: entityData,
    }),
};

export default ApiService;
