import styled from 'styled-components';
import { Button } from 'antd';

export const GridContainer = styled('div')`
  display: flex;
  column-gap: 170px;
  row-gap: 48px;
  flex-wrap: wrap;
  justify-content: center;

  > a {
    height: fit-content;
    width: 350px;
  }
`;

export const Container = styled('div')`
  padding: 0px;
`;

export const StyledButton = styled(Button)`
  min-width: 150px;
`;

export const SignUpContainer = styled('div')`
  text-align: end;
  padding-right: 24px;
`;
