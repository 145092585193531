import React from 'react';
import { Navigate } from 'react-router-dom';

interface IPrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = () => {
  // const token = Helpers.getToken();

  // return token && !Helpers.isObjEmpty(token) ? (
  //   <>{children}</>
  // ) : (
  //   <Navigate to='/' />
  // );

  return <Navigate to="/" />;
};

export default PrivateRoute;
