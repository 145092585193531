import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification, Table, Input, Button, Modal } from 'antd';
import {
  PlusOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import {
  IData,
  IDataArea,
  EditedData,
} from 'store/ducks/DataEntry/ICTServices/types';
import useCountries from 'utils/hooks/fetchHooks/useCountries';
import { generateUniqueId } from 'utils/hooks/generateUniqueId';
import useFilters from 'utils/hooks/useFilters';
import DynamicField from 'components/DynamicCalendarField/DynamicField';
import CustomSelect from 'components/CustomSelect';
import { globalOp } from 'store/ducks/Global';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import { setSelecedEntity_ICT } from 'store/ducks/DataEntry/ICTServices/ICTServiceSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../../DataEntry.styled';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { functionOp } from 'store/ducks/DataEntry/Functions';

const Data: React.FC = () => {
  const [data, setData] = useState<IDataArea[]>([]);
  const [editedData, setEditedData] = useState<EditedData>({});
  const [changedData, setChangedData] = useState<Record<string, any>[]>([]);
  const [sensitivenessOfData, setSensitivenessOfData] = useState([]);
  const [functionNames, setFunctionNames] = useState<
    { label: string; value: string }[]
  >([]);
  const isLoggedIn = localStorage.getItem('authToken');
  const [isTooltipVisible, setIsTooltipVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const { countries } = useCountries();
  const { selectedEntityForICT, selectedFunctionForICT } = useSelector(
    (state: any) => state.ictServices,
  );
  const { selectedICTForContractualInformation } = useSelector(
    (state: any) => state.contractualInformation,
  );
  const dispatch = useDispatch();
  const { query } = useFilters();
  const fetchICT = ict_serviceOp.useFetchICTServicesForData();
  const fetchFunction = ict_serviceOp.useFetchFunctionsForData();

  useEffect(() => {
    if (!selectedEntityForICT) {
      const selectedEntity = {
        entityId: query.entityId ? Number(query.entityId) : 0,
        entity: query.entity ? String(query.entity) : '',
      };
      dispatch(setSelecedEntity_ICT(selectedEntity));
    }
  }, [query]);

  const fetchData = async () => {
    try {
      let response;
      let data;

      if (selectedICTForContractualInformation) {
        response = await fetchICT();
        data = response?.data.ict_systems;
      } else if (selectedFunctionForICT) {
        response = await fetchFunction();
        data = response?.data.ict_systems;
      }

      if (data?.length === 0) {
        notification.warning({
          message: 'Warning',
          description: response?.data?.message || 'No data available',
          placement: 'topRight',
          duration: 20,
        });
      }
      const mappedData = data?.map((item: any, index: number) => ({
        key: index,
        ict_system: item.name,
        ict_system_id: item.ict_system_id,
        data: item.data
          ? item.data.map((iData: any) => ({
              sensitivenessOfDataStoredbyICTTPSP_id:
                iData.sensitiveness_of_data?.id,
              sensitivenessOfDataStoredbyICTTPSP_name:
                iData.sensitiveness_of_data?.name,
              data_id: iData.id,
              data_location_of_data_at_rest: iData.location_of_data_at_rest.id,
              data_location_of_data_management:
                iData.location_of_data_management.id,
              data_description: iData.description,
              data_ict_system_id: iData.ict_system_id,
              data_start_date: iData.start_date,
              data_end_date: iData.end_date,
            }))
          : [],
      }));

      setData(mappedData || []);
    } catch (error) {
      console.log(error, 'error');
      setData([]);
    }
  };

  useEffect(() => {
    if (selectedICTForContractualInformation || selectedFunctionForICT) {
      fetchData();
      fetchSensitivinessOfData();
    }
  }, [
    selectedEntityForICT,
    selectedICTForContractualInformation,
    selectedFunctionForICT,
  ]);

  const fetchSensitivinessOfData = async () => {
    try {
      const response = await globalOp.fetchSensitivenessOfData();
      if (response?.data.sensitiveness_of_data) {
        const formattedOptions = response.data.sensitiveness_of_data.map(
          (sensitiveness: { name: any; id: any }) => ({
            label: sensitiveness.name,
            value: sensitiveness.id,
          }),
        );
        setSensitivenessOfData(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addNewData = (rowKey: string | number) => {
    const newData: IData = {
      sensitivenessOfDataStoredbyICTTPSP_id: '',
      sensitivenessOfDataStoredbyICTTPSP_name: '',
      data_id: generateUniqueId(),
      data_location_of_data_at_rest: '',
      data_location_of_data_management: '',
      data_description: '',
      data_start_date: '',
      data_end_date: '9999-01-01',
    };

    setData((prevData) => {
      return prevData.map((record) => {
        if (record.ict_system_id === rowKey) {
          return {
            ...record,
            data: [...record.data, newData],
          };
        }
        return record;
      });
    });
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    rowKey: string,
  ) => {
    const { value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    const updatedRow = data.find(
      (row) => row.ict_system_id.toString() == rowKey,
    );
    if (updatedRow) {
      const updatedRowWithChanges: Record<string, any> = {
        ...updatedRow,
        [fieldName]: value,
      };
      if (updatedRow.data) {
        const match = fieldName.match(/^(\d+)_newItem_(.+)$/);
        if (match) {
          const idFromFieldName = match[1];
          const actualFieldName = match[2];
          const targetIndex = updatedRow.data.findIndex(
            (item) => item.data_id.toString() === idFromFieldName,
          );

          if (targetIndex !== -1) {
            updatedRowWithChanges.data[targetIndex] = {
              ...updatedRow.data[targetIndex],
              [actualFieldName]: value,
            };
          } else {
            console.log(
              `No matching data found for idFromFieldName (${idFromFieldName}) in datas`,
            );
          }
        }
      }
      const cleanedUpRow = Object.keys(updatedRowWithChanges).reduce(
        (acc: Record<string, any>, key) => {
          const newKey = key.replace(/^[^a-zA-Z]+/, '');
          acc[newKey] = updatedRowWithChanges[key];
          return acc;
        },
        {},
      );

      setChangedData((prevChangedData) => {
        const newChangedData = [...prevChangedData];
        const existingRowIndex = newChangedData.findIndex(
          (row) => row.ict_system_id === cleanedUpRow.ict_system_id,
        );

        if (existingRowIndex !== -1) {
          const existingRow = newChangedData[existingRowIndex];
          const mergedRow = { ...existingRow, ...cleanedUpRow };
          if (JSON.stringify(existingRow) !== JSON.stringify(mergedRow)) {
            newChangedData[existingRowIndex] = mergedRow;
          }
        } else {
          newChangedData.push(cleanedUpRow);
        }

        return newChangedData;
      });
    } else {
      console.log('No match found for ict_system_id:', rowKey);
    }
  };
  const updateChangedData = (editedData: any, changedData: any) => {
    if (editedData && typeof editedData === 'object') {
      Object.keys(editedData).forEach((key: string) => {
        const keyFromEditedData = parseInt(key.split('_')[0]);
        const matchingChangedRow = changedData.find((dataItem: any) => {
          return dataItem.ict_system_id === keyFromEditedData;
        });

        if (matchingChangedRow) {
          const fieldName = key.replace(/^\d+_/, '');
          if (matchingChangedRow.hasOwnProperty(fieldName)) {
            matchingChangedRow[fieldName] = editedData[key];
          }
        }
      });
    } else {
      console.error('editedData is not an object:', editedData);
    }

    setChangedData([...changedData]);
  };

  useEffect(() => {
    updateChangedData(editedData, changedData);
  }, [editedData]);

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.ict_system_id,
  }));
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  const handleOpenTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: true }));
  };

  const handleCloseTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: false }));
  };

  const handleSubmit = async () => {
    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited Datas to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    const formattedData = {
      entity_name: selectedEntityForICT?.label,

      Data: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.data_id,
            location_of_data_at_rest: itemData.data_location_of_data_at_rest,
            location_of_data_management:
              itemData.data_location_of_data_management,
            description: itemData.data_description,
            start_date: itemData.data_start_date,
            end_date: itemData.data_end_date,
            ict_system_id: item.ict_system_id,
            sensitiveness_of_data_stored_by_ict_tpsp_id:
              itemData.sensitivenessOfDataStoredbyICTTPSP_id,
          })),
        )
        .flat(),

      token: isLoggedIn,
    };
    try {
      if (selectedICTForContractualInformation) {
        const response = await ict_serviceOp.postICTServices(formattedData);
      } else if (selectedFunctionForICT) {
        const response = await functionOp.insertFunctions(formattedData);
      }
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns: EditableColumnType<IDataArea>[] = [
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>ICT System</p>
          </div>
        </Styled.TitleComponent>
      ),
      dataIndex: 'ict_system',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      render: (_, record) => {
        return (
          <div>
            {record.data.map((itemData: IData, index: number) => {
              const idFieldName = `${itemData.data_id}_newItem_data_id`;
              const descriptionFieldName = `${itemData.data_id}_newItem_data_description`;
              const restLocationFieldName = `${itemData.data_id}_newItem_data_location_of_data_at_rest`;
              const managementLocationFieldName = `${itemData.data_id}_newItem_data_location_of_data_management`;
              const startDateFieldName = `${itemData.data_id}_newItem_data_start_date`;
              const endDateFieldName = `${itemData.data_id}_newItem_data_end_date`;
              const sensitivenessOfDataStoredByICTTPSPName = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_name`;
              const sensitivenessOfDataStoredByICTTPSPId = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_id`;
              const rowKey = record.key;
              const handleRemoveData = (dataKey: number, dataID: number) => {
                Modal.confirm({
                  title: 'Are you sure you want to remove this Data?',
                  content: 'This action cannot be undone.',
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk: async () => {
                    const dataToRemove = data.find(
                      (item) => item.key === dataKey,
                    );
                    try {
                      const response = await globalOp.inactivateData([dataID]);
                      const updatedDatas = data.map((data) => {
                        if (data.key === dataKey) {
                          return {
                            ...data,
                            datas: data.data.filter(
                              (data: IData) => data.data_id !== dataID,
                            ),
                          };
                        }
                        return data;
                      });
                      setData(updatedDatas);
                      fetchData();
                    } catch (error) {
                      console.error('Failed to remove Data:', error);
                    }
                  },
                  onCancel: () => {
                    notification.info({
                      message: 'Action Canceled',
                      description: 'The Data removal has been canceled.',
                      duration: 10,
                    });
                  },
                });
              };
              return (
                <Styled.TooltipContainer
                  key={itemData.data_id || index}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                        <Button
                          size="small"
                          type="text"
                          danger
                          onClick={() =>
                            handleRemoveData(record.key, itemData.data_id)
                          }
                        >
                          <DeleteOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Location of the data at rest (storage)
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Identify the country of location of the data at rest (storage) using the ISO 3166–1 alpha–2 code.
If there are several countries of location, additional row(s) shall be used for each country.
(country)
(mandatory if ’Yes’ is reported in B_02.02.0140)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <CustomSelect
                          options={countries}
                          placeholder="Select an option"
                          value={
                            countries.find(
                              (country) =>
                                country.value ===
                                (editedData.hasOwnProperty(
                                  restLocationFieldName,
                                )
                                  ? editedData[restLocationFieldName]
                                  : itemData.data_location_of_data_at_rest),
                            )?.label || itemData.data_location_of_data_at_rest
                          }
                          onChange={(
                            value: string,
                            option: { label: string; value: string },
                          ) => {
                            const idEvent = {
                              target: {
                                value: option.value,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;
                            handleEditChange(
                              idEvent,
                              restLocationFieldName,
                              rowKey,
                            );
                          }}
                          size="large"
                          filterOption={(
                            input: string,
                            option: { label: string },
                          ) =>
                            option?.label
                              ?.toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Location of management of the data (processing)
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Identify the country of location of the management of the data (processing) using the ISO 3166–1 alpha–2 code.
If there are several countries of location, additional row(s) shall be used for each country.
(country)
(mandatory if the ICT service is based on or foresees data processing)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <CustomSelect
                          options={countries}
                          placeholder="Select an option"
                          value={
                            countries.find(
                              (country) =>
                                country.value ===
                                (editedData.hasOwnProperty(
                                  managementLocationFieldName,
                                )
                                  ? editedData[managementLocationFieldName]
                                  : itemData.data_location_of_data_management),
                            )?.label ||
                            itemData.data_location_of_data_management
                          }
                          onChange={(
                            value: string,
                            option: { label: string; value: string },
                          ) => {
                            const idEvent = {
                              target: {
                                value: option.value,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;
                            handleEditChange(
                              idEvent,
                              managementLocationFieldName,
                              rowKey,
                            );
                          }}
                          size="large"
                          filterOption={(
                            input: string,
                            option: { label: string },
                          ) =>
                            option?.label
                              ?.toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Sensitiveness of data stored by Information and
                          Communications Technology's Third-Party Service
                          Provider Description
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(itemData.data_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Start date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(startDateFieldName)
                              ? String(editedData[startDateFieldName] ?? '')
                              : String(itemData.data_start_date ?? '')
                          }
                          fieldName={startDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, startDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          End date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(endDateFieldName)
                              ? String(
                                  editedData[endDateFieldName] ?? '9999-01-01',
                                )
                              : String(itemData.data_end_date ?? '9999-01-01')
                          }
                          fieldName={endDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, endDateFieldName, record.key)
                          }
                          type="date"
                          useDefaultDate={true}
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Sensitiveness of the data stored by the ICT
                            third-party service provider
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Identify the level of sensitiveness of the data stored or processed by the ICT third-party service provider using one of the options provided in the following closed list:
1.	Low
2.	Medium;
3.	High.
The most sensitive data take precedence: e.g. if both ‘Medium’ and ‘High’ apply, then ‘High’ shall be selected.
(closed set of options)
(mandatory if the ICT third-party service provider stores data and if the ICT service is supporting a critical or important function or material part thereof)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <CustomSelect
                          options={sensitivenessOfData}
                          placeholder="Select an option"
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessOfDataStoredByICTTPSPName,
                            )
                              ? editedData[
                                  sensitivenessOfDataStoredByICTTPSPName
                                ]
                              : itemData.sensitivenessOfDataStoredbyICTTPSP_name
                          }
                          onChange={(
                            value: string,
                            option: { label: string; value: string },
                          ) => {
                            const idEvent = {
                              target: {
                                value: option.value,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;
                            handleEditChange(
                              idEvent,
                              sensitivenessOfDataStoredByICTTPSPId,
                              rowKey,
                            );

                            const nameEvent = {
                              target: {
                                value: option.label,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;
                            handleEditChange(
                              nameEvent,
                              sensitivenessOfDataStoredByICTTPSPName,
                              rowKey,
                            );
                          }}
                          size="large"
                          filterOption={(
                            input: string,
                            option: { label: string },
                          ) =>
                            option?.label
                              ?.toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(descriptionFieldName)
                          ? typeof editedData[descriptionFieldName] ===
                            'boolean'
                            ? editedData[descriptionFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[descriptionFieldName])
                          : String(itemData.data_description)
                      }
                      readOnly
                      addonAfter={<PlusCircleOutlined />}
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewData(record.key)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Styled.ButtonDiv isEmpty={dataWithKey.length === 0}></Styled.ButtonDiv>
      <Styled.TooltipTableContainer>
        <Table
          dataSource={dataWithKey}
          rowKey="key"
          scroll={{ x: 'max-content' }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePaginationChange,
            showSizeChanger: pagination.showSizeChanger,
            pageSizeOptions: pagination.pageSizeOptions,
            position: pagination.position,
          }}
          columns={columns}
        />
      </Styled.TooltipTableContainer>
      {dataWithKey.length > 0 && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      )}
    </>
  );
};

export default Data;
