import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialGlobalState from './initialState';

const ictServiceSlice = createSlice({
  name: 'ict_service',
  initialState: initialGlobalState(),
  reducers: {},
});

export const {} = ictServiceSlice.actions;
export default ictServiceSlice;
