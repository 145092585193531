import { IEntityState } from './types';

const initialEntityState = (): IEntityState => ({
  entities: [],
  loading: false,
  isAddRow: false,
  addRowCount: 0,
  entityType: [],
  hierarchyInGroup: [],
  natureOfEntity: [],
  countryComponent: [],
  competentAuthority: [],
  valueOfTotalAssets: [],
  activitiesByTypeEntity: [],
  isEntityAdded: false,
  isHierarchyInGroupAdded: false,
  isEntityTypesAdded: false,
  isNatureOfEntityAdded: false,
  isCompetentAuthorityAdded: false,
  isCountryAdded: false,
  isValueOfTotalAssetsAdded: false,
  isActivitiesByEntityTypesAdded: false,
  isAddRowFilled: false,
  nameOfEntity: '',
  idOfEntity: 0,
  nameOfEntityType: '',
  nameOfHierarchy: '',
  nameOfNature: '',
  nameOfCountry: '',
  nameOfCurrency: '',
  idOfSelectedFinancialEntity: null,
  isSubmitionTrue: false,
});

export default initialEntityState;
