import ApiService from 'utils/ApiCalls';

const fetchBranches = async (data: any) => {
  try {
    const response = await ApiService.getBranches(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchEntityContractualArrangements = async (data: any) => {
  try {
    const response = await ApiService.getEntityContractualArrangements(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchContractualArrangementsLei = async (data: any) => {
  try {
    const response = await ApiService.getContractualArrangementsLei(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchContractualArrangementsICT = async (data: any) => {
  try {
    const response = await ApiService.getContractualArrangementsICT(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchContractualArrangementsICTUse = async (data: any) => {
  try {
    const response = await ApiService.getContractualArrangementsICTUse(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export default {
  fetchEntityContractualArrangements,
  fetchBranches,
  fetchContractualArrangementsLei,
  fetchContractualArrangementsICT,
  fetchContractualArrangementsICTUse,
};
