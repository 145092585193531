import React from 'react';
import StepComponent from 'components/StepComponent/CustomSteps';
import ThirdPartyServiceProvider from 'components/Tables/DataEntry/TPP/ThirdPartyServiceProvider/ThirdPartyServiceProvider';
import NonSubstReason from 'components/Tables/DataEntry/TPP/NonSubstReason/NonSubstReason';
import PersonType from 'components/Tables/DataEntry/TPP/PersonType/PersonType';
import CurrencyAndCosts from 'components/Tables/DataEntry/TPP/CurencyAndCosts/CurencyAndCosts';

const TPPSteps: React.FC = () => {
  const steps = [
    {
      title: 'ICT third-party service provider',
      content: <ThirdPartyServiceProvider />,
    },
    {
      title:
        'Reason where the ICT third- party service provider is considered not substitutable or difficult to be substitutable',
      content: <NonSubstReason />,
    },
    {
      title: 'Type of person of the ICT third-party service provider',
      content: <PersonType />,
    },
    { title: 'Currency And Costs', content: <CurrencyAndCosts /> },
  ];

  return (
    <div>
      <StepComponent steps={steps} title="Entity Steps" />
    </div>
  );
};

export default TPPSteps;
