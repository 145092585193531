import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, message, Steps, Layout, theme } from 'antd';
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { TitleList, TitleDataEntry } from 'store/ducks/globalTypes';
import { RootState } from 'store/configureStore';
import {
  setSelectedTPP,
  set_isTppUpdated,
} from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import {
  setSelectedContract_ICT,
  setSelectedFunction_ICT,
  setSelectedTPP_ICT,
  setSelecedEntity_ICT,
} from 'store/ducks/DataEntry/ICTServices/ICTServiceSlice';
import {
  setSelecedEntity_Contractual_Information,
  setSelectedBranch_Contractual_Information,
  setSelectedICT_Contractual_Information,
  setSelectedTPP_Contractual_Information,
} from 'store/ducks/DataEntry/ContractualInformation/contractualInformationSlice';
import GoBackButton from 'components/GoBackButton';
import TitleBar from 'components/TitleBar/TitleBar';
import useFilters from 'utils/hooks/useFilters';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';
import { contractual_informationOp } from 'store/ducks/DataEntry/ContractualInformation';
import { ISelectedValueContract } from 'store/ducks/DataEntry/ICTServices/types';
import { ISelectedValueICT } from 'store/ducks/DataEntry/ContractualInformation/types';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import {
  set_idOfSelectedFinancialEntity,
  set_isSubmitionTrue,
} from 'store/ducks/DataEntry/Entities/entitySlice';
import * as Styled from 'components/Layout/Layout.styled';

interface Step {
  title: string;
  content: React.ReactNode;
}

interface StepComponentProps {
  steps: Step[];
  title: string;
  children?: React.ReactNode;
  footer?: JSX.Element;
}
const StepComponent: React.FC<StepComponentProps> = ({ steps, footer }) => {
  const { token } = theme.useToken();
  const dispatch = useDispatch();
  const location = useLocation();
  const [current, setCurrent] = useState(0);
  const [open, setOpen] = useState(false);
  const [all, setAll] = useState(false);
  const [pathNameTPP, setPathNameTPP] = useState(false);
  const [selectedTPPForICT, setSelectedTPPForICT] = useState<
    { value: string; label: string } | undefined
  >(undefined);
  const fetchICT = ict_serviceOp.useFetchICTServices();
  const [selectedFunctionForICT, setSelectedFunctionForICT] = useState<
    { value: string; label: string } | undefined
  >(undefined);
  const [selectedContractForICT, setSelectedContractForICT] = useState<
    string | undefined
  >(undefined);
  const [
    selectedBranchForContractualInformation,
    setSelectedBranchForContractualInformation,
  ] = useState<{ value: string; label: string } | undefined>(undefined);
  const [
    selectedICTForContractualInformation,
    setSelectedICTForContractualInformation,
  ] = useState<string | undefined>(undefined);

  const [tppdData, setTPPData] = useState<any[]>([]);
  const [entityMaintaining, setEntityMaintaining] = useState<any[]>([]);
  const [functionData, setFunctionData] = useState<any[]>([]);
  const [contractsData, setContractsData] = useState<any[]>([]);
  const [branchData, setBranchData] = useState<any[]>([]);
  const [ictData, setICTData] = useState<any[]>([]);
  const [refNumber, setRefNumber] = useState<
    {
      key: number;
      ictService_contractual_arrangement_reference_number: string;
    }[]
  >([]);
  const { query, handleFilterChange, extractedOptions } = useFilters();
  const { scheme } = useSelector((state: RootState) => state.global);
  const { idOfSelectedFinancialEntity, isSubmitionTrue } = useSelector(
    (state: RootState) => state.entities,
  );

  const { selectedTPP, isTppUpdated } = useSelector(
    (state: RootState) => state.tpp,
  );

  const { Sider } = Layout;
  const showDrawer = () => setOpen(true);
  const next = () => setCurrent((prev) => prev + 1);
  const prev = () => setCurrent((prev) => prev - 1);
  const onClose = () => setOpen(false);
  const onStepChange = (stepIndex: number) => setCurrent(stepIndex);

  const applyFilters = () => {
    setOpen(false);
    message.success('Filters applied');
  };
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#aaa',
    backgroundColor: '#f0f2f5',
    borderRadius: '8px',
    border: '1px solid #d9d9d9',
    marginTop: 16,
  };

  useEffect(() => {
    setAll(steps[current]?.title === 'ICT third-party service provider');
  }, [current, steps]);

  useEffect(() => {
    if (
      steps[current]?.title !== 'ICT third-party service provider' &&
      selectedTPP === 'All'
    ) {
      dispatch(setSelectedTPP(''));
      message.info('Please select a valid TPP.');
    }
  }, [current, steps]);

  const fetchICTServices = async () => {
    try {
      const response = await fetchICT();
      const data = response?.data['ICT services'];
      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        ictService_contractual_arrangement_reference_number:
          item.contractual_arrangements.arrangement_reference_number,
      }));
      setRefNumber(mappedData);
    } catch (error) {}
  };

  const fetchEntityMaintaining = async () => {
    try {
      const response = await entityOp.fetchAllFinancialEntities();
      const data = response?.data;
      if (data.length > 0) {
        const updatedTPPData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          financial_entity_name: item.financial_entity_name,
        }));
        setEntityMaintaining(updatedTPPData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTPPbyEntity = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;
      if (data.length > 0) {
        const updatedTPPData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          third_party_service_provider_name:
            item.third_party_service_provider_name,
        }));
        setTPPData(updatedTPPData);
      } else {
        setTPPData([]);
      }
      setSelectedTPPForICT(undefined);
      dispatch(setSelectedTPP(''));
    } catch (error) {
      console.log(error, 'error');
      setTPPData([]);
    }
  };

  const fetchFunctionsByEntity = async () => {
    try {
      const response = await ict_serviceOp.fetchFunctionsbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data;
      if (data.length > 0) {
        const updatedFunctionsData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          function_name: item.function_name,
        }));
        setFunctionData(updatedFunctionsData);
      } else {
        setFunctionData([]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchContractsByEntity = async () => {
    try {
      const response =
        await ict_serviceOp.fetchContractualArrangementsbyEntityName({
          entity_id: query.entityId,
          entity_name: query.entity,
        });
      const data = response?.data;

      if (data.length > 0) {
        const updatedContractsData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          description: item.description,
        }));
        setContractsData(updatedContractsData);
      } else {
        setContractsData([]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchBranchesByEntity = async () => {
    try {
      const response =
        await contractual_informationOp.fetchBranchesbyEntityName({
          entity_id: query.entityId,
          entity_name: query.entity,
        });
      const data = response?.data;
      if (data.length > 0) {
        const updatedBranchesData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          branch_name: item.branch_name,
        }));
        setBranchData(updatedBranchesData);
      } else {
        setBranchData([]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchICTServicesByEntity = async () => {
    try {
      const response =
        await contractual_informationOp.fetchICTServicesbyEntityName({
          entity_id: query.entityId,
          entity_name: query.entity,
        });
      const data = response?.data;
      if (data.length > 0) {
        const updatedICTServicedData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          ict_services_name: item.ict_services_name,
        }));
        setICTData(updatedICTServicedData);
      } else {
        setICTData([]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    fetchEntityMaintaining();
  }, []);

  useEffect(() => {
    fetchICTServices();
  }, [selectedContractForICT]);

  // useEffect(() => {
  //   fetchICTServicesForData()
  // }, [selectedICTForContractualInformation]);

  useEffect(() => {
    if (isSubmitionTrue) {
      fetchEntityMaintaining();
      dispatch(set_isSubmitionTrue(false));
    }
  }, [isSubmitionTrue]);

  useEffect(() => {
    if (query.entity) {
      fetchTPPbyEntity();
      fetchFunctionsByEntity();
      fetchBranchesByEntity();
      fetchContractsByEntity();
      fetchICTServicesByEntity();
    }
  }, [query.entity]);

  useEffect(() => {
    if (isTppUpdated) {
      fetchTPPbyEntity();
      dispatch(set_isTppUpdated(false));
    }
  }, [isTppUpdated]);

  const handleTPPForICTChange = (value: string, option: { label: string }) => {
    setSelectedTPPForICT({ value, label: option.label });
    dispatch(setSelectedTPP(value));

    if (value) {
      const selectedTPP = {
        value: value,
        label: option.label,
      };
      dispatch(setSelectedTPP_ICT(selectedTPP));
      dispatch(setSelectedTPP_Contractual_Information(selectedTPP));
      setSelectedFunctionForICT(undefined);
      setSelectedContractForICT(undefined);
      setSelectedBranchForContractualInformation(undefined);
      setSelectedICTForContractualInformation(undefined);
    }
  };

  const isTPPDisabled =
    selectedFunctionForICT ||
    selectedContractForICT ||
    selectedBranchForContractualInformation ||
    selectedICTForContractualInformation !== undefined;
  const isFunctionDisabled =
    selectedContractForICT || selectedTPPForICT !== undefined;
  const isContractDisabled =
    selectedTPPForICT || selectedFunctionForICT !== undefined;

  const isBranchDisabled =
    selectedICTForContractualInformation || selectedTPPForICT !== undefined;
  const isICTDisabled =
    selectedBranchForContractualInformation || selectedTPPForICT !== undefined;
  const isICTForDataDisabled = selectedFunctionForICT !== undefined;
  const isFunctionForDataDisabled =
    selectedICTForContractualInformation !== undefined;

  useEffect(() => {
    if (isTPPDisabled) {
      dispatch(setSelectedTPP_Contractual_Information(null));
      // setSelectedTPP(null);
      // setSelectedTPP_Contractual_Information(null);
      setSelectedTPP_ICT(null);
      setSelectedTPPForICT(undefined);
      dispatch(setSelectedTPP_ICT(null));
    }
    if (isFunctionDisabled) {
      setSelectedFunctionForICT(undefined);
      dispatch(setSelectedFunction_ICT(null));
    }
    if (isContractDisabled) {
      setSelectedContractForICT(undefined);
      dispatch(setSelectedContract_ICT(null));
    }
    if (isBranchDisabled) {
      setSelectedBranchForContractualInformation(undefined);
      dispatch(setSelectedBranch_Contractual_Information(null));
    }
    if (isICTDisabled) {
      setSelectedICTForContractualInformation(undefined);
      dispatch(setSelectedICT_Contractual_Information(null));
    }
    if (isICTForDataDisabled) {
      setSelectedICTForContractualInformation(undefined);
      dispatch(setSelectedICT_Contractual_Information(null));
    }
    if (isFunctionForDataDisabled) {
      setSelectedFunctionForICT(undefined);
      dispatch(setSelectedFunction_ICT(null));
    }
  }, [
    isTPPDisabled,
    isFunctionDisabled,
    isContractDisabled,
    isBranchDisabled,
    isICTDisabled,
    isICTForDataDisabled,
    isFunctionForDataDisabled,
    setSelectedTPPForICT,
    setSelectedFunctionForICT,
    setSelectedContractForICT,
    setSelectedBranchForContractualInformation,
    setSelectedICTForContractualInformation,
  ]);

  const handleChangeFinancialEntity = (selectedOption: any) => {
    dispatch(set_idOfSelectedFinancialEntity(selectedOption));
  };

  const handleChange = (selectedLabel: string) => {
    const selectedOption = extractedOptions.find(
      (option: { label: string }) => option.label === selectedLabel,
    );
    if (selectedOption) {
      const selectedEntity = {
        entityId: selectedOption.key,
        entity: selectedOption.label,
      };
      dispatch(setSelecedEntity_ICT(selectedEntity));

      dispatch(setSelecedEntity_Contractual_Information(selectedEntity));

      handleFilterChange(selectedOption.label, selectedOption.key);
      setSelectedTPPForICT(undefined);
      setSelectedFunctionForICT(undefined);
      setSelectedBranchForContractualInformation(undefined);
      setSelectedContractForICT(undefined);
      setSelectedICTForContractualInformation(undefined);
    } else {
      console.log('No matching option found for label:', selectedLabel);
    }
  };

  const handleContractForICTChange = (value: string | undefined) => {
    setSelectedContractForICT(value);

    if (value) {
      const contractPayload: ISelectedValueContract = { value };
      dispatch(setSelectedContract_ICT(contractPayload));
    } else {
      dispatch(setSelectedContract_ICT(null));
    }

    setSelectedTPPForICT(undefined);
    setSelectedFunctionForICT(undefined);
  };
  const handleICTServicesForICTChange = (value: string | undefined) => {
    setSelectedICTForContractualInformation(value);

    if (value) {
      const ictPayload: ISelectedValueICT = { value };
      dispatch(setSelectedICT_Contractual_Information(ictPayload));
    } else {
      dispatch(setSelectedICT_Contractual_Information(null));
    }

    setSelectedTPPForICT(undefined);
    setSelectedFunctionForICT(undefined);
  };

  const handleRefresh = () => {
    setSelectedTPPForICT(undefined);
    setSelectedFunctionForICT(undefined);
    setSelectedBranchForContractualInformation(undefined);
    setSelectedContractForICT(undefined);
    setSelectedICTForContractualInformation(undefined);
  };

  const titleMap: Record<string, string> = {
    '/data_entry/third_party_provider': TitleDataEntry.THIRD_PARTY_PROVIDER,
    '/data_entry/entity_maintaining_registry':
      TitleDataEntry.ENTITY_MAINTAINING_REGISTRY,
    '/data_entry/entity': TitleDataEntry.ENTITY,
    '/data_entry/function': TitleDataEntry.FUNCTION,
    '/data_entry/ict_service': TitleDataEntry.ICT_SERVICES,
    '/data_entry/contractual_information':
      TitleDataEntry.CONTRACTUAL_INFORMATION,
    '/data_entry/branch': TitleDataEntry.BRANCH,
    '/data_entry/data': TitleDataEntry.DATA,
  };

  const title = titleMap[location.pathname];

  useEffect(() => {
    if (location.pathname === '/data_entry/third_party_provider') {
      setPathNameTPP(true);
    }
  }, [location]);

  const handleFunctionForICTChange = (
    value: string | undefined,
    option: { label: string },
  ) => {
    const selectedOption = functionData.find((item) => item.id === value);
    const selectedFunction = selectedOption
      ? { value: selectedOption.id, label: selectedOption.function_name }
      : undefined;

    setSelectedFunctionForICT(selectedFunction);

    if (selectedFunction) {
      dispatch(setSelectedFunction_ICT(selectedFunction));

      setSelectedTPPForICT(undefined);
      setSelectedContractForICT(undefined);
    } else {
      console.log('No matching function found for value:', value);
    }
  };

  const handleBranchForContractualInformationChange = (
    value: string | undefined,
    option: { label: string },
  ) => {
    const selectedOption = branchData.find((item) => item.id === value);
    const selectedBranch = selectedOption
      ? { value: selectedOption.id, label: selectedOption.branch_name }
      : undefined;

    setSelectedBranchForContractualInformation(selectedBranch);

    if (selectedBranch) {
      dispatch(setSelectedBranch_Contractual_Information(selectedBranch));

      setSelectedTPPForICT(undefined);
      setSelectedICTForContractualInformation(undefined);
    } else {
      console.log('No matching branch found for value:', value);
    }
  };

  return (
    <Styled.StyledLayout token={token}>
      <TitleBar title={TitleList.DATA_ENTRY} />
      <Styled.WorkingAreaButton>
        <div>
          <GoBackButton to="/" text="Working areas" />
          <GoBackButton
            to={`/data_entry${location.search}`}
            text="Data Entry"
          />
        </div>

        <Button type="primary" onClick={showDrawer} icon={<FilterOutlined />}>
          Filter
        </Button>
      </Styled.WorkingAreaButton>
      <Styled.HorizontalLayout theme={scheme}>
        <Styled.StepsComponent>
          <Styled.AreaTitle>
            <Styled.TitleContainer theme={scheme}>
              <div>
                <Styled.SubtitleText>
                  {title ? (
                    <Styled.DataEntryTitle>{title}</Styled.DataEntryTitle>
                  ) : null}
                </Styled.SubtitleText>
              </div>
            </Styled.TitleContainer>
          </Styled.AreaTitle>
          <Styled.SelectedFilters>
            {query.entity && <h2>Entity: {query.entity}</h2>}
            {steps[current]?.title === 'Branch' && (
              <Styled.BranchInfo>
                If there is no branch please fill 'n/a' in all the required
                fields
              </Styled.BranchInfo>
            )}

            {selectedTPPForICT?.label && (
              <h2>
                ICT third-party service provider: {selectedTPPForICT.label}
              </h2>
            )}
            {selectedBranchForContractualInformation?.label && (
              <h2>Branch: {selectedBranchForContractualInformation.label}</h2>
            )}
            {selectedICTForContractualInformation && (
              <h2>ICT Service: {selectedICTForContractualInformation}</h2>
            )}
            {selectedFunctionForICT?.label && (
              <h2>Function: {selectedFunctionForICT.label}</h2>
            )}
            {selectedContractForICT && (
              <h2>
                Contractual Arrangement:
                {refNumber[0]
                  ?.ictService_contractual_arrangement_reference_number
                  ? refNumber[0]
                      ?.ictService_contractual_arrangement_reference_number
                  : selectedContractForICT}{' '}
              </h2>
            )}
          </Styled.SelectedFilters>
          {!query.entity && (
            <Styled.AlertInfo
              description="Please filter"
              type="info"
              closable
            />
          )}
          <Steps current={current} items={items} onChange={onStepChange} />
          {query.entity &&
            steps[current]?.title === 'ICT third-party service provider' &&
            !selectedTPP && (
              <Styled.AlertInfoForTPP
                description="Please filter the TPP."
                type="info"
                closable
              />
            )}
          {steps[current]?.title === 'Currency And Costs' && (
            <Styled.AlertInfoReporting
              description="In order to have the correct contractual arrangement number selectable, please fill in contractual arrangements first."
              type="error"
              closable
            />
          )}

          <Styled.SelectionOfTPP>
            {pathNameTPP && (
              <Styled.SelectFilter
                placeholder="ICT third-party service provider"
                options={tppdData
                  .filter(
                    (item) =>
                      item && item.id && item.third_party_service_provider_name,
                  )
                  .map((item) => ({
                    value: item.id,
                    label: item.third_party_service_provider_name,
                    key: item.key,
                  }))}
                // options={
                //   steps[current]?.title === 'ICT third-party service provider'
                //     ? [
                //         { value: 'All', label: 'All', key: 'all' },
                //         ...tppdData
                //           .filter(
                //             (item) =>
                //               item &&
                //               item.id &&
                //               item.third_party_service_provider_name,
                //           )
                //           .map((item) => ({
                //             value: item.id,
                //             label: item.third_party_service_provider_name,
                //             key: item.key,
                //           })),
                //       ]
                //     : tppdData
                //         .filter(
                //           (item) =>
                //             item &&
                //             item.id &&
                //             item.third_party_service_provider_name,
                //         )
                //         .map((item) => ({
                //           value: item.id,
                //           label: item.third_party_service_provider_name,
                //           key: item.key,
                //         }))
                // }
                onChange={handleTPPForICTChange}
                value={selectedTPPForICT}
              />
            )}
          </Styled.SelectionOfTPP>
          {steps[current]?.title !== 'Branch' &&
            steps[current]?.title !== 'Function' &&
            steps[current]?.title !== 'ICTService' &&
            steps[current]?.title !== 'Contractual Information' &&
            steps[current]?.title !==
              'Entity Maintaining Register Of Information' &&
            steps[current]?.title !== 'Data' &&
            query.entity && (
              <Styled.StepButtons>
                <div>
                  <Button
                    onClick={prev}
                    disabled={
                      steps[current]?.title ===
                        'ICT third-party service provider' ||
                      steps[current]?.title === 'Entity'
                    }
                  >
                    Previous
                  </Button>
                  {current < steps.length - 1 && (
                    <Button type="primary" onClick={next}>
                      Next
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={() => message.success('Processing complete!')}
                    >
                      Done
                    </Button>
                  )}
                </div>
              </Styled.StepButtons>
            )}

          <div style={contentStyle}>
            {steps[current].content} {footer && <div>{footer}</div>}{' '}
          </div>
        </Styled.StepsComponent>
      </Styled.HorizontalLayout>
      <Styled.StyledLayout token={token}>
        <Sider>
          <Styled.AntDrawer title="Filters" onClose={onClose} open={open}>
            {steps[current]?.title !== 'ICTService' &&
              steps[current]?.title !== 'Contractual Information' &&
              steps[current]?.title !==
                'Entity Maintaining Register Of Information' &&
              steps[current]?.title !== 'Data' && (
                <Styled.SelectFilter
                  placeholder="Entity"
                  options={extractedOptions.map(
                    (option: { label: any; key: any }) => ({
                      value: option.label,
                      key: option.key,
                      label: option.label,
                    }),
                  )}
                  value={query.entity}
                  onChange={handleChange}
                />
              )}
            {steps[current]?.title ===
              'Entity Maintaining Register Of Information' && (
              <Styled.SelectFilter
                placeholder="Entity Maintaining Register"
                options={entityMaintaining.map(
                  (option: {
                    id: number;
                    key: any;
                    financial_entity_name: string;
                  }) => ({
                    value: option.id,
                    key: option.key,
                    label: option.financial_entity_name,
                  }),
                )}
                value={idOfSelectedFinancialEntity}
                onChange={handleChangeFinancialEntity}
              />
            )}
            {steps[current]?.title === 'ICTService' && (
              <>
                <Styled.SelectFilter
                  placeholder="Entity"
                  options={extractedOptions.map(
                    (option: { label: any; key: any }) => ({
                      value: option.label,
                      key: option.key,
                      label: option.label,
                    }),
                  )}
                  value={query.entity}
                  onChange={handleChange}
                />
                <Styled.SelectFilter
                  placeholder="Contractual arrangements"
                  options={contractsData.map((item) => ({
                    value: item.id,
                    label: item.description || item.id,
                    key: item.key,
                  }))}
                  onChange={(value: string | undefined) =>
                    handleContractForICTChange(value)
                  }
                  value={selectedContractForICT}
                  disabled={isContractDisabled}
                />

                {/* <Styled.SelectFilter
                  placeholder="ICT third-party service provider"
                  options={tppdData.map((item) => ({
                    value: item.id,
                    label: item.third_party_service_provider_name,
                    key: item.key,
                  }))}
                  onChange={handleTPPForICTChange}
                  value={selectedTPPForICT}
                  disabled={isTPPDisabled}
                /> */}
                <Styled.StyledButton onClick={handleRefresh} type="primary">
                  <ReloadOutlined />
                </Styled.StyledButton>
              </>
            )}

            {steps[current]?.title === 'Contractual Information' && (
              <>
                <Styled.SelectFilter
                  placeholder="Entity"
                  options={extractedOptions.map(
                    (option: { label: any; key: any }) => ({
                      value: option.label,
                      key: option.key,
                      label: option.label,
                    }),
                  )}
                  value={query.entity}
                  onChange={handleChange}
                />
                <Styled.SelectFilter
                  placeholder="ICT third-party service provider"
                  options={tppdData.map((item) => ({
                    value: item.id,
                    label: item.third_party_service_provider_name,
                    key: item.key,
                  }))}
                  onChange={handleTPPForICTChange}
                  value={selectedTPPForICT}
                  disabled={isTPPDisabled}
                />
                {/* <Styled.SelectFilter
                  placeholder="ICT Services"
                  options={ictData.map((item) => ({
                    value: item.id,
                    label: item.description,
                    key: item.key,
                  }))}
                  onChange={(value: string | undefined) =>
                    handleICTServicesForICTChange(value)
                  }
                  value={selectedICTForContractualInformation}
                  disabled={isICTDisabled}
                /> */}

                <Styled.StyledButton onClick={handleRefresh}>
                  <ReloadOutlined />
                </Styled.StyledButton>
              </>
            )}
            {steps[current]?.title === 'Data' && (
              <>
                <Styled.SelectFilter
                  placeholder="Entity"
                  options={extractedOptions.map(
                    (option: { label: any; key: any }) => ({
                      value: option.label,
                      key: option.key,
                      label: option.label,
                    }),
                  )}
                  value={query.entity}
                  onChange={handleChange}
                />
                <Styled.SelectFilter
                  placeholder="ICT Services"
                  options={ictData.map((item) => ({
                    value: item.id,
                    label: item.description,
                    key: item.key,
                  }))}
                  onChange={(value: string | undefined) =>
                    handleICTServicesForICTChange(value)
                  }
                  value={selectedICTForContractualInformation}
                  disabled={isICTForDataDisabled}
                />
                <Styled.SelectFilter
                  placeholder="Functions"
                  options={functionData.map((item) => ({
                    value: item.id,
                    label: item.description,
                    key: item.key,
                  }))}
                  onChange={handleFunctionForICTChange}
                  value={selectedFunctionForICT}
                  disabled={isFunctionForDataDisabled}
                />
                <Styled.StyledButton onClick={handleRefresh} type="primary">
                  <ReloadOutlined />
                </Styled.StyledButton>
              </>
            )}
            <Styled.StyledButton type="primary" onClick={applyFilters}>
              Display
            </Styled.StyledButton>
          </Styled.AntDrawer>
        </Sider>
      </Styled.StyledLayout>
    </Styled.StyledLayout>
  );
};

export default StepComponent;
