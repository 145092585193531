import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';

interface DataType {
  key: React.Key;
  branch_identification_code: string;
  branch_name: string;
  country_id: number;
  head_office_lei: string;
  name_of_entity: string;
}

interface RT0103Props {
  entityName: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Identification code of the branch',
    width: 100,
    dataIndex: 'branch_identification_code',
    key: 'branch_identification_code',
    fixed: 'left',
  },
  {
    title: 'LEI of the financial entity head office of the branch',
    width: 100,
    dataIndex: 'head_office_lei',
    key: 'head_office_lei',
  },
  {
    title: 'Name of the branch',
    width: 100,
    dataIndex: 'branch_name',
    key: 'branch_name',
    fixed: 'left',
  },
  {
    title: 'Country of the branch',
    width: 100,
    dataIndex: 'country_id',
    key: 'country_id',
  },
  {
    title: 'Name of the entity',
    width: 100,
    dataIndex: 'name_of_entity',
    key: 'name_of_entity',
  },
];

const RT0103: React.FC<RT0103Props> = ({ entityName }) => {
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        entity_name: entityName,
      };
      try {
        const fetchedData = await reportingOp.fetchBranches(params);
        if (Array.isArray(fetchedData)) {
          const formattedData = fetchedData.map((item: any, index: number) => ({
            key: index,
            branch_identification_code: item.branch_identification_code,
            branch_name: item.branch_name,
            country_id: item.country_id,
            head_office_lei: item.head_office_lei,
            name_of_entity: item.name_of_entity,
          }));
          setData(formattedData);
        } else {
          console.error('Fetched data is not an array:', fetchedData);
        }
      } catch (error) {
        console.error('Error fetching branches:', error);
        setData([]);
      }
    };

    if (entityName) {
      fetchData();
    }
  }, [entityName, dispatch]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default RT0103;
