import React from 'react';
import WorkingArea from 'components/WorkingAreas/WorkingAreas';
import InsourceLayout from 'components/Layout/DataEntryLayout/Layout';
import OutsourceLayout from 'components/Layout/ReportingLayout/Layout';
import EntitySteps from 'pages/EntitySteps/EntitySteps';
import TPPSteps from 'pages/TPPSteps/TPPSteps';
import BranchSteps from 'pages/BranchSteps/BranchSteps';
import FunctionSteps from 'pages/FunctionSteps/FunctionSteps';
import LoginForm from 'pages/Login/Login';
import ForgotPass from 'pages/ForgotPassword/ForgotPassword';
import ICTServiceSteps from 'pages/ICTServiceSteps/ICTServiceSteps';

const routes = [
  {
    id: 1,
    path: '/',
    private: false,
    component: () => <LoginForm />,
  },
  {
    id: 2,
    path: '/working-area',
    private: false,
    component: () => <WorkingArea />,
  },
  {
    id: 3,
    path: '/data-entry',
    private: false,
    component: () => <InsourceLayout />,
  },
  {
    id: 4,
    path: '/reporting',
    private: false,
    component: () => <OutsourceLayout />,
  },
  {
    id: 5,
    path: '/data-entry/entity',
    private: false,
    component: () => <EntitySteps />,
  },
  {
    id: 6,
    path: '/data-entry/third-party-provider',
    private: false,
    component: () => <TPPSteps />,
  },
  {
    id: 7,
    path: '/data-entry/branch',
    private: false,
    component: () => <BranchSteps />,
  },
  {
    id: 8,
    path: '/data-entry/function',
    private: false,
    component: () => <FunctionSteps />,
  },
  {
    id: 9,
    path: '/forgot-password',
    private: false,
    component: () => <ForgotPass />,
  },
  {
    id: 10,
    path: '/data-entry/ict-service',
    private: false,
    component: () => <ICTServiceSteps />,
  },
];

export default routes;
