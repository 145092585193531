import React, { useState, useEffect } from 'react';
import { Button, notification, Input, Table, Modal } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  CloseCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { functionOp } from 'store/ducks/DataEntry/Functions';
import useFilters from 'utils/hooks/useFilters';
import {
  ICertificate,
  IFunction,
} from 'store/ducks/DataEntry/Functions/types';
import useLicensedActivities from 'utils/hooks/fetchHooks/useLicensedActivities';
import useICTSystems from 'utils/hooks/fetchHooks/useICTSystems';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import DynamicField from 'components/DynamicCalendarField/DynamicField';
import { generateUniqueId } from 'utils/hooks/generateUniqueId';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';
import * as Styled from '../../DataEntry.styled';

interface EditableColumnType<T> extends ColumnType<T> {
  editable?: true;
}

interface EditedData {
  [key: string]: string | number | boolean | undefined;
}

const Function = () => {
  const { query } = useFilters();
  dayjs.extend(customParseFormat);
  const { confirm } = Modal;
  const [data, setData] = useState<IFunction[]>([]);
  const [editedData, setEditedData] = useState<EditedData>({});
  const [changedData, setChangedData] = useState<Record<string, any>[]>([]);
  const {licensedActivities } = useLicensedActivities();
  const {ictSystems } = useICTSystems(query.entityId ?? "", query.entity ?? "");
  const [fLevels, setFLevels] = useState([]);
  const [impact, setImpact] = useState([]);
  const [criticallyAssessments, setCriticallyAssessments] = useState<
    { label: string; value: string }[]
  >([]);
  const [isTooltipVisible, setIsTooltipVisible] = useState<{
    [key: string]: boolean;
  }>({});

  const addNewCertificate = (rowKey: string | number) => {
    const newCertificate: ICertificate = {
      certificates_id: generateUniqueId(),
      certificates_description: '',
      certificates_validity: '',
      certificates_date_of_issuance: '',
      certificates_date_of_certificate_register: '',
      certificates_function_id: rowKey,
      certificates_registry: '',
      certificates_end_date: '9999-01-01',
      certificates_start_date: '',
    };

    setData((prevData) => {
      return prevData.map((record) => {
        if (record.f_function_id === rowKey) {
          return {
            ...record,
            certificates: [...record.certificates, newCertificate],
          };
        }
        return record;
      });
    });
  };

  const fetchAssessments = async () => {
    try {
      const response = await ict_serviceOp.fetchCriticalityAssessments();
      if (response?.data.criticality_assessments) {
        const formattedOptions = response.data.criticality_assessments.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setCriticallyAssessments(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchImpactOfDiscontinuingICTServices = async () => {
    try {
      const response =
        await ict_serviceOp.fetchImpactOfDiscontinuingICTServices();
      if (response?.data.impact_of_discontinuing_ict_services) {
        const formattedOptions =
          response.data.impact_of_discontinuing_ict_services.map(
            (impact: { description: any; id: any }) => ({
              label: impact.description,
              value: impact.id,
            }),
          );
        setImpact(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFunctionLevels = async () => {
    try {
      const response = await functionOp.fetchFunctionLevels();
      if (response?.data.function_levels) {
        const formattedOptions = response.data.function_levels.map(
          (level: { name: any; id: any }) => ({
            label: level.name,
            value: level.id,
          }),
        );
        setFLevels(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFunctionbyEntity = async () => {
    try {
      const response = await functionOp.fetchFunctionbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.functions;

      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        isNew: false,
        isEdited: false,
        criticality_assessment_id: item.criticality_assessment.id,
        criticality_assessment_name: item.criticality_assessment.name,
        criticality_assessment_description:
          item.criticality_assessment.description,
        auswahl_function_discont_id: item.impact_of_discontinuing?.id,
        auswahl_function_discont_name: item.impact_of_discontinuing?.name,
        auswahl_function_discont_description:
          item.impact_of_discontinuing?.description,
        selection_licenced_act_id: item.licensed_activity.id,
        selection_licenced_act_name: item.licensed_activity.name,
        selection_licenced_act_description: item.licensed_activity.description,
        dora_criticality_name: item.dora_criticality?.name,
        ict_systems: item.ict_systems
          ? item.ict_systems.map((ict_system: any) => ({
              ict_systems_id: ict_system.id,
              ict_systems_name: ict_system.name,
            }))
          : [],
        certificates: item.certificates
          ? item.certificates.map((certificate: any) => ({
              certificates_id: certificate.id,
              certificates_description: certificate.description,
              certificates_validity: certificate.validity,
              certificates_date_of_issuance: certificate.date_of_issuance,
              certificates_registry: certificate.certificate_registry,
              certificates_date_of_certificate_register:
                certificate.date_of_certificate_register,
              certificates_function_id: certificate.function_id,
              certificates_end_date: certificate.end_date,
              certificates_start_date: certificate.start_date,
            }))
          : [],
        function_levels: item.function_level
          ? item.function_level.map((level: any) => ({
              function_level_id: level.id,
              function_level_name: level.name,
              function_level_description: level.description,
            }))
          : [],
        f_function_level_id: item.function_level?.[0]?.id?.toString() || '',
        f_function_id: item.function_id,
        f_function_identifier: item.function_identifier,
        f_function_name: item.function_name,
        f_criticality_assessment_id: item.criticality_assessment_id,
        f_reasons_for_criticality: item.reasons_for_criticality,
        f_date_of_last_assessment: item.date_of_last_assessment,
        f_recovery_time_objective: item.recovery_time_objective,
        f_recovery_point_objective: item.recovery_point_objective,
        f_impact_of_discontinuing_id: item.impact_of_discontinuing_id,
        f_licensed_activity_id: item.licensed_activity_id,
        f_entity_id: item.entity_id,
        f_function_description: item.description,
        f_start_date: item.start_date,
        f_end_date: item.end_date,
        function_level_id:
          item.function_level.length > 0 ? item.function_level[0].id : null,
        function_level_name:
          item.function_level.length > 0 ? item.function_level[0].name : null,
        function_level_description:
          item.function_level.length > 0
            ? item.function_level[0].description
            : null,
      }));
      setData(mappedData);
    } catch (error) {
      console.log(error, 'error');
      setData([]);
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchFunctionbyEntity();
      fetchAssessments();
      fetchImpactOfDiscontinuingICTServices();
      fetchFunctionLevels();
    }
  }, [query]);

  const handleOpenTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: true }));
  };

  const handleCloseTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: false }));
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement> | string ,
    fieldName: string,
    rowKey: string,
  ) => {
    const value = typeof e === 'string' ? e : e.target.value;

    setEditedData((prevData) => {
      const newData = { ...prevData, [fieldName]: value };
      return newData;
    });

    const updatedRow = data.find(
      (row) => row.f_function_id.toString() == rowKey,
    );

    if (updatedRow) {
      const updatedRowWithChanges: Record<string, any> = {
        ...updatedRow,
        [fieldName]: value,
      };
      if (updatedRow.certificates) {
        const match = fieldName.match(/^(\d+)_newcert_(.+)$/);
        if (match) {
          const idFromFieldName = match[1];
          const actualFieldName = match[2];

          const targetIndex = updatedRow.certificates.findIndex(
            (cert) => cert.certificates_id.toString() === idFromFieldName,
          );

          if (targetIndex !== -1) {
            updatedRowWithChanges.certificates[targetIndex] = {
              ...updatedRow.certificates[targetIndex],
              [actualFieldName]: value,
            };
          } else {
            console.log(
              `No matching certificate found for idFromFieldName (${idFromFieldName}) in certificates`,
            );
          }
        }
      };
      const cleanedUpRow = Object.keys(updatedRowWithChanges).reduce(
        (acc: Record<string, any>, key) => {
          const newKey = key.replace(/^[^a-zA-Z]+/, '');
          acc[newKey] = updatedRowWithChanges[key];
          return acc;
        },
        {},
      );

      setChangedData((prevChangedData) => {
        const newChangedData = [...prevChangedData];
        const existingRowIndex = newChangedData.findIndex(
          (row) => row.f_function_id === cleanedUpRow.f_function_id,
        );

        if (existingRowIndex !== -1) {
          const existingRow = newChangedData[existingRowIndex];
          const mergedRow = { ...existingRow, ...cleanedUpRow };
          if (JSON.stringify(existingRow) !== JSON.stringify(mergedRow)) {
            newChangedData[existingRowIndex] = mergedRow;
          }
        } else {
          newChangedData.push(cleanedUpRow);
        }

        return newChangedData;
      });
    } else {
      console.log('No match found for f_function_id:', rowKey);
    }
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = data.filter((item) => item.key !== key);
        fetchFunctionbyEntity();
        setData(updatedDataSource);
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: IFunction) => {
    if (record.f_function_id === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The function ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the function with ID ${record.f_function_id}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response = await functionOp.inactivateFunction([
            record.f_function_id,
          ]);
          fetchFunctionbyEntity();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };

  const updateChangedData = (editedData: any, changedData: any) => {
    if (editedData && typeof editedData === 'object') {
      Object.keys(editedData).forEach((key: string) => {
        const keyFromEditedData = parseInt(key.split('_')[0]);
        const matchingChangedRow = changedData.find((dataItem: any) => {
          return dataItem.f_function_id === keyFromEditedData;
        });

        if (matchingChangedRow) {
          const fieldName = key.replace(/^\d+_/, '');
          if (matchingChangedRow.hasOwnProperty(fieldName)) {
            matchingChangedRow[fieldName] = editedData[key];
          }
          if (
            fieldName.includes('certificates_start_date') ||
            fieldName.includes('certificates_end_date')
          ) {
            matchingChangedRow[fieldName] =
              editedData[key] !== undefined
                ? String(editedData[key] ?? '')
                : null;
          }
        }
      });
    } else {
      console.error('editedData is not an object:', editedData);
    }

    setChangedData([...changedData]);
  };

  useEffect(() => {
    updateChangedData(editedData, changedData);
  }, [editedData]);

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.f_function_id,
  }));

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };
  const addNewRow = () => {
    const function_id = generateUniqueId();
    const newRowTemplate: IFunction = {
      key: generateUniqueId(),
      isNew: true,
      criticality_assessment_id: '',
      criticality_assessment_name: '',
      criticality_assessment_description: '',
      auswahl_function_discont_id: generateUniqueId(),
      auswahl_function_discont_name: '',
      auswahl_function_discont_description: '',
      selection_licenced_act_id: '',
      selection_licenced_act_name: '',
      selection_licenced_act_description: '',
      f_function_id: function_id,
      f_function_identifier: '',
      f_function_name: '',
      f_criticality_assessment_id: '',
      f_reasons_for_criticality: '',
      f_date_of_last_assessment: '',
      f_recovery_time_objective: '',
      f_recovery_point_objective: '',
      f_impact_of_discontinuing_id: '',
      f_licensed_activity_id: '',
      f_entity_id: query.entityId,
      f_function_level_id: '',
      f_function_description: '',
      f_start_date: '',
      f_end_date: '9999-01-01',
      function_level_id: '',
      function_level_name: '',
      function_level_description: '',
      data_ict_system_name: '',
      data_ict_system_description: '',
      dora_criticality_name: '',
      certificates: [],
      ict_systems: [],
      function_levels: [
        {
          function_level_id: '',
          function_level_name: '',
          function_level_description: '',
        },
      ],

      data: [],
      data_sensitiveness_name: '',
      data_sensitiveness_description: '',
    };
    const newRow = structuredClone(newRowTemplate);

    setData((prevData) => [...prevData, newRow]);
    setChangedData((prevChangedData) => [...prevChangedData, newRow]);
  };

  const handleSubmit = async () => {
    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited Functions to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    };
   
    const formattedData = {
      entity_name: query.entity,

      Function: changedData.map((item) => ({
        id: item.f_function_id,
        function_identifier: item.f_function_identifier,
        function_name: item.f_function_name,
        criticality_assessment_id: item.criticality_assessment_id,
        reasons_for_criticality: item.f_reasons_for_criticality,
        date_of_last_assessment: item.f_date_of_last_assessment,
        recovery_time_objective: item.f_recovery_time_objective,
        recovery_point_objective: item.f_recovery_point_objective,
        impact_of_discontinuing_id: item.f_impact_of_discontinuing_id,
        licensed_activity_id: item.f_licensed_activity_id,
        entity_id: Number(item.f_entity_id),
        function_level_id: item.f_function_level_id,
        description: item.f_function_description,
        start_date: item.f_start_date,
        end_date: item.f_end_date,
        alternatives_exit_id: item.alternatives_exit_id,
        ict_system_ids: item.ict_system_id
          ? item.ict_system_id 
          : item.ict_systems
          ? item.ict_systems.map((ict: { ict_systems_id: string }) => ict.ict_systems_id)
          : [],
      })),

      Certificates: changedData
        .map((item) =>
          item.certificates.map((cert: ICertificate) => ({
            id: cert.certificates_id,
            description: cert.certificates_description,
            validity: cert.certificates_validity,
            date_of_issuance: cert.certificates_date_of_issuance,
            certificate_registry: cert.certificates_registry,
            date_of_certificate_register:
              cert.certificates_date_of_certificate_register,
            function_id: cert.certificates_function_id,
            end_date: cert.certificates_end_date,
            start_date: cert.certificates_start_date,
          })),
        )
        .flat(),
    };

    const recoveryData = {
      entity_name: query.entity,
      data_list: changedData.map((item) => ({
        id: item.auswahl_function_discont_id,
        recovery_time_objective: item.f_recovery_time_objective,
        recovery_point_objective: item.f_recovery_point_objective,
      })),
    };
    try {
      const response = await functionOp.insertFunctions(formattedData);
      const recoveryResponse =
        await functionOp.updateRecoveryObjective(recoveryData);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns: EditableColumnType<IFunction>[] = [
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Function</p>
            <p>B_06.01.0030</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Function name according to the financial entity’s internal organisation.
            (alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Funktionsbezeichnung entsprechend der
            internen Organisation des
            Finanzunternehmens."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'f_function_name',
      editable: true,
      fixed: 'left',
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_function_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.f_function_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Criticality or importance assessment</p>
            <p>B_06.01.0060</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Use this column to indicate whether the function is critical or important according to the financial entity’s assessment. One of the options in the following closed list shall be used:
              1. Yes
              2. No
              3. Assessment not performed"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie in dieser Spalte an, ob die Funktion
              nach Einschätzung des Finanzunternehmens
              kritisch oder wichtig ist.
              Aus der folgenden
              erschöpfenden Liste ist eine Option
              auszuwählen:
              1. Ja
              2. Nein
              3. Keine Bewertung durchgeführt"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'criticality_assessment_name',
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_criticality_assessment_name`;
        const idFieldName = `${record.f_function_id}_criticality_assessment_id`;
        const idFunctionFieldName = `${record.f_function_id}_f_criticality_assessment_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={criticallyAssessments}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.criticality_assessment_name
            }
            onChange={(value: string, option: any) => {
              const eventName = {
                target: { value: option.label },
              } as React.ChangeEvent<HTMLInputElement>;

              const eventId = {
                target: { value: option.value },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(eventName, fieldName, rowKey);
              handleEditChange(eventId, idFieldName, rowKey);
              handleEditChange(eventId, idFunctionFieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: <>Criticality assessment description</>,
      dataIndex: 'criticality_assessment_description',
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Date of the last assessment of criticality or importance</p>
            <p>B_06.01.0080</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the date using ISO 8601 (yyyy– mm–dd) code of the date of the last assessment of criticality or importance in case the function is supported by ICT services provided by ICT third-party service providers.
Where the assessment of the function’s criticality or importance is not performed, it shall be filled in with ‘9999-12-31’
(date)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie das Datum unter Verwendung des
            ISO 8601-Codes (JJJJ-MM-TT) des Datums der
            letzten Bewertung der Kritikalität oder
            Bedeutung an, falls die Funktion durch
            IKT-Dienstleistungen unterstützt wird, die von
            IKT-Drittdienstleistern bereitgestellt werden.
            Wird keine Bewertung der Kritikalität oder
            Bedeutung der Funktion vorgenommen, ist an
            dieser Stelle „9999-12-31“ anzugeben."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'f_date_of_last_assessment',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_date_of_last_assessment`;
        const rowKey = record.key;
        return (
          <DynamicField
            value={
              editedData.hasOwnProperty(fieldName)
                ? String(editedData[fieldName] ?? '')
                : String(record.f_date_of_last_assessment ?? '')
            }
            fieldName={fieldName}
            rowKey={record.key}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEditChange(e, fieldName, rowKey)
            }
            type="date"
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Reasons for criticality or importance</p>
            <p>B_06.01.0070</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Brief explanation on the reasons for classifying the function as critical or important (300 characters maximum)
(alphanumerical)
(optional)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Kurze Erläuterung der Gründe für die
            Einstufung der Funktion als kritisch oder
            wichtig (max. 300 Zeichen)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'f_reasons_for_criticality',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_reasons_for_criticality`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.f_reasons_for_criticality
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Recovery time objective of the function</p>
            <p>B_06.01.0090</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="In number of hours. Where the recovery time objective is less than 1 hour, ‘1’ shall be reported. Where the recovery time objective of the function is not defined, ‘0’ shall be reported.
            (natural number)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Die Angabe erfolgt in Stunden. Beträgt die
            Vorgabe für die Wiederherstellungszeit weniger
            als 1 Stunde, ist „1“ anzugeben. Ist die Vorgabe
            für die Wiederherstellungszeit der Funktion
            nicht definiert, ist „0“ anzugeben."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'f_recovery_time_objective',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_recovery_time_objective`;
        const rowKey = record.key;

        const validateInput = (value: string) => {
          if (value.trim() === '') return '0';
          const parsedValue = parseInt(value, 10);
          return isNaN(parsedValue) || parsedValue < 1
            ? '1'
            : parsedValue.toString();
        };

        return (
          <Input
            value={
              editedData?.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.f_recovery_time_objective
            }
            onBlur={(e) => {
              const validatedValue = validateInput(e.target.value);
              if (e.target.value !== validatedValue) {
                e.target.value = validatedValue;
                handleEditChange(e, fieldName, rowKey);
              }
            }}
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Recovery point objective of the function</p>
            <p>B_06.01.0100</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="In number of hours. Where the recovery point objective is less than 1 hour, ‘1’ shall be reported. Where the recovery point objective of the function is not defined, ‘0’ shall be reported.
            (natural number)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Die Angabe erfolgt in Stunden. Beträgt die
            Vorgabe für den Wiederherstellungspunkt
            weniger als 1 Stunde, ist „1“ anzugeben. Ist die
            Vorgabe für den Wiederherstellungspunkt der
            Funktion nicht definiert, ist „0“ anzugeben."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'f_recovery_point_objective',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_recovery_point_objective`;
        const rowKey = record.key;

        const validateInput = (value: string) => {
          if (value.trim() === '') return '0';
          const parsedValue = parseInt(value, 10);
          return isNaN(parsedValue) || parsedValue < 1
            ? '1'
            : parsedValue.toString();
        };

        return (
          <Input
            value={
              editedData?.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.f_recovery_point_objective
            }
            onBlur={(e) => {
              const validatedValue = validateInput(e.target.value);
              if (e.target.value !== validatedValue) {
                e.target.value = validatedValue;
                handleEditChange(e, fieldName, rowKey);
              }
            }}
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Licenced activity </p>
            <p>B_06.01.0020</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the licenced activities referred to in the underlying legal acts listed in Annex II for the different types of financial entities. Where the function is not linked to a registered or licenced activity, ‘support functions’ shall be reported."
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Eine der in den einschlägigen Rechtsakten in
            Anhang II genannten genehmigten Tätigkeiten
            für die verschiedenen Arten von
            Finanzunternehmen.
            Ist die Funktion nicht mit einer registrierten
            oder genehmigten Tätigkeit verbunden, so ist
            „Unterstützungsfunktionen“ anzugeben."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'selection_licenced_act_name',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_selection_licenced_act_name`;
        const fieldId = `${record.f_function_id}_selection_licenced_act_id`;
        const fieldIdFunction = `${record.f_function_id}_f_licensed_activity_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={licensedActivities}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.selection_licenced_act_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldId, rowKey);
              handleEditChange(idEvent, fieldIdFunction, rowKey);
              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Function identifier</p>
            <p>B_02.02.0050, B_06.01.0010</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="The function identifier shall be composed by the letter F (capital letter) followed by a natural number (e.g. “F1” for the 1st function identifier and “Fn” for the nth function identifier with “n” being a natural number).
Each combination between ‘LEI of the financial entity making use of the ICT service(s)’ (B_06.01.0040), ‘Function name’ (B_06.01.0030) and ‘Licenced activity’ (B_06.01.0020) shall have a unique function identifier.
Example: a financial entity which operates under two licensed activities (‘activity A’ and ‘activity B’) will be given two unique ‘function identifiers’ for the same function X (e.g. Sales) performed for activity A and activity B.
(pattern)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Die Funktionskennung besteht aus dem
            Buchstaben F (Hauptbuchstabe), gefolgt von einer
            natürlichen Zahl (z. B. „F1“ für die
            1. Funktionskennung und „Fn“ für die
            n. Funktionskennung, wobei „n“ eine natürliche Zahl
            ist).
            Jede Kombination zwischen „LEI des
            Finanzunternehmens, das die IKT-Dienstleistung(en)
            in Anspruch nimmt“ (B_06.01.0040), „Bezeichnung
            der Funktion“ (B_06.01.0030) und „Genehmigte
            Tätigkeit“ (B_06.01.0020) muss eine eindeutige
            Funktionskennung aufweisen. Beispiel: Ein Finanzunternehmen, das im
            Rahmen von zwei genehmigten Tätigkeiten
            („Tätigkeit A“ und „Tätigkeit B“) agiert, erhält
            zwei eindeutige „Funktionskennungen“ für
            dieselbe Funktion X (z. B.
            Vertrieb), die jeweils
            für Tätigkeit A bzw. Tätigkeit B ausgeführt wird."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'f_function_identifier',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_function_identifier`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.f_function_identifier
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Start Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'f_start_date',
      render: (text: string, record) => {
        const fieldName = `${record.f_function_id}_f_start_date`;
        const rowKey = record.key;
        return (
          <Styled.InputField>
            <DynamicField
              value={
                editedData.hasOwnProperty(fieldName)
                  ? String(editedData[fieldName] ?? '')
                  : String(record.f_start_date ?? '')
              }
              fieldName={fieldName}
              rowKey={record.key}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEditChange(e, fieldName, rowKey)
              }
              type="date"
            />
          </Styled.InputField>
        );
      },
    },
    {
      title: (
        <>
          End Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'f_end_date',
      render: (text: string, record) => {
        const fieldName = `${record.f_function_id}_f_end_date`;
        const rowKey = record.key;
        return (
          <Styled.InputField>
            <DynamicField
              value={
                editedData.hasOwnProperty(fieldName)
                  ? String(editedData[fieldName] ?? '9999-01-01')
                  : String(record.f_end_date ?? '9999-01-01')
              }
              fieldName={fieldName}
              rowKey={record.key}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEditChange(e, fieldName, rowKey)
              }
              type="date"
              useDefaultDate={true}
            />
          </Styled.InputField>
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> Impact of discontinuing</p>
            <p>B.06.01.0110</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Use this column to indicate the impact of discontinuing the function according to the financial entity’s assessment. One of the options in the following closed list shall be used:
            1.	Low
            2.	Medium;
            3.	High;
            4.	Assessment not performed.
            (closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie in dieser Spalte die Auswirkung der
            Einstellung der Funktion nach Einschätzung des
            Finanzunternehmens an. Aus der folgenden
            erschöpfenden Liste ist eine Option
            auszuwählen:
            1. Gering
            2. Mittel
            3. Hoch
            4. Keine Bewertung durchgeführt"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'f_impact_of_discontinuing_id',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_impact_of_discontinuing_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={impact}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.f_impact_of_discontinuing_id
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <>
          Function level<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'function_level_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_function_level_name`;
        const fieldId = `${record.f_function_id}_function_level_id`;
        const fieldId1 = `${record.f_function_id}_f_function_level_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={fLevels}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.function_level_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldId, rowKey);
              handleEditChange(event, fieldId1, rowKey);
              const eventName = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(eventName, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: <>Function level description</>,
      dataIndex: 'function_level_description',
      editable: true,
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: (
        <>
         ICT Systems<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ict_system',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_ict_system_name`;
        const fieldId = `${record.f_function_id}_ict_system_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={ictSystems}
            mode="multiple"
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ict_systems?.map((ict: { ict_systems_name: any; }) => ict.ict_systems_name) || []
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldId, rowKey);
              const eventName = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(eventName, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: <>Certificates</>,
      dataIndex: 'certificates',
      key: 'certificates',
      render: (_, record) => {
        return (
          <div>
            {(record.certificates as ICertificate[]).map(
              (cert: ICertificate) => {
                const idFieldName = `${cert.certificates_id}_newcert_certificates_id`;
                const descriptionFieldName = `${cert.certificates_id}_newcert_certificates_description`;
                const registryFieldName = `${cert.certificates_id}_newcert_certificates_registry`;
                const issuanceDateFieldName = `${cert.certificates_id}_newcert_certificates_date_of_issuance`;
                const validityFieldName = `${cert.certificates_id}_newcert_certificates_validity`;
                const dateOfRegisterFieldName = `${cert.certificates_id}_newcert_certificates_date_of_certificate_register`;
                const startDateFieldName = `${cert.certificates_id}_newcert_certificates_start_date`;
                const endDateFieldName = `${cert.certificates_id}_newcert_certificates_end_date`;
                const fieldNameFunctions = `${cert.certificates_id}_newcert_certificates_function_id`;
                const rowKey = record.key;
                const handleRemoveCertificates = (
                  certificateKey: number,
                  certificateID: number,
                ) => {
                  Modal.confirm({
                    title: 'Are you sure you want to remove this Certificate?',
                    content: 'This action cannot be undone.',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                      try {
                        const response = await tppOp.inactivateCertificates([
                          certificateID,
                        ]);
                        const updatedCertificates = data.map((certificate) => {
                          if (certificate.key === certificateKey) {
                            return {
                              ...certificate,
                              certificates: certificate.certificates.filter(
                                (certificate: ICertificate) =>
                                  certificate.certificates_id !== certificateID,
                              ),
                            };
                          }
                          return certificate;
                        });
                        setData(updatedCertificates);
                        if (record.isNew) {
                          const updatedCertificates = data.map((item) => {
                            const filteredCertificates =
                              item.certificates.filter(
                                (certificate: ICertificate) =>
                                  certificate.certificates_id !==
                                  cert.certificates_id,
                              );
                            return {
                              ...item,
                              certificates: filteredCertificates,
                            };
                            // return item;
                          });

                          setData(updatedCertificates);
                        } else {
                          fetchFunctionbyEntity();
                        }
                      } catch (error) {
                        console.error('Failed to remove Certificate:', error);
                      }
                    },
                    onCancel: () => {
                      notification.info({
                        message: 'Action Canceled',
                        description:
                          'The Certificate removal has been canceled.',
                        duration: 10,
                      });
                    },
                  });
                };
                return (
                  <Styled.TooltipContainer
                    key={cert.certificates_id}
                    visible={isTooltipVisible[idFieldName]}
                    title={
                      <div>
                        <Styled.TooltipButtonContainer>
                          <Button
                            size="small"
                            type="text"
                            onClick={() => handleCloseTooltip(idFieldName)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                          <Button
                            size="small"
                            type="text"
                            danger
                            onClick={() =>
                              handleRemoveCertificates(
                                record.key,
                                cert.certificates_id,
                              )
                            }
                          >
                            <DeleteOutlined />
                          </Button>
                        </Styled.TooltipButtonContainer>
                        <Styled.InputField>
                          <strong>
                            Register <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(registryFieldName)
                                ? typeof editedData[registryFieldName] ===
                                  'boolean'
                                  ? editedData[registryFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[registryFieldName])
                                : String(cert.certificates_registry)
                            }
                            onChange={(e) =>
                              handleEditChange(e, registryFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Issuance date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(issuanceDateFieldName)
                                ? String(
                                    editedData[issuanceDateFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_issuance ?? '',
                                  )
                            }
                            fieldName={issuanceDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                issuanceDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Validity <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(validityFieldName)
                                ? typeof editedData[validityFieldName] ===
                                  'boolean'
                                  ? editedData[validityFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[validityFieldName])
                                : String(cert.certificates_validity)
                            }
                            onChange={(e) =>
                              handleEditChange(e, validityFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>Certificate Description</strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(descriptionFieldName)
                                ? typeof editedData[descriptionFieldName] ===
                                  'boolean'
                                  ? editedData[descriptionFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[descriptionFieldName])
                                : String(cert.certificates_description)
                            }
                            onChange={(e) =>
                              handleEditChange(e, descriptionFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Date of register<Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(dateOfRegisterFieldName)
                                ? String(
                                    editedData[dateOfRegisterFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_certificate_register ??
                                      '',
                                  )
                            }
                            fieldName={dateOfRegisterFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                dateOfRegisterFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>

                        <Styled.InputField>
                          <strong>
                            Start date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(startDateFieldName)
                                ? String(editedData[startDateFieldName] ?? '')
                                : String(cert.certificates_start_date ?? '')
                            }
                            fieldName={startDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                startDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            End date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(endDateFieldName)
                                ? String(
                                    editedData[endDateFieldName] ??
                                      '9999-01-01',
                                  )
                                : String(
                                    cert.certificates_end_date ?? '9999-01-01',
                                  )
                            }
                            fieldName={endDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(e, endDateFieldName, record.key)
                            }
                            type="date"
                            useDefaultDate={true}
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Certificate Function id
                            <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            type="text"
                            value={
                              editedData.hasOwnProperty(fieldNameFunctions)
                                ? String(editedData[fieldNameFunctions] ?? '')
                                : String(record.f_function_id ?? '')
                            }
                            disabled
                          />
                        </Styled.InputField>
                      </div>
                    }
                    open={isTooltipVisible[idFieldName] || false}
                    trigger={[]}
                  >
                    <Styled.TagComponent
                      color="blue"
                      onClick={() => handleOpenTooltip(idFieldName)}
                    >
                      <Input
                        value={
                          editedData.hasOwnProperty(descriptionFieldName)
                            ? typeof editedData[descriptionFieldName] ===
                              'boolean'
                              ? editedData[descriptionFieldName]
                                ? 'true'
                                : 'false'
                              : String(editedData[descriptionFieldName])
                            : String(cert.certificates_description)
                        }
                        readOnly
                        addonAfter={<PlusCircleOutlined />}
                      />
                    </Styled.TagComponent>
                    <Styled.GlobalStyle />
                  </Styled.TooltipContainer>
                );
              },
            )}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewCertificate(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record) => (
        <>
          {record.isNew ? (
            <Button danger onClick={() => handleRemoveRow(record.key)}>
              Remove
            </Button>
          ) : (
            <Button
              danger
              onClick={() => handleInactivate(record as IFunction)}
            >
              Soft delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      <Styled.ButtonDiv isEmpty={dataWithKey.length === 0}>
        {query.entity && (
          <Styled.AreaButton type="primary" onClick={addNewRow}>
            Add Row
          </Styled.AreaButton>
        )}
      </Styled.ButtonDiv>
      <Styled.TooltipTableContainer>
        <Table
          dataSource={dataWithKey}
          rowKey="key"
          scroll={{ x: 'max-content' }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePaginationChange,
            showSizeChanger: pagination.showSizeChanger,
            pageSizeOptions: pagination.pageSizeOptions,
            position: pagination.position,
          }}
          columns={columns}
        />
      </Styled.TooltipTableContainer>
      {dataWithKey.length > 0 && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      )}
    </div>
  );
};

export default Function;
