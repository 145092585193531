import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledGoBackButton = styled(Button)`
  text-transform: uppercase;
  display: flex;
  align-items: center;

  .anticon {
    font-size: 18px;
  }
`;

export const StyledLink = styled(Link)`
  width: fit-content;
`;
