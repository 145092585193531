import { IGlobalState } from './types';
import { ThemeList } from '../globalTypes';

const initialGlobalState = (): IGlobalState => ({
  error: null,
  loading: false,
  scheme: (localStorage.getItem('theme') as ThemeList) || ThemeList.MAIN_THEME,
  searchParams: '',
  filtersData: [],
  appliedFiltersResult: [],
  tableFilters: {
    applicability: ['all'],
  },
});

export default initialGlobalState;
