import React from 'react';
import { Select } from 'antd';
import * as Styled from './CustomSelect.styled';

export interface ICustomSelectProps {
  options?: any;
  placeholder?: string;
  filters?: boolean;
  size?: string;
  [rest: string]: any;
}

export const CustomSelect: React.FC<ICustomSelectProps> = ({
  options,
  placeholder,
  size,
  filters = false,
  ...rest
}) => (
  <>
    {filters ? (
      <Styled.FiltersWrapper>
        <Select placeholder={placeholder} options={options} {...rest} />
      </Styled.FiltersWrapper>
    ) : (
      <Styled.SelectWrapper size={size}>
        <Select placeholder={placeholder} options={options} {...rest} />
      </Styled.SelectWrapper>
    )}
  </>
);
