import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  arrangement_reference_number: string;
  branch_identification_code: string;
  lei_entity_making_use_ICT_service: string;
  // name_of_entity: string;
  nature_of_entity: string;
}

interface B0401Props {
  entityName: string | null | undefined;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Contractual arrangement reference number - <span>0010</span>
        </p>
        <AdditionalInformation
          description="As reported in B_02.01.0010
Identify the reference number of the contractual arrangement in relation to the financial entity making use of the ICT services provided
(alphanumerical)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'arrangement_reference_number',
    key: 'arrangement_reference_number',
    fixed: 'left',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          LEI of the financial entity making use of the ICT service(s) -{' '}
          <span>0020</span>
        </p>
        <AdditionalInformation
          description="Identify the financial entity making use of the ICT service(s) using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard
(alphanumerical)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'lei_entity_making_use_ICT_service',
    key: 'lei_entity_making_use_ICT_service',
    fixed: 'left',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Nature of the financial entity making use of the ICT service(s) -{' '}
          <span>0030</span>
        </p>
        <AdditionalInformation
          description="One of the options in the following closed list shall be used:
1.	The financial entity making use of the ICT service(s) is a branch of a financial entity
2.	The financial entity making use of the ICT service(s) is not a branch
(closed set of options)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'nature_of_entity',
    key: 'nature_of_entity',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Identification code of the branch - <span>0040</span>
        </p>
        <AdditionalInformation
          description="Identification code of the branch as reported in B_01.03.0010
(alphanumerical)
(mandatory if the financial entity making use of the ICT service(s) is a branch of a financial entity (B_04.01.0030))"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'branch_identification_code',
    key: 'branch_identification_code',
  },
  //   {
  //     title: (
  //       <Styled.AddttionalInfoContainer>
  //         Name of the financial entity
  //         <AdditionalInformation description="Legal name of the financial entity maintaining and updating the register of information
  // (alphanumerical)" />
  //       </Styled.AddttionalInfoContainer>
  //     ),
  //     width: 100,
  //     dataIndex: 'entity_name',
  //     key: 'entity_name',
  //   },
];
const B0401: React.FC<B0401Props> = ({ entityName }) => {
  const { date, is_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData =
        await reportingOp.fetchContractualArrangementsICTUse(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          arrangement_reference_number: item.arrangement_reference_number,
          branch_identification_code: item.branch_identification_code,
          lei_entity_making_use_ICT_service:
            item.lei_entity_making_use_ICT_service,
          // name_of_entity: item.name_of_entity,
          nature_of_entity: item['Nature of entity'],
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0401;
