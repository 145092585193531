import { combineReducers } from 'redux';
import globalSlice from './ducks/Global';
import entitySlice from './ducks/DataEntry/Entities';
import tppSlice from './ducks/DataEntry/ThirdPartyProviders';
import branchSlice from './ducks/DataEntry/Branch';
import functionSlice from './ducks/DataEntry/Functions';

export default combineReducers({
  global: globalSlice.reducer,
  entities: entitySlice.reducer,
  tpp: tppSlice.reducer,
  branch: branchSlice.reducer,
  function: functionSlice.reducer,
});
