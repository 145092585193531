import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { extractTableHeaders } from 'utils/hooks/reportTableUtils';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  contractual_arangement_ref_num: string;
  identification_code: string;
  type_of_code: string;
}

interface B0302Props {
  entityName: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Contractual arrangement reference number - <span>0010</span>
        </p>
        <AdditionalInformation
          description="As reported in B_02.02.0010
Identify the contractual arrangement reference number signed by the ICT third-party service provider
(alphanumerical)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'contractual_arangement_ref_num',
    key: 'contractual_arangement_ref_num',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          Identification code of ICT third-party service provider -
          <span>0020</span>
        </p>
        <AdditionalInformation
          description="As reported in B_05.01.0010
Code to identify the ICT third-party service provider as reported in B_05.01.0020 for that provider.
(alphanumerical)
"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'identification_code',
    key: 'identification_code',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>
          {' '}
          Type of code to identify the ICT third-party service provider -{' '}
          <span>0030</span>
        </p>
        <AdditionalInformation
          description="Type of code to identify the ICT third-party service provider reported in B_05.01.0010
1.	‘LEI’ for LEI
2.	‘EUID’ for EUID
3.	‘Country Code’+Underscore+’Type of Code’ for non LEI and non EUID code
Country Code: Identify the ISO 3166–1 alpha–2 code of the country of issuance of the other code to identify the ICT third- party service provider
Type of Code:
1.	CRN for Corporate registration number
2.	VAT for VAT number
3.	PNR for Passport Number
4.	NIN for National Identity Number
Only LEI or EUID shall be used for legal persons, as identified in B_05.01.0070, whereas alternative code may be used only for an individual acting in a business capacity.
Only LEI shall be used for legal persons that are not established in the Union.
(pattern)"
        />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'type_of_code',
    key: 'type_of_code',
  },
];

const B0302: React.FC<B0302Props> = ({ entityName }) => {
  const { date, is_displayed, headers } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData = await reportingOp.fetchRT0302(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          contractual_arangement_ref_num:
            item['Contractual arrangement ref. number'],
          identification_code: item['ICT service provider code'],
          type_of_code: item['Type of ICT service provider code'],
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  useEffect(() => {
    extractTableHeaders(memoizedColumns, dispatch);
  }, []);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0302;
