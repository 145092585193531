import React from 'react';
import { TableColumnsType } from 'antd';
import * as XLSX from 'xlsx';

const sheetTitles = [
  'B_02.03',
  'B_01.03',
  'B_03.01',
  'B_03.03',
  'B_04.01',
  'B_01.01',
  'B_01.02',
  'B_02.01',
  'B_02.02',
  'B_03.02',
  'B_05.01',
  'B_05.02',
  'B_06.01',
  'B_07.01',
  // 'B_99.01',
];

const columnHeadersMap = [
  [
    'Contractual arrangement reference number - 0010',
    'Contractual arrangement linked to the contractual arrangement referred in B.02.03.0010 - 0020',
  ],
  [
    'Identification code of the branch - 0010',
    'LEI of the financial entity head office of the branch - 0020',
    'Name of the branch - 0030',
    'Country of the branch - 0040',
  ],
  [
    'Contractual arrangement reference number - 0010',
    'LEI of the entity signing the contractual arrangement - 0020',
  ],
  [
    'Contractual arrangement reference number - 0010',
    'LEI of the financial entity providing ICT services - 0020',
  ],
  [
    'Contractual arrangement reference number - 0010',
    'LEI of the financial entity making use of the ICT service(s) - 0020',
    'Nature of the financial entity making use of the ICT service(s) - 0030',
    'Identification code of the branch - 0040',
  ],
  [
    'LEI of the financial entity maintaining the register of information - 0010',
    'Name of the financial entity - 0020',
    'Country of the entity -0030',
    'Type of entity - 0040',
    'Competent Authority - 0050',
    'Date of the reporting - 0060',
  ],
  [
    'LEI of the financial entity - 0010',
    'Name of the entity - 0020',
    'Country of the financial entity - 0030',
    'Type of financial entity - 0040',
    'Hierarchy of the financial entity within the group (where applicable) - 0050',
    'LEI of the direct parent undertaking of the entity - 0060',
    'Date of last update - 0070',
    'Date of integration in the Register of information - 0080',
    'Date of deletion in the Register of information - 0090',
    'Currency - 0100',
    'Value of total assets of the financial entity - 0110',
  ],
  [
    'Contractual arrangement reference number - 0010',
    'Type of contractual arrangement - 0020',
    'Overarching contractual arrangement reference number 0030',
    'Currency of the amount reported in B.02.01.0050 - 0040',
    'Annual expense or estimated cost of the contractual arrangement for the past year - 0050',
  ],
  [
    'Contractual arrangement reference number - 0010',
    'LEI of the financial entity making use of the ICT service(s) - 0020',
    'Identification code of the ICT third-party service provider - 0030',
    'Type of code to identify the ICT third-party service provider - 0040',
    'Function identifier - 0050',
    'Type of ICT services - 0060',
    'Start date of the contractual arrangement - 0070',
    'End date of the contractual arrangement - 0080',
    'Reason of the termination or ending of the contractual arrangement - 0090',
    'Notice period for the financial entity making use of the ICT service(s) - 0100',
    'Notice period for the ICT third-party service provider - 0110',
    'Country of the governing law of the contractual arrangement - 0120',
    'Country of provision of the ICT services - 0130',
    'Storage of data - 0140',
    'Location of the data at rest (storage) - 0150',
    'Location of management of the data (processing) - 0160',
    'Sensitiveness of the data stored by the ICT third-party service provider - 0170',
    'Level of reliance on the ICT service supporting the critical or important function. - 0180',
  ],
  [
    'Contractual arrangement reference number - 0010',
    'Identification code of ICT third-party service provider - 0020',
    'Type of code to identify the ICT third-party service provider - 0030',
  ],
  [
    'Identification code of ICT third-party service provider - 0010',
    'Type of code to identify the ICT third-party service provider 0020',
    'Additional identification code of ICT third-party service provider - 0030',
    'Type of additional identification code of the ICT third-party service provider - 0040',
    'Legal name of the ICT third-party service provider - 0050',
    'Name of the ICT third-party service provider in Latin alphabet - 0060',
    'Type of person of the ICT third-party service provider - 0070',
    'Country of the ICT third-party service provider’s headquarters - 0080',
    'Currency of the amount reported in RT.05.01.0070 - 0090',
    'Total annual expense or estimated cost of the ICT third-party service provider - 0100',
    'Identification code of the ICT third-party service provider’s ultimate parent undertaking - 0110',
    'Type of code to identify the ICT third-party service provider’s ultimate parent undertaking - 0120',
  ],
  [
    'Contractual arrangement reference number - 0010',
    'Type of ICT services - 0020',
    'Identification code of the ICT third-party service provider - 0030',
    'Type of code to identify the ICT third-party service provider - 0040',
    'Rank - 0050',
    'Identification code of the recipient of sub-contracted ICT services - 0060',
    'Type of code to identify the recipient of sub-contracted ICT services - 0070',
  ],
  [
    'Function Identifier - 0010',
    'Licenced activity - 0020',
    'Function name - 0030',
    'LEI of the financial entity - 0040',
    'Criticality or importance assessment - 0050',
    'Reasons for criticality or importance - 0060',
    'Date of the last assessment of criticality or importance - 0070',
    'Recovery time objective of the function - 0080',
    'Recovery point objective of the function - 090',
    'Impact of discontinuing the function - 0100',
  ],
  [
    'Contractual arrangement reference number - 0010',
    'Identification code of the ICT third-party service provider - 0020',
    'Type of code to identify the ICT third-party service provider - 0030',
    'Type of ICT services - 0040',
    'Substitutability of the ICT third-party service provider - 0050',
    'Reason if the ICT third-party service provider is considered not substitutable or difficult to be substitutable - 0060',
    'Date of the last audit on the ICT third-party service provider - 0070',
    'Existence of an exit plan - 0080',
    'Possibility of reintegration of the contracted ICT service - 0090',
    'Impact of discontinuing the ICT services - 0100',
    'Are there alternative ICT third-party service providers identified? - 0110',
    'Identification of alternative ICT TPP - 0120',
  ],
  // [
  //   '',
  //   'Column Code',
  //   'Column Name',
  //   'Description/Internal definition of the option',
  // ],
];
export const tableData = [
  {
    title: 'B_01.01',
    description: 'Entity maintaining the register of information',
  },
  {
    title: 'B_01.02',
    description:
      'List of entities within the scope of the register of information',
  },
  { title: 'B_01.03', description: 'List of branches' },
  {
    title: 'B_02.01',
    description: 'Contractual arrangements – General information',
  },
  {
    title: 'B_02.02',
    description: 'Contractual arrangements – Specific information',
  },
  {
    title: 'B_02.03',
    description: 'List of intra-group contractual arrangements',
  },
  {
    title: 'B_03.01',
    description:
      'Entities signing the Contractual arrangements for receiving ICT service(s) or on behalf of the entities making use of the ICT service(s)',
  },
  {
    title: 'B_03.02',
    description:
      'ICT third-party service providers signing the contractual arrangements for providing ICT service(s)',
  },
  {
    title: 'B_03.03',
    description:
      'Entities signing the Contractual arrangements for providing ICT service(s) to other entity within the scope of consolidation ',
  },
  {
    title: 'B_04.01',
    description: 'Financial entities making use of the ICT services',
  },
  { title: 'B_05.01', description: 'ICT third-party service provider' },
  { title: 'B_05.02', description: 'ICT service supply chains' },
  { title: 'B_06.01', description: 'Functions identification' },
  { title: 'B_07.01', description: 'Assessment of the ICT services' },
  {
    title: 'B_99.01',
    description:
      'Terminology used by financial entities using the ICT services',
  },
];

export const tableDataAdditional = [
  {
    title: 'AR.01.01',
    description: 'Entity maintaining the register of information',
  },
  {
    title: 'Functions and ICT-Services',
    description: '',
  },
  {
    title: 'ICT - Assets',
    description: '',
  },
  {
    title: 'Information Assets (Data)',
    description: '',
  },
  {
    title: 'Functions',
    description: '',
  },
  {
    title: 'Third Party Service Provider',
    description: '',
  },
  {
    title: 'ICT-Services',
    description: '',
  },
  {
    title: 'Outsourcing Registry (EBA Guidelines On Outsourcing)',
    description: '',
  },
  {
    title: 'Certificates',
    description: '',
  },
  {
    title: '',
    description: '',
  },
  {
    title: '',
    description: '',
  },
];

export const generateExcelData = (fetchedData: any[], tableTitle: string) => {
  if (!fetchedData || fetchedData.length === 0) {
    throw new Error('No data available');
  }

  let orderedKeys: string[] = [];
  if (Array.isArray(fetchedData) && fetchedData.length > 0) {
    orderedKeys = Object.keys(fetchedData[0]);
  } else if (typeof fetchedData === 'object' && fetchedData !== null) {
    orderedKeys = Object.keys(fetchedData);
  }

  const columnOrderMap: Record<string, string[]> = {
    'B_01.01': [
      'lei_entity',
      'entity_name',
      'iso_code',
      'entity_type',
      'competent_authority',
      'reporting_date',
    ],

    'B_01.02': [
      'lei_entity',
      'entity_name',
      'country_name',
      'entity_type',
      'hierarchy_in_group',
      'parent_lei',
      'date_of_last_update',
      'integration_date',
      'deletion_date',
      'currency',
      'total_assets',
    ],

    'B_01.03': [
      'branch_identification_code',
      'head_office_lei',
      'branch_name',
      'iso_code',
    ],

    'B_02.01': [
      'arrangement_reference_number',
      'contract_type',
      'overarching_reference_number',
      'currency',
      'annual_expense',
    ],
    'B_02.02': [
      'Contractual arrangement ref. number',
      'LEI of the entity using ICT service',
      'Type of ICT service provider code',
      'Type of ICT service provider code',
      'Function identifier',
      'Type of ICT services',
      'Start date',
      'End date',
      'Termination reason',
      'Entity notice period',
      'Provider notice period',
      'Governing law country',
      'Country of ICT services',
      'Storage of data',
      'Data location (storage)',
      'Data location (processing)',
      'Data sensitiveness',
      'Level of reliance',
    ],
    'B_02.03': [
      'arrangement_reference_number',
      'linked_contractual_arrangement',
    ],
    'B_03.01': [
      'arrangement_reference_number',
      'lei_entity_signing_contractual_arrangement',
    ],
    'B_03.02': [
      'Contractual arrangement ref. number',
      'ICT service provider code',
      'Type of ICT service provider code',
    ],
    'B_03.03': ['arrangement_reference_number', 'lei_of_entity'],
    'B_04.01': [
      'arrangement_reference_number',
      'lei_entity_making_use_ICT_service',
      'Nature of entity',
      'branch_identification_code',
    ],
    'B_05.01': [
      'ICT service provider code',
      'Type of ICT service provider code',
      'Additional identification code of ICT third-party service provi',
      'Type of additional identification code to identify the ICT thir',
      'Legal name of the ICT third- party service provider',
      'Name of ICT service provider',
      'Type of person',
      'Headquarters country',
      'Reported currency',
      'Total annual expense',
      'Ultimate parent code',
      'Type of ultimate parent code',
    ],
    'B_05.02': [
      'Contractual arrangement ref. number',
      'Type of ICT services',
      'ICT service provider code',
      'Type of ICT service provider code',
      'Rank',
      'Recipient ID code',
      'Recipient ID type',
    ],
    'B_06.01': [
      'Function identifier',
      'Licenced activity',
      'Function name',
      'LEI of the financial entity',
      'Criticality assessment',
      'Reasons for criticality',
      'Date of the last assessment of criticality',
      'Recovery time objective of the function',
      'Recovery point objective of the function',
      'Impact of discontinuing the function',
    ],
    'B_07.01': [
      'Arrangement reference number',
      'ICT service provider identification code',
      'Type of ICT service provider code',
      'Type of ICT services',
      'Substitutability of ICT provider',
      'Reason for non-substitutability',
      'Date of last audit',
      'Exit plan exists',
      'Reintegration possible',
      'Impact of discontinuing ICT services',
      'Alternative providers identified',
      'Alternative ICT TPP identification',
    ],
    // 'B_99.01': [
    //   '',
    //   'Column Code',
    //   'Column Name',
    //   'Description/Internal definition of the option',
    // ],
  };

  if (columnOrderMap[tableTitle]) {
    orderedKeys = columnOrderMap[tableTitle];
  }

  const index = sheetTitles.indexOf(tableTitle);
  if (index === -1) {
    throw new Error(`Table title "${tableTitle}" not found in sheetTitles`);
  }
  const headers = columnHeadersMap[index] || [];

  let reorderedData: Record<string, any>[] = [];

  if (Array.isArray(fetchedData)) {
    reorderedData = fetchedData.map((item: Record<string, any>) =>
      orderedKeys.reduce(
        (acc, key) => {
          const value = item[key];

          acc[key] =
            (key === 'iso_code' || key === 'country_name') &&
            typeof value === 'string'
              ? value.slice(0, 2)
              : (value ?? '');

          return acc;
        },
        {} as Record<string, any>,
      ),
    );
  } else if (typeof fetchedData === 'object' && fetchedData !== null) {
    reorderedData = [
      orderedKeys.reduce(
        (acc, key) => {
          const value = (fetchedData as Record<string, any>)[key];

          acc[key] =
            (key === 'iso_code' || key === 'country_name') &&
            typeof value === 'string'
              ? value.slice(0, 2)
              : (value ?? '');

          return acc;
        },
        {} as Record<string, any>,
      ),
    ];
  } else {
    console.error('Unexpected data format for fetchedData:', fetchedData);
  }

  const data = [
    headers,
    ...reorderedData.map((item) => orderedKeys.map((key) => item[key])),
  ];

  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, ws, tableTitle);

  return { wb, title: tableTitle };
};
