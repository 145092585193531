import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { generateExcelData } from '../../constants/excelData';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import AR0101 from 'components/Tables/Reporting/Additionals/AR0101/AR0101';
import { reportingOp } from 'store/ducks/Reportings';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';

export interface ReportModalProps {
  title: string;
  tableNumber: string;
}

const ReportModal: React.FC<ReportModalProps> = ({ title, tableNumber }) => {
  const { date } = useSelector((state: RootState) => state.reportings);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const entityName =
    typeof queryParams.entity_name === 'string' ? queryParams.entity_name : '';

  const showModal = () => {
    setOpen(true);
  };

  const fetchDataByTableNumber = async (tableNumber: string, params: any) => {
    switch (tableNumber) {
      case 'AR.01.01':
        return await reportingOp.fetchAR0101(params);
      default:
        throw new Error('Invalid tables');
    }
  };

  const handleOk = async () => {
    setLoading(true);

    try {
      const params = {
        entity_name: queryParams.entity_name,
        date,
      };

      const fetchedData = await fetchDataByTableNumber(tableNumber, params);

      // const { wb, title: excelTitle } = generateExcelData(fetchedData, title);
      // XLSX.writeFile(wb, `${excelTitle}.xlsx`);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {title}
      </Button>
      <Modal
        open={open}
        centered
        width={1500}
        title={title}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          entityName && (
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleOk}
              disabled
            >
              <DownloadOutlined />
              Download
            </Button>
          ),
        ]}
      >
        {title === 'AR.01.01' ? <AR0101 entityName={entityName} /> : null}
      </Modal>
    </>
  );
};

export default ReportModal;
