import React, { useState, useEffect } from 'react';
import { Button, notification, Input, Table } from 'antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { functionOp } from 'store/ducks/DataEntry/Functions';
import useFilters from 'utils/hooks/useFilters';
import {
  IData,
  IICTService,
  ICertificate,
  IFunction,
} from 'store/ducks/DataEntry/Functions/types';
import * as Styled from '../../DataEntry.styled';
import DynamicField from 'components/DynamicCalendarField/DynamicField';

interface EditableColumnType<T> extends ColumnType<T> {
  editable?: true;
}

interface EditedData {
  [key: string]: string | number | boolean | undefined;
}

const Function = () => {
  const { query } = useFilters();
  const [data, setData] = useState<IFunction[]>([]);
  const [editedData, setEditedData] = useState<EditedData>({});
  const [changedData, setChangedData] = useState<Record<string, any>[]>([]);
  const [isTooltipVisible, setIsTooltipVisible] = useState<{
    [key: string]: boolean;
  }>({});

  const addNewIctSystem = (rowKey: string | number) => {
    const newIctSystem: IICTService = {
      ict_systems_id: generateUniqueId(),
      ict_systems_name: '',
      ict_systems_description: '',
      ict_systems_start_date: '',
      ict_systems_end_date: '',
    };

    setData((prevData) =>
      prevData.map((record) => {
        if (record.f_function_id === rowKey) {
          return {
            ...record,
            ict_systems: [...record.ict_systems, { ...newIctSystem }],
          };
        }
        return record;
      }),
    );
  };

  const addNewCertificate = (rowKey: string | number) => {
    const newCertificate: ICertificate = {
      certificates_id: generateUniqueId(),
      certificates_description: '',
      certificates_validity: '',
      certificates_date_of_issuance: '',
      certificates_date_of_certificate_register: '',
      certificates_third_party_service_provider_id: generateUniqueId(),
      certificates_function_id: generateUniqueId(),
      certificates_ict_service_id: generateUniqueId(),
      certificates_registry: '',
      certificates_end_date: '',
      certificates_start_date: '',
    };

    setData((prevData) => {
      return prevData.map((record) => {
        if (record.f_function_id === rowKey) {
          return {
            ...record,
            certificates: [...record.certificates, newCertificate],
          };
        }
        return record;
      });
    });
  };

  const addNewData = (rowKey: string | number) => {
    const newData: IData = {
      sensitivenessOfDataStoredbyICTTPSP_id: generateUniqueId(),
      sensitivenessOfDataStoredbyICTTPSP_name: '',
      sensitivenessOfDataStoredbyICTTPSP_description: '',
      dataSensitiveness_id: generateUniqueId(),
      dataSensitiveness_sensitiveness_id: generateUniqueId(),
      dataSensitiveness_sensitiveness_name: '',
      dataSensitiveness_description: '',
      dataSensitiveness_ict_system_id: generateUniqueId(),
      data_id: generateUniqueId(),
      data_location_of_data_at_rest: '',
      data_location_of_data_management: '',
      data_sensitiveness_id: generateUniqueId(),
      data_description: '',
      data_ict_system_id: generateUniqueId(),
      data_start_date: '',
      data_end_date: '',
    };

    setData((prevData) => {
      return prevData.map((record) => {
        if (record.f_function_id === rowKey) {
          return {
            ...record,
            data: [...record.data, newData],
          };
        }
        return record;
      });
    });
  };

  const fetchTPPbyEntity = async () => {
    try {
      const response = await functionOp.fetchFunctionbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.functions;
      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        isNew: false,
        isEdited: false,
        critically_assessment_id: item.criticality_assessment.id,
        critically_assessment_name: item.criticality_assessment.name,
        critically_assessment_description:
          item.criticality_assessment.description,
        critically_assessment_dora_criticality:
          item.criticality_assessment.dora_criticality,
        critically_assessment_dora_regulator_criticality:
          item.criticality_assessment.dora_regulator_criticality,
        critically_assessment_functions_criticality:
          item.criticality_assessment.functions_criticality,
        critically_assessment_ict_services_criticality:
          item.criticality_assessment.ict_services_criticality,
        critically_assessment_marisk_criticality:
          item.criticality_assessment.marisk_criticality,
        auswahl_function_discont_id: item.impact_of_discontinuing.id,
        auswahl_function_discont_name: item.impact_of_discontinuing.name,
        auswahl_function_discont_description:
          item.impact_of_discontinuing.description,
        selection_licenced_act_id: item.licensed_activity.id,
        selection_licenced_act_name: item.licensed_activity.name,
        selection_licenced_act_description: item.licensed_activity.description,
        ict_systems: item.ict_systems
          ? item.ict_systems.map((ict_system: any) => ({
              ict_systems_id: ict_system.id,
              ict_systems_name: ict_system.name,
              ict_systems_description: ict_system.description,
              ict_systems_start_date: ict_system.start_date,
              ict_systems_end_date: ict_system.end_date,
            }))
          : [],
        certificates: item.certificates
          ? item.certificates.map((certificate: any) => ({
              certificates_id: certificate.id,
              certificates_description: certificate.description,
              certificates_validity: certificate.validity,
              certificates_date_of_issuance: certificate.date_of_issuance,
              certificates_registry: certificate.certificate_registry,
              certificates_date_of_certificate_register:
                certificate.date_of_certificate_register,
              certificates_third_party_service_provider_id:
                certificate.third_party_service_provider_id,
              certificates_function_id: certificate.function_id,
              certificates_ict_service_id: certificate.ict_service_id,
              certificates_end_date: certificate.end_date,
              certificates_start_date: certificate.start_date,
            }))
          : [],
        f_function_id: item.function_id,
        f_function_identifier: item.function_identifier,
        f_function_name: item.function_name,
        f_criticality_assessment_id: item.criticality_assessment_id,
        f_reasons_for_criticality: item.reasons_for_criticality,
        f_date_of_last_assessment: item.date_of_last_assessment,
        f_recovery_time_objective: item.recovery_time_objective,
        f_recovery_point_objective: item.recovery_point_objective,
        f_impact_of_discontinuing_id: item.impact_of_discontinuing_id,
        f_licensed_activity_id: item.licensed_activity_id,
        f_entity_id: item.entity_id,
        f_possibility_of_reintegration_id: item.possibility_of_reintegration_id,
        f_function_level_id: item.function_level_id,
        f_function_description: item.description,
        f_start_date: item.start_date,
        f_end_date: item.end_date,
        function_level_id: item.function_level.id,
        function_level_name: item.function_level.name,
        function_level_description: item.function_level.description,

        data: item.data
          ? item.data.map((iData: any) => ({
              sensitivenessOfDataStoredbyICTTPSP_id:
                iData.sensitiveness.data_stored_by_ict_tpsp.id,
              sensitivenessOfDataStoredbyICTTPSP_name:
                iData.sensitiveness.data_stored_by_ict_tpsp.name,
              sensitivenessOfDataStoredbyICTTPSP_description:
                iData.sensitiveness.data_stored_by_ict_tpsp.description,
              dataSensitiveness_id: iData.sensitiveness.id,
              dataSensitiveness_sensitiveness_name: iData.sensitiveness.name,
              dataSensitiveness_description: iData.sensitiveness.description,
              // dataSensitiveness_sensitivinessOfDataStoredbyICTTPSP_id:
              //   iData.sensitiveness.data_stored_by_ict_tpsp.name,
              data_id: iData.id,
              data_location_of_data_at_rest: iData.location_of_data_at_rest,
              data_location_of_data_management:
                iData.location_of_data_management,
              data_sensitiveness_id: iData.sensitiveness.id,
              data_description: iData.description,
              data_ict_system_id: iData.ict_system_id,
              data_start_date: iData.start_date,
              data_end_date: iData.end_date,
            }))
          : [],
      }));
      setData(mappedData);
    } catch (error) {
      console.log(error, 'error');
      setData([]);
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchTPPbyEntity();
    }
  }, [query]);

  const handleOpenTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: true }));
  };

  const handleCloseTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: false }));
  };

  const handleDateChange = (
    rowKey: string | number,
    fieldName: string,
    newValue: string,
  ) => {
    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: newValue,
    }));
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    rowKey: string,
  ) => {
    const { value } = e.target;

    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    const updatedRow = data.find(
      (row) => row.f_function_id.toString() == rowKey,
    );

    if (updatedRow) {
      const updatedRowWithChanges: Record<string, any> = {
        ...updatedRow,
        [fieldName]: value,
      };

      if (fieldName === 'ict_systems_id' || 'certificates_id' || 'data_id') {
        if (updatedRow.ict_systems) {
          const match = fieldName.match(/^(\d+)_systems_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];

            const targetIndex = updatedRow.ict_systems.findIndex(
              (system) => system.ict_systems_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.ict_systems[targetIndex] = {
                ...updatedRow.ict_systems[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching system found for idFromFieldName (${idFromFieldName}) in ict_systems`,
              );
            }
          }
        }
        if (updatedRow.certificates) {
          const match = fieldName.match(/^(\d+)_newcert_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];

            const targetIndex = updatedRow.certificates.findIndex(
              (cert) => cert.certificates_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.certificates[targetIndex] = {
                ...updatedRow.certificates[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching certificate found for idFromFieldName (${idFromFieldName}) in certificates`,
              );
            }
          }
        }
        if (updatedRow.data) {
          const match = fieldName.match(/^(\d+)_newItem_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];
            const targetIndex = updatedRow.data.findIndex(
              (item) => item.data_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.data[targetIndex] = {
                ...updatedRow.data[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching data found for idFromFieldName (${idFromFieldName}) in datas`,
              );
            }
          }
        }
      }
      const cleanedUpRow = Object.keys(updatedRowWithChanges).reduce(
        (acc: Record<string, any>, key) => {
          const newKey = key.replace(/^[^a-zA-Z]+/, '');
          acc[newKey] = updatedRowWithChanges[key];
          return acc;
        },
        {},
      );

      setChangedData((prevChangedData) => {
        const newChangedData = [...prevChangedData];
        const existingRowIndex = newChangedData.findIndex(
          (row) => row.f_function_id === cleanedUpRow.f_function_id,
        );

        if (existingRowIndex !== -1) {
          const existingRow = newChangedData[existingRowIndex];
          const mergedRow = { ...existingRow, ...cleanedUpRow };
          if (JSON.stringify(existingRow) !== JSON.stringify(mergedRow)) {
            newChangedData[existingRowIndex] = mergedRow;
          }
        } else {
          newChangedData.push(cleanedUpRow);
        }

        return newChangedData;
      });
    } else {
      console.log('No match found for f_function_id:', rowKey);
    }
  };
  const updateChangedData = (editedData: any, changedData: any) => {
    if (editedData && typeof editedData === 'object') {
      Object.keys(editedData).forEach((key: string) => {
        const keyFromEditedData = parseInt(key.split('_')[0]);
        const matchingChangedRow = changedData.find((dataItem: any) => {
          return dataItem.f_function_id === keyFromEditedData;
        });

        if (matchingChangedRow) {
          const fieldName = key.replace(/^\d+_/, '');
          if (matchingChangedRow.hasOwnProperty(fieldName)) {
            matchingChangedRow[fieldName] = editedData[key];
          }
          if (
            fieldName.includes('certificates_start_date') ||
            fieldName.includes('certificates_end_date')
          ) {
            matchingChangedRow[fieldName] =
              editedData[key] !== undefined
                ? String(editedData[key] ?? '')
                : null;
          }
        }
      });
    } else {
      console.error('editedData is not an object:', editedData);
    }

    setChangedData([...changedData]);
  };

  useEffect(() => {
    updateChangedData(editedData, changedData);
  }, [editedData]);

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.f_function_id,
  }));

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };
  let uniqueIdCounter = 0;
  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now();
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId = Number(`${timestampPart}${uniqueIdCounter}${randomPart}`);
    return uniqueId;
  };

  const addNewRow = () => {
    const newRowTemplate: IFunction = {
      key: generateUniqueId(),
      isNew: true,
      critically_assessment_id: generateUniqueId(),
      critically_assessment_name: '',
      critically_assessment_description: '',
      auswahl_function_discont_id: generateUniqueId(),
      auswahl_function_discont_name: '',
      auswahl_function_discont_description: '',
      selection_licenced_act_id: generateUniqueId(),
      selection_licenced_act_name: '',
      selection_licenced_act_description: '',
      f_function_id: generateUniqueId(),
      f_function_identifier: '',
      f_function_name: '',
      f_criticality_assessment_id: generateUniqueId(),
      f_reasons_for_criticality: '',
      f_date_of_last_assessment: '',
      f_recovery_time_objective: '',
      f_recovery_point_objective: '',
      f_impact_of_discontinuing_id: generateUniqueId(),
      f_licensed_activity_id: generateUniqueId(),
      f_entity_id: generateUniqueId(),
      f_possibility_of_reintegration_id: generateUniqueId(),
      f_function_level_id: generateUniqueId(),
      f_function_description: '',
      f_start_date: '',
      f_end_date: '',
      function_level_id: generateUniqueId(),
      function_level_name: '',
      function_level_description: '',
      data_ict_system_name: 'data_ict_system_name',
      data_ict_system_description: '',
      certificates: [
        {
          certificates_id: generateUniqueId(),
          certificates_description: '',
          certificates_registry: '',
          certificates_date_of_issuance: '',
          certificates_validity: '',
          certificates_date_of_certificate_register: '',
          certificates_third_party_service_provider_id: generateUniqueId(),
          certificates_function_id: generateUniqueId(),
          certificates_ict_service_id: generateUniqueId(),
          certificates_end_date: '',
          certificates_start_date: '',
        },
      ],
      ict_systems: [
        {
          ict_systems_id: generateUniqueId(),
          ict_systems_name: '',
          ict_systems_description: '',
          ict_systems_start_date: '',
          ict_systems_end_date: '',
        },
      ],
      data: [
        {
          sensitivenessOfDataStoredbyICTTPSP_id: generateUniqueId(),
          sensitivenessOfDataStoredbyICTTPSP_name: '',
          sensitivenessOfDataStoredbyICTTPSP_description: '',
          dataSensitiveness_id: generateUniqueId(),
          dataSensitiveness_sensitiveness_id: generateUniqueId(),
          dataSensitiveness_sensitiveness_name: '',
          dataSensitiveness_description: '',
          dataSensitiveness_ict_system_id: generateUniqueId(),
          data_id: generateUniqueId(),
          data_location_of_data_at_rest: '',
          data_location_of_data_management: '',
          data_sensitiveness_id: generateUniqueId(),
          data_description: '',
          data_ict_system_id: generateUniqueId(),
          data_start_date: '',
          data_end_date: '',
        },
      ],
      data_sensitiveness_name: '',
      data_sensitiveness_description: '',
      critically_assessment_dora_criticality: '',
      critically_assessment_dora_regulator_criticality: '',
      critically_assessment_functions_criticality: '',
      critically_assessment_ict_services_criticality: '',
      critically_assessment_marisk_criticality: '',
    };
    const newRow = structuredClone(newRowTemplate);

    setData((prevData) => [...prevData, newRow]);
    setChangedData((prevChangedData) => [...prevChangedData, newRow]);
  };

  const handleSubmit = async () => {
    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited Functions to submit.',
        placement: 'topRight',
      });
      return;
    }

    const formattedData = {
      entity_name: query.entity,
      SensetevenessOfData: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.sensitivenessOfDataStoredbyICTTPSP_id,
            name: itemData.sensitivenessOfDataStoredbyICTTPSP_name,
            description:
              itemData.sensitivenessOfDataStoredbyICTTPSP_description,
          })),
        )
        .flat(),

      DataSensitiveness: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.data_sensitiveness_id,
            name: itemData.dataSensitiveness_sensitiveness_name,
            sensitiveness_of_data_stored_by_ict_tpsp_id:
              itemData.sensitivenessOfDataStoredbyICTTPSP_id,
            description: itemData.dataSensitiveness_id,
          })),
        )
        .flat(),

      Data: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.data_id,
            location_of_data_at_rest: itemData.data_location_of_data_at_rest,
            location_of_data_management:
              itemData.data_location_of_data_management,
            sensitiveness_id: itemData.dataSensitiveness_id,
            ict_system_id: itemData.data_ict_system_id,
            description: itemData.data_description,
            start_date: itemData.data_start_date,
            end_date: itemData.data_end_date,
          })),
        )
        .flat(),

      CriticalityAssessment: changedData.map((item) => ({
        id: item.critically_assessment_id,
        name: item.critically_assessment_name,
        description: item.critically_assessment_description,
        critically_assessment_dora_criticality:
          item.critically_assessment_dora_criticality,
        critically_assessment_dora_regulator_criticality:
          item.critically_assessment_dora_regulator_criticality,
        critically_assessment_functions_criticality:
          item.critically_assessment_functions_criticality,
        critically_assessment_ict_services_criticality:
          item.critically_assessment_ict_services_criticality,
        critically_assessment_marisk_criticality:
          item.critically_assessment_marisk_criticality,
      })),

      AuswahlFunctionDiscont: changedData.map((item) => ({
        id: item.auswahl_function_discont_id,
        name: item.auswahl_function_discont_name,
        description: item.auswahl_function_discont_description,
      })),

      SelectionLicencedAct: changedData.map((item) => ({
        id: item.selection_licenced_act_id,
        name: item.selection_licenced_act_name,
        description: item.selection_licenced_act_description,
      })),

      FunctionLevel: changedData.map((item) => ({
        id: item.function_level_id,
        name: item.function_level_name,
        description: item.function_level_description,
      })),

      Function: changedData.map((item) => ({
        id: item.f_function_id,
        function_identifier: item.f_function_identifier,
        function_name: item.f_function_name,
        criticality_assessment_id: item.f_criticality_assessment_id,
        reasons_for_criticality: item.f_reasons_for_criticality,
        date_of_last_assessment: item.f_date_of_last_assessment,
        recovery_time_objective: item.f_recovery_time_objective,
        recovery_point_objective: item.f_recovery_point_objective,
        impact_of_discontinuing_id: item.f_impact_of_discontinuing_id,
        licensed_activity_id: item.f_licensed_activity_id,
        entity_id: item.f_entity_id,
        possibility_of_reintegration_id: item.f_possibility_of_reintegration_id,
        function_level_id: item.f_function_level_id,
        description: item.f_function_description,
        start_date: item.f_start_date,
        end_date: item.f_end_date,
      })),

      ICTSystems: changedData
        .map((item) =>
          item.ict_systems.map((ict: IICTService) => ({
            id: ict.ict_systems_id,
            name: ict.ict_systems_name,
            description: ict.ict_systems_description,
            start_date: ict.ict_systems_start_date,
            end_date: ict.ict_systems_end_date,
          })),
        )
        .flat(),

      Certificates: changedData
        .map((item) =>
          item.certificates.map((cert: ICertificate) => ({
            id: cert.certificates_id,
            description: cert.certificates_description,
            validity: cert.certificates_validity,
            date_of_issuance: cert.certificates_date_of_issuance,
            certificates_registry: cert.certificates_registry,
            date_of_certificate_register:
              cert.certificates_date_of_certificate_register,
            third_party_service_provider_id:
              cert.certificates_third_party_service_provider_id,
            function_id: cert.certificates_function_id,
            ict_service_id: cert.certificates_ict_service_id,
            end_date: cert.certificates_end_date,
            start_date: cert.certificates_start_date,
          })),
        )
        .flat(),
    };

    console.log(formattedData, 'form');
    try {
      const response = await functionOp.insertFunctions(formattedData);
      notification.success({
        message: 'Submission Successful',
        description: 'The data was successfully submitted.',
        placement: 'topRight',
      });
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const columns: EditableColumnType<IFunction>[] = [
    {
      title: (
        <>
          Critically assessment name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'critically_assessment_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Dora criticality<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'critically_assessment_dora_criticality',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_dora_criticality`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_dora_criticality
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Dora regulator criticality<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'critically_assessment_dora_regulator_criticality',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_dora_regulator_criticality`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_dora_regulator_criticality
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Functions criticality<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'critically_assessment_functions_criticality',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_functions_criticality`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_functions_criticality
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          ICT services criticality<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'critically_assessment_ict_services_criticality',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_ict_services_criticality`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_ict_services_criticality
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Marisk criticality<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'critically_assessment_marisk_criticality',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_marisk_criticality`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_marisk_criticality
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Critically assessment description<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'critically_assessment_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Auswahl function discont name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'auswahl_function_discont_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_auswahl_function_discont_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.auswahl_function_discont_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Auswahl function discont description
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'auswahl_function_discont_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_auswahl_function_discont_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.auswahl_function_discont_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Selection licenced act name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'selection_licenced_act_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_selection_licenced_act_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.selection_licenced_act_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Function identifier<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'f_function_identifier',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_function_identifier`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.f_function_identifier
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },

    {
      title: (
        <>
          Start Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'f_start_date',
      render: (text: string, record) => {
        const fieldName = `${record.f_function_id}_f_start_date`;
        const rowKey = record.key;
        return (
          <Styled.InputField>
            <DynamicField
              value={
                editedData.hasOwnProperty(fieldName)
                  ? String(editedData[fieldName] ?? '')
                  : String(record.f_start_date ?? '')
              }
              fieldName={fieldName}
              rowKey={record.key}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEditChange(e, fieldName, rowKey)
              }
              type="date"
            />
          </Styled.InputField>
        );
      },
    },
    {
      title: (
        <>
          End Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'f_end_date',
      render: (text: string, record) => {
        const fieldName = `${record.f_function_id}_f_end_date`;
        const rowKey = record.key;
        return (
          <Styled.InputField>
            <DynamicField
              value={
                editedData.hasOwnProperty(fieldName)
                  ? String(editedData[fieldName] ?? '')
                  : String(record.f_end_date ?? '')
              }
              fieldName={fieldName}
              rowKey={record.key}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEditChange(e, fieldName, rowKey)
              }
              type="date"
            />
          </Styled.InputField>
        );
      },
    },
    {
      title: (
        <>
          ICT Systems<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ict_systems',
      key: 'ict_systems',
      render: (_, record) => {
        return (
          <div>
            {(record.ict_systems as IICTService[]).map(
              (system: IICTService) => {
                const idFieldName = `${system.ict_systems_id}_systems_ict_systems_id`;
                const nameFieldName = `${system.ict_systems_id}_systems_ict_systems_name`;
                const descriptionFieldName = `${system.ict_systems_id}_systems_ict_systems_description`;
                const startDateFieldName = `${system.ict_systems_id}_systems_ict_systems_start_date`;
                const endDateFieldName = `${system.ict_systems_id}_systems_ict_systems_end_date`;
                const rowKey = record.key;
                return (
                  <Styled.TooltipContainer
                    key={system.ict_systems_id}
                    visible={isTooltipVisible[idFieldName]}
                    title={
                      <div>
                        <Styled.TooltipButtonContainer>
                          <Button
                            size="small"
                            type="text"
                            onClick={() => handleCloseTooltip(idFieldName)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                        </Styled.TooltipButtonContainer>
                        <Styled.InputField>
                          <strong>
                            Name <Styled.Asterisx>*</Styled.Asterisx>{' '}
                          </strong>

                          <Input
                            value={
                              editedData.hasOwnProperty(nameFieldName)
                                ? typeof editedData[nameFieldName] === 'boolean'
                                  ? editedData[nameFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[nameFieldName])
                                : String(system.ict_systems_name)
                            }
                            onChange={(e) =>
                              handleEditChange(e, nameFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>Description</strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(descriptionFieldName)
                                ? typeof editedData[descriptionFieldName] ===
                                  'boolean'
                                  ? editedData[descriptionFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[descriptionFieldName])
                                : String(system.ict_systems_description)
                            }
                            onChange={(e) =>
                              handleEditChange(e, descriptionFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Start date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(startDateFieldName)
                                ? String(editedData[startDateFieldName] ?? '')
                                : String(system.ict_systems_start_date ?? '')
                            }
                            fieldName={startDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                startDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            End date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(endDateFieldName)
                                ? String(editedData[endDateFieldName] ?? '')
                                : String(system.ict_systems_end_date ?? '')
                            }
                            fieldName={endDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(e, endDateFieldName, record.key)
                            }
                            type="date"
                          />
                        </Styled.InputField>
                      </div>
                    }
                    open={isTooltipVisible[idFieldName] || false}
                    trigger={[]}
                  >
                    <Styled.TagComponent
                      color="blue"
                      onClick={() => handleOpenTooltip(idFieldName)}
                    >
                      <Input
                        value={
                          editedData.hasOwnProperty(nameFieldName)
                            ? typeof editedData[nameFieldName] === 'boolean'
                              ? editedData[nameFieldName]
                                ? 'true'
                                : 'false'
                              : String(editedData[nameFieldName])
                            : String(system.ict_systems_name)
                        }
                        readOnly
                      />
                    </Styled.TagComponent>
                    <Styled.GlobalStyle />
                  </Styled.TooltipContainer>
                );
              },
            )}
            <Button
               type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewIctSystem(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Certificates<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'certificates',
      key: 'certificates',
      render: (_, record) => {
        return (
          <div>
            {(record.certificates as ICertificate[]).map(
              (cert: ICertificate) => {
                const idFieldName = `${cert.certificates_id}_newcert_certificates_id`;
                const descriptionFieldName = `${cert.certificates_id}_newcert_certificates_description`;
                const registryFieldName = `${cert.certificates_id}_newcert_certificates_registry`;
                const issuanceDateFieldName = `${cert.certificates_id}_newcert_certificates_date_of_issuance`;
                const validityFieldName = `${cert.certificates_id}_newcert_certificates_validity`;
                const dateOfRegisterFieldName = `${cert.certificates_id}_newcert_certificates_date_of_certificate_register`;
                const startDateFieldName = `${cert.certificates_id}_newcert_certificates_start_date`;
                const endDateFieldName = `${cert.certificates_id}_newcert_certificates_end_date`;
                const rowKey = record.key;
                return (
                  <Styled.TooltipContainer
                    key={cert.certificates_id}
                    visible={isTooltipVisible[idFieldName]}
                    title={
                      <div>
                        <Styled.TooltipButtonContainer>
                          <Button
                            size="small"
                            type="text"
                            onClick={() => handleCloseTooltip(idFieldName)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                        </Styled.TooltipButtonContainer>
                        <Styled.InputField>
                          <strong>
                            Registry <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(registryFieldName)
                                ? typeof editedData[registryFieldName] ===
                                  'boolean'
                                  ? editedData[registryFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[registryFieldName])
                                : String(cert.certificates_registry)
                            }
                            onChange={(e) =>
                              handleEditChange(e, registryFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Issuance date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(issuanceDateFieldName)
                                ? String(
                                    editedData[issuanceDateFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_issuance ?? '',
                                  )
                            }
                            fieldName={issuanceDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                issuanceDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Validity <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(validityFieldName)
                                ? typeof editedData[validityFieldName] ===
                                  'boolean'
                                  ? editedData[validityFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[validityFieldName])
                                : String(cert.certificates_validity)
                            }
                            onChange={(e) =>
                              handleEditChange(e, validityFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>Certificate Description</strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(descriptionFieldName)
                                ? typeof editedData[descriptionFieldName] ===
                                  'boolean'
                                  ? editedData[descriptionFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[descriptionFieldName])
                                : String(cert.certificates_description)
                            }
                            onChange={(e) =>
                              handleEditChange(e, descriptionFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Date of register<Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(dateOfRegisterFieldName)
                                ? String(
                                    editedData[dateOfRegisterFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_certificate_register ??
                                      '',
                                  )
                            }
                            fieldName={dateOfRegisterFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                dateOfRegisterFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>

                        <Styled.InputField>
                          <strong>
                            Start date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(startDateFieldName)
                                ? String(editedData[startDateFieldName] ?? '')
                                : String(cert.certificates_start_date ?? '')
                            }
                            fieldName={startDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                startDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            End date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(endDateFieldName)
                                ? String(editedData[endDateFieldName] ?? '')
                                : String(cert.certificates_end_date ?? '')
                            }
                            fieldName={endDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(e, endDateFieldName, record.key)
                            }
                            type="date"
                          />
                        </Styled.InputField>
                      </div>
                    }
                    open={isTooltipVisible[idFieldName] || false}
                    trigger={[]}
                  >
                    <Styled.TagComponent
                      color="blue"
                      onClick={() => handleOpenTooltip(idFieldName)}
                    >
                      <Input
                        value={
                          editedData.hasOwnProperty(descriptionFieldName)
                            ? typeof editedData[descriptionFieldName] ===
                              'boolean'
                              ? editedData[descriptionFieldName]
                                ? 'true'
                                : 'false'
                              : String(editedData[descriptionFieldName])
                            : String(cert.certificates_description)
                        }
                        readOnly
                      />
                    </Styled.TagComponent>
                    <Styled.GlobalStyle />
                  </Styled.TooltipContainer>
                );
              },
            )}
            <Button
               type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewCertificate(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Data<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'data',
      key: 'data',
      render: (_, record) => {
        return (
          <div>
            {record.data.map((itemData: IData, index: number) => {
              const idFieldName = `${itemData.data_id}_newItem_data_id`;
              const descriptionFieldName = `${itemData.data_id}_newItem_data_description`;
              const restLocationFieldName = `${itemData.data_id}_newItem_data_location_of_data_at_rest`;
              const startDateFieldName = `${itemData.data_id}_newItem_data_start_date`;
              const endDateFieldName = `${itemData.data_id}_newItem_data_end_date`;
              const managementLocationFieldName = `${itemData.data_id}_newItem_data_location_of_data_management`;
              const sensitivenessNameFieldName = `${itemData.data_id}_newItem_dataSensitiveness_sensitiveness_name`;
              const sensitivenessDescriptionFieldName = `${itemData.data_id}_newItem_dataSensitiveness_description`;
              const sensitivenessOfDataStoredByICTTPSPName = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_name`;
              const sensitivenessOfDataStoredByICTTPSPDescription = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_description`;
              const rowKey = record.key;
              return (
                <Styled.TooltipContainer
                  key={itemData.data_id || index}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>
                          Location of Data at Rest{' '}
                          <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(restLocationFieldName)
                              ? typeof editedData[restLocationFieldName] ===
                                'boolean'
                                ? editedData[restLocationFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[restLocationFieldName])
                              : String(itemData.data_location_of_data_at_rest)
                          }
                          onChange={(e) =>
                            handleEditChange(e, restLocationFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Location of Data Management{' '}
                          <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              managementLocationFieldName,
                            )
                              ? typeof editedData[
                                  managementLocationFieldName
                                ] === 'boolean'
                                ? editedData[managementLocationFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[managementLocationFieldName],
                                  )
                              : String(
                                  itemData.data_location_of_data_management,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              managementLocationFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Description:</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(itemData.data_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Start date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(startDateFieldName)
                              ? String(editedData[startDateFieldName] ?? '')
                              : String(itemData.data_start_date ?? '')
                          }
                          fieldName={startDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, startDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          End date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(endDateFieldName)
                              ? String(editedData[endDateFieldName] ?? '')
                              : String(itemData.data_end_date ?? '')
                          }
                          fieldName={endDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, endDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Sensitiveness Name{' '}
                          <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessNameFieldName,
                            )
                              ? typeof editedData[
                                  sensitivenessNameFieldName
                                ] === 'boolean'
                                ? editedData[sensitivenessNameFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[sensitivenessNameFieldName])
                              : String(
                                  itemData.dataSensitiveness_sensitiveness_name,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessNameFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Sensitiveness Description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessDescriptionFieldName,
                            )
                              ? typeof editedData[
                                  sensitivenessDescriptionFieldName
                                ] === 'boolean'
                                ? editedData[sensitivenessDescriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[
                                      sensitivenessDescriptionFieldName
                                    ],
                                  )
                              : String(itemData.dataSensitiveness_description)
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessDescriptionFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Sensitiveness of data stored by ICT TPSP Name{' '}
                          <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessOfDataStoredByICTTPSPName,
                            )
                              ? typeof editedData[
                                  sensitivenessOfDataStoredByICTTPSPName
                                ] === 'boolean'
                                ? editedData[
                                    sensitivenessOfDataStoredByICTTPSPName
                                  ]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[
                                      sensitivenessOfDataStoredByICTTPSPName
                                    ],
                                  )
                              : String(
                                  itemData.sensitivenessOfDataStoredbyICTTPSP_name,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessOfDataStoredByICTTPSPName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Sensitiveness of data stored by ICT TPSP Description
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessOfDataStoredByICTTPSPDescription,
                            )
                              ? typeof editedData[
                                  sensitivenessOfDataStoredByICTTPSPDescription
                                ] === 'boolean'
                                ? editedData[
                                    sensitivenessOfDataStoredByICTTPSPDescription
                                  ]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[
                                      sensitivenessOfDataStoredByICTTPSPDescription
                                    ],
                                  )
                              : String(
                                  itemData.sensitivenessOfDataStoredbyICTTPSP_description,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessOfDataStoredByICTTPSPDescription,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(descriptionFieldName)
                          ? typeof editedData[descriptionFieldName] ===
                            'boolean'
                            ? editedData[descriptionFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[descriptionFieldName])
                          : String(itemData.data_description)
                      }
                      readOnly
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Button
               type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewData(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Function level name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'function_level_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_function_level_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.function_level_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Function level description<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'function_level_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_function_level_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.function_level_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
  ];

  return (
    <>
      {query.entity && (
        <Styled.AreaButton type="primary" onClick={addNewRow}>
          Add Row
        </Styled.AreaButton>
      )}
      <Styled.TooltipTableContainer>
        <Table
          dataSource={dataWithKey}
          rowKey="key"
          scroll={{ x: 'max-content' }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePaginationChange,
            showSizeChanger: pagination.showSizeChanger,
            pageSizeOptions: pagination.pageSizeOptions,
            position: pagination.position,
          }}
          columns={columns}
        />
      </Styled.TooltipTableContainer>
      {query.entity && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
        )}
    </>
  );
};

export default Function;
