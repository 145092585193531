const changePassInputs = [
  {
    label: 'Old password',
    name: 'old_password',
  },
  {
    label: 'New password',
    name: 'new_password',
  },
];

export default changePassInputs;
