import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme, Card, Layout, Button, message } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { AppDispatch, RootState } from 'store/configureStore';
import TitleBar from 'components/TitleBar';
import { useLocation } from 'react-router-dom';
import CustomSelect from 'components/CustomSelect';
import { TitleList } from 'store/ducks/globalTypes';
import qs from 'qs';
import GoBackButton from 'components/GoBackButton';
import ReportModal from 'components/Modal/Modal';
import { tableData } from 'constants/excelData';
import useBasicFilters from 'utils/hooks/useBasicFilters';
import { globalOp } from 'store/ducks/Global';
import { reportingOp } from 'store/ducks/Reportings';
import * as XLSX from 'xlsx';
import * as Styled from '../Layout.styled';

const { useToken } = theme;
const { Sider } = Layout;

const ReportingLayout: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { token } = useToken();
  const { scheme } = useSelector((state: RootState) => state.global);
  const [open, setOpen] = useState(false);
  const { query, handleFilterChangeForReportings, extractedOptions } =
    useBasicFilters();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    dispatch(globalOp.setFilters());
  }, [dispatch]);

  const entityName = typeof query.entity === 'string' ? query.entity : '';

  const applyFilters = () => {
    setOpen(false);
    message.success('Filters applied');
  };

  const handleExcelSheetsDownload = async () => {
    try {
      const params = {};
      const fetchOperations = [
        reportingOp.fetchEntityContractualArrangements(params),
        reportingOp.fetchBranches(params),
        reportingOp.fetchContractualArrangementsLei(params),
        reportingOp.fetchContractualArrangementsICT(params),
        reportingOp.fetchContractualArrangementsICT(params),
      ];

      const sheetTitles = [
        'RT.02.03',
        'RT.01.03',
        'RT.03.01',
        'RT.03.03',
        'RT.04.01',
      ];

      const results = await Promise.all(fetchOperations);
      const wb = XLSX.utils.book_new();

      results.forEach((data, index) => {
        const sheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, sheet, sheetTitles[index]);
      });

      XLSX.writeFile(wb, `Registry of Information for ${entityName}.xlsx`);

      setOpen(false);
    } catch (error) {
      console.error('Error fetching data or generating Excel:', error);
    }
  };

  return (
    <Styled.StyledLayout token={token}>
      <TitleBar title={TitleList.REPORTING} />
      <h4>Informationsregister</h4>
      <Styled.WorkingAreaButton>
        <div>
          <GoBackButton to="/working-area" text="Working areas" />
        </div>
        <Button
          type="primary"
          onClick={showDrawer}
          icon={open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        ></Button>
      </Styled.WorkingAreaButton>
      <Styled.AlertComponent>
        {!query.entity && (
          <Styled.AlertInfo
            description="In order to retrieve the data, it would be necessary to filter the entity first."
            type="info"
            showIcon
            closable
          />
        )}
      </Styled.AlertComponent>
      <Styled.HorizontalLayout theme={scheme}>
        <Styled.Container>
          <Styled.GridContainer>
            <Styled.ReportCard>
              <Styled.ExcelDownload>
                <Card.Meta
                  title={
                    <Styled.StyledButton
                      type="primary"
                      onClick={handleExcelSheetsDownload}
                    >
                      Registry of Information
                    </Styled.StyledButton>
                  }
                  description={<p>Format to be sent to the regulator</p>}
                />
              </Styled.ExcelDownload>
            </Styled.ReportCard>
            {tableData.map((item, index) => (
              <Styled.ReportCard key={index}>
                <Card.Meta
                  title={
                    <ReportModal title={item.title} tableNumber={item.title} />
                  }
                  description={<p>{item.description}</p>}
                />
              </Styled.ReportCard>
            ))}
          </Styled.GridContainer>
        </Styled.Container>
      </Styled.HorizontalLayout>
      <Styled.StyledLayout token={token}>
        <Sider>
          <Styled.AntDrawer title="Filters" onClose={onClose} open={open}>
            <CustomSelect
              placeholder="Select Options"
              onChange={handleFilterChangeForReportings}
              options={extractedOptions}
              defaultValue={queryParams.entity}
            />
            <Styled.StyledButton type="primary" onClick={applyFilters}>
              Display
            </Styled.StyledButton>
          </Styled.AntDrawer>
        </Sider>
      </Styled.StyledLayout>
    </Styled.StyledLayout>
  );
};

export default ReportingLayout;
