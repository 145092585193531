import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ISelectedValueICT,
  ISelectedValue,
  ISelectedEntityForContractualInformation,
} from './types';
import initialContractualInformationState from './initialState';

const contractualInformationSlice = createSlice({
  name: 'contractual_information',
  initialState: initialContractualInformationState(),
  reducers: {
    setSelectedBranch_Contractual_Information: (
      state,
      action: PayloadAction<ISelectedValue | null>,
    ) => {
      state.selectedBranchForContractualInformation = action.payload;
    },
    setSelectedICT_Contractual_Information: (
      state,
      action: PayloadAction<ISelectedValueICT | null>,
    ) => {
      state.selectedICTForContractualInformation = action.payload;
    },
    setSelectedTPP_Contractual_Information: (
      state,
      action: PayloadAction<ISelectedValue | null>,
    ) => {
      state.selectedTPPForContractualInformation = action.payload;
    },
    setSelecedEntity_Contractual_Information: (
      state,
      action: PayloadAction<ISelectedEntityForContractualInformation | null>,
    ) => {
      state.selectedEntityForContractualInformation = action.payload;
    },
  },
});

export const {
  setSelectedBranch_Contractual_Information,
  setSelectedICT_Contractual_Information,
  setSelectedTPP_Contractual_Information,
  setSelecedEntity_Contractual_Information,
} = contractualInformationSlice.actions;

export default contractualInformationSlice;
