import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialGlobalState from './initialState';

const thirdPartyProvidersSlice = createSlice({
  name: 'tpp',
  initialState: initialGlobalState(),
  reducers: {
    setProviderNames: (state, action: PayloadAction<string[]>) => {
      state.providerNames = action.payload;
    },
    setSelectedTPP(state, action: PayloadAction<string>) {
      state.selectedTPP = action.payload;
    },
  },
});

export const { setProviderNames, setSelectedTPP } =
  thirdPartyProvidersSlice.actions;
export default thirdPartyProvidersSlice;
