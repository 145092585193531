import { useState, useEffect } from "react";
import { globalOp } from "store/ducks/Global";

interface ICTSystem {
  label: string;
  value: any;
}

const useICTSystems = (entityId: string, entityName: string) => {
  const [ictSystems, setIctSystems] = useState<ICTSystem[]>([]);

  const fetchICTSystems = async () => {
    try {
      const response = await globalOp.fetchICTSystemsbyEntityName({
        entity_id: entityId,
        entity_name: entityName,
      });
      const data = response?.data;
      if (data?.length > 0) {
        const formattedOptions = data.map((system: { name: any; id: any }) => ({
          label: system.name,
          value: system.id,
        }));
        setIctSystems(formattedOptions);
      }
    } catch (error) {
      console.error("Error fetching ICT systems:", error);
    }
  };

  useEffect(() => {
    if (entityId && entityName) {
      fetchICTSystems();
    }
  }, [entityId, entityName]);

  return { ictSystems, refetch: fetchICTSystems };
};

export default useICTSystems;
