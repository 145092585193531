import React, { useState, useEffect } from 'react';
import { notification, DatePicker } from 'antd';
import dayjs from 'dayjs';
import CustomTable from 'components/CustomTable/CustomTable';
import useFilters from 'utils/hooks/useFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import * as Styled from '../../DataEntry.styled';
import {
  IActivitiesByTypeEntityDataType,
  DataType,
} from 'store/ducks/DataEntry/Entities/types';

const ActivitiesByTypeEntity: React.FC = () => {
  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<
    IActivitiesByTypeEntityDataType[]
  >([]);
  const [initialData, setInitialData] = useState<
    IActivitiesByTypeEntityDataType[]
  >([]);

  const handleSave = (row: IActivitiesByTypeEntityDataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const fetchActivities = async () => {
    try {
      const response = await entityOp.fetchActivitiesByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.activities;
      const mappedData = data.map((activity: any, index: number) => ({
        key: index,
        id: activity.activity_id || 0,
        activity_name: activity.activity_name || '',
        activity_date: activity.activity_date || 0,
        description: activity.description || '',
        entity_type_id: activity.entity_type_id || 0,
        entity_name: response?.data?.entity_name || '',
        isNew: false,
        isEdited: false,
      }));

      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchActivities();
    }
  }, [query]);

  const handleSubmit = async () => {
    if (JSON.stringify(dataSource) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
      });
      return;
    }
    const editedData = dataSource.filter((item) => item.isEdited);

    if (editedData.length === 0) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
      });
      return;
    }
    const data_list = editedData.map(
      ({ id, activity_name, activity_date, description, entity_type_id }) => ({
        id,
        activity_name,
        activity_date,
        description,
        entity_type_id,
      }),
    );
    try {
      const payload = {
        entity_name: query.entity,
        data_list,
      };
      const response = await entityOp.fetchActivitiesByTypeEntity(payload);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const handleDateChange = (key: number, field: string, dateString: string) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        [field]: dateString,
        isEdited: true,
      });
      setDataSource(newData);
    }
  };

  const columns = [
    {
      title: (
        <>
          Activity name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'activity_name',
      editable: true,
    },
    {
      title: (
        <>
          Activity date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'activity_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                handleDateChange(
                  record.key,
                  'activity_date',
                  formattedDateString,
                );
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: 'Entity name',
      dataIndex: 'entity_name',
    },
  ];

  return (
    <Styled.StyledWrapper hasEntity={!!query.entity}>
      <CustomTable
        columns={columns}
        dataSource={dataSource as IActivitiesByTypeEntityDataType[]}
        handleSave={handleSave}
      />
       {query.entity && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
        )}
    </Styled.StyledWrapper>
  );
};

export default ActivitiesByTypeEntity;
