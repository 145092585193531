import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, message, Steps, Layout, theme } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { TitleList } from 'store/ducks/globalTypes';
import { RootState } from 'store/configureStore';
import {
  setProviderNames,
  setSelectedTPP,
} from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import GoBackButton from 'components/GoBackButton';
import TitleBar from 'components/TitleBar/TitleBar';
import useBasicFilters from 'utils/hooks/useBasicFilters';
import CustomSelect from 'components/CustomSelect';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';
import * as Styled from 'components/Layout/Layout.styled';

interface Step {
  title: string;
  content: React.ReactNode;
}

interface StepComponentProps {
  steps: Step[];
  title: string;
}
const StepComponent: React.FC<StepComponentProps> = ({ steps }) => {
  const { token } = theme.useToken();
  const dispatch = useDispatch();
  const location = useLocation();

  const [current, setCurrent] = useState(0);
  const [open, setOpen] = useState(false);
  const [all, setAll] = useState(false);
  const [pathNameTPP, setPathNameTPP] = useState(false);
  const [selectedTPPForICT, setSelectedTPPForICT] = useState<
    string | undefined
  >(undefined);
  const [selectedFunctionForICT, setSelectedFunctionForICT] = useState<
    string | undefined
  >(undefined);
  const [selectedContractForICT, setSelectedContractForICT] = useState<
    string | undefined
  >(undefined);
  const [tppdData, setTPPData] = useState<any[]>([]);
  const [functionData, setFunctionData] = useState<any[]>([]);
  const [contractsData, setContractsData] = useState<any[]>([]);
  const { query, handleFilterChange, extractedOptions } = useBasicFilters();
  const { scheme } = useSelector((state: RootState) => state.global);
  const { selectedTPP } = useSelector((state: RootState) => state.tpp);
  const { Sider } = Layout;
  const showDrawer = () => setOpen(true);
  const next = () => setCurrent((prev) => prev + 1);
  const prev = () => setCurrent((prev) => prev - 1);
  const onClose = () => setOpen(false);
  const onStepChange = (stepIndex: number) => setCurrent(stepIndex);

  const applyFilters = () => {
    setOpen(false);
    message.success('Filters applied');
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#aaa',
    backgroundColor: '#f0f2f5',
    borderRadius: '8px',
    border: '1px dashed #d9d9d9',
    marginTop: 16,
  };

  useEffect(() => {
    setAll(steps[current]?.title === 'ThirdPartyServiceProvider');
  }, [current, steps]);

  useEffect(() => {
    if (
      steps[current]?.title !== 'ThirdPartyServiceProvider' &&
      selectedTPP === 'All'
    ) {
      dispatch(setSelectedTPP(''));
      message.info('Please select a valid TPP.');
    }
  }, [current, steps]);

  const fetchTPPbyEntity = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;
      if (data.length > 0) {
        const updatedTPPData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          third_party_service_provider_name:
            item.third_party_service_provider_name,
        }));
        setTPPData(updatedTPPData);
      } else {
        setTPPData([]);
      }
      setSelectedTPPForICT(undefined);
      dispatch(setSelectedTPP(''));
    } catch (error) {
      console.log(error, 'error');
      setTPPData([]);
    }
  };

  const fetchFunctionsByEntity = async () => {
    try {
      const response = await ict_serviceOp.fetchFunctionsbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data;
      // console.log(data, 'functions');
      if (data.length > 0) {
        const updatedFunctionsData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          function_name: item.function_name,
        }));
        setFunctionData(updatedFunctionsData);
      } else {
        setFunctionData([]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchContractsByEntity = async () => {
    try {
      const response =
        await ict_serviceOp.fetchContractualArrangementsbyEntityName({
          entity_id: query.entityId,
          entity_name: query.entity,
        });
      const data = response?.data;
      // console.log(data, 'contracts');

      if (data.length > 0) {
        const updatedContractsData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          description: item.description,
        }));
        setContractsData(updatedContractsData);
      } else {
        setContractsData([]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchTPPbyEntity();
      fetchFunctionsByEntity();
      fetchContractsByEntity();
    }
  }, [query.entity]);

  const handleTPPForICTChange = (value: string) => {
    setSelectedTPPForICT(value);
    dispatch(setSelectedTPP(value));
  };

  const handleChange = (selectedLabel: string) => {
    const selectedOption = extractedOptions.find(
      (option: { label: string }) => option.label === selectedLabel,
    );
    if (selectedOption) {
      handleFilterChange(selectedOption.label, selectedOption.key);
      setSelectedTPPForICT(undefined);
      setSelectedFunctionForICT(undefined);
      setSelectedContractForICT(undefined);
    } else {
      console.log('No matching option found for label:', selectedLabel);
    }
  };

  useEffect(() => {
    if (location.pathname === '/data-entry/third-party-provider') {
      setPathNameTPP(true);
    }
  }, [location]);

  return (
    <Styled.StyledLayout token={token}>
      <TitleBar title={TitleList.DATA_ENTRY} />
      <Styled.WorkingAreaButton>
        <div>
          <GoBackButton to="/" text="Working areas" />
          <GoBackButton to="/data-entry" text="Data Entry" />
        </div>
        <Button
          type="primary"
          onClick={showDrawer}
          icon={open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        ></Button>
      </Styled.WorkingAreaButton>
      <Styled.HorizontalLayout theme={scheme}>
        <Styled.StepsComponent>
          {!query.entity && (
            <Styled.AlertInfo
              description="In order to retrieve the data, it would be necessary to filter the entity first."
              type="info"
              showIcon
              closable
            />
          )}
          <Steps current={current} items={items} onChange={onStepChange} />
          <Styled.SelectionOfTPP>
            {pathNameTPP && (
              <Styled.SelectFilter
                placeholder="TPP"
                options={
                  steps[current]?.title === 'ThirdPartyServiceProvider'
                    ? [
                        { value: 'All', label: 'All', key: 'all' },
                        ...tppdData
                          .filter(
                            (item) =>
                              item &&
                              item.id &&
                              item.third_party_service_provider_name,
                          )
                          .map((item) => ({
                            value: item.id,
                            label: item.third_party_service_provider_name,
                            key: item.key,
                          })),
                      ]
                    : tppdData
                        .filter(
                          (item) =>
                            item &&
                            item.id &&
                            item.third_party_service_provider_name,
                        )
                        .map((item) => ({
                          value: item.id,
                          label: item.third_party_service_provider_name,
                          key: item.key,
                        }))
                }
                onChange={handleTPPForICTChange}
                value={selectedTPPForICT}
              />
            )}
          </Styled.SelectionOfTPP>
          <Styled.StepButtons>
            <div>
              <Button
                onClick={prev}
                disabled={
                  steps[current]?.title === 'ThirdPartyServiceProvider' ||
                  steps[current]?.title === 'Entity' ||
                  steps[current]?.title === 'Branch' ||
                  steps[current]?.title === 'ICTService' ||
                  steps[current]?.title === 'Function'
                }
              >
                Previous
              </Button>
              {current < steps.length - 1 && (
                <Button type="primary" onClick={next}>
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => message.success('Processing complete!')}
                >
                  Done
                </Button>
              )}
            </div>
          </Styled.StepButtons>
          <div style={contentStyle}>{steps[current].content}</div>
        </Styled.StepsComponent>
      </Styled.HorizontalLayout>
      <Styled.StyledLayout token={token}>
        <Sider>
          <Styled.AntDrawer title="Filters" onClose={onClose} open={open}>
            {steps[current]?.title !== 'ICTService' && (
              <Styled.SelectFilter
                placeholder="Entity"
                options={extractedOptions.map(
                  (option: { label: any; key: any }) => ({
                    value: option.label,
                    key: option.key,
                    label: option.label,
                  }),
                )}
                value={query.entity}
                onChange={handleChange}
              />
            )}

            {steps[current]?.title === 'ICTService' && (
              <>
                <Styled.SelectFilter
                  placeholder="Entity"
                  options={extractedOptions.map(
                    (option: { label: any; key: any }) => ({
                      value: option.label,
                      key: option.key,
                      label: option.label,
                    }),
                  )}
                  value={query.entity}
                  onChange={handleChange}
                />
                <Styled.SelectFilter
                  placeholder="TPP"
                  options={tppdData.map((item) => ({
                    value: item.id,
                    label: item.third_party_service_provider_name,
                    key: item.key,
                  }))}
                  onChange={handleTPPForICTChange}
                  value={selectedTPPForICT}
                />
                <Styled.SelectFilter
                  placeholder="Functions"
                  options={functionData.map((item) => ({
                    value: item.id,
                    label: item.function_name,
                    key: item.key,
                  }))}
                  onChange={(
                    value: React.SetStateAction<string | undefined>,
                  ) => {
                    setSelectedFunctionForICT(value);
                  }}
                  value={selectedFunctionForICT}
                />
                <Styled.SelectFilter
                  placeholder="Contractual arrangements"
                  options={contractsData.map((item) => ({
                    value: item.id,
                    label: item.description,
                    key: item.key,
                  }))}
                  onChange={(
                    value: React.SetStateAction<string | undefined>,
                  ) => {
                    setSelectedContractForICT(value);
                  }}
                  value={selectedContractForICT}
                />
              </>
            )}
            <Styled.StyledButton type="primary" onClick={applyFilters}>
              Display
            </Styled.StyledButton>
          </Styled.AntDrawer>
        </Sider>
      </Styled.StyledLayout>
    </Styled.StyledLayout>
  );
};

export default StepComponent;
