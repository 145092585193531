import React from 'react';
import StepComponent from 'components/StepComponent/CustomSteps';
import EntityMaintainingregistry from 'components/Tables/DataEntry/EntityMaintainingRegistry/EntityMaintainingRegistry';

const steps = [
  {
    title: 'Entity Maintaining Register Of Information',
    content: <EntityMaintainingregistry />,
  },
];

const EntityMaintainingregistrySteps: React.FC = () => {
  return (
    <StepComponent
      steps={steps}
      title="Entity Maintaining Register Of Information Steps"
    />
  );
};

export default EntityMaintainingregistrySteps;
