import React, { useState, useEffect } from 'react';
import useFilters from 'utils/hooks/useFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import i18nIsoCountries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { notification, DatePicker, Input, Button, Modal } from 'antd';
import {
  PlusOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import {
  ITPP,
} from 'store/ducks/DataEntry/ThirdPartyProviders/types';
import { generateUniqueId } from 'utils/hooks/generateUniqueId';
import {
  EditableColumnType,
  DataType,
} from 'components/CustomTable/CustomTable';
import useCountries from 'utils/hooks/fetchHooks/useCountries';
import {
  setProviderNames,
  set_isAddRowTpp,
  set_isTppAdded,
  set_tpps,
  set_addRowCountTpp,
  set_certificates,
  set_tppId,
  set_nameOfTpp,
  set_isTppUpdated,
} from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import useTppStatus from 'utils/hooks/useTppStatus';
import * as Styled from '../../DataEntry.styled';
import CustomSelect from 'components/CustomSelect';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { IData } from 'store/ducks/DataEntry/Functions/types';
import { globalOp } from 'store/ducks/Global';

const ThirdPartyServiceProvider: React.FC = () => {
  useTppStatus();
  const dispatch = useDispatch();
  const { query } = useFilters();
  const [combinedData, setCombinedData] = useState<ITPP[]>([]);
  i18nIsoCountries.registerLocale(enLocale);
  const { selectedTPP, addRowCountTpp, tpps, certificates } = useSelector(
    (state: RootState) => state.tpp,
  );
  const { confirm } = Modal;
  const { countries } = useCountries();
  const [substitutability, setSubstitubility] = useState([]);
  const [impact, setImpact] = useState([]);
  const [alternatives, setAlternatives] = useState<
    { label: string; value: string }[]
  >([]);
  const [possibility, setPossibility] = useState<
    { label: string; value: string }[]
  >([]);
  // const [alternatives, setAlternatives] = useState([]);
  const [doraRegCriticality, setDoraRegCriticality] = useState<
    { label: string; value: string }[]
  >([]);
  const [localRegCriticality, setLocalRegCriticality] = useState<
    { label: string; value: string }[]
  >([]);
  const [providerCodeTypes, setProviderCodeTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [canAddRow, setCanAddRow] = useState(true);
  const [dataSource, setDataSource] = useState<ITPP[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<ITPP[]>([]);
  const [entityNames, setEntityNames] = useState<
    { label: string; value: string }[]
  >([]);
  const [functions, setFunctions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ictServices, setICTServices] = useState<
    { label: string; value: string }[]
  >([]);
  const [count, setCount] = useState(1);
  const [nonSubstReasons, setNonSubstReasons] = useState<
    { label: string; value: string }[]
  >([]);
  const [tppName, setTppName] = useState<string>('');
  const [personTypes, setPersonTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [tooltipsForData, setTooltipsForData] = useState<any[]>([]);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: number]: boolean;
  }>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const isLoggedIn = localStorage.getItem('authToken');
  const [natures, setNatures] = useState<{ label: string; value: string }[]>(
    [],
  );

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const fetchTPPbyEntity = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;
      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        ict_service_provider_identification_code:
          item.ict_service_provider_identification_code,
        id: item.id,
        notice_period: item.notice_period,
        identification_code_type: item.identification_code_type,
        tpp_code_type_id: item.tpp_code_type_id,
        non_subst_reason_id: item.non_subst_reason_id,
        third_party_service_provider_name:
          item.third_party_service_provider_name,
        person_type_id: item.person_type_id,
        headquarters_country: item.headquarters_country,
        iso_code: item.iso_code,
        entity_nature: item.entity_nature,
        ultimate_parent_identification_code:
          item.ultimate_parent_identification_code,
        ultimate_parent_identification_code_type:
          item.ultimate_parent_identification_code_type,
        last_audit_date: item.last_audit_date,
        description: item.description,
        entity_id: item.entity_id,
        // dora_regulator_criticality: item.dora_regulator_criticality,
        end_date: item.end_date ? item.end_date : '9999-01-01',
        start_date: item.start_date,
        isNew: false,
        isEdited: false,
        certificates: [],
        add_ict_service_provider_identification_code:
          item.add_ict_service_provider_identification_code,
        add_identification_code_type: item.add_identification_code_type,
        third_party_service_provider_legal_name:
          item.third_party_service_provider_legal_name,
        dora_regulator_criticality_id: item.dora_regulator_criticality_id,
        dora_regulator_criticality_name: item.dora_regulator_criticality_name,
        local_regulator_criticality_id: item.local_regulator_criticality_id,
        local_regulator_criticality_name: item.local_regulator_criticality_name,
        alternative_providers_identified_id:
          item.alternative_providers_identified_id,
        alternative_providers_identified_name:
          item.alternative_providers_identified_name,
        // alternatives_exit_id: item.alternatives_exit_id,
        // alternatives_exit_name: item.alternatives_exit_name,
        exit_plan_exists_id: item.exit_plan_exists_id,
        exit_plan_exists_name: item.exit_plan_exists_name,
        possibility_of_reintegration_id: item.possibility_of_reintegration_id,
        possibility_of_reintegration_name:
          item.possibility_of_reintegration_name,
        rank: item.rank,
        recipient_identification_code: item.recipient_identification_code,
        recipient_identification_type: item.recipient_identification_type,
        substitutability_of_ict_provider_name:
          item.substitutability_of_ict_provider_name,
        substitutability_of_ict_provider_id:
          item.substitutability_of_ict_provider_id,
      }));

      setDataSource(mappedData);

      setCount(mappedData.length);
      const providerNames = mappedData.map(
        (item: { third_party_service_provider_name: any }) =>
          item.third_party_service_provider_name,
      );
      dispatch(setProviderNames(providerNames));
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchReintegrationPossibilities = async () => {
    try {
      const response = await ict_serviceOp.fetchReintegrationPossibilities();
      if (response?.data.possibility_of_reintegration) {
        const formattedOptions = response.data.possibility_of_reintegration.map(
          (possibility: { description: any; id: any }) => ({
            label: possibility.description,
            value: possibility.id,
          }),
        );
        setPossibility(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFunctions = async () => {
    try {
      const response = await tppOp.fetchFunctions();
      if (response?.data.functions) {
        const formattedOptions = response.data.functions.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setFunctions(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProviderCodeTypes = async () => {
    try {
      const response = await tppOp.fetchProviderCodeTypes();
      if (response?.data.third_party_service_provider_code_types) {
        const formattedOptions =
          response.data.third_party_service_provider_code_types.map(
            (reasons: { name: any; id: any }) => ({
              label: reasons.name,
              value: reasons.id,
            }),
          );
        setProviderCodeTypes(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNatures = async () => {
    try {
      const response = await entityOp.fetchNatureID();
      if (response?.data.nature_of_entities) {
        const formattedOptions = response.data.nature_of_entities.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setNatures(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchICTServices = async () => {
    try {
      const response = await tppOp.fetchICTServices();
      if (response?.data.ict_services) {
        const formattedOptions = response.data.ict_services.map(
          (reasons: { arrangement_reference_number: any; id: any }) => ({
            label: reasons.arrangement_reference_number,
            value: reasons.id,
          }),
        );
        setICTServices(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(set_certificates(tooltipsForData));
  }, [tooltipsForData, dispatch]);

  const getCertificates = async () => {
    try {
      const matchedTPP = dataSource.find(
        (item: any) => item.id.toString() === selectedTPP.toString(),
      );

      if (matchedTPP) {
        const thirdPartyServiceProviderName =
          matchedTPP.third_party_service_provider_name;
        setTppName(thirdPartyServiceProviderName);
        const response = await tppOp.setCertificates(
          thirdPartyServiceProviderName,
          selectedTPP,
        );
        const data = response.certificates;
        const mappedData = data.map((certificate: any, index: number) => ({
          key: index,
          id: certificate.id,
          description: certificate.description,
          validity: certificate.validity,
          date_of_issuance: certificate.date_of_issuance,
          certificate_registry: certificate.certificate_registry,
          date_of_certificate_register:
            certificate.date_of_certificate_register,
          third_party_service_provider_id:
            certificate.third_party_service_provider_id,
          start_date: certificate.start_date,
          end_date: certificate.end_date ? certificate.end_date : '9999-01-01',
          isNew: false,
          isEdited: false,
          tpp_name: response.tpp_name,
        }));
        setTooltipsForData(mappedData);
        // set_activitiesByTypeEntity(mappedData);
        // dispatch(set_certificates(mappedData));
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchSubstitutability = async () => {
    try {
      const response = await globalOp.fetchSubstitutability();
      if (response?.data.substitutability_of_ict_provider) {
        const formattedOptions =
          response.data.substitutability_of_ict_provider.map(
            (subst: { description: any; id: any }) => ({
              label: subst.description,
              value: subst.id,
            }),
          );
        setSubstitubility(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNonSubstReasons = async () => {
    try {
      const response = await tppOp.fetchNonSubstReasons();
      if (response?.data.non_subst_reasons) {
        const formattedOptions = response.data.non_subst_reasons.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setNonSubstReasons(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPersonTypes = async () => {
    try {
      const response = await tppOp.fetchPersonTypes();
      if (response?.data) {
        const formattedOptions = response.data.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setPersonTypes(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEntityNames = async () => {
    try {
      const response = await entityOp.fetchEntityNames();
      if (response?.data) {
        const formattedOptions = response.data.map(
          (reasons: { name_of_entity: any; id: any }) => ({
            label: reasons.name_of_entity,
            value: reasons.id,
          }),
        );
        setEntityNames(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCombinedData([...tpps, ...dataSource]);
  }, [dataSource, tpps]);

  useEffect(() => {
    if (tpps.length < 1) {
      setCombinedData([...dataSource]);
    } else {
      setCombinedData([...tpps]);
      dispatch(set_addRowCountTpp(1));
    }
  }, [dataSource, tpps]);

  useEffect(() => {
    if (query.entity) {
      setDataSource([]);
      fetchTPPbyEntity();
      fetchNonSubstReasons();
      fetchSubstitutability();
      fetchPersonTypes();
      fetchEntityNames();
      fetchFunctions();
      fetchReintegrationPossibilities();
      fetchICTServices();
      fetchAlternativesExit();
      fetchDoraRegulatorCriticality();
      fetchLocalRegCriticality();
      fetchNatures();
      fetchProviderCodeTypes();
    } else {
      dispatch(set_tpps([]));
      dispatch(set_addRowCountTpp(0));
    }
  }, [query.entity]);

  useEffect(() => {
    getCertificates();
  }, [selectedTPP]);

  useEffect(() => {
    if (tpps.length > 0) {
      setCombinedData([...filteredDataSource, ...tpps]);
    }
  }, [tpps]);

  useEffect(() => {
    if (dataSource.length === 0 && !query) {
      setFilteredDataSource([]);
      return;
    }

    if (selectedTPP === 'All') {
      const filteredDataSourceWithoutDuplicates = dataSource.filter(
        (item: ITPP) => !tpps.some((tpp: ITPP) => tpp.key === item.key),
      );
      setFilteredDataSource(filteredDataSourceWithoutDuplicates);
      setCombinedData([...filteredDataSourceWithoutDuplicates, ...tpps]);
    } else {
      const filteredData = dataSource.filter(
        (item: ITPP) => item.id.toString() === selectedTPP.toString(),
      );

      const filteredDataSourceWithoutDuplicates = filteredData.filter(
        (item: ITPP) => !tpps.some((tpp: ITPP) => tpp.key === item.key),
      );

      setFilteredDataSource(filteredDataSourceWithoutDuplicates);
      setCombinedData([...filteredDataSourceWithoutDuplicates, ...tpps]);
    }
  }, [dataSource, selectedTPP, tpps]);

  const handleAddRow = (record?: any, index?: number) => {
    const newlyAddedTpps = tpps.filter((tpp) => tpp.isNew);
    if (!canAddRow) {
      notification.info({
        message: 'Row already added',
        description: 'Please submit to add a new tpp',
        duration: 20,
      });
      return;
    }
    dispatch(set_isAddRowTpp(true));

    const newData: ITPP = {
      key: generateUniqueId(),
      // id: selectedTPP !== 'All' ? selectedTPP : generateUniqueId(),
      id: generateUniqueId(),
      ict_service_provider_identification_code: '',
      notice_period: null,
      identification_code_type: '',
      tpp_code_type_id: '',
      substitutability: null,
      non_subst_reason_id: '',
      third_party_service_provider_name: '',
      person_type_id: '',
      headquarters_country: '',
      iso_code: '',
      entity_nature: '',
      ultimate_parent_identification_code: '',
      ultimate_parent_identification_code_type: '',
      add_ict_service_provider_identification_code: '',
      add_identification_code_type: '',
      third_party_service_provider_legal_name: '',
      last_audit_date: '',
      description: '',
      entity_id: Number(query.entityId),
      // dora_regulator_criticality: '',
      dora_regulator_criticality_id: '',
      dora_regulator_criticality_name: '',
      local_regulator_criticality_id: '',
      local_regulator_criticality_name: '',
      alternative_providers_identified_id: '',
      alternative_providers_identified_name: '',
      // alternatives_exit_id: '',
      // alternatives_exit_name: '',
      exit_plan_exists_id: '',
      exit_plan_exists_name: '',
      possibility_of_reintegration_id: '',
      possibility_of_reintegration_name: '',
      end_date: '9999-01-01',
      rank: '',
      recipient_identification_code: '',
      recipient_identification_type: '',
      substitutability_of_ict_provider_name: '',
      substitutability_of_ict_provider_id: '',
      start_date: '',
      isNew: true,
      certificates: [],
    };

    dispatch(set_tppId(newData.id));
    const updatedDataSource = [...dataSource, newData];
    setDataSource(updatedDataSource);
    setCombinedData([...updatedDataSource, ...tpps]);

    dispatch(set_tpps([newData]));
    if (selectedTPP === 'All' || selectedTPP === newData.id.toString()) {
      setFilteredDataSource((prevFiltered) => [...prevFiltered, newData]);
      setCombinedData(filteredDataSource);
    }
    setCanAddRow(false);
  };
  // const fetchAlternativesExit = async () => {
  //   try {
  //     const response = await ict_serviceOp.fetchAlternativesExit();
  //     if (response?.data.alternatives_exit) {
  //       const formattedOptions = response.data.alternatives_exit.map(
  //         (alternatives: { alternative_ict_tpp_identification: any; id: any, }) => ({
  //           label: alternatives.alternative_ict_tpp_identification,
  //           value: alternatives.id,
  //         }),
  //       );
  //       setAlternatives(formattedOptions);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const fetchAlternativesExit = async () => {
    try {
      const response = await ict_serviceOp.fetchYesNo();
      if (response?.data.yes_no_statuses) {
        const formattedOptions = response.data.yes_no_statuses.map(
          (reasons: { description: any; id: any }) => ({
            label: reasons.description,
            value: reasons.id,
          }),
        );
        setAlternatives(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDoraRegulatorCriticality = async () => {
    try {
      const response = await tppOp.fetchDoraRegulatorCriticality();
      if (response?.data.dora_regulator_criticality) {
        const formattedOptions = response.data.dora_regulator_criticality.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setDoraRegCriticality(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLocalRegCriticality = async () => {
    try {
      const response = await ict_serviceOp.fetchLocalRegulatorCriticality();
      if (response?.data.local_regulator_criticality) {
        const formattedOptions = response.data.local_regulator_criticality.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setLocalRegCriticality(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = (row: ITPP) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (index > -1) {
      newData.splice(index, 1, {
        ...newData[index],
        ...row,
        isEdited: !newData[index]?.isNew || false,
      });
      setDataSource(newData);
    } else {
      newData.push({
        ...row,
        isEdited: false,
      });
    }
    if (row.isNew) {
      dispatch(set_nameOfTpp(row.third_party_service_provider_name));
    }
    setDataSource(newData);
    setCombinedData([...newData, ...tpps]);
    tpps.length < 1 ? setCombinedData(newData) : setCombinedData([...tpps]);
    const updatedTpps = [...tpps];
    const tppIndex = updatedTpps.findIndex((item) => item.key === row.key);

    if (tppIndex > -1) {
      updatedTpps[tppIndex] = { ...updatedTpps[tppIndex], ...row };
    } else {
      updatedTpps.push(row);
    }
    dispatch(set_tpps(updatedTpps));
  };

  const areAllFieldsFilled = (tpps: ITPP[]) => {
    const newlyAddedTpps = tpps.filter((tpp) => tpp.isNew);
    return newlyAddedTpps.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  const handleOpenTooltip = (certificateId: string) => {
    setTooltipVisibility((prev) => ({ ...prev, [certificateId]: true }));
  };

  const handleCloseTooltip = (certificateId: string) => {
    setTooltipVisibility((prev) => ({ ...prev, [certificateId]: false }));
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = tpps.filter((item) => item.key !== key);
        dispatch(set_tpps(updatedDataSource));
        dispatch(set_addRowCountTpp(0));
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: ITPP) => {
    if (record.id === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The tpp ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the tpp with ID ${record.id}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response = await tppOp.inactivateTPP([Number(record.id)]);
          fetchTPPbyEntity();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };

  const handleDeleteCertificate = (certificateId: number) => {
    confirm({
      title: 'Are you sure you want to delete this certificate?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        const certificateToDelete = tooltipsForData.find(
          (certificate) => certificate.id === certificateId,
        );

        if (certificateToDelete?.isNew) {
          setTooltipsForData((prevData) =>
            prevData.filter((certificate) => certificate.id !== certificateId),
          );
        } else {
          try {
            await tppOp.inactivateCertificates([certificateId]);
            getCertificates();
            // setTooltipsForData((prevData) =>
            //   prevData.filter((activity) => activity.id !== activityId)
            // );
            // dispatch(set_activitiesByTypeEntity(tooltipsForData));
          } catch (error) {
            console.error('Failed to inactivate activity:', error);
          }
        }
      },
      onCancel: () => {
        console.log('Delete action cancelled');
      },
    });
  };
  useEffect(() => {
    if (addRowCountTpp === 1) {
      const allFieldsFilled = areAllFieldsFilled(tpps);
      if (allFieldsFilled) {
        dispatch(set_isTppAdded(true));
      } else {
        dispatch(set_isTppAdded(false));
      }
    }
  }, [tpps, addRowCountTpp]);

  const handleSubmit = async () => {
    const changedData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );
    changedData.forEach((item) => {
      if (item.notice_period) {
        item.notice_period = Number(item.notice_period);
      }
    });

    const changedCertificates = certificates.filter(
      (item) => item.isNew || item.isEdited,
    );
    if (changedData.length === 0 && changedCertificates.length === 0) {
      notification.warning({
        message: 'No Changes',
        description:
          'There are no new or edited ICT third-party service providers or Certificates to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    const newlyAddedTpps = tpps.filter((tpp) => tpp.isNew);
    if (newlyAddedTpps.length !== 0) {
      const allFieldsFilled = areAllFieldsFilled(newlyAddedTpps);
      if (!allFieldsFilled) {
      } else {
        dispatch(set_isTppAdded(true));
      }
    }
    try {
      const response = await tppOp.fetchTPP({
        entity_name: query.entity,
        ThirdPartyServiceProvider: changedData,
        token: isLoggedIn,
      });
      dispatch(set_isTppUpdated(true));
      // dispatch(set_tpps([]));
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const responseCertificates = await tppOp.insertCertificates({
        tpp_name: changedCertificates[0]?.tpp_name || '',
        entity_name: query.entity,
        data_list: changedCertificates,
        token: isLoggedIn,
      });
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>ICT third-party service provider name in Latin Alphabet</p>
            <p>B_05.01.0060</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Name of the ICT third-party service provider in Latin alphabet. Where the name of the ICT third-party service provider reported in B_05.01.0050 is in Latin alphabet, it shall be repeated also in this data field."
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Name des IK
            T-Drittdienstleisters in lateinischer Schrift.
            Ist der Name des IK
            T-Drittanbieters gemäß B_05.01.0050 in
            lateinischer Schrift angegeben, so ist er in diesem Datenfeld zu
            wiederholen."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'third_party_service_provider_name',
      fixed: 'left',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Legal name of the ICT third- party service provider</p>
            <p>B_05.01.0050</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Legal name of the ICT third-party service provider as registered in business register in Latin, Cyrillic or Greek alphabets.
            (alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Offizielle Bezeichnung des IK
            T-Drittdienstleisters, wie im
            Unternehmensregister in lateinischer, kyrillischer oder
            griechischer Schrift angegeben."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'third_party_service_provider_legal_name',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> Identification code of the ICT third-party service provider</p>
            <p>B_05.02.0030</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Code to identify the ICT third-party service provider.
            Where LEI is used, it shall be provided as a 20-character, alpha- numeric code based on the ISO 17442 standard.
            Where EUID is used, it shall be provided as specified in Article 9 of the Commission Implementing Regulation (EU) 2021/1042.
            (alphanumerical)
            "
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Wie in B_05.01.0010 für diesen IK
            T-Drittdienstleister
            angegeben.
            Beispiele:
            — Identifikationscode des direkten
            IK
            T-Drittdienstleisters, der für das
            Finanzunternehmen IK
            T-Dienstleistungen
            erbringt;
            — Identifikationscode des
            Unterauftragnehmers mit
            Rangnummer 2, der für den direkten
            IK
            T-Drittanbieter Dienstleistungen erbringt."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ict_service_provider_identification_code',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> Notice period for the ICT third-party service provider</p>
            <p>B_02.02.0110</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the notice period for terminating the contractual arrangement by the direct ICT third-party service provider in a business-as-usual case. The notice period shall be expressed as number of calendar days from the counterparty’s receipt of the request to terminate the ICT service.
(natural number)
(mandatory if the ICT service is supporting a critical or important function)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie die Kündigungsfrist für die Kündigung der vertraglichen Vereinbarung durch
            den IKT-Drittdienstleister in einem Szenario mit unveränderten Rahmenbedingungen
            an. Die Kündigungsfrist wird als Anzahl von Kalendertagen ab dem Eingang des
            Antrags auf Kündigung der IKT-Dienstleistung bei der Gegenpartei ausgedrückt."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'notice_period',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Type of code to identify the ICT third-party service provider</p>
            <p>B_03.02.0030</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Type of code to identify the ICT third-party service provider reported in B_05.01.0010
            1.	‘LEI’ for LEI
            2.	‘EUID’ for EUID
            3.	‘Country Code’+Underscore+’Type of Code’ for non LEI and non EUID code
            Country Code: Identify the ISO 3166–1 alpha–2 code of the country of issuance of the other code to identify the ICT third- party service provider
            Type of Code:
            1.	CRN for Corporate registration number
            2.	VAT for VAT number
            3.	PNR for Passport Number
            4.	NIN for National Identity Number
            Only LEI or EUID shall be used for legal persons, as identified in B_05.01.0070, whereas alternative code may be used only for an individual acting in a business capacity.
            Only LEI shall be used for legal persons that are not established in the Union.
            (pattern)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Art des Codes zur Identifizierung des IK
            T-Drittdienstleisters
            gemäß B_05.01.0010.
            1. „LEI“ für LEI,
            2. „EUID“ für EUID,
            3. „Ländercode“+Unterstrich+„Art des Codes“ bei Nicht-LEIund Nicht-EUID-Codes.
            Ländercode: Geben Sie den ISO 3166-1-Alpha-2-Code des
            Landes an, in dem der andere Code zur Identifizierung des
            IK
            T-Drittdienstleisters ausgestellt wurde.
            Art des Codes:
            1. CRN für die Handelsregisternummer,
            2.
            VAT für die
            Umsatzsteuer-Identifikationsnummer,
            3. PNR für die Reisepassnummer,
            4. NIN für die Nummer des Personalausweises.
            Für juristische Personen gemäß den Angaben in B_05.01.0070
            sind nur die LEI oder die EUID zu verwenden; ein alternativer
            Code darf nur für eine natürliche Person verwendet werden, die
            als
            Unternehmer handelt.
            Für nicht in der
            Union niedergelassene juristische Personen ist
            nur die LEI zu verwenden."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'identification_code_type',
      editable: false,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        return (
          <CustomSelect
            value={
              providerCodeTypes.find(
                (option) =>
                  String(option.value) ===
                  String(record.identification_code_type),
              )?.label || record.identification_code_type
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) =>
              handleSave({
                ...record,
                identification_code_type: option.label,
                tpp_code_type_id: value,
              } as ITPP)
            }
            options={providerCodeTypes}
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> Substitutability of the ICT third-party service provider </p>
            <p>B_07.01.0050</p>
          </div>
          <AdditionalInformation
            color="red"
            description="Use this column to provide the results of the financial entity’s assessment in relation to the degree of substitutability of the ICT third- party service provider to perform the specific ICT services supporting a critical or important function.
            One of the options in the following closed list shall be used:
            1.	Not substitutable;
            2.	Highly complex substitutability;
            3.	Medium	complexity	in	terms	of substitutability;
            4.	Easily substitutable.
            (closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie in dieser Spalte die Ergebnisse der
            Bewertung des Finanzunternehmens in
            Bezug auf den Grad der Substituierbarkeit des
            IK
            T-Drittdienstleisters für die Erbringung der
            spezifischen IK
            T-Dienstleistungen zur
            Unterstützung einer kritischen oder
            wichtigen Funktion an.
            Aus der folgenden erschöpfenden Liste ist
            eine Option auszuwählen:
            1. Nicht substituierbar
            2. Äußerst komplizierte Substituierbarkeit
            3. Mittelschwere Substituierbarkeit
            4. Problemlos substituierbar"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'substitutability_of_ict_provider_name',
      editable: false,
      className: 'highlighted-column',
      render: (_: any, record: any) => (
        <CustomSelect
          value={record.substitutability_of_ict_provider_name}
          onChange={(value: string, option: { label: string; value: number }) =>
            handleSave({
              ...record,
              substitutability_of_ict_provider_name: option.label,
              substitutability_of_ict_provider_id: option.value,
            } as ITPP)
          }
          options={substitutability}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> Rank</p>
            <p>B_05.02.0050</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Where the ICT third-party service provider is signing the contractual arrangement with the financial entity, it is considered as a direct ICT third-party service provider and the ‘rank’ to be reported shall be 1;
Where the ICT third-party service provider is signing the contract with the direct ICT third-party service provider, it is considered as a subcontractor and the ‘rank’ to be reported shall be 2;
The same logic apply to all the following subcontractors by incrementing the ‘rank’.
Where multiple ICT third-party service providers have the same ‘rank’ in the ICT service supply chain, financial entities shall report the same ‘rank’ for all those ICT third-party service providers.
(natural number)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Wenn der IK
            T-Drittdienstleister die vertragliche
            Vereinbarung mit dem Finanzunternehmen
            unterzeichnet, gilt er als direkter IK
            T-Drittdienstleister
            und ist mit der „Rangnummer“ 1 zu kennzeichnen.
            Wenn der IK
            T-Drittdienstleister den
            Vertrag mit dem
            direkten IK
            T-Drittdienstleister unterzeichnet, gilt er als
            Unterauftragnehmer und ist mit der „Rangnummer“ 2
            zu kennzeichnen. Diese Logik gilt auch für alle folgenden
            Unterauftragnehmer, deren „Rangnummer“ entsprechend
            jeweils um eine Zahl erhöht werden muss.
            Haben mehrere IKT-Drittdienstleister denselben „Rang“ in
            der IKT-Dienstleistungskette, so ist von den
            Finanzunternehmen für alle diese IKT-Drittdienstleister
            derselbe „Rang“ einzutragen."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'rank',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        return (
          <Input
            value={record.rank}
            onChange={(e) =>
              handleSave({
                ...record,
                rank: e.target.value,
              } as ITPP)
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Identification code of the recipient of sub-contracted ICT
              services
            </p>
            <p>B_05.02.0060</p>
          </div>
          <AdditionalInformation
            color="red"
            description="To be left blank if the ICT third-party service provider (template B_05.02.0030) is a direct ICT third-party service provider i.e. at ‘rank’ r = 1 (template B_05.02.0050);
                  Where the ICT third-party service provider is at ‘rank’ r = n where n>1, indicate the ‘Identification code of the recipient of the sub-contracted services’ at ‘rank’ r=n-1 that subcontracted the ICT service (even partially) to the ICT third-party service provider at ‘rank’ r=n.
                  Examples:
                  —	The identification code of the direct ICT third- party service provider receiving the service from the subcontractor at rank 2;
                  —	The identification code of the subcontractor at rank 2 receiving the service from the subcontractor at rank 3.
                  The code used to identify the recipient of sub- contracted ICT services shall match the identification code provided in B_05.01.0010 for that provider.
                  (alphanumerical)
                  (mandatory Not applicable for rank 1)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Nicht auszufüllen, wenn der IKT-Drittdienstleister (Vorlage
              B_05.02.0030) ein direkter IKT-Drittdienstleister ist, d. h.
              den „Rang“ r = 1 hat (Vorlage B_05.02.0050).
              Wenn der IKT-Drittdienstleister den „Rang“ r = n hat (wobei
              n eine Zahl größer als 1 ist), ist der „Identifikationscode des
              Empfängers von im Unterauftrag vergebenen
              Dienstleistungen“ mit dem „Rang“ r=n-1 anzugeben, wenn
              eine (selbst nur teilweise) Untervergabe der
              IKT-Dienstleistung im Zusammenhang mit dem
              IKT-Drittdienstleister mit dem „Rang“ r=n erfolgt ist.
              Beispiele:
              — Identifikationscode des direkten
              IKT-Drittdienstleisters, der die Dienstleistung vom
              Unterauftragnehmer mit der Rangnummer 2 erhält.
              — Identifikationscode des Unterauftragnehmers mit der
              Rangnummer 2, der die Dienstleistung vom
              Unterauftragnehmer mit der Rangnummer 3 erhält.
              Der zur Identifizierung des Empfängers von im
              Unterauftrag vergebenen IKT-Dienstleistungen verwendete
              Code muss mit dem in B_05.01.0010 für diesen
              Dienstleister angegebenen Identifikationscode
              übereinstimmen."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'recipient_identification_code',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        return (
          <Input
            value={record.recipient_identification_code}
            onChange={(e) =>
              handleSave({
                ...record,
                recipient_identification_code: e.target.value,
              } as ITPP)
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Type of code to identify the recipient of sub-contracted ICT
              services (if the rank of the subcontracted ICT service is bigger
              than 1)
            </p>
            <p>B_05.02.0070</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="To be left blank where the ICT third-party service provider template B_05.02.0030) is at rank r = 1 (template B_05.02.0050);
              Where the ICT third-party service provider is at ‘rank’ r = n where n>1, indicate the ‘Type of code to identify the recipient of the sub-contracted service’ at ‘rank’ r=n-1 that subcontracted the ICT service (even partially) to the ICT third-party service provider at ‘rank’ r=n.
              1.	‘LEI’ for LEI
              2.	‘EUID’ for EUID
              3.	CRN for Corporate registration number
              4.	VAT for VAT number
              5.	PNR for Passport Number
              6.	NIN for National Identity Number
              The type of code used to identify the recipient of sub- contracted ICT services shall match the identification code provided in B_05.01.0020 for that provider.
              (pattern)
              (mandatory Not applicable for rank 1)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Nicht auszufüllen, wenn der IKT-Drittdienstleister (Vorlage
              B_05.02.0030) den Rang r = 1 hat (Vorlage B_05.02.0050).
              Wenn der IKT-Drittdienstleister den „Rang“ r = n hat (wobei
              n eine Zahl größer als 1 ist), ist die „Art des Codes zur
              Identifizierung des Empfängers von im Unterauftrag
              vergebenen Dienstleistungen“ mit dem „Rang“ r=n-1
              anzugeben, wenn eine (selbst nur teilweise) Untervergabe
              der IKT-Dienstleistung im Zusammenhang mit dem
              IKT-Drittdienstleister mit dem „Rang“ r=n erfolgt ist. 1. „LEI“ für LEI,
              2. „EUID“ für EUID,
              3. CRN für die Handelsregisternummer,
              4. VAT für die Umsatzsteuer-Identifikationsnummer,
              5. PNR für die Reisepassnummer,
              6. NIN für die Nummer des Personalausweises.
              Die Art des Codes zur Identifizierung des Empfängers von
              im Unterauftrag vergebenen IKT-Dienstleistungen muss mit
              dem in B_05.01.0020 für diesen Dienstleister angegebenen
              Identifikationscode übereinstimmen."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'recipient_identification_type',
      editable: false,
      width: 400,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        return (
          <CustomSelect
            options={providerCodeTypes}
            value={record.recipient_identification_type}
            onChange={(value: any) => {
              handleSave({
                ...record,
                recipient_identification_type: value,
              } as ITPP);
            }}
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Country of the ICT third - party service provider’s headquarters
            </p>
            <p>B_05.01.0080</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the ISO 3166–1 alpha–2 code of the country in which the global operating headquarters of ICT third-party service provider are located (usually, this country is the country of tax residence).
(country)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie den ISO 3166-1-Alpha-2-Code des Landes an, in dem
            sich die weltweite Hauptverwaltung des IK
            T-Drittdienstleisters
            befindet (in der Regel handelt es sich bei dem Land um das Land
            der steuerlichen Ansässigkeit)."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'headquarters_country',
      editable: false,

      render: (_, record) => (
        <CustomSelect
          options={countries}
          value={record.headquarters_country}
          onChange={(value: any) => {
            const selectedCountry = countries.find(
              (country) => country.value === value,
            );

            if (selectedCountry) {
              const countryName = selectedCountry.label;

              const isoCode = i18nIsoCountries.getAlpha2Code(countryName, 'en');

              handleSave({
                ...record,
                headquarters_country: selectedCountry?.label || '',
                iso_code: isoCode || '',
              } as ITPP);
            }
          }}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: <>Iso code</>,
      dataIndex: 'iso_code',
      editable: false,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Nature of the financial entity making use of the ICT service(s)
            </p>
            <p>B_04.01.0030</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the options in the following closed list shall be used:
              1.	The financial entity making use of the ICT service(s) is a branch of a financial entity
              2.	The financial entity making use of the ICT service(s) is not a branch
              (closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Aus der folgenden erschöpfenden Liste ist
            eine Option auszuwählen:
            1. Das Finanzunternehmen, das die
            IKT-Dienstleistung(en) in Anspruch
            nimmt, ist eine Zweigniederlassung
            eines Finanzunternehmens.
            2. Das Finanzunternehmen, das die
            IKT-Dienstleistung(en) in Anspruch
            nimmt, ist keine Zweigniederlassung."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'entity_nature',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => (
        <CustomSelect
          options={natures}
          value={record.entity_nature}
          onChange={(value: any, option: { label: string; value: string }) =>
            handleSave({
              ...record,
              entity_nature: option.label,
            } as ITPP)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Additional identification code of ICT third-party service provider
            </p>
            <p>B_05.01.0030</p>
          </div>
          <AdditionalInformation
            color="red"
            description="Additional code to identify the ICT third-party service provider, where available.
            (alphanumerical)
            (optional)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Zusätzlicher Code zur Identifizierung des
            IK
            T-Drittdienstleisters, wenn verfügbar."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'add_ict_service_provider_identification_code',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Type of additional identification code to identify the ICT
              third-party service provider
            </p>
            <p>B_05.01.0040</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="The type of additional code to identify the ICT third-party service provider reported in B_05.01.0030:
            1.	‘LEI’ for LEI
            2.	‘EUID’ for EUID
            3.	CRN for Corporate registration number
            4.	VAT for VAT number
            5.	PNR for Passport Number
            6.	NIN for National Identity Number
            LEI or EUID shall be used for legal persons, as identified in B_05.01.0070, whereas alternative code may be used only for an individual acting in a business capacity.
            Only LEI shall be used for legal persons that are not established in the Union.
            (pattern)
            (mandatory, if B_05.01.0030 is reported)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Art des zusätzlichen Codes zur Identifizierung des
            IK
            T-Drittdienstleisters gemäß den Angaben in B_05.01.0030:
            1. „LEI“ für LEI,
            2. „EUID“ für EUID,
            3. CRN für die Handelsregisternummer,
            4.
            VAT für die
            Umsatzsteuer-Identifikationsnummer,
            5. PNR für die Reisepassnummer,
            6. NIN für die Nummer des Personalausweises.
            Für juristische Personen gemäß den Angaben in B_05.01.0070
            sind die LEI oder die EUID zu verwenden; ein alternativer Code
            darf nur für eine natürliche Person verwendet werden, die in als
            Unternehmer handelt.
            Für nicht in der
            Union niedergelassene juristische Personen ist
            nur die LEI zu verwenden."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'add_identification_code_type',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Identification code of the ICT third-party service provider’s
              ultimate parent undertaking
            </p>
            <p>B_05.01.0110</p>
          </div>
          <AdditionalInformation
            color="red"
            description="Code to identify the ICT third-party service provider’s ultimate parent undertaking.
The code used to identify ultimate parent undertaking in this field shall match the identification code provided in B_05.01.0010 for that ultimate parent undertaking.
Where the ICT third-party service provider is not part of a group, the identification code used to identify that ICT third- party service provider in B_05.01.0010 shall be repeated also in this data field.
(alphanumerical)
(mandatory if the ICT third- party service provider is not the ultimate parent undertaking)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Code zur Identifizierung des obersten Mutterunternehmens des
            IK
            T-Drittdienstleisters.
            Der zur Identifizierung des obersten Mutterunternehmens in
            diesem Feld verwendete Code muss mit dem in B_05.01.0010
            für dieses oberste Mutterunternehmen angegebenen
            Identifikationscode übereinstimmen. Ist der IKT-Drittdienstleister nicht Teil einer Gruppe, so ist der
            zur Identifizierung dieses IKT-Drittdienstleisters in
            B_05.01.0010 verwendete Identifikationscode in diesem
            Datenfeld zu wiederholen."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ultimate_parent_identification_code',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Type of code to identify the ICT third-party service provider’s
              ultimate parent undertaking
            </p>
            <p>B_05.01.0120</p>
          </div>
          <AdditionalInformation
            color="red"
            description="Type of code to identify the ICT third-party service provider’s ultimate parent undertaking in B_05.01.0110.
            The type of the code used to identify ultimate parent undertaking in this field shall match the identification code provided in B_05.01.0020 for that ultimate parent undertaking. Where the ICT third-party service provider is not part of a group, the type of the identification code used to identify that ICT third-party service provider in B_05.01.0020 shall be repeated also in this data field.
            (pattern)
            (mandatory if the ICT third- party service provider is not the ultimate parent undertaking)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Art des Codes zur Identifizierung obersten
            Mutterunternehmens des IKT-Drittdienstleisters gemäß
            B_05.01.0110.
            Die Art des Codes zur Identifizierung des obersten
            Mutterunternehmens in diesem Feld muss mit dem in
            B_05.01.0020 für dieses oberste Mutterunternehmen
            angegebenen Identifikationscode übereinstimmen.
            Ist der IKT-Drittdienstleister nicht Teil einer Gruppe, so ist die
            Art des Identifikationscodes, der in B_05.01.0020 zur
            Identifizierung dieses IKT-Drittdienstleisters verwendet wurde,
            in diesem Datenfeld zu wiederholen."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ultimate_parent_identification_code_type',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Date of the last audit on the ICT third-party service provider
            </p>
            <p>B_07.01.0070</p>
          </div>

          <AdditionalInformation
            color="red"
            description="Use this column to provide the date of the last audit on the specific ICT services provided by the ICT third-party service provider.
            This column relates to audits conducted by any of the following:
            (a)	the internal audit department or any other additional qualified personnel of the financial entity;
            (b)	a joint team together with other clients of the same ICT third-party service provider (‘pooled audit’);
            (c)	a third party appointed by the supervised entity to audit the service provider.
            
            This column does not relate to the reception or reference date of third-party certifications or internal audit reports of the ICT third- party service provider, the annual monitoring date of the arrangement by the financial entity or the date of review of the risk assessment performed by the financial entity.
            This column shall be used to report all types of audits performed by any of the subjects referred to in points (a), (b) and (c) concerning fully or partially the ICT services provided by the ICT third-party service provider.
            To report the date, the ISO 8601 (yyyy– mm–dd) code shall be used.
            Where no audit has been performed, it shall be filled in with ‘9999-12-31’.
            (date)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie in dieser Spalte das Datum des
            letzten Audits über die spezifischen
            IK
            T-Dienstleistungen an, die vom
            IK
            T-Drittdienstleister erbracht werden.
            Diese Spalte bezieht sich auf Audits, die von
            einer der folgenden Stellen durchgeführt
            werden:
            a) der Innenrevision oder sonstigem
            zusätzlichen qualifizierten Personal des
            Finanzunternehmens,
            b) einem gemeinsamen
            Team mit anderen
            Kunden desselben
            IK
            T-Drittdienstleisters („Sammelaudit“),
            c) einem Dritten, der vom beaufsichtigten
            Unternehmen mit dem Audit über den
            Dienstleister beauftragt wird.
            Diese Spalte bezieht sich nicht auf den
            Eingang oder den Stichtag von
            Zertifizierungen durch Dritte oder von
            Innenrevisionsberichten des
            IK
            T-Drittdienstleisters, den Zeitpunkt der
            jährlichen
            Überwachung der
            Vereinbarung
            durch das Finanzunternehmen oder den
            Zeitpunkt der
            Überprüfung der
            Risikobewertung durch das
            Finanzunternehmen. In dieser Spalte sind alle Arten von Audits
            anzugeben, die von einer der unter den
            Buchstaben a, b und c genannten Stellen
            durchgeführt wurden und sich ganz oder
            teilweise auf die vom IK
            T-Drittdienstleister
            erbrachten IK
            T-Dienstleistungen beziehen.
            Für die Angabe des Datums ist der
            ISO 8601-Code (JJJJ-MM-TT) zu verwenden.
            Wurde kein Audit durchgeführt, ist an dieser
            Stelle „9999-12-31“ anzugeben."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'last_audit_date',
      editable: false,
      className: 'highlighted-column',
      render: (text: string, record: DataType) => {
        const entityRecord = record as ITPP;
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = date
                  ? dayjs(date).format('YYYY-MM-DD')
                  : '';
                const updatedRow: ITPP = {
                  ...entityRecord,
                  last_audit_date: formattedDateString,
                };
                handleSave(updatedRow);
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Dora Regulator Criticality</p>
          </div>

          <AdditionalInformation
            color="red"
            description="content will be added"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Inhalt wird hinzugefügt"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'dora_regulator_criticality_name',
      editable: false,
      render: (_: any, record: any) => {
        const selectedOption = doraRegCriticality.find(
          (option) => option.value === record.dora_regulator_criticality_id,
        );

        return (
          <CustomSelect
            value={
              selectedOption
                ? selectedOption.label
                : record.dora_regulator_criticality_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) =>
              handleSave({
                ...record,
                dora_regulator_criticality_name: option.label,
                dora_regulator_criticality_id: option.value,
              } as ITPP)
            }
            options={doraRegCriticality}
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Local Regulator Criticality</p>
          </div>

          <AdditionalInformation
            color="red"
            description="content will be added"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Inhalt wird hinzugefügt"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'local_regulator_criticality_name',
      editable: false,
      render: (_: any, record: any) => {
        const selectedOption = doraRegCriticality.find(
          (option) => option.value === record.local_regulator_criticality_id,
        );

        return (
          <CustomSelect
            value={
              selectedOption
                ? selectedOption.label
                : record.dora_regulator_criticality_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) =>
              handleSave({
                ...record,
                local_regulator_criticality_name: option.label,
                local_regulator_criticality_id: option.value,
              } as ITPP)
            }
            options={localRegCriticality}
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Are there alternative ICT third-party service providers
              identified?
            </p>
            <p>B_07.01.0110</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the options in the following closed list shall be used:
          1.	Yes;
          2.	No;
          7. Assessment not performed.
          For each ICT third-party service provider supporting a critical or important function, the assessment to identify an alternative service provider shall be performed.
          (closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Aus der folgenden erschöpfenden Liste ist
            eine Option auszuwählen:
            1. Ja
            2. Nein
            7. Keine Bewertung durchgeführt
            Für jeden IKT-Drittdienstleister, der eine
            kritische oder wichtige Funktion unterstützt,
            muss eine Bewertung zur Ermittlung eines
            alternativen Dienstleisters durchgeführt
            werden"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'alternative_providers_identified_name',
      editable: false,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const selectedOption = alternatives.find(
          (option) =>
            option.value === record.alternative_providers_identified_id,
        );

        return (
          <CustomSelect
            value={
              selectedOption
                ? selectedOption.label
                : record.alternative_providers_identified_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) =>
              handleSave({
                ...record,
                alternative_providers_identified_name: option.label,
                alternative_providers_identified_id: option.value,
              } as ITPP)
            }
            options={alternatives}
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    // {
    //   title: (
    //     <>
    //       Alternative Exit
    //     </>
    //   ),
    //   dataIndex: 'alternatives_exit_name',
    //   editable: false,
    //   render: (_: any, record: any) => {
    //     const selectedOption = alternatives.find(
    //       (option) => option.value === record.alternatives_exit_id
    //     );

    //     return (
    //       <CustomSelect
    //         value={selectedOption ? selectedOption.label : record.alternatives_exit_name}
    //         onChange={(value: string, option: { label: string; value: string }) =>
    //           handleSave({
    //             ...record,
    //             alternatives_exit_name: option.label,
    //             alternatives_exit_id: option.value,
    //           } as ITPP)
    //         }
    //         options={alternatives}
    //         filterOption={(input: string, option: { label: string }) =>
    //           option?.label?.toLowerCase().includes(input.toLowerCase())
    //         }
    //       />
    //     );
    //   }
    // },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Exit plan exists</p>
            <p>B_07.01.0080</p>
          </div>
          <AdditionalInformation
            color="red"
            description="Use this column to report the existence of an exit plan from the ICT third-party service provider in relation to the specific ICT service provided.
               One of the options in the following closed list shall be used:
               1.	Yes;
               2.	No.
              [Yes/No]"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie in dieser Spalte an, ob ein
            Ausstiegsplan für den IK
            T-Drittdienstleister
            in Bezug auf die spezielle erbrachte
            IK
            T-Dienstleistung vorliegt.
            Aus der folgenden erschöpfenden Liste ist
            eine Option auszuwählen:
            1. Ja
            2. Nein"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'exit_plan_exists_name',
      editable: false,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const selectedOption = alternatives.find(
          (option) => option.value === record.exit_plan_exists_id,
        );

        return (
          <CustomSelect
            value={
              selectedOption
                ? selectedOption.label
                : record.exit_plan_exists_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) =>
              handleSave({
                ...record,
                exit_plan_exists_name: option.label,
                exit_plan_exists_id: option.value,
              } as ITPP)
            }
            options={alternatives}
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> Possibility of reintegration</p>
            <p>B_07.01.0090</p>
          </div>
          <AdditionalInformation
            color="red"
            description="One of the options in the following closed list shall be used: 1. Easy; 2. Difficult; 3. Highly complex. Use this column where the ICT service is provided by an ICT third-party service provider that is not an ICT intra-group service provider"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Aus der folgenden erschöpfenden Liste ist
            eine Option auszuwählen:
            1. Problemlos
            2. Schwierig
            3. Äußerst kompliziert
            Verwenden Sie diese Spalte, wenn die
            IK
            T-Dienstleistung von einem
            IK
            T-Drittdienstleister erbracht wird, der kein
            gruppeninterner IK
            T-Dienstleister ist."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'possibility_of_reintegration_name',
      editable: false,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const selectedOption = possibility.find(
          (option) => option.value === record.possibility_of_reintegration_id,
        );

        return (
          <CustomSelect
            value={
              selectedOption
                ? selectedOption.label
                : record.possibility_of_reintegration_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) =>
              handleSave({
                ...record,
                possibility_of_reintegration_name: option.label,
                possibility_of_reintegration_id: option.value,
              } as ITPP)
            }
            options={possibility}
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <>
          Start Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'start_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as ITPP;
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = date
                  ? dayjs(date).format('YYYY-MM-DD')
                  : '';
                const updatedRow: ITPP = {
                  ...entityRecord,
                  start_date: formattedDateString,
                };
                handleSave(updatedRow);
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          End Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'end_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as ITPP;
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={
                text && text !== '9999-01-01'
                  ? dayjs(text)
                  : dayjs('9999-01-01')
              }
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const updatedRow: ITPP = {
                  ...entityRecord,
                  end_date: entityRecord.end_date
                    ? entityRecord.end_date
                    : '9999-01-01',
                };
                handleSave(updatedRow);
              }}
            />
          </div>
        );
      },
    },
    {
      title: <>Certificates</>,
      dataIndex: 'certificates',
      render: (_: any, record: any) => {
        const relatedCertificates = tooltipsForData.filter(
          (cert) => cert.third_party_service_provider_id === record.id,
        );
        const handleAddCertificate = (recordId: any) => {
          const newCertificate = {
            id: generateUniqueId(),
            description: '',
            validity: '',
            date_of_issuance: '',
            certificate_registry: '',
            date_of_certificate_register: '',
            third_party_service_provider_id: recordId,
            tpp_name: record.third_party_service_provider_name,
            start_date: '',
            end_date: '9999-01-01',
            isNew: true,
          };
          setTooltipsForData((prevData) => [...prevData, newCertificate]);
          dispatch(set_certificates(tooltipsForData));
        };

        const handleUpdateCertificate = (
          certificateId: string,
          field: string,
          value: any,
        ) => {
          setTooltipsForData((prevData) =>
            prevData.map((cert) =>
              cert.id === certificateId
                ? { ...cert, [field]: value, isEdited: true }
                : cert,
            ),
          );
          dispatch(set_certificates(tooltipsForData));
        };

        return (
          <div>
            {relatedCertificates.map((certificate) => (
              <Styled.TooltipContainer
                key={certificate.id}
                title={
                  <div>
                    <Styled.TooltipButtonContainer>
                      <Button
                        size="small"
                        type="text"
                        onClick={() => handleCloseTooltip(certificate.id)}
                      >
                        <CloseCircleOutlined />
                      </Button>
                      <Button
                        size="small"
                        type="text"
                        danger
                        onClick={() => handleDeleteCertificate(certificate.id)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Styled.TooltipButtonContainer>
                    <Styled.InputField>
                      <strong>Description</strong>
                      <Input
                        defaultValue={certificate.description || ''}
                        onChange={(e) =>
                          handleUpdateCertificate(
                            certificate.id,
                            'description',
                            e.target.value,
                          )
                        }
                      />
                    </Styled.InputField>
                    <Styled.InputField>
                      <strong>
                        Validity <Styled.Asterisx>*</Styled.Asterisx>{' '}
                      </strong>
                      <Input
                        defaultValue={certificate.validity || ''}
                        onChange={(e) =>
                          handleUpdateCertificate(
                            certificate.id,
                            'validity',
                            e.target.value,
                          )
                        }
                      />
                    </Styled.InputField>
                    <Styled.InputField>
                      <strong>Date of Issuance</strong>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={
                          certificate.date_of_issuance
                            ? dayjs(certificate.date_of_issuance, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date) => {
                          const formattedDate =
                            date?.format('YYYY-MM-DD') || '';
                          handleUpdateCertificate(
                            certificate.id,
                            'date_of_issuance',
                            formattedDate,
                          );
                        }}
                      />
                    </Styled.InputField>
                    <Styled.InputField>
                      <strong>
                        Certificate Registry
                        <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <Input
                        defaultValue={certificate.certificate_registry}
                        onChange={(e) =>
                          handleUpdateCertificate(
                            certificate.id,
                            'certificate_registry',
                            e.target.value,
                          )
                        }
                      />
                    </Styled.InputField>

                    <Styled.InputField>
                      <strong>
                        Date of Certificate Register
                        <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={
                          certificate.date_of_certificate_register
                            ? dayjs(
                                certificate.date_of_certificate_register,
                                'YYYY-MM-DD',
                              )
                            : null
                        }
                        onChange={(date) => {
                          const formattedDate =
                            date?.format('YYYY-MM-DD') || '';
                          handleUpdateCertificate(
                            certificate.id,
                            'date_of_certificate_register',
                            formattedDate,
                          );
                        }}
                      />
                    </Styled.InputField>
                    <Styled.InputField>
                      <strong>
                        Start Date <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={
                          certificate.start_date
                            ? dayjs(certificate.start_date, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date) => {
                          const formattedDate =
                            date?.format('YYYY-MM-DD') || '';
                          handleUpdateCertificate(
                            certificate.id,
                            'start_date',
                            formattedDate,
                          );
                        }}
                      />
                    </Styled.InputField>

                    <Styled.InputField>
                      <strong>
                        End Date <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={
                          certificate.end_date
                            ? dayjs(certificate.end_date, 'YYYY-MM-DD')
                            : dayjs('9999-01-01')
                        }
                        onChange={(date) => {
                          const formattedDate =
                            date?.format('YYYY-MM-DD') || dayjs('9999-01-01');
                          handleUpdateCertificate(
                            certificate.id,
                            'end_date',
                            formattedDate,
                          );
                        }}
                      />
                    </Styled.InputField>

                    <Styled.InputField>
                      <strong>ICT third-party service provider name</strong>
                      <Input
                        value={
                          record.third_party_service_provider_name || tppName
                        }
                        disabled
                      />
                    </Styled.InputField>
                  </div>
                }
                visible={tooltipVisibility[certificate.id] || false}
                trigger="click"
              >
                <Styled.OpenTooltipBtn
                  type="link"
                  onClick={() => handleOpenTooltip(certificate.id)}
                >
                  <Input
                    value={certificate.description || 'Add Certificate'}
                    readOnly
                    addonAfter={<PlusCircleOutlined />}
                  />
                </Styled.OpenTooltipBtn>
              </Styled.TooltipContainer>
            ))}

            <Button
              type="dashed"
              onClick={() => {
                handleAddCertificate(record.id);
              }}
              icon={<PlusOutlined />}
            ></Button>
            <Styled.GlobalStyle />
          </div>
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Reason where the ICT third- party service provider is considered
              not substitutable or difficult to be substitutable
            </p>
            <p>B_07.01.0060</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the options in the following closed list shall be used:
            1.	The lack of real alternatives, even partial, due to the limited number of ICT third- party service providers active on a specific market, or the market share of the relevant ICT third-party service provider, or the technical complexity or sophistication involved, including in relation to any proprietary technology, or the specific features of the ICT third- party service provider’s organisation or activity;
            2.	Difficulties in relation to a partial or full migration of the relevant data and workloads from the relevant ICT third- party service provider to another ICT third-party service provider or by reintegrating them in the financial entity’s operations, due either to significant financial costs, time or other resources that the migration process may entail, or to an increased ICT risk or other operational risks to which the financial entity might be exposed;
            3.	Both reasons referred to in points 1 and 2.
            (closed set of options)
            (mandatory in case “not substitutable” or “highly complex substitutability” is selected in B_07.01.0041)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Aus der folgenden erschöpfenden Liste ist
            eine Option auszuwählen:
            1. Mangel an echten, auch teilweisen
            Alternativen aufgrund der begrenzten
            Zahl von IK
            T-Drittdienstleistern, die auf
            einem bestimmten Markt tätig sind, oder
            des Marktanteils des betreffenden
            IK
            T-Drittdienstleisters oder der damit
            verbundenen technischen
            Komplexität
            oder Differenziertheit, auch in Bezug auf
            proprietäre
            Technologien, oder der
            besonderen Merkmale der Organisation
            oder Tätigkeit des
            IK
            T-Drittdienstleisters,2. Schwierigkeiten bei der teilweisen oder
            vollständigen Migration der
            einschlägigen Daten und Arbeitslasten
            vom jeweiligen IK
            T-Drittdienstleister zu
            einem anderen IK
            T-Drittdienstleister
            oder bei deren Wiedereingliederung in
            den Betrieb des Finanzunternehmens,
            die entweder auf erhebliche finanzielle
            Kosten, zeitliche oder sonstige
            Ressourcen, die der Migrationsprozess
            mit sich bringen kann, oder auf erhöhte
            IK
            T-Risiken oder sonstige operationelle
            Risiken zurückzuführen sind, denen das
            Finanzunternehmen ausgesetzt sein
            könnte,
            3. Beide unter Punkt 1 und 2 genannten
            Gründe."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'non_subst_reason_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => (
        <CustomSelect
          options={nonSubstReasons}
          value={record.non_subst_reason_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              non_subst_reason_id: value,
            } as ITPP)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Type of person of the ICT third-party service provider</p>
            <p>B_05.01.0070</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the options in the following closed list shall be used:
            1.	Legal person, excluding individuals acting in business capacity
            2.	Individual acting in a business capacity
            (closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Aus der folgenden erschöpfenden Liste ist eine Option
            auszuwählen:
            1. Juristische Person, ausgenommen natürliche Personen, die
            als
            Unternehmer handeln
            2. Natürliche Person, die als
            Unternehmer handelt"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'person_type_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => (
        <CustomSelect
          options={personTypes}
          value={record.person_type_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              person_type_id: value,
            } as ITPP)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record) => (
        <>
          {record.isNew ? (
            <Button danger onClick={() => handleRemoveRow(record.key)}>
              Remove
            </Button>
          ) : (
            <Button danger onClick={() => handleInactivate(record as ITPP)}>
              Soft delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    dataSource && (
      <div>
        {query.entity && (
          <Styled.AreaButton onClick={handleAddRow} type="primary">
            Add Row
          </Styled.AreaButton>
        )}
        <Styled.HighlightedTable>
          <CustomTable
            columns={defaultColumns}
            dataSource={combinedData}
            handleSave={handleSave}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </Styled.HighlightedTable>
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      </div>
    )
  );
};

export default ThirdPartyServiceProvider;
