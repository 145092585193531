import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { notification, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import useFilters from 'utils/hooks/useFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import {
  EditableColumnType,
  DataType,
} from 'components/CustomTable/CustomTable';
import { setSelectedTPP } from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import { ICurrencyAndCosts } from 'store/ducks/DataEntry/ThirdPartyProviders/types';
import * as Styled from '../../DataEntry.styled';

const CurrencyAndCosts: React.FC = () => {
  const dispatch = useDispatch();

  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<ICurrencyAndCosts[]>([]);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const { selectedTPP } = useSelector((state: RootState) => state.tpp);
  const [selectedTPPID, setSelectedTPPID] = useState<string>('');
  const [tppName, setTppName] = useState<string>('');

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const getCurrencyAndCosts = async () => {
    try {
      const response = await tppOp.setCurrencyAndCosts(tppName, selectedTPPID);
      const mappedData = [
        {
          key: 0,
          currency_id: response.costs.currency_id,
          currency_name: response.currency.name,
          currency_description: response.currency.description,
          costs_id: response.costs.id,
          currency_amount_reported: response.costs.currency_amount_reported,
          annual_expense: response.costs.annual_expense,
          total_annual_expense: response.costs.total_annual_expense,
          cost_description: response.costs.description,
          id: response.currency.id,
          contractual_arrangement_id: response.costs.contractual_arrangement_id,
          date_id: response.costs.date_id,
          third_party_service_provider_id:
            response.costs.third_party_service_provider_id,
          start_date: response.start_date,
          end_date: response.end_date,
          isNew: false,
          isEdited: false,
          tpp_name: response.tpp_name,
        },
      ];
      setDataSource(mappedData);
      setCount(mappedData.length);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchTPPID = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;

      const dataTPP = data.map((item: any, index: number) => ({
        id: item.id,
        third_party_service_provider_name:
          item.third_party_service_provider_name,
      }));
      const matchedTPP = dataTPP.find(
        (item: ICurrencyAndCosts) =>
          item.id.toString() === selectedTPP.toString(),
      );

      if (matchedTPP) {
        setSelectedTPPID(matchedTPP.id);
        setTppName(matchedTPP.third_party_service_provider_name);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      if (selectedTPP === '') {
        fetchTPPID();
      } else if (selectedTPP) {
        dispatch(setSelectedTPP(selectedTPP));
      } else if (!selectedTPP) {
        dispatch(setSelectedTPP(''));
        setDataSource([]);
      }
    }
  }, [query.entity]);

  useEffect(() => {
    if (selectedTPP === '' && dataSource.length > 0 && query.entity) {
      setDataSource([]);
      setSelectedTPPID('');
    }
  }, [selectedTPP, dataSource, query.entity]);

  useEffect(() => {
    if (selectedTPP && query.entity) {
      fetchTPPID();
    }
  }, [selectedTPP, query.entity]);

  useEffect(() => {
    if (selectedTPPID && query.entity) {
      getCurrencyAndCosts();
    }
  }, [selectedTPPID, query.entity]);

  const handleAddRow = (record?: any, index?: number) => {
    let uniqueIdCounter = 0;
    const generateUniqueId = (): number => {
      uniqueIdCounter += 1;
      const timestampPart = Date.now();
      const randomPart = Math.floor(Math.random() * 1000);

      const uniqueId = Number(
        `${timestampPart}${uniqueIdCounter}${randomPart}`,
      );
      return uniqueId;
    };

    const newData: ICurrencyAndCosts = {
      key: generateUniqueId(),
      id: generateUniqueId(),
      currency_id: generateUniqueId(),
      currency_name: '',
      currency_description: '',
      costs_id: generateUniqueId(),
      currency_amount_reported: null,
      annual_expense: null,
      total_annual_expense: null,
      cost_description: '',
      contractual_arrangement_id: generateUniqueId(),
      date_id: generateUniqueId(),
      third_party_service_provider_id: generateUniqueId(),
      start_date: '',
      end_date: '',
      tpp_name: tppName,
      isNew: true,
    };

    const insertIndex = dataSource.findIndex(
      (item) => item.key === record?.key,
    );

    const updatedDataSource = [
      ...dataSource.slice(0, insertIndex + 1),
      newData,
      ...dataSource.slice(insertIndex + 1),
    ];

    setDataSource(updatedDataSource);
    setCount(count + 1);
  };

  const handleDateChange = (key: number, field: string, dateString: string) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        [field]: dateString,
        isEdited: !item.isNew,
      });
      setDataSource(newData);
    }
  };

  const handleSave = (row: ICurrencyAndCosts) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const handleSubmit = async () => {
    const filteredData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );

    if (filteredData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited currency or costs to submit.',
        placement: 'topRight',
      });
      return;
    }

    const currencies_list = filteredData.map((item) => ({
      id: item.id,
      currency_name: item.currency_name,
      currency_description: item.currency_description,
    }));

    const costs_list = filteredData.map((item) => ({
      id: item.costs_id,
      currency_amount_reported: item.currency_amount_reported,
      annual_expense: item.annual_expense,
      total_annual_expense: item.total_annual_expense,
      cost_description: item.cost_description,
      currency_id: item.currency_id,
      contractual_arrangement_id: item.contractual_arrangement_id,
      date_id: item.date_id,
      third_party_service_provider_id: item.third_party_service_provider_id,
    }));

    const payload = {
      tpp_name: tppName,
      entity_name: query.entity,
      currencies_list,
      costs_list,
    };

    try {
      await tppOp.insertCurrencyAndCosts(payload);
      notification.success({
        message: 'Submission Success',
        description: 'Data successfully submitted.',
        placement: 'topRight',
      });

      await getCurrencyAndCosts();
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description:
          'There was an error submitting the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: (
        <>
          Currency Name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'currency_name',
      editable: true,
      type: 'currency',
    },
    {
      title: 'Currency description',
      dataIndex: 'currency_description',
      editable: true,
      type: 'currency',
    },
    {
      title: (
        <>
          Currency amount reported<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'currency_amount_reported',
      editable: true,
      type: 'cost',
    },
    {
      title: (
        <>
          Annual expense<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'annual_expense',
      editable: true,
      type: 'cost',
    },
    {
      title: (
        <>
          Total annual expense<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'total_annual_expense',
      editable: false,
      type: 'cost',
    },
    {
      title: (
        <>
          Start Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'start_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                handleDateChange(record.key, 'start_date', formattedDateString);
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          End Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'end_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                handleDateChange(record.key, 'end_date', formattedDateString);
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Cost description',
      dataIndex: 'cost_description',
      editable: true,
      type: 'cost',
    },
    {
      title: 'TPP name',
      dataIndex: 'tpp_name',
      editable: false,
      fixed: 'right',
    },
  ];

  return (
    dataSource && (
      <Styled.StyledWrapper hasEntity={!!query.entity}>
        <CustomTable
          columns={defaultColumns}
          dataSource={dataSource}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
         {query.entity && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
        )}
      </Styled.StyledWrapper>
    )
  );
};

export default CurrencyAndCosts;
