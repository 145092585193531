import AxiosInstance from './AxiosInterceptor';
import Helpers from './Helpers';
import { IChangePass, IResetPass } from 'store/ducks/globalTypes';

const ApiService = {
  
  login: (data: { username: string; password: string }) =>
    AxiosInstance({
      url: '/api/login',
      method: 'post',
      data,
    }),

  logout: () =>
    AxiosInstance({
      url: '/api/logout',
      method: 'post',
    }),

  forgotPassword: (email: string) =>
    AxiosInstance({
      url: '/api/forgot-password',
      method: 'post',
      data: { email },
    }),

    resetPassword: (
      formData: IResetPass,
      token: string | qs.ParsedQs | string[] | qs.ParsedQs[]
    ) =>
      AxiosInstance({
        url: '/api/reset-password',
        method: 'post',
        params: { token },
        data: formData
      }),

  signup: (data: any) =>
    AxiosInstance({
      url: '/api/signup',
      method: 'post',
      data,
    }),

  changePass: (
    formData: IChangePass,
    token: string | qs.ParsedQs | string[] | qs.ParsedQs[],
  ) =>
    AxiosInstance({
      url: '/api/change-password',
      method: 'post',
      params: { token },
      data: formData,
    }),

  getFilters: () =>
    AxiosInstance({
      url: '/api/entities',
      method: 'get',
    }),

  getEntityContractualArrangements: (data: any) => {
    return AxiosInstance({
      url: '/api/entity-contractual-arrangements',
      method: 'get',
      params: {
        entity_id: data.entity_name, 
      },
    });
  },

  postEntity: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-entity',
      method: 'post',
      data: entityData,
    }),

  getBranches: (data: any) => {
    return AxiosInstance({
      url: '/api/entity-branches',
      method: 'get',
      params: {
        entity_id: data.entity_name, 
      },
    });
  },

  getContractualArrangementsLei: (data: any) => {
    return AxiosInstance({
      url: '/api/contractual-arrangements-lei',
      method: 'get',
      params: {
        entity_id: data.entity_name, 
      },
    });
  },

  getContractualArrangementsICT: (data: any) => {
    return AxiosInstance({
      url: '/api/contractual-arrangements-ict',
      method: 'get',
      params: {
        entity_id: data.entity_name, 
      },
    });
  },

  getContractualArrangementsICTUse: (data: any) => {
    return AxiosInstance({
      url: '/api/contractual-arrangements-ict-use',
      method: 'get',
      params: {
        entity_id: data.entity_name, 
      },
    });
  },

  postEntityType: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-entity-type',
      method: 'post',
      data: entityData,
    }),

  postHierarchyGroup: (hierarchyGroupData: any) =>
    AxiosInstance({
      url: '/api/insert-hierarchy-group',
      method: 'post',
      data: hierarchyGroupData,
    }),

  getAllEntities: () => {
    return AxiosInstance({
      url: '/api/get-all-entities',
      method: 'get',
    });
  },

  postEntityTypeByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-type',
      method: 'post',
      data: entityData,
    }),

  postHierarchyGroupByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-hierarchy',
      method: 'post',
      data: entityData,
    }),

  postNatureOfEntityByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-nature',
      method: 'post',
      data: entityData,
    }),

  postCompetentAuthorityByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-competent-authority',
      method: 'post',
      data: entityData,
    }),

  postCountryByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-country',
      method: 'post',
      data: entityData,
    }),

  postValueOfTotalAssetsByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-value-of-total-assets',
      method: 'post',
      data: entityData,
    }),

  postActivitiesByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-activities-by-entity-type',
      method: 'post',
      data: entityData,
    }),

  postEntitiesByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-by-id',
      method: 'post',
      data: entityData,
    }),

  postTPPbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-third-party-service-provider',
      method: 'post',
      data: entityData,
    }),

  postNatureOfEntity: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-nature-of-entity',
      method: 'post',
      data: entityData,
    }),

  postCompetentAuthority: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-competent-authority',
      method: 'post',
      data: entityData,
    }),

  postCountry: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-country',
      method: 'post',
      data: entityData,
    }),

  postValueOfTotalAssets: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-value-of-total-assets',
      method: 'post',
      data: entityData,
    }),

  postActivitiesByTypeEntity: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-activities-by-type-entity',
      method: 'post',
      data: entityData,
    }),

  getNonSubstReason: () => {
    return AxiosInstance({
      url: '/api/list-non-subst-reasons',
      method: 'get',
    });
  },

  fetchNonSubstReason: (tpp_name: any, tpp_id: string) =>
    AxiosInstance({
      url: '/api/get-non-subst-reason-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),

  fetchPersonType: (tpp_name: any, tpp_id: string) =>
    AxiosInstance({
      url: '/api/get-person-type-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),

  fetchCurrencyAndCosts: (tpp_name: any, tpp_id: string) =>
    AxiosInstance({
      url: '/api/get-costs-and-currency-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),

  fetchCertificates: (tpp_name: string, tpp_id: string) =>
    AxiosInstance({
      url: '/api/filter-certificates-by-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),

  postTPP: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-third-party-service-provider',
      method: 'post',
      data: entityData,
    }),

  postNonSubstReason: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-non-subst-reason',
      method: 'post',
      data: entityData,
    }),

  postPersonTypes: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-person-type',
      method: 'post',
      data: entityData,
    }),

  postCurrencyAndCosts: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-currency-costs',
      method: 'post',
      data: entityData,
    }),

  postCertificates: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-certificates',
      method: 'post',
      data: entityData,
    }),

  postBranchByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-branch-country-assets-by-entity',
      method: 'post',
      data: entityData,
    }),

  postBranches: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-branch-country-assets',
      method: 'post',
      data: entityData,
    }),

  postFunctionByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/filter-function-details',
      method: 'post',
      data: entityData,
    }),

  postFunctions: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-function-details',
      method: 'post',
      data: entityData,
    }),

  postFunctionsbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-functions',
      method: 'post',
      data: entityData,
    }),

  postContractualArrangementsbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-contractual-arrangements-entity',
      method: 'post',
      data: entityData,
    }),

  fetchICTServices: (
    selectedContractForICT: any,
    selectedEntityForICT: any,
    selectedFunctionForICT: any,
    selectedTPPForICT: any,
  ) => {
    return AxiosInstance({
      url: '/api/filter-ict-services',
      method: 'post',
      data: {
        entity_id: selectedEntityForICT ? selectedEntityForICT.entityId : null,
        entity_name: selectedEntityForICT ? selectedEntityForICT.entity : null,
        contractual_arrangements_id: selectedContractForICT
          ? selectedContractForICT.value
          : null,
        tpp_id: selectedTPPForICT ? selectedTPPForICT.value : null,
        tpp_name: selectedTPPForICT ? selectedTPPForICT.label : null,
        function_id: selectedFunctionForICT
          ? selectedFunctionForICT.value
          : null,
        function_name: selectedFunctionForICT
          ? selectedFunctionForICT.label
          : null,
      },
    });
  },

  postICTServices: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-ict-service-details',
      method: 'post',
      data: entityData,
    }),

  postBranchesbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-branches-entity',
      method: 'post',
      data: entityData,
    }),

  postICTServicesbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-ict-services-entity',
      method: 'post',
      data: entityData,
    }),

  fetchContractualInformation: (
    selectedBranchForContractualInformation: any,
    selectedICTForContractualInformation: any,
    selectedTPPForContractualInformation: any,
    selectedEntityForContractualInformation: any,
  ) => {
    return AxiosInstance({
      url: '/api/filter-contractual-arrangements',
      method: 'post',
      data: {
        branch_id: selectedBranchForContractualInformation
          ? selectedBranchForContractualInformation.value
          : null,
        branch_name: selectedBranchForContractualInformation
          ? selectedBranchForContractualInformation.label
          : null,
        entity_id: selectedEntityForContractualInformation
          ? selectedEntityForContractualInformation.entityId
          : null,
        entity_name: selectedEntityForContractualInformation
          ? selectedEntityForContractualInformation.entity
          : null,
        tpp_id: selectedTPPForContractualInformation
          ? selectedTPPForContractualInformation.value
          : null,
        tpp_name: selectedTPPForContractualInformation
          ? selectedTPPForContractualInformation.label
          : null,
        ict_service_id: selectedICTForContractualInformation
          ? selectedICTForContractualInformation.value
          : null,
      },
    });
  },

  postContractualInformation: () =>
    AxiosInstance({
      url: '/api/insert-contractual-details',
      method: 'post',
    }),

  fetchUserActivityStatus: (username: string) =>
    AxiosInstance({
      url: '/api/toggle_user_statuss',
      method: 'post',
      data: username,
    }),
};

export default ApiService;
