import { createSlice } from '@reduxjs/toolkit';
import initialGlobalState from './initialState';

const reportingSlice = createSlice({
  name: 'reporting',
  initialState: initialGlobalState(),
  reducers: {},
});

export default reportingSlice;
