import { useEffect } from 'react';
import {
  useQueryParams,
  StringParam,
  withDefault,
  ArrayParam,
} from 'use-query-params';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/configureStore';
import { globalOp } from 'store/ducks/Global';
import { IFilters } from 'store/ducks/globalTypes';

const useFilters = () => {
  const dispatch: AppDispatch = useDispatch();
  const FiltersParam = withDefault(ArrayParam, []);
  const [query, setQuery] = useQueryParams({
    entity: StringParam,
    entityId: StringParam,
  });

  const { filtersData } = useSelector((state: RootState) => ({
    filtersData: state.global.filtersData as IFilters[],
  }));

  const handleFilterChange = (value: string, id: number | string) => {
    setQuery((latestQuery) => {
      const newQuery = {
        ...latestQuery,
        entity: value,
        entityId: typeof id === 'number' ? id.toString() : id,
      };
      return newQuery;
    });
  };
  // const handleFilterChangeForReportings = (value: string, id: number | string) => {
  //   setQuery((latestQuery) => {
  //       const newQuery = {
  //           ...latestQuery,
  //           entity: value,
  //           entityId: typeof id === 'number' ? id.toString() : id,
  //       };
  //       return newQuery;
  //   });
  // };

  // TODO: for reporting change

  const handleFilterChangeForReportings = (value: string, id: number) => {
    setQuery((latestQuery) => ({
      ...latestQuery,
      entity_name: value,
      entityId: id.toString(),
    }));
  };

  const extractedOptions = filtersData.flatMap((item) => {
    if (Array.isArray(item.options)) {
      return item.options.map((option) => {
        const mappedOption = {
          label: option.name_of_entity,
          value: option.value,
          key: option.id,
        };

        return mappedOption;
      });
    }
    return [];
  });

  useEffect(() => {
    dispatch(globalOp.setFilters());
  }, [dispatch]);

  useEffect(() => {
    console.log('Query updated in useFilters:', query);
  }, [query]);

  return {
    query,
    handleFilterChange,
    extractedOptions,
    handleFilterChangeForReportings,
  };
};

export default useFilters;
