import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { theme, Card, Avatar } from 'antd';
import { RootState } from 'store/configureStore';
import { TitleList } from 'store/ducks/globalTypes';
import TitleBar from 'components/TitleBar';
import GoBackButton from 'components/GoBackButton';
import dataEntries from 'constants/dataEntries';
import useFilters from 'utils/hooks/useFilters';
import * as Styled from '../Layout.styled';

const { useToken } = theme;

const DataEntryLayout: React.FC = () => {
  const { token } = useToken();
  const { scheme } = useSelector((state: RootState) => state.global);
  const { query} = useFilters();
  const entries = dataEntries();
  const navigate = useNavigate();
  const location = useLocation();

  const handleCardClick = (area: string) => {
    navigate(`/data_entry/${area}${location.search}`);
  };

  // const handleCardClick = (area: string, title: string) => {
  //   // Example of appending query params dynamically
  //   const params = new URLSearchParams(location.search);
  //   params.set('entity', title);  // Set 'entity' query param based on selected area
  //   params.set('entityId', '1');  // You can modify this value based on your data or logic

  //   navigate(`/data_entry?${params.toString()}`);
  // };

  return (
    <Styled.StyledLayout token={token}>
      <TitleBar title={TitleList.DATA_ENTRY} />
      <h4>Informationsregister</h4>
      <Styled.WorkingAreaButton>
        <div>
          <GoBackButton to="/working_area" text="Working areas" />
        </div>
      </Styled.WorkingAreaButton>
      <Styled.HorizontalLayout theme={scheme}>
        <Styled.Container>
          <Styled.GridContainer>
            {entries.map((item: any) => (
              <Card
                key={item.area}
                onClick={() => handleCardClick(item.area)}
                style={{ minWidth: 300 }}
              >
                <Card.Meta
                  key={item.area}
                  avatar={
                    <Avatar
                      src={item.imgSrc}
                      style={{ width: 70, height: 70 }}
                    />
                  }
                  title={item.title}
                  description={
                    <>
                      <p>{item.infoText}</p>
                    </>
                  }
                />
              </Card>
            ))}
          </Styled.GridContainer>
        </Styled.Container>
      </Styled.HorizontalLayout>
    </Styled.StyledLayout>
  );
};

export default DataEntryLayout;
