import styled from 'styled-components';
import { Layout, Card, Button, Drawer, Alert } from 'antd';
import { IToken, ThemeList } from 'store/ducks/globalTypes';
import { variablesList } from 'constants/variablesList';
import { IThemeSelectorProps } from 'components/ThemeSelector/ThemeSelector';
import CustomSelect from 'components/CustomSelect';

const { Header } = Layout;

export const StyledLayout = styled(Layout)<IToken>`
  min-height: 100vh;

  > .ant-layout {
    min-height: initial;

    .ant-layout-header {
      height: fit-content;
    }
  }

  aside {
    padding-right: ${(props) => `${props.token.paddingLG}px`};
    flex: initial !important;
    text-align: center;
    max-width: fit-content !important;
    min-width: fit-content !important;
    width: fit-content !important;
    position: relative !important;
    right: 0px;
  }

  h4 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    padding-right: 34px;
  }
`;

export const AntDrawer = styled(Drawer)`
  .ant-drawer-mask {
    background-color: red;
  }
`;

export const HorizontalLayout = styled('div')<IThemeSelectorProps>`
  padding: 0 24px 24px;
  border-bottom: 1px solid
    ${(props) => variablesList[props.theme as ThemeList].defaultTabBg};

  .ant-tabs-nav-list {
    width: -webkit-fill-available;
    margin-bottom: 1rem;

    .ant-tabs-tab {
      width: -webkit-fill-available;
      display: table;
    }
  }
`;

export const StyledHeader = styled(Header)<IToken>`
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-inline: ${(props) => `${props.token.paddingLG}px`};
  line-height: ${(props) => `${props.token.lineHeightHeading2}px`};
`;

export const GridContainer = styled('div')`
  display: flex;
  column-gap: 170px;
  row-gap: 48px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;

  > a {
    height: fit-content;
    width: 350px;
  }

  .ant-card {
    width: 350px;

    .ant-card-meta-title {
      white-space: pre-line !important;
    }

    .ant-card-body {
      cursor: pointer;
    }
  }
`;

export const Container = styled('div')`
  padding: 0px;
`;

export const ReportCard = styled(Card)`
  width: 350px;
  text-align: center;

  .ant-btn {
    margin-top: 9px;
    width: -webkit-fill-available;
    font-size: 16px;
    font-weight: 600;
  }

  .ant-card-body {
    cursor: auto !important;
  }
`;

export const StyledButton = styled(Button)`
  width: 110px;
  text-transform: uppercase;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
  margin-top: 20px;
`;

export const FilterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  gap: 8px;
`;

export const OpenCloseButton = styled(Button)`
  width: 30px;
  text-transform: uppercase;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
`;

export const ExcelDownload = styled.div`
  text-align: -webkit-center;
  margin-bottom: 3rem;

  button {
    width: auto;
  }
`;

export const StepsComponent = styled('div')`
  padding: 0px;

  .ant-steps-item {
    flex: auto;

    .ant-steps-item-title {
      font-size: 15px;
      color: #008080 !important;
    }
  }
`;

export const AlertComponent = styled('div')`
  padding-inline: 24px;
`;

export const AlertInfo = styled(Alert)`
  float: right;
  max-width: 430px;
  margin: 10px 0;
  position: relative;
`;

export const SelectionOfTPP = styled.div`
  position: relative;
  top: 71px;
  left: 189px;
`;

export const StepButtons = styled.div`
  display: flex;
  gap: 8px;

  div {
    display: flex;
    gap: 14px;
  }
  position: relative;

  div {
    position: absolute;
    top: 39px;
    left: 14px;
    z-index: 1;
  }
`;

export const WorkingAreaButton = styled.div`
  display: flex;
  margin: 0 24px 24px;
  justify-content: space-between;

  div {
    display: flex;
    gap: 15px;
  }
`;

export const SelectFilter = styled(CustomSelect)`
  // margin: 10px 0;

  .ant-select-selector {
    width: 162px !important;
  }
`;
