import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { notification, Table, Input, Button } from 'antd';
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { contractual_informationOp } from 'store/ducks/DataEntry/ContractualInformation';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import {
  IContractulInformation,
  IContracts,
  ICosts,
  EditedData,
} from 'store/ducks/DataEntry/ContractualInformation/types';
import useFilters from 'utils/hooks/useFilters';
import * as Styled from '../../DataEntry.styled';
import DynamicField from 'components/DynamicCalendarField/DynamicField';

const ContractualInformation: React.FC = () => {
  const [data, setData] = useState<IContractulInformation[]>([]);
  const [editedData, setEditedData] = useState<EditedData>({});
  const [changedData, setChangedData] = useState<Record<string, any>[]>([]);
  const [isTooltipVisible, setIsTooltipVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const { query } = useFilters();
  const {
    selectedBranchForContractualInformation,
    selectedICTForContractualInformation,
    selectedTPPForContractualInformation,
    selectedEntityForContractualInformation,
  } = useSelector((state: any) => state.contractualInformation);

  const fetchContractualInformation =
    contractual_informationOp.useFetchContractualInformation();
    console.log(selectedEntityForContractualInformation, 'selectedEntityForContractualInformation')
  const fetchContracts = async () => {
    try {
      const response = await fetchContractualInformation();
      const data = response?.data.arrangements;
      console.log(data, 'data');
      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        terminatReason_id: item.terminat_reason.id,
        terminatReason_name: item.terminat_reason.name,
        terminatReason_description: item.terminat_reason.description,
        typeofContractualArrangement_id: item.contract_type_arrangement.id,
        typeofContractualArrangement_name: item.contract_type_arrangement.name,
        typeofContractualArrangement_description:
          item.contract_type_arrangement.description,
        contractType_id: item.contract_type.id,
        contractType_name: item.contract_type.name,
        contractType_description: item.contract_type.description,
        costs: item.costs
          ? item.costs.map((cost: any) => ({
              costs_id: cost.id,
              costs_currency_amount_reported: cost.currency_amount_reported,
              costs_annual_expense: cost.annual_expense,
              costs_total_annual_expense: cost.total_annual_expense,
              costs_description: cost.description,
              costs_currency_id: cost.currency.id,
              costs_contractual_arrangement_id: cost.contractual_arrangement_id,
              costs_date_id: cost.date_id,
              costs_third_party_service_provider_id:
                cost.third_party_service_provider.id,
              costs_start_date: cost.start_date,
              costs_end_date: cost.end_date,
              currency_name: cost.currency.currency_name,
              currency_description: cost.currency.description,
              currency_id: cost.currency.id,
            }))
          : [],
        country_id: item.governing_law_country.id,
        country_country_name: item.governing_law_country.country_name,
        country_iso_code: item.governing_law_country.iso_code,
        country_description: item.governing_law_country.description,
        contractualArrangement_id: item.id,
        contractualArrangement_arrangement_reference_number:
          item.arrangement_reference_number,
        contractualArrangement_start_date: item.start_date,
        contractualArrangement_end_date: item.end_date,
        contractualArrangement_terminat_reason_id: item.terminat_reason.id,
        contractualArrangement_contract_type_id: item.contract_type.id,
        contractualArrangement_contract_type_arrangement_id:
          item.contract_type_arrangement.id,
        contractualArrangement_overarching_reference_number:
          item.overarching_reference_number,
        contractualArrangement_linked_contractual_arrangement:
          item.linked_contractual_arrangement,
        contractualArrangement_description: item.description,
        contractualArrangement_governing_law_country_id:
          item.governing_law_country.id,
        contractualArrangement_branch_id: item.branch.id,
        contractualArrangement_entity_id: item.entity.id,
        contractualArrangement_third_party_service_provider_id:
          item.third_party_service_provider.id,
        contracts: item.contracts
          ? item.contracts.map((contract: any) => ({
              contract_third_party_service_provider_id:
                item.third_party_service_provider.id,
              contract_id: contract.id,
              contract_name: contract.name,
              contract_description: contract.description,
            }))
          : [],
      }));
      setData(mappedData);
    } catch (error) {
      console.log(error, 'error');
      setData([]);
    }
  };
  const shouldFetch =
    (selectedEntityForContractualInformation &&
      selectedBranchForContractualInformation) ||
    selectedICTForContractualInformation ||
    selectedTPPForContractualInformation;

  useEffect(() => {
    if (shouldFetch) {
      fetchContracts();
    }
  }, [
    selectedEntityForContractualInformation,
    selectedBranchForContractualInformation,
    selectedICTForContractualInformation,
    selectedTPPForContractualInformation,
    query,
  ]);

  const addNewCost = (rowKey: string | number) => {
    const newCost: ICosts = {
      costs_id: generateUniqueId(),
      costs_currency_amount_reported: '',
      costs_annual_expense: '',
      costs_total_annual_expense: '',
      costs_description: '',
      costs_currency_id: generateUniqueId(),
      costs_contractual_arrangement_id: generateUniqueId(),
      costs_date_id: generateUniqueId(),
      costs_third_party_service_provider_id: generateUniqueId(),
      costs_start_date: '',
      costs_end_date: '',
      currency_name: '',
      currency_description: '',
      currency_id: generateUniqueId(),
    };

    setData((prevData) =>
      prevData.map((record) => {
        if (record.contractualArrangement_id === rowKey) {
          return {
            ...record,
            costs: [...record.costs, { ...newCost }],
          };
        }
        return record;
      }),
    );
  };
  const addNewContract = (rowKey: string | number) => {
    const newContract: IContracts = {
      contract_third_party_service_provider_id: generateUniqueId(),
      contract_id: generateUniqueId(),
      contract_name: '',
      contract_description: '',
    };

    setData((prevData) =>
      prevData.map((record) => {
        if (record.contractualArrangement_id === rowKey) {
          return {
            ...record,
            contracts: [...record.contracts, { ...newContract }],
          };
        }
        return record;
      }),
    );
  };

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.contractualArrangement_id,
  }));

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  let uniqueIdCounter = 0;
  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now();
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId = Number(`${timestampPart}${uniqueIdCounter}${randomPart}`);
    return uniqueId;
  };

  const addNewRow = () => {
    const newRowTemplate: IContractulInformation = {
      key: generateUniqueId(),
      isNew: true,
      terminatReason_id: generateUniqueId(),
      terminatReason_name: '',
      terminatReason_description: '',
      typeofContractualArrangement_id: generateUniqueId(),
      typeofContractualArrangement_name: '',
      typeofContractualArrangement_description: '',
      contractType_id: generateUniqueId(),
      contractType_name: '',
      contractType_description: '',
      costs: [
        {
          costs_id: generateUniqueId(),
          costs_currency_amount_reported: '',
          costs_annual_expense: '',
          costs_total_annual_expense: '',
          costs_description: '',
          costs_currency_id: generateUniqueId(),
          costs_contractual_arrangement_id: generateUniqueId(),
          costs_date_id: generateUniqueId(),
          costs_third_party_service_provider_id: generateUniqueId(),
          costs_start_date: '',
          costs_end_date: '',
          currency_name: '',
          currency_description: '',
          currency_id: generateUniqueId(),
        },
      ],
      contracts: [
        {
          contract_third_party_service_provider_id: generateUniqueId(),
          contract_id: generateUniqueId(),
          contract_name: '',
          contract_description: '',
        },
      ],
      country_id: generateUniqueId(),
      country_country_name: '',
      country_iso_code: '',
      country_description: '',
      contractualArrangement_id: generateUniqueId(),
      contractualArrangement_arrangement_reference_number: '',
      contractualArrangement_start_date: '',
      contractualArrangement_end_date: '',
      contractualArrangement_terminat_reason_id: generateUniqueId(),
      contractualArrangement_contract_type_id: generateUniqueId(),
      contractualArrangement_contract_type_arrangement_id: generateUniqueId(),
      contractualArrangement_overarching_reference_number: '',
      contractualArrangement_linked_contractual_arrangement: '',
      contractualArrangement_description: '',
      contractualArrangement_governing_law_country_id: generateUniqueId(),
      contractualArrangement_branch_id: generateUniqueId(),
      contractualArrangement_entity_id: generateUniqueId(),
      contractualArrangement_third_party_service_provider_id:
        generateUniqueId(),
    };
    const newRow = structuredClone(newRowTemplate);

    setData((prevData) => [...prevData, newRow]);
    setChangedData((prevChangedData) => [...prevChangedData, newRow]);
  };
  const handleOpenTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: true }));
  };

  const handleCloseTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: false }));
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    rowKey: string,
  ) => {
    const { value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    const updatedRow = data.find(
      (row) => row.contractualArrangement_id.toString() == rowKey,
    );

    if (updatedRow) {
      const updatedRowWithChanges: Record<string, any> = {
        ...updatedRow,
        [fieldName]: value,
      };
      if (fieldName === 'costs_id' || 'contract_id') {
        if (updatedRow.costs) {
          const match = fieldName.match(/^(\d+)_c_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];
            const targetIndex = updatedRow.costs.findIndex(
              (cost) => cost.costs_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.costs[targetIndex] = {
                ...updatedRow.costs[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching cost found for idFromFieldName (${idFromFieldName}) in cost`,
              );
            }
          }
        }
        if (updatedRow.contracts) {
          const match = fieldName.match(/^(\d+)_newContract_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];
            const targetIndex = updatedRow.contracts.findIndex(
              (contract) => contract.contract_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.contracts[targetIndex] = {
                ...updatedRow.contracts[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching contract found for idFromFieldName (${idFromFieldName}) in contracts`,
              );
            }
          }
        }
      }

      const cleanedUpRow = Object.keys(updatedRowWithChanges).reduce(
        (acc: Record<string, any>, key) => {
          const newKey = key.replace(/^[^a-zA-Z]+/, '');
          acc[newKey] = updatedRowWithChanges[key];
          return acc;
        },
        {},
      );

      setChangedData((prevChangedData) => {
        const newChangedData = [...prevChangedData];
        const existingRowIndex = newChangedData.findIndex(
          (row) =>
            row.contractualArrangement_id ===
            cleanedUpRow.contractualArrangement_id,
        );

        if (existingRowIndex !== -1) {
          const existingRow = newChangedData[existingRowIndex];
          const mergedRow = { ...existingRow, ...cleanedUpRow };
          if (JSON.stringify(existingRow) !== JSON.stringify(mergedRow)) {
            newChangedData[existingRowIndex] = mergedRow;
          }
        } else {
          newChangedData.push(cleanedUpRow);
        }

        return newChangedData;
      });
    } else {
      console.log('No match found for contractualArrangement_id:', rowKey);
    }
  };
  const updateChangedData = (editedData: any, changedData: any) => {
    if (editedData && typeof editedData === 'object') {
      Object.keys(editedData).forEach((key: string) => {
        const keyFromEditedData = parseInt(key.split('_')[0]);
        const matchingChangedRow = changedData.find((dataItem: any) => {
          return dataItem.contractualArrangement_id === keyFromEditedData;
        });

        if (matchingChangedRow) {
          const fieldName = key.replace(/^\d+_/, '');
          if (matchingChangedRow.hasOwnProperty(fieldName)) {
            matchingChangedRow[fieldName] = editedData[key];
          }
        }
      });
    } else {
      console.error('editedData is not an object:', editedData);
    }

    setChangedData([...changedData]);
  };

  useEffect(() => {
    updateChangedData(editedData, changedData);
  }, [editedData]);

  const handleSubmit = async () => {
    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description:
          'There are no new or edited Contractual Informations to submit.',
        placement: 'topRight',
      });
      return;
    }
    const formattedData = {
      tpp_name: selectedTPPForContractualInformation?.label,
      branch_name: selectedBranchForContractualInformation?.label,
      ict_service_id: selectedICTForContractualInformation?.value,
      entity_name: selectedEntityForContractualInformation?.label,

      contract_type: changedData.map((item) => ({
        id: item.contractType_id,
        name: item.contractType_name,
        description: item.contractType_description,
      })),

      terminat_reason: changedData.map((item) => ({
        id: item.terminatReason_id,
        name: item.terminatReason_name,
        description: item.terminatReason_description,
      })),

      type_of_contractual_arrangement: changedData.map((item) => ({
        id: item.typeofContractualArrangement_id,
        name: item.typeofContractualArrangement_name,
        description: item.typeofContractualArrangement_description,
      })),

      contractual_arrangement: changedData.map((item) => {
        return {
          id: item?.contractualArrangement_id,
          arrangement_reference_number:
            item?.contractualArrangement_arrangement_reference_number,
          start_date: item?.contractualArrangement_start_date,
          end_date: item?.contractualArrangement_end_date,
          terminat_reason_id: item?.contractualArrangement_terminat_reason_id,
          contract_type_id: item?.contractualArrangement_contract_type_id,
          contract_type_arrangement_id:
            item?.contractualArrangement_contract_type_arrangement_id,
          overarching_reference_number:
            item?.contractualArrangement_overarching_reference_number,
          linked_contractual_arrangement:
            item?.contractualArrangement_linked_contractual_arrangement,
          description: item?.contractualArrangement_description,
          governing_law_country_id:
            item?.contractualArrangement_governing_law_country_id,
          branch_id: item?.contractualArrangement_branch_id,
          entity_id: item?.contractualArrangement_entity_id,
          third_party_service_provider_id:
            item?.contractualArrangement_third_party_service_provider_id,
        };
      }),

      countries: changedData.map((item) => {
        return {
          country_id: item.country_id,
          country_country_name: item.country_country_name,
          country_iso_code: item.country_iso_code,
          country_description: item.country_description,
        };
      }),

      contract: changedData.flatMap(
        (item) =>
          item.contracts?.map((contract: IContracts) => ({
            id: contract.contract_id,
            name: contract.contract_name,
            description: contract.contract_description,
            third_party_service_provider_id:
              contract.contract_third_party_service_provider_id,
          })) || [],
      ),

      costs: changedData.flatMap(
        (item) =>
          item.costs?.map((cost: ICosts) => ({
            costs_id: cost.costs_id,
            costs_currency_amount_reported: cost.costs_currency_amount_reported,
            costs_annual_expense: cost.costs_annual_expense,
            costs_total_annual_expense: cost.costs_total_annual_expense,
            costs_description: cost.costs_description,
            costs_currency_id: cost.costs_currency_id,
            costs_contractual_arrangement_id:
              cost.costs_contractual_arrangement_id,
            costs_date_id: cost.costs_date_id,
            costs_third_party_service_provider_id:
              cost.costs_third_party_service_provider_id,
            costs_start_date: cost.costs_start_date,
            costs_end_date: cost.costs_end_date,
          })) || [],
      ),

      currency: changedData.flatMap(
        (item) =>
          item.costs?.map((cost: ICosts) => ({
            currency_name: cost.currency_name,
            currency_description: cost.currency_description,
            currency_id: cost.currency_id,
          })) || [],
      ),
    };

    try {
      const response =
        await contractual_informationOp.postContractualInformation(
          formattedData,
        );
      notification.success({
        message: 'Submission Successful',
        description: 'The data was successfully submitted.',
        placement: 'topRight',
      });
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const showAddRowButton = shouldFetch && data?.length > 0;

  const columns: EditableColumnType<IContractulInformation>[] = [
    {
      title: (
        <>
          Arrangement reference number<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'contractualArrangement_arrangement_reference_number',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_arrangement_reference_number`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_arrangement_reference_number
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Arrangement start date<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'contractualArrangement_start_date',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_start_date`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_start_date
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Arrangement end date<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'contractualArrangement_end_date',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_end_date`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_end_date
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Overarching reference number <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'contractualArrangement_overarching_reference_number',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_overarching_reference_number`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_overarching_reference_number
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Linked contractual arrangement<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'contractualArrangement_linked_contractual_arrangement',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_linked_contractual_arrangement`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_linked_contractual_arrangement
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'contractualArrangement_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Terminat reason name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'terminatReason_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_terminatReason_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.terminatReason_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Terminat reason description',
      dataIndex: 'terminatReason_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_terminatReason_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.terminatReason_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Type of contractual arrangement name
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'typeofContractualArrangement_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_typeofContractualArrangement_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.typeofContractualArrangement_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Type of contractual arrangement description',
      dataIndex: 'typeofContractualArrangement_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_typeofContractualArrangement_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.typeofContractualArrangement_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Contract type name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'contractType_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractType_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractType_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Contract type description',
      dataIndex: 'contractType_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractType_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractType_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Costs<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'costs',
      key: 'costs',
      render: (_, record) => {
        return (
          <div>
            {(record.costs as ICosts[]).map((cost: ICosts) => {
              const idFieldName = `${cost.costs_id}_c_costs_id`;
              const currencyAmountReportedFieldName = `${cost.costs_id}_c_costs_currency_amount_reported`;
              const annualExpenseFieldName = `${cost.costs_id}_c_costs_annual_expense`;
              const totalAnnualExpenseFieldName = `${cost.costs_id}_c_costs_total_annual_expense`;
              const descriptionFieldName = `${cost.costs_id}_c_costs_description`;
              const currencyNameFieldName = `${cost.costs_id}_c_currency_name`;
              const currencyDescriptionFieldName = `${cost.costs_id}_c_currency_description`;
              const startDateFieldName = `${cost.costs_start_date}_c_costs_start_date`;
              const endDateFieldName = `${cost.costs_end_date}_c_costs_end_date`;
              const rowKey = record.key;
              return (
                <Styled.TooltipContainer
                  key={cost.costs_id}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>
                          Currency amount reported{' '}
                          <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              currencyAmountReportedFieldName,
                            )
                              ? typeof editedData[
                                  currencyAmountReportedFieldName
                                ] === 'boolean'
                                ? editedData[currencyAmountReportedFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[currencyAmountReportedFieldName],
                                  )
                              : String(cost.costs_currency_amount_reported)
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              currencyAmountReportedFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Annual expense <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(annualExpenseFieldName)
                              ? typeof editedData[annualExpenseFieldName] ===
                                'boolean'
                                ? editedData[annualExpenseFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[annualExpenseFieldName])
                              : String(cost.costs_annual_expense)
                          }
                          onChange={(e) =>
                            handleEditChange(e, annualExpenseFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Total annual expense{' '}
                          <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              totalAnnualExpenseFieldName,
                            )
                              ? typeof editedData[
                                  totalAnnualExpenseFieldName
                                ] === 'boolean'
                                ? editedData[totalAnnualExpenseFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[totalAnnualExpenseFieldName],
                                  )
                              : String(cost.costs_total_annual_expense)
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              totalAnnualExpenseFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Start date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(startDateFieldName)
                              ? String(editedData[startDateFieldName] ?? '')
                              : String(cost.costs_start_date ?? '')
                          }
                          fieldName={startDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, startDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          End date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(endDateFieldName)
                              ? String(editedData[endDateFieldName] ?? '')
                              : String(cost.costs_end_date ?? '')
                          }
                          fieldName={endDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, endDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(cost.costs_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Currency name <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(currencyNameFieldName)
                              ? typeof editedData[currencyNameFieldName] ===
                                'boolean'
                                ? editedData[currencyNameFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[currencyNameFieldName])
                              : String(cost.currency_name)
                          }
                          onChange={(e) =>
                            handleEditChange(e, currencyNameFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Currency description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              currencyDescriptionFieldName,
                            )
                              ? typeof editedData[
                                  currencyDescriptionFieldName
                                ] === 'boolean'
                                ? editedData[currencyDescriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[currencyDescriptionFieldName],
                                  )
                              : String(cost.currency_description)
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              currencyDescriptionFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(descriptionFieldName)
                          ? typeof editedData[descriptionFieldName] ===
                            'boolean'
                            ? editedData[descriptionFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[descriptionFieldName])
                          : String(cost.costs_description)
                      }
                      readOnly
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewCost(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Contracts<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'contracts',
      key: 'contracts',
      render: (_, record) => {
        return (
          <div>
            {(record.contracts as IContracts[]).map((contract: IContracts) => {
              const idFieldName = `${contract.contract_id}_newcontract_costs_id`;
              const contractNameFieldName = `${contract.contract_id}_newContract_contract_name`;
              const descriptionFieldName = `${contract.contract_id}_newContract_contract_description`;
              const rowKey = record.key;
              return (
                <Styled.TooltipContainer
                  key={contract.contract_id}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>
                          Name <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(contractNameFieldName)
                              ? typeof editedData[contractNameFieldName] ===
                                'boolean'
                                ? editedData[contractNameFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[contractNameFieldName])
                              : String(contract.contract_name)
                          }
                          onChange={(e) =>
                            handleEditChange(e, contractNameFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(contract.contract_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(contractNameFieldName)
                          ? typeof editedData[contractNameFieldName] ===
                            'boolean'
                            ? editedData[contractNameFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[contractNameFieldName])
                          : String(contract.contract_name)
                      }
                      readOnly
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewContract(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Country name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'country_country_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_country_country_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.country_country_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Country iso code<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'country_iso_code',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_country_iso_code`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.country_iso_code
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Country description',
      dataIndex: 'country_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_country_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.country_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
  ];

  return (
    <>
      {showAddRowButton && (
        <Styled.AreaButton type="primary" onClick={addNewRow}>
          Add Row
        </Styled.AreaButton>
      )}
      <Styled.TooltipTableContainer>
        <Table
          dataSource={dataWithKey}
          rowKey="key"
          scroll={{ x: 'max-content' }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePaginationChange,
            showSizeChanger: pagination.showSizeChanger,
            pageSizeOptions: pagination.pageSizeOptions,
            position: pagination.position,
          }}
          columns={columns}
        />
      </Styled.TooltipTableContainer>
      {query.entity && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
        )}
    </>
  );
};

export default ContractualInformation;
