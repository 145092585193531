import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Form, theme } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { globalOp } from 'store/ducks/Global';
import AuthLayout from 'components/Layout/AuthLayout/AuthLayout';
import * as Styled from './Login.styled';

const { useToken } = theme;

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
  };

  return (
    <AuthLayout>
      <Form form={form} name="login">
        <Styled.ItemsContainer>
          <Styled.StyledFormItem
            token={token}
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              type="email"
              onChange={(event) => setEmail(event.target.value)}
            />
          </Styled.StyledFormItem>
          <Styled.StyledFormItem
            token={token}
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password
              onChange={(event) => setPassword(event.target.value)}
            />
          </Styled.StyledFormItem>
        </Styled.ItemsContainer>

        <Styled.BottomSection>
          <Styled.StyledButton type="primary" onClick={handleSubmit}>
            Sign In
          </Styled.StyledButton>
          <Styled.StyledLink to="/forgot-password">
            <span>Forgot your password?</span>
            <span>Click here</span>
          </Styled.StyledLink>
        </Styled.BottomSection>
      </Form>
    </AuthLayout>
  );
};

export default LoginForm;
