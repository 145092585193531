import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { Input, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import useFilters from 'utils/hooks/useFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import {
  setSelectedTPP,
  set_isPersonTypeAdded,
  set_personType,
} from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import { IPersonType } from 'store/ducks/DataEntry/ThirdPartyProviders/types';
import * as Styled from '../../DataEntry.styled';
import useTppStatus from 'utils/hooks/useTppStatus';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';

const PersonType: React.FC = () => {
  useTppStatus();
  const dispatch = useDispatch();

  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<IPersonType[]>([]);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const [isActive, setIsActive] = useState<boolean>(false);
  const {
    selectedTPP,
    tpps,
    personType,
    addRowCountTpp,
    nameOfTpp,
    isTppUpdated,
  } = useSelector((state: RootState) => state.tpp);
  const [combinedData, setCombinedData] = useState<IPersonType[]>([]);
  const [initialData, setInitialData] = useState<IPersonType[]>([]);
  const [selectedTPPID, setSelectedTPPID] = useState<string>('');
  const [tppName, setTppName] = useState<string>('');
  const isLoggedIn = localStorage.getItem('authToken');

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    setCombinedData([...dataSource, ...personType]);
  }, [dataSource, personType]);

  const getPersonType = async () => {
    try {
      const response = await tppOp.setPersonType(tppName, selectedTPPID);
      const mappedData = [
        {
          key: 0,
          id: response.person_type.id,
          name: response.person_type.name,
          description: response.person_type.description,
          isNew: false,
          isEdited: false,
          tpp_name: response.tpp_name,
        },
      ];
      setDataSource(mappedData);
      setInitialData(mappedData);
      setCount(mappedData.length);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchTPPID = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;

      const dataTPP = data.map((item: any, index: number) => ({
        id: item.id,
        third_party_service_provider_name:
          item.third_party_service_provider_name,
        active: item.active,
      }));

      const matchedTPP = dataTPP.find(
        (item: IPersonType) => item.id.toString() === selectedTPP.toString(),
      );

      if (matchedTPP) {
        setSelectedTPPID(matchedTPP.id);
        setTppName(matchedTPP.third_party_service_provider_name);
        setIsActive(matchedTPP.active);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      if (selectedTPP === '') {
        fetchTPPID();
      } else if (selectedTPP) {
        dispatch(setSelectedTPP(selectedTPP));
      } else if (!selectedTPP) {
        dispatch(setSelectedTPP(''));
        setDataSource([]);
      }
    }
  }, [query.entity]);

  useEffect(() => {
    if (selectedTPP === '' && dataSource.length > 0 && query.entity) {
      setDataSource([]);
      setSelectedTPPID('');
    }
  }, [selectedTPP, dataSource, query.entity]);

  useEffect(() => {
    if (selectedTPP && query.entity) {
      fetchTPPID();
    }
  }, [selectedTPP, query.entity]);

  useEffect(() => {
    if (selectedTPPID && query.entity) {
      getPersonType();
    }
  }, [selectedTPPID, query.entity]);

  // useEffect(() => {
  //   if (addRowCountTpp === 1 && tpps.length > personType.length) {
  //     if (nameOfTpp) {
  //       const newRow = {
  //         key: generateUniqueId(),
  //         id: generateUniqueId(),
  //         name: '',
  //         description: '',
  //         tpp_name: nameOfTpp,
  //         isNew: true,
  //         active: true,
  //       };
  //       dispatch(set_personType([...personType, newRow]));
  //     }
  //   }
  // }, [tpps, query.entity, nameOfTpp, personType]);

  useEffect(() => {
    if (addRowCountTpp === 0) {
      dispatch(set_personType([]));
    }
  }, [addRowCountTpp, personType.length, dispatch]);

  const handleSave = (row: IPersonType) => {
    const isRowInDataSource = dataSource.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = dataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );

      setDataSource(updatedDataSource);
      setCombinedData([...updatedDataSource, ...personType]);
    } else {
      const updatedEntityType = personType.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setCombinedData([...dataSource, ...updatedEntityType]);
      dispatch(set_personType(updatedEntityType));
    }
  };

  const areAllFieldsFilled = (personType: IPersonType[]) => {
    return personType.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };
  useEffect(() => {
    if (addRowCountTpp === 1) {
      const allFieldsFilled = areAllFieldsFilled(personType);
      if (allFieldsFilled) {
        dispatch(set_isPersonTypeAdded(true));
      } else {
        dispatch(set_isPersonTypeAdded(false));
      }
    }
  }, [personType, addRowCountTpp]);

  const handleSubmit = async () => {
    if (combinedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no edited Person Types to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (!areAllFieldsFilled(combinedData)) {
      notification.warning({
        message: 'Not submitted',
        description: 'Please fill in all the required fields in the data.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    try {
      const newRows = combinedData.filter((row) => row.isNew);
      const updatedRows = combinedData.filter((row) => !row.isNew);

      for (const newRow of newRows) {
        await tppOp.insertPersonTypes({
          tpp_name: newRow.tpp_name,
          entity_name: query.entity,
          data_list: [newRow],
          token: isLoggedIn,
        });
      }

      if (updatedRows.length > 0) {
        await tppOp.insertPersonTypes({
          tpp_name: tppName,
          entity_name: query.entity,
          data_list: updatedRows,
          token: isLoggedIn,
        });
      }
      getPersonType();
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: (
        <Styled.TitleComponent>
          Type of person of the ICT third-party service provider
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the options in the following closed list shall be used:
            1.	Legal person, excluding individuals acting in business capacity
            2.	Individual acting in a business capacity
            (closed set of options)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'name',
      editable: false,
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: 'Person Type Description',
      dataIndex: 'description',
      // editable: true,
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: 'ICT third-party service provider',
      dataIndex: 'tpp_name',
      editable: false,
      fixed: 'right',
      render: (text: string) => <Input value={text} disabled />,
    },
  ];

  return (
    dataSource && (
      <Styled.StyledWrapper hasEntity={!!query.entity}>
        <CustomTable
          columns={defaultColumns}
          dataSource={combinedData}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        {query.entity && (
          <Styled.AreaButton type="primary" onClick={handleSubmit}>
            Submit
          </Styled.AreaButton>
        )}
      </Styled.StyledWrapper>
    )
  );
};

export default PersonType;
