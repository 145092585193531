import React, { useState, useEffect } from 'react';
import useAdvancedFilters from 'utils/hooks/useAdvancedFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import { notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import * as Styled from '../../DataEntry.styled';

interface IICTServices {
  key: number;
  sensitivenessOfDataStoredbyICTTPSP_id: number;
  sensitivenessOfDataStoredbyICTTPSP_name: string;
  sensitivenessOfDataStoredbyICTTPSP_description: string;
  dataSensitiveness_id: number;
  dataSensitiveness_location_of_data_at_rest: string;
  dataSensitiveness_location_of_data_management: string;
  dataSensitiveness_sensitiveness_id: number;
  dataSensitiveness_description: string;
  dataSensitiveness_ict_system_id: number;
  yesNo_id: number; // changed to storage_of_data
  yesNo_status: boolean;
  yesNo_description: string;
  auswahlServicesDiscount_id: number;
  auswahlServicesDiscount_name: string;
  auswahlServicesDiscount_description: string;
  ictServiceType_id: number;
  ictServiceType_name: string;
  ictServiceType_description: string;
  levelofSupportingICTService_id: number;
  levelofSupportingICTService_name: string;
  levelofSupportingICTService_description: string;
  ictService_id: number;
  ictService_ict_service_type_id: number;
  ictService_third_party_service_provider_id: number;
  ictService_description: string;
  ictService_notice_period: string;
  ictService_level_of_supporting_ict_service_id: number;
  ictService_rank: string;
  ictService_auswahl_services_discount_id: number;
  ictService_storage_of_data_id: number;
  ictService_recipient_identification_code: string;
  ictService_recipient_identification_type: string;
  ictService_function_id: number;
  ictService_country_id: number;
  ictService_ict_service_level_id: number;
  ictService_impact_of_discontinuing_function_id: number;
  ictService_impact_of_discontinuing_ict_services_id: number;
  ictService_alternatives_exit_id: number;
  ictService_substitutability_of_ict_provider_id: number;
  ictService_level_of_reliance_id: number;
  alternatives_exit_id: number;
  alternatives_exit_alternative_providers_identified: string;
  alternatives_exit_exit_plan_exists: string;
  alternatives_exit_reintegration_possible: string;
  alternatives_exit_alternative_ict_tpp_identification: string;
  alternatives_exit_description: string;
  impactOfDiscontinuingFunction_id: number;
  impactOfDiscontinuingFunction_description: string;
  impactOfDiscontinuingIctServices_id: number;
  impactOfDiscontinuingIctServices_description: string;
  date_id: number;
  date_date_of_reporting: string;
  date_date_of_last_update: string;
  date_description: string;
  date_ict_service_id: number;
  date_contractual_arrangement_id: number;
  levelOfRelianceICTService_id: number;
  levelOfRelianceICTService_name: string;
  levelOfRelianceICTService_description: string;
  ictServiceLevel_id: number;
  ictServiceLevel_name: string;
  ictServiceLevel_description: string;
  ictSystems_id: number;
  ictSystems_name: string;
  ictSystems_description: string;
  certificates_id: number;
  certificates_description: string;
  certificates_validity: boolean;
  certificates_date_of_issuance: string;
  certificates_date_of_certificate_register: string;
  certificates_third_party_service_provider_id: number;
  certificates_function_id: number;
  certificates_ict_service_id: number;
  certificates_certificate_registry: string;
  isNew?: boolean;
  isEdited?: boolean;
}

const ICTServices: React.FC = () => {
  const { query } = useAdvancedFilters();
  const [dataSource, setDataSource] = useState<IICTServices[]>([]);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const fetchICTServices = async () => {
    try {
      const response = await ict_serviceOp.fetchICTServices();
      //  console.log(response, 'response');
      //  console.log(response, 'resp');
      // const response = await branchOp.fetchBranchbyEntityName({
      //   entity_id: query.entityId,
      //   entity_name: query.entity,
      // });
      // const data = response?.data;
      // const mappedData = [
      //   {
      //     key: 0,

      //     isNew: false,
      //     isEdited: false,
      //   },
      // ];
      // setDataSource(mappedData);
      // setCount(mappedData.length);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchICTServices();
    }
  }, [query]);

  const handleAddRow = (record?: any, index?: number) => {
    // const maxId = dataSource.reduce(
    //   (max, item) => (item.id_branch > max ? item.id_branch : max),
    //   0,
    // );
    const newId = +1;

    // const newData: IICTServices = {
    //   key: newId,

    //   isNew: true,
    // };

    const insertIndex = dataSource.findIndex(
      (item) => item.key === record?.key,
    );

    const updatedDataSource = [
      ...dataSource.slice(0, insertIndex + 1),
      // newData,
      ...dataSource.slice(insertIndex + 1),
    ];

    setDataSource(updatedDataSource);
    setCount(count + 1);
  };

  const handleSave = (row: IICTServices) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const handleSubmit = async () => {
    const changedData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );

    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited ICT Services to submit.',
        placement: 'topRight',
      });
      return;
    }

    const formattedData = {
      entity_name: query.entity,
      data_list: [],
    };

    // try {
    //   const response = await branchOp.insertBranches(formattedData);
    //   console.log(response, 'response');
    //   notification.success({
    //     message: 'Submission Successful',
    //     description: 'The data was successfully submitted.',
    //     placement: 'topRight',
    //   });
    // } catch (error) {
    //   console.error('Submission Error:', error);
    //   notification.error({
    //     message: 'Submission Error',
    //     description: 'There was an error sending the data. Please try again.',
    //     placement: 'topRight',
    //   });
    // }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: 'Sensitiveness of data stored by ICTTPSP - id',
      dataIndex: 'sensitivenessOfDataStoredbyICTTPSP_id',
      editable: false,
    },
    {
      title: 'Sensitiveness of data stored by ICTTPSP - name',
      dataIndex: 'sensitivenessOfDataStoredbyICTTPSP_name',
      editable: true,
    },
    {
      title: 'Sensitiveness of data stored by ICTTPSP - description',
      dataIndex: 'sensitivenessOfDataStoredbyICTTPSP_description',
      editable: true,
    },
    {
      title: 'Data sensitiveness - id',
      dataIndex: 'dataSensitiveness_id',
      editable: false,
    },
    {
      title: 'Data Sensitiveness - location of data at rest',
      dataIndex: 'dataSensitiveness_location_of_data_at_rest',
      editable: true,
    },
    {
      title: 'Data Sensitiveness - location of data management',
      dataIndex: 'dataSensitiveness_location_of_data_management',
      editable: true,
    },
    {
      title: 'Data Sensitiveness - sensitiveness id',
      dataIndex: 'dataSensitiveness_sensitiveness_id',
      editable: false,
    },
    {
      title: 'Data sensitiveness - description',
      dataIndex: 'dataSensitiveness_description',
      editable: true,
    },
    {
      title: 'Data sensitiveness - ict system id',
      dataIndex: 'dataSensitiveness_ict_system_id',
      editable: false,
    },
    {
      title: 'Yes/no - id', //TODO change
      dataIndex: 'yesNo_id',
      editable: false,
    },
    {
      title: 'Yes/no - status', //TODO change
      dataIndex: 'yesNo_status',
      editable: true,
    },
    {
      title: 'Yes/no - description', //TODO change
      dataIndex: 'yesNo_description',
      editable: true,
    },
    {
      title: 'Auswahl services discount - id',
      dataIndex: 'auswahlServicesDiscount_id',
      editable: false,
    },
    {
      title: 'Auswahl services discount - name',
      dataIndex: 'auswahlServicesDiscount_name',
      editable: true,
    },
    {
      title: 'Auswahl services discount - description',
      dataIndex: 'auswahlServicesDiscount_description',
      editable: true,
    },
    {
      title: 'ICT service type - id',
      dataIndex: 'ictServiceType_id',
      editable: false,
    },
    {
      title: 'ICT service type - name',
      dataIndex: 'ictServiceType_name',
      editable: true,
    },
    {
      title: 'ICT service type - description',
      dataIndex: 'ictServiceType_description',
      editable: true,
    },
    {
      title: 'Level of supporting ICT service - id',
      dataIndex: 'levelofSupportingICTService_id',
      editable: false,
    },
    {
      title: 'CLevel of supporting ICT service - name',
      dataIndex: 'levelofSupportingICTService_name',
      editable: true,
    },
    {
      title: 'Level of supporting ICT service - description',
      dataIndex: 'levelofSupportingICTService_description',
      editable: true,
    },
    {
      title: 'ICT service - id',
      dataIndex: 'ictService_id',
      editable: false,
    },
    {
      title: 'ICT Service - ict service type id',
      dataIndex: 'ictService_ict_service_type_id',
      editable: false,
    },
    {
      title: 'ICT Service - third party service provider id',
      dataIndex: 'ictService_third_party_service_provider_id',
      editable: false,
    },
    {
      title: 'ICT service - description',
      dataIndex: 'ictService_description',
      editable: true,
    },
    {
      title: 'ICT service - notice period',
      dataIndex: 'ictService_notice_period',
      editable: true,
    },
    {
      title: 'ICT service - level of supporting ict service id',
      dataIndex: 'ictService_level_of_supporting_ict_service_id',
      editable: false,
    },
    {
      title: 'ICT Service - rank',
      dataIndex: 'ictService_rank',
      editable: true,
    },
    {
      title: 'ICT Service - auswahl services discount id',
      dataIndex: 'ictService_auswahl_services_discount_id',
      editable: false,
    },
    {
      title: 'ICT Service - storage of data id',
      dataIndex: 'ictService_storage_of_data_id',
      editable: false,
    },
    {
      title: 'ICT Service - recipient identification code',
      dataIndex: 'ictService_recipient_identification_code',
      editable: true,
    },
    {
      title: 'ICT Service - resipient identification type',
      dataIndex: 'ictService_recipient_identification_type',
      editable: true,
    },
    {
      title: 'ICT Service - function id',
      dataIndex: 'ictService_function_id',
      editable: false,
    },
    {
      title: 'ICT Service - country id',
      dataIndex: 'ictService_country_id',
      editable: false,
    },
    {
      title: 'ICT Service - ict service level id',
      dataIndex: 'ictService_ict_service_level_id',
      editable: false,
    },
    {
      title: 'ICT Service - impact of discountinuing function id',
      dataIndex: 'ictService_impact_of_discontinuing_function_id',
      editable: false,
    },
    {
      title: 'ICT Service - impact of discontinuing ict services id',
      dataIndex: 'ictService_impact_of_discontinuing_ict_services_id',
      editable: false,
    },
    {
      title: 'ICT service - alternatives exit id',
      dataIndex: 'ictService_alternatives_exit_id',
      editable: false,
    },
    {
      title: 'ICT Service - substitutability of ICT provider id',
      dataIndex: 'ictService_substitutability_of_ict_provider_id',
      editable: false,
    },
    {
      title: 'ICT Service - level of reliance id',
      dataIndex: 'ictService_level_of_reliance_id',
      editable: false,
    },
    {
      title: 'Alternatives exit id',
      dataIndex: 'alternatives_exit_id',
      editable: false,
    },
    {
      title: 'Alternatives exit -  alternative providers identified',
      dataIndex: 'alternatives_exit_alternative_providers_identified',
      editable: true,
    },
    {
      title: 'Alternatives exit - exit plan exists',
      dataIndex: 'alternatives_exit_exit_plan_exists',
      editable: true,
    },
    {
      title: 'Alternatives exit - reintegration possible',
      dataIndex: 'alternatives_exit_reintegration_possible',
      editable: true,
    },
    {
      title: 'Alternatives exit - alternative ict tpp identification',
      dataIndex: 'alternatives_exit_alternative_ict_tpp_identification',
      editable: true,
    },
    {
      title: 'Alternatives exit - description',
      dataIndex: 'alternatives_exit_description',
      editable: true,
    },
    {
      title: 'Impact of Discontinuing function - id',
      dataIndex: 'impactOfDiscontinuingFunction_id',
      editable: false,
    },
    {
      title: 'Impact of discontinuing function - description',
      dataIndex: 'impactOfDiscontinuingFunction_description',
      editable: true,
    },
    {
      title: 'Impact of discontinuing ICT Services - id',
      dataIndex: 'impactOfDiscontinuingIctServices_id',
      editable: false,
    },
    {
      title: 'Impact of discontinuing ICT Services - description',
      dataIndex: 'impactOfDiscontinuingIctServices_description',
      editable: true,
    },
    {
      title: 'Date id',
      dataIndex: 'date_id',
      editable: false,
    },
    {
      title: 'Date of reporting',
      dataIndex: 'date_date_of_reporting',
      editable: true,
    },
    {
      title: 'Date of last update',
      dataIndex: 'date_date_of_last_update',
      editable: true,
    },
    {
      title: 'Date - description',
      dataIndex: 'date_description',
      editable: true,
    },
    {
      title: 'Date - ict service id',
      dataIndex: 'date_ict_service_id',
      editable: false,
    },
    {
      title: 'Date - contractual arrangement id',
      dataIndex: 'date_contractual_arrangement_id',
      editable: false,
    },
    {
      title: 'Level of Reliance ICT Service - id',
      dataIndex: 'levelOfRelianceICTService_id',
      editable: false,
    },
    {
      title: 'Level of Reliance ICT Service - name',
      dataIndex: 'levelOfRelianceICTService_name',
      editable: true,
    },
    {
      title: 'Level of Reliance ICT Service - description',
      dataIndex: 'levelOfRelianceICTService_description',
      editable: true,
    },
    {
      title: 'ICT Service level - id',
      dataIndex: 'ictServiceLevel_id',
      editable: false,
    },
    {
      title: 'ICT Service level - name',
      dataIndex: 'ictServiceLevel_name',
      editable: true,
    },
    {
      title: 'ICT Service level - description',
      dataIndex: 'ictServiceLevel_description',
      editable: true,
    },
    {
      title: 'ICT Systems - id',
      dataIndex: 'ictSystems_id',
      editable: false,
    },
    {
      title: 'ICT Systems - name',
      dataIndex: 'ictSystems_name',
      editable: true,
    },
    {
      title: 'ICT Systems - description',
      dataIndex: 'ictSystems_description',
      editable: true,
    },
    {
      title: 'Certificates - id',
      dataIndex: 'certificates_id',
      editable: false,
    },
    {
      title: 'Certificates - description',
      dataIndex: 'certificates_description',
      editable: true,
    },
    {
      title: 'Certificates - validity',
      dataIndex: 'certificates_validity',
      editable: true,
    },
    {
      title: 'Certificates - date of issuance',
      dataIndex: 'certificates_date_of_issuance',
      editable: true,
    },
    {
      title: 'Certificates - date of certificate register',
      dataIndex: 'certificates_date_of_certificate_register',
      editable: true,
    },
    {
      title: 'Certificates - tpp id',
      dataIndex: 'certificates_third_party_service_provider_id',
      editable: false,
    },
    {
      title: 'Certificates - function id',
      dataIndex: 'certificates_function_id',
      editable: false,
    },
    {
      title: 'Certificates - ict service id',
      dataIndex: 'certificates_ict_service_id',
      editable: false,
    },
    {
      title: 'Certificates - certificate registry',
      dataIndex: 'certificates_certificate_registry',
      editable: true,
    },
    {
      title: 'Create',
      dataIndex: 'add_row',
      key: 'add_row',
      width: '10px',
      fixed: 'right',
      render: (_: any, record: { key: React.Key }, index: number) => (
        <Styled.OperationsRow onClick={() => handleAddRow(record, index)}>
          <PlusOutlined />
        </Styled.OperationsRow>
      ),
    },
  ];

  return (
    dataSource && (
      <div>
        <Styled.AreaButton onClick={handleAddRow} type="primary">
          Add a row
        </Styled.AreaButton>
        <CustomTable
          columns={defaultColumns}
          dataSource={dataSource}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      </div>
    )
  );
};

export default ICTServices;
