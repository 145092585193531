import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import RouterOutlet from './RouterOutlet';
import { theme } from './constants/theme';
import { RootState } from 'store/configureStore';
import { themesList } from './constants/themesList';
import { ThemeList } from 'store/ducks/globalTypes';
import ThemeSelector from './components/ThemeSelector';
import useAutoLogout from 'utils/hooks/useAutoLogout';

const App: React.FC = () => {
  const { scheme } = useSelector((state: RootState) => state.global);
  const navigate = useNavigate();

  const isLoggedIn = !!localStorage.getItem('authToken');
  useEffect(() => {
    if (isLoggedIn && window.location.pathname === '/') {
      navigate('/working_area', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  const logout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  useAutoLogout(logout, 15 * 60 * 1000);

  return (
    <ThemeSelector theme={scheme as ThemeList}>
      <React.Suspense fallback={<div>Loading</div>}>
        <ConfigProvider theme={theme(themesList[scheme as ThemeList])}>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <RouterOutlet />
          </QueryParamProvider>
        </ConfigProvider>
      </React.Suspense>
    </ThemeSelector>
  );
};

export default App;
