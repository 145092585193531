import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';

interface DataType {
  key: React.Key;
  arrangement_reference_number: string;
  branch_identification_code: string;
  lei_entity_making_use_ICT_service: string;
  name_of_entity: string;
  nature_of_entity: string;
}

interface RT0401Props {
  entityName: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Contractual arrangement reference number',
    width: 100,
    dataIndex: 'arrangement_reference_number',
    key: 'arrangement_reference_number',
    fixed: 'left',
  },
  {
    title: 'LEI of the entity making use of the ICT service(s)',
    width: 100,
    dataIndex: 'lei_entity_making_use_ICT_service',
    key: 'branch_identification_code',
    fixed: 'left',
  },
  {
    title: 'Nature of the entity making use of the ICT service(s)',
    width: 100,
    dataIndex: 'nature_of_entity',
    key: 'nature_of_entity',
  },
  {
    title: 'Identification code of the branch',
    width: 100,
    dataIndex: 'branch_identification_code',
    key: 'branch_identification_code',
  },
  {
    title: 'Name of the entity',
    width: 100,
    dataIndex: 'name_of_entity',
    key: 'name_of_entity',
  },
];
const RT0401: React.FC<RT0401Props> = ({ entityName }) => {
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        entity_name: entityName,
      };
      try {
        const fetchedData =
          await reportingOp.fetchContractualArrangementsICT(params);
        if (Array.isArray(fetchedData)) {
          const formattedData = fetchedData.map((item: any, index: number) => ({
            key: index,
            arrangement_reference_number: item.arrangement_reference_number,
            branch_identification_code: item.branch_identification_code,
            lei_entity_making_use_ICT_service:
              item.lei_entity_making_use_ICT_service,
            name_of_entity: item.name_of_entity,
            nature_of_entity: item.nature_of_entity,
          }));
          setData(formattedData);
        } else {
          console.error('Fetched data is not an array:', fetchedData);
        }
      } catch (error) {
        console.error('Error fetching branches:', error);
        setData([]);
      }
    };

    if (entityName) {
      fetchData();
    }
  }, [entityName, dispatch]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default RT0401;
