import React from 'react';
import StepComponent from 'components/StepComponent/CustomSteps';
import EntityType from 'components/Tables/DataEntry/Entity/EntityType/EntityType';
import HierarchyInGroup from 'components/Tables/DataEntry/Entity/HierarchyInGroup/HierarchyInGroup';
import NatureOfEntity from 'components/Tables/DataEntry/Entity/NatureOfEntity/NatureOfEntity';
import Entity from 'components/Tables/DataEntry/Entity/Entity/Entity';
import CompetentAuthority from 'components/Tables/DataEntry/Entity/CompetentAuthority/CompetentAuthority';
import CountryComponent from 'components/Tables/DataEntry/Entity/Country/Country';
import ValueOfTotalAssets from 'components/Tables/DataEntry/Entity/ValueOfTotalAssets/ValueOfTotalAssets';
import ActivitiesByTypeEntity from 'components/Tables/DataEntry/Entity/ActivitiesByTypeEntity/ActivitiesByTypeEntity';

const steps = [
  { title: 'Entity', content: <Entity /> },
  { title: 'EntityType', content: <EntityType /> },
  { title: 'HierarchyInGroup', content: <HierarchyInGroup /> },
  { title: 'NatureOfEntity', content: <NatureOfEntity /> },
  { title: 'CompetentAuthority', content: <CompetentAuthority /> },
  { title: 'CountryComponent', content: <CountryComponent /> },
  { title: 'ValueOfTotalAssets', content: <ValueOfTotalAssets /> },
  { title: 'ActivitiesByTypeEntity', content: <ActivitiesByTypeEntity /> },
];

const EntitySteps: React.FC = () => {
  return <StepComponent steps={steps} title="Entity Steps" />;
};

export default EntitySteps;
