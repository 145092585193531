import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { Button, DatePicker, Input, Table, notification, Modal } from 'antd';
import {
  PlusOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import useFilters from 'utils/hooks/useFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { IEntityTypeData } from 'store/ducks/DataEntry/Entities/types';
import {
  set_activitiesByTypeEntity,
  set_isActivitiesByEntityTypesAdded,
  set_entityType,
  set_isEntityTypesAdded,
} from 'store/ducks/DataEntry/Entities/entitySlice';
import useLicensedActivities from 'utils/hooks/fetchHooks/useLicensedActivities';
import { generateUniqueId } from 'utils/hooks/generateUniqueId';
import useEntityStatus from 'utils/hooks/useEntityStatus';
import dayjs from 'dayjs';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../../DataEntry.styled';
import { functionOp } from 'store/ducks/DataEntry/Functions';
import CustomSelect from 'components/CustomSelect';
import { EditableColumnType } from 'components/CustomTable/CustomTable';

const EntityType: React.FC = () => {
  useEntityStatus();
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<IEntityTypeData[]>([]);
  const [initialData, setInitialData] = useState<IEntityTypeData[]>([]);
  const [combinedData, setCombinedData] = useState<IEntityTypeData[]>([]);
  const [tooltipsForData, setTooltipsForData] = useState<any[]>([]);
  const { licensedActivities } = useLicensedActivities();
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: number]: boolean;
  }>({});
  const resetTriggered = useRef(false);
  const {
    addRowCount,
    entityType,
    activitiesByTypeEntity,
    entities,
    nameOfEntity,
    nameOfEntityType,
    idOfEntity,
  } = useSelector((state: RootState) => state.entities);
  const isLoggedIn = localStorage.getItem('authToken');

  useEffect(() => {
    setCombinedData([...entityType, ...dataSource]);
  }, [dataSource, entityType]);

  useEffect(() => {
    dispatch(set_activitiesByTypeEntity(tooltipsForData));
  }, [tooltipsForData, dispatch]); //

  const handleInputChange = (key: number, field: string, value: string) => {
    setDataSource((prevDataSource) => {
      const updatedDataSource = prevDataSource.map((item) =>
        item.key === key ? { ...item, [field]: value } : item,
      );

      setCombinedData((prevCombinedData) =>
        prevCombinedData.map((item) =>
          item.key === key ? { ...item, [field]: value } : item,
        ),
      );
      const updatedEntityType = entityType.map((item) =>
        item.key === key ? { ...item, [field]: value } : item,
      );
      dispatch(set_entityType(updatedEntityType));
      return updatedDataSource;
    });
  };

  const fetchEntityTypes = async () => {
    try {
      const response = await entityOp.fetchEntityTypeByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.entity_type;
      const mappedData = [
        {
          key: generateUniqueId(),
          id: data?.id || 0,
          name: data?.name || '',
          description: data?.description || '',
          entity_name: response?.data?.entity_name || query.entity,
          activities: [],
        },
      ];

      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchActivities = async () => {
    try {
      const response = await entityOp.fetchActivitiesByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.activities;
      const mappedData = data.map((activity: any, index: number) => ({
        key: index,
        id: activity.activity_id || 0,
        activity_name: activity.activity_name || '',
        activity_date: activity.activity_date || '',
        description: activity.description || '',
        entity_type_id: activity.entity_type_id || 0,
        entity_name: response?.data?.entity_name || query.entity,
        isNew: false,
        isEdited: false,
      }));
      setTooltipsForData(mappedData);
      set_activitiesByTypeEntity(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchEntityTypes();
      fetchActivities();
    }
  }, [query]);

  useEffect(() => {
    if (addRowCount === 0) {
      dispatch(set_entityType([]));
    }
  }, [addRowCount, entityType.length, dispatch]);

  const areAllFieldsFilled = (entityType: IEntityTypeData[]) => {
    return entityType.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };
  useEffect(() => {
    if (addRowCount === 1) {
      const allFieldsFilled = areAllFieldsFilled(entityType);
      if (allFieldsFilled) {
        dispatch(set_isEntityTypesAdded(true));
        dispatch(set_isActivitiesByEntityTypesAdded(true));
      } else {
        dispatch(set_isEntityTypesAdded(false));
        dispatch(set_isActivitiesByEntityTypesAdded(false));
      }
    }
  }, [entityType, addRowCount]);

  const handleOpenTooltip = (key: number) => {
    setTooltipVisibility((prev) => ({ ...prev, [key]: true }));
  };

  const handleCloseTooltip = (key: number) => {
    setTooltipVisibility((prev) => ({ ...prev, [key]: false }));
  };

  const handleSubmitEntityType = async () => {
    if (
      JSON.stringify(combinedData) === JSON.stringify(initialData) &&
      JSON.stringify(activitiesByTypeEntity) === JSON.stringify([])
    ) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (!areAllFieldsFilled(combinedData)) {
      notification.warning({
        message: 'Not submitted',
        description: 'Please fill in all the required fields in the data.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    if (entityType.length !== 0) {
      const allFieldsFilled = areAllFieldsFilled(entityType);

      if (!allFieldsFilled) {
        notification.warning({
          message: 'Not submitted',
          description: 'Please fill in all the required fields in Entity Type.',
          placement: 'topRight',
          duration: 20,
        });
        return;
      } else {
        dispatch(set_isEntityTypesAdded(true));
      }
    }
    const unfilledNewActivities = activitiesByTypeEntity.filter(
      (activity) => !activity.activity_name || !activity.activity_date,
    );
    if (unfilledNewActivities.length !== 0) {
      const allFieldsFilled = unfilledNewActivities.every((item) => {
        return (
          item.activity_name !== '' &&
          item.activity_date !== '' &&
          item.description !== ''
        );
      });
      if (!allFieldsFilled) {
        notification.warning({
          message: 'Not submitted',
          description:
            'Please fill in all the required fields in Activities By Type entity.',
          placement: 'topRight',
          duration: 20,
        });
        return;
      }
    }

    const data_list = activitiesByTypeEntity.map(
      ({ id, activity_name, activity_date, description, entity_type_id }) => ({
        id,
        activity_name,
        activity_date,
        description,
        entity_type_id,
      }),
    );
    try {
      const payloadEntityType = {
        entity_name: query.entity,
        data_list: [...combinedData],
        token: isLoggedIn,
      };
      const response = await entityOp.fetchEntityType(payloadEntityType);
      const payloadActivities = {
        entity_name: query.entity,
        data_list,
        token: isLoggedIn,
      };
      await entityOp.fetchActivitiesByTypeEntity(payloadActivities);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const updateActivityValue = (id: string, field: string, value: string) => {
    setTooltipsForData((prevData) =>
      prevData.map((activity) =>
        activity.id === id ? { ...activity, [field]: value } : activity,
      ),
    );
    dispatch(set_activitiesByTypeEntity(tooltipsForData));
  };

  const handleDeleteActivity = (activityId: number) => {
    confirm({
      title: 'Are you sure you want to delete this activity?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        const activityToDelete = tooltipsForData.find(
          (activity) => activity.id === activityId,
        );

        if (activityToDelete?.isNew) {
          setTooltipsForData((prevData) =>
            prevData.filter((activity) => activity.id !== activityId),
          );
        } else {
          try {
            await entityOp.inactivateLicencedActivity([activityId]);
            fetchActivities();
            // setTooltipsForData((prevData) =>
            //   prevData.filter((activity) => activity.id !== activityId)
            // );
            // dispatch(set_activitiesByTypeEntity(tooltipsForData));
          } catch (error) {
            console.error('Failed to inactivate activity:', error);
          }
        }
      },
      onCancel: () => {
        console.log('Delete action cancelled');
      },
    });
  };

  const columns: EditableColumnType<IEntityTypeData>[] = [
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Type of financial entity</p>
            <p>B_01.01.0040</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the type of financial entity using one of the options in the following closed list:
1.	credit institutions;
2.	payment institutions, including payment institutions exempted pursuant to Directive
(EU) 2015/2366 of the European Parliament and of the Council (1);
3.	account information service providers;
4.	electronic money institutions, including electronic money institutions exempted pursuant to Directive 2009/110/EC of the European Parliament and of the Council (2);
5.	investment firms;
6.	crypto-asset service providers authorised under Regulation (EU) 2023/1114 of the European Parliament and of the Council (3)
7.	issuers of asset-referenced tokens authorised under Regulation (EU) 2023/1114;
8.	central securities depositories;
9.	central counterparties;
10.	trading venues;
11.	trade repositories;
12.	managers of alternative investment funds;
13.	management companies;
14.	data reporting service providers;
15.	insurance and reinsurance undertakings;
16.	insurance	intermediaries,	reinsurance intermediaries and ancillary insurance intermediaries;
17.	institutions for occupational retirement provision;
18.	credit rating agencies;
19.	administrators of critical benchmarks;
20.	crowdfunding service providers;
21.	securitisation repositories.
22.	other financial entity;
Where the register of information is maintained at the group level by the parent undertaking, which is not itself subject to the obligation to maintain such register, i.e. it does not fall under the definition of financial entities set out in Article 2 of the Regulation (EU) 2022/2554 (e.g., financial holding company, mixed financial holding company or mixed-activity holding company) ‘Other financial entity’ option shall be chosen.

(closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Benennen Sie anhand einer der Optionen aus der
            folgenden erschöpfenden Liste die Art des
            Finanzunternehmens:
            1. Kreditinstitute,
            2. Zahlungsinstitute, einschließlich nach der Richtlinie
            (EU) 2015/2366 des Europäischen Parlaments und
            des Rates ausgenommene Zahlungsinstitute (1),
            3. Kontoinformationsdienstleister,
            4. E-Geld-Institute, einschließlich nach der Richtlinie
            2009/110/EG des Europäischen Parlaments und
            des Rates ausgenommene E-Geld-Institute (2),
            5. Wertpapierfirmen,
            6. Anbieter von Krypto-Dienstleistungen, die gemäß
            der Verordnung (EU) 2023/1114 des Europäischen
            Parlaments und des Rates zugelassen sind (3),
            7. Emittenten wertreferenzierter Token, die gemäß der
            Verordnung (EU) 2023/1114 zugelassen sind,
            8. Zentralverwahrer,
            9. zentrale Gegenparteien,
            10. Handelsplätze,
            11. Transaktionsregister,
            12. Verwalter alternativer Investmentfonds,
            13. Verwaltungsgesellschaften,
            14. Datenbereitstellungsdienste,
            15. Versicherungs- und
            Rückversicherungsunternehmen,
            16. Versicherungsvermittler,
            Rückversicherungsvermittler und
            Versicherungsvermittler in Nebentätigkeit,
            17. Einrichtungen der betrieblichen Altersversorgung,
            18. Ratingagenturen,
            19. Administratoren kritischer Referenzwerte,
            20. Schwarmfinanzierungsdienstleister,
            21. Verbriefungsregister,
            22. sonstige Finanzunternehmen.
            Wird das Informationsregister auf Gruppenebene vom
            Mutterunternehmen geführt, das selbst nicht der Pflicht
            zur Führung eines solchen Registers unterliegt, da es
            nicht unter die Definition der Finanzunternehmen
            gemäß Artikel 2 der Verordnung (EU) 2022/2554 fällt
            (z. B. Finanzholdinggesellschaft, gemischte
            Finanzholdinggesellschaft oder gemischte
            Holdinggesellschaft), ist die Option „Sonstiges
            Finanzunternehmen“ zu wählen."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'name',
      editable: false,
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: 'Entity Type description',
      dataIndex: 'description',
      editable: false,
      // render: (_: any, record: IEntityTypeData) => {
      //   return (
      //     <Input
      //       defaultValue={record.description || ''}
      //       onChange={(e) =>
      //         handleInputChange(record.key, 'description', e.target.value)
      //       }
      //       style={{ marginBottom: 8 }}
      //     />
      //   );
      // },
    },
    {
      title: (
        <>
          Licensed Activities per entity type
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'activities',
      render: (_: any, record: IEntityTypeData) => {
        const relatedActivities = tooltipsForData.filter(
          (a) => a.entity_type_id === record.id,
        );

        const handleAddActivityClickForData = () => {
          const newActivity = {
            id: generateUniqueId(),
            activity_name: '',
            description: '',
            activity_date: '',
            entity_type_id: record.id,
            isNew: true,
          };

          setTooltipsForData((prevData) => [...prevData, newActivity]);
          dispatch(set_activitiesByTypeEntity(tooltipsForData));
        };

        return (
          <div>
            {relatedActivities.map((activity) => (
              <Styled.TooltipContainer
                key={activity.id}
                title={
                  <div>
                    <Styled.TooltipButtonContainer>
                      <Button
                        size="small"
                        type="text"
                        onClick={() => handleCloseTooltip(activity.id)}
                      >
                        <CloseCircleOutlined />
                      </Button>
                      <Button
                        size="small"
                        type="text"
                        danger
                        onClick={() => handleDeleteActivity(activity.id)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Styled.TooltipButtonContainer>
                    <Styled.InputField>
                      <strong>
                        Licensed Activity <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <CustomSelect
                        options={licensedActivities}
                        value={activity.activity_name || ''}
                        fieldName="activity_name"
                        onChange={(value: string, option: any) => {
                          updateActivityValue(
                            activity.id,
                            'activity_name',
                            option.label,
                          );
                        }}
                      />
                    </Styled.InputField>
                    <Styled.InputField>
                      <strong>Activity description</strong>
                      <Input
                        defaultValue={activity.description || ''}
                        onChange={(e) =>
                          updateActivityValue(
                            activity.id,
                            'description',
                            e.target.value,
                          )
                        }
                        style={{ marginBottom: 8 }}
                      />
                    </Styled.InputField>
                    <Styled.InputField>
                      <strong>
                        Activity date <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={
                          activity.activity_date
                            ? dayjs(activity.activity_date, 'YYYY-MM-DD')
                            : null
                        }
                        onClick={(e) => e.stopPropagation()}
                        onChange={(date) => {
                          if (date) {
                            const formattedDateString = date
                              ? dayjs(date).format('YYYY-MM-DD')
                              : '';
                            updateActivityValue(
                              activity.id,
                              'activity_date',
                              formattedDateString,
                            );
                          } else {
                            updateActivityValue(
                              activity.id,
                              'activity_date',
                              '',
                            );
                          }
                        }}
                      />
                    </Styled.InputField>
                  </div>
                }
                visible={tooltipVisibility[activity.id] || false}
              >
                <Styled.OpenTooltipBtn
                  type="link"
                  onClick={() => handleOpenTooltip(activity.id)}
                >
                  <Input
                    value={activity.activity_name || 'Add Activity'}
                    readOnly
                    addonAfter={<PlusCircleOutlined />}
                  />
                </Styled.OpenTooltipBtn>
              </Styled.TooltipContainer>
            ))}
            <Button
              type="dashed"
              onClick={handleAddActivityClickForData}
              icon={<PlusOutlined />}
            />
            <Styled.GlobalStyle />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Entity Name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'entity_name',
      editable: false,
      render: (text: string) => <Input value={text} disabled />,
    },
  ];

  return (
    <Styled.StyledWrapper hasEntity={!!query.entity}>
      <Styled.TooltipTableContainer style={{ marginTop: '64px' }}>
        <Table
          columns={columns}
          dataSource={combinedData}
          rowKey="key"
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePaginationChange,
            showSizeChanger: pagination.showSizeChanger,
            pageSizeOptions: pagination.pageSizeOptions,
            position: pagination.position,
          }}
        />
      </Styled.TooltipTableContainer>
      <Styled.AreaButton type="primary" onClick={handleSubmitEntityType}>
        Submit
      </Styled.AreaButton>
    </Styled.StyledWrapper>
  );
};

export default EntityType;
