import styled, { createGlobalStyle } from 'styled-components';
import { Button, Tooltip } from 'antd';

export const TypeComponent = styled('div')`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 4px 11px;
    cursor: pointer;
    color: black !important;
    border: none;
    border-radius: 2px;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  .ant-table-cell a {
    &:hover {
      color: black;
    }
  }
  .ant-picker-dropdown {
    z-index: 1050;
  }
  .ant-table-cell-row-hover .ant-picker {
    pointer-events: auto;
  }
  .ant-picker-input {
    color: black !important;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    // margin: 0 0 16px 0;
    margin: 16px;
  }
`;

export const OperationsRow = styled.a`
  color: black;

  span svg {
    font-size: 20px;
  }
`;

export const AreaButton = styled(Button)`
  margin: 16px;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    .ant-tooltip-inner {
      width: 520px;
      div {
        border-bottom: 1px solid;
        strong, span{
          font-size: 16px;
        }
      }
  
    }
  }
`;
