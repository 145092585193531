import React from 'react';
import StepComponent from 'components/StepComponent/CustomSteps';
import Function from 'components/Tables/DataEntry/Function/Functions/Function';

const steps = [
  {
    title: 'Function',
    content: <Function />,
  },
];

const FunctionSteps: React.FC = () => {
  return <StepComponent steps={steps} title="Function Steps" />;
};

export default FunctionSteps;
