import React from 'react';
import StepComponent from 'components/StepComponent/CustomSteps';
import ICTService from 'components/Tables/DataEntry/ICTServices/ICTServices/ICTServices';

const steps = [
  {
    title: 'ICTService',
    content: <ICTService />,
  },
];

const ICTServiceSteps: React.FC = () => {
  return <StepComponent steps={steps} title="ICT Service Steps" />;
};

export default ICTServiceSteps;
