import ApiService from 'utils/ApiCalls';

const fetchBranches = async (data: any) => {
  try {
    const response = await ApiService.getBranches(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchEntityContractualArrangements = async (data: any) => {
  try {
    const response = await ApiService.getEntityContractualArrangements(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchContractualArrangementsLei = async (data: any) => {
  try {
    const response = await ApiService.getContractualArrangementsLei(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchContractualArrangementsICT = async (data: any) => {
  try {
    const response = await ApiService.getContractualArrangementsICT(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchRT0101 = async (data: any) => {
  try {
    const response = await ApiService.getRT0101(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchRT0102 = async (data: any) => {
  try {
    const response = await ApiService.getRT0102(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
const fetchRT0201 = async (data: any) => {
  try {
    const response = await ApiService.getRT0201(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
const fetchRT0202 = async (data: any) => {
  try {
    const response = await ApiService.getRT0202(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
const fetchRT0302 = async (data: any) => {
  try {
    const response = await ApiService.getRT0302(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
const fetchRT0501 = async (data: any) => {
  try {
    const response = await ApiService.getRT0501(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
const fetchRT0502 = async (data: any) => {
  try {
    const response = await ApiService.getRT0502(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
const fetchRT0601 = async (data: any) => {
  try {
    const response = await ApiService.getRT0601(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
const fetchRT0701 = async (data: any) => {
  try {
    const response = await ApiService.getRT0701(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchAR0101 = async (data: any) => {
  try {
    const response = await ApiService.getART0101(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchB9901 = async (data: any) => {
  try {
    const response = await ApiService.getB9901(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchContractualArrangementsICTUse = async (data: any) => {
  try {
    const response = await ApiService.getContractualArrangementsICTUse(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export default {
  fetchEntityContractualArrangements,
  fetchBranches,
  fetchContractualArrangementsLei,
  fetchContractualArrangementsICT,
  fetchContractualArrangementsICTUse,
  fetchRT0101,
  fetchRT0102,
  fetchRT0201,
  fetchRT0202,
  fetchRT0302,
  fetchRT0501,
  fetchRT0502,
  fetchRT0601,
  fetchRT0701,
  fetchAR0101,
  fetchB9901,
};
