export const displayTitleMap: Record<string, string[]> = {
  'B_01.01': [
    'LEI of the financial entity maintaining the register of information - 0010',
    'Name of the financial entity - 0020',
    'Country of the entity -0030',
    'Type of entity - 0040',
    'Competent Authority - 0050',
    'Date of the reporting - 0060',
  ],
  'B_01.02': [
    'LEI of the financial entity - 0010',
    'Name of the entity - 0020',
    'Country of the financial entity - 0030',
    'Type of financial entity - 0040',
    'Hierarchy of the financial entity within the group (where applicable) - 0050',
    'LEI of the direct parent undertaking of the entity - 0060',
    'Date of last update - 0070',
    'Date of integration in the Register of information - 0080',
    'Date of deletion in the Register of information - 0090',
    'Currency - 0100',
    'Value of total assets of the financial entity - 0110',
  ],
  'B_01.03': [
    'Identification code of the branch - 0010',
    'LEI of the financial entity head office of the branch - 0020',
    'Name of the branch - 0030',
    'Country of the branch - 0040',
  ],
  'B_02.01': [
    'Contractual arrangement reference number - 0010',
    'Type of contractual arrangement - 0020',
    'Overarching contractual arrangement reference number 0030',
    'Currency of the amount reported in B.02.01.0050 - 0040',
    'Annual expense or estimated cost of the contractual arrangement for the past year - 0050',
  ],
  'B_02.02': [
    'Contractual arrangement reference number - 0010',
    'LEI of the financial entity making use of the ICT service(s) - 0020',
    'Identification code of the ICT third-party service provider - 0030',
    'Type of code to identify the ICT third-party service provider - 0040',
    'Function identifier - 0050',
    'Type of ICT services - 0060',
    'Start date of the contractual arrangement - 0070',
    'End date of the contractual arrangement - 0080',
    'Reason of the termination or ending of the contractual arrangement - 0090',
    'Notice period for the financial entity making use of the ICT service(s) - 0100',
    'Notice period for the ICT third-party service provider - 0110',
    'Country of the governing law of the contractual arrangement - 0120',
    'Country of provision of the ICT services - 0130',
    'Storage of data - 0140',
    'Location of the data at rest (storage) - 0150',
    'Location of management of the data (processing) - 0160',
    'Sensitiveness of the data stored by the ICT third-party service provider - 0170',
    'Level of reliance on the ICT service supporting the critical or important function. - 0180',
  ],
  'B_02.03': [
    'Contractual arrangement reference number - 0010',
    'Contractual arrangement linked to the contractual arrangement referred in B.02.03.0010 - 0020',
  ],
  'B_03.01': [
    'Contractual arrangement reference number - 0010',
    'LEI of the entity signing the contractual arrangement - 0020',
  ],
  'B_03.02': [
    'Contractual arrangement reference number - 0010',
    'Identification code of ICT third-party service provider - 0020',
    'Type of code to identify the ICT third-party service provider - 0030',
  ],
  'B_03.03': [
    'Contractual arrangement reference number - 0010',
    'LEI of the financial entity providing ICT services - 0020',
  ],
  'B_04.01': [
    'Contractual arrangement reference number - 0010',
    'LEI of the financial entity making use of the ICT service(s) - 0020',
    'Nature of the financial entity making use of the ICT service(s) - 0030',
    'Identification code of the branch - 0040',
  ],
  'B_05.01': [
    'Identification code of ICT third-party service provider - 0010',
    'Type of code to identify the ICT third-party service provider 0020',
    'Additional identification code of ICT third-party service provider - 0030',
    'Type of additional identification code of the ICT third-party service provider - 0040',
    'Legal name of the ICT third- party service provider - 0050',
    'Name of the ICT third-party service provider in Latin alphabet - 0060',
    'Type of person of the ICT third-party service provider - 0070',
    'Country of the ICT third-party service provider’s headquarters - 0080',
    'Currency of the amount reported in RT.05.01.0070 - 0090',
    'Total annual expense or estimated cost of the ICT third-party service provider - 0100',
    'Identification code of the ICT third-party service provider’s ultimate parent undertaking - 0110',
    'Type of code to identify the ICT third-party service provider’s ultimate parent undertaking - 0120',
  ],
  'B_05.02': [
    'Contractual arrangement reference number - 0010',
    'Type of ICT services - 0020',
    'Identification code of the ICT third-party service provider - 0030',
    'Type of code to identify the ICT third-party service provider - 0040',
    'Rank - 0050',
    'Identification code of the recipient of sub-contracted ICT services - 0060',
    'Type of code to identify the recipient of sub-contracted ICT services - 0070',
  ],
  'B_06.01': [
    'Function Identifier - 0010',
    'Licenced activity - 0020',
    'Function name - 0030',
    'LEI of the financial entity - 0040',
    'Criticality or importance assessment - 0050',
    'Reasons for criticality or importance - 0060',
    'Date of the last assessment of criticality or importance - 0070',
    'Recovery time objective of the function - 0080',
    'Recovery point objective of the function - 090',
    'Impact of discontinuing the function - 0100',
  ],
  'B_07.01': [
    'Contractual arrangement reference number - 0010',
    'Identification code of the ICT third-party service provider - 0020',
    'Type of code to identify the ICT third-party service provider - 0030',
    'Type of ICT services - 0040',
    'Substitutability of the ICT third-party service provider - 0050',
    'Reason if the ICT third-party service provider is considered not substitutable or difficult to be substitutable - 0060',
    'Date of the last audit on the ICT third-party service provider - 0070',
    'Existence of an exit plan - 0080',
    'Possibility of reintegration of the contracted ICT service - 0090',
    'Impact of discontinuing the ICT services - 0100',
    'Are there alternative ICT third-party service providers identified? - 0110',
    'Identification of alternative ICT TPP - 0120',
  ],
};