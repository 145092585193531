import styled from 'styled-components';

export const AddttionalInfoContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #ff6f61;
    font-style: italic;
    font-weight: bold;
  }
`;
