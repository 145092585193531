import { IContractualInformationState } from './types';

const initialContractualInformationState =
  (): IContractualInformationState => ({
    loading: false,
    selectedBranchForContractualInformation: null,
    selectedICTForContractualInformation: null,
    selectedTPPForContractualInformation: null,
    selectedEntityForContractualInformation: null,
  });

export default initialContractualInformationState;
