import styled from 'styled-components';

export const PopoverComponent = styled('div')`
  width: 500px;
`;

export const PopoverIconMark = styled('div')`
  .anticon-question-circle {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
