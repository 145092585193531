import Images from 'constants/images';

const dataEntries = () => {
  const entries = [
    {
      area: 'entity_maintaining_registry',
      title: 'Entity Maintaining Register Of Information I',
      infoText: 'info',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/entity_maintaining_registry',
    },
    {
      area: 'entity',
      title: 'Entity II',
      infoText: 'info about entity',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/entity',
    },
    {
      area: 'branch',
      title: 'Branch III',
      infoText: 'info about Branch',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/branch',
    },
    {
      area: 'function',
      title: 'Function IV',
      infoText: 'info about Function',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/function',
    },
    {
      area: 'third_party_provider',
      title: 'ICT third-party service provider V',
      infoText: 'info about ICT third-party service provider',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/third_party_provider',
    },
    {
      area: 'contractual_information',
      title: 'Contractual Information VI',
      infoText: 'info about Contractual information',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/contractual_information',
    },
    {
      area: 'ict_service',
      title: 'ICT Service VII',
      infoText: 'info about ICT_service',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/ict_service',
    },
    {
      area: 'data',
      title: 'Data VIII',
      infoText: 'info about Data',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/data',
    },
  ];
  return entries;
};

export default dataEntries;
