import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEntityState } from './types';
import initialGlobalState from './initialState';
import { IEntitiesResponse } from './types';

const entitySlice = createSlice({
  name: 'entity',
  initialState: initialGlobalState(),
  reducers: {
    set_entities(
      state: IEntityState,
      action: PayloadAction<IEntitiesResponse>,
    ) {
      state.entities = action.payload;
      state.loading = false;
    },
  },
});

export default entitySlice;
