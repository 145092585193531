import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, notification, Input, Table, Modal, DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ColumnType } from 'antd/es/table';
import { functionOp } from 'store/ducks/DataEntry/Functions';
import useFilters from 'utils/hooks/useFilters';
import { IEntityMaintainingRegistry } from 'store/ducks/DataEntry/Entities/types';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import CustomTable, {
  DataType,
  EditableColumnType,
} from 'components/CustomTable/CustomTable';
import CustomSelect from 'components/CustomSelect';
import useCountries from 'utils/hooks/fetchHooks/useCountries';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { RootState } from 'store/configureStore';
import { set_isSubmitionTrue } from 'store/ducks/DataEntry/Entities/entitySlice';
import { generateUniqueId } from 'utils/hooks/generateUniqueId';
import * as Styled from '../DataEntry.styled';

const EntityMaintainingregistry = () => {
  const { query } = useFilters();
  dayjs.extend(customParseFormat);
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const [data, setData] = useState<IEntityMaintainingRegistry[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [initialData, setInitialData] = useState<IEntityMaintainingRegistry[]>(
    [],
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [changedData, setChangedData] = useState<Record<string, any>[]>([]);
  const { countries } = useCountries();
  const [entityTypes, setEntityTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [competentAuthorities, setCompetentAuthorities] = useState<
    { label: string; value: string }[]
  >([]);
  const { idOfSelectedFinancialEntity } = useSelector(
    (state: RootState) => state.entities,
  );
  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const fetchFinancialEntitiesById = async () => {
    try {
      if (idOfSelectedFinancialEntity === null) return;
      const response = await entityOp.fetchFinancialEntitiesById(
        idOfSelectedFinancialEntity,
      );
      const data = response?.data || [];
      const mappedData = [
        {
          key: 0,
          isNew: false,
          isEdited: false,
          id: data.financial_entity_id || 0,
          lei_entity_maintaining_register_information:
            data.lei_entity_maintaining_register_information || '',
          financial_entity_id: data.financial_entity_id || 0,
          financial_entity_name: data.financial_entity_name || '',
          country_id: data.country_id || '',
          entity_type_id: data.entity_type_id || '',
          competent_authority_id: data.competent_authority_id || '',
          reporting_date: data.reporting_date || '',
        },
      ];
      setData(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
      setData([]);
    }
  };

  const handleSave = (row: IEntityMaintainingRegistry) => {
    const isRowInDataSource = data.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = data.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setData(updatedDataSource);
    }
  };

  // const handleRemoveRow = (key: number) => {
  //   Modal.confirm({
  //     title: 'Are you sure you want to remove this row?',
  //     content: 'This action cannot be undone.',
  //     okText: 'Yes',
  //     cancelText: 'No',
  //     onOk: () => {
  //       const updatedDataSource = data.filter((item) => item.key !== key);
  //       // fetchFunctionbyEntity();
  //       setData(updatedDataSource);
  //       notification.success({
  //         message: 'Row Removed',
  //         description: 'The row has been successfully removed.',
  //         duration: 10,
  //       });
  //     },
  //     onCancel: () => {
  //       notification.info({
  //         message: 'Action Canceled',
  //         description: 'The row removal has been canceled.',
  //         duration: 10,
  //       });
  //     },
  //   });
  // };

  // const handleInactivate = (record: IEntityMaintainingRegistry) => {
  //   if (record.f_function_id === undefined) {
  //     notification.error({
  //       message: 'Inactivation Error',
  //       description: 'The function ID is missing.',
  //       placement: 'topRight',
  //       duration: 20,
  //     });
  //     return;
  //   }

  //   confirm({
  //     title: 'Confirm Inactivation',
  //     content: `Are you sure you want to soft delete the function with ID ${record.f_function_id}?`,
  //     okText: 'Yes',
  //     cancelText: 'No',
  //     onOk: async () => {
  //       try {
  //         const response = await functionOp.inactivateFunction([
  //           record.f_function_id,
  //         ]);
  //         fetchFunctionbyEntity();
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     },
  //     onCancel: () => {
  //       console.log('Soft delete cancelled by the user.');
  //     },
  //   });
  // };

  const fetchEntityType = async () => {
    try {
      const response = await entityOp.fetchEntityTypes();
      if (response?.data.entity_types) {
        const formattedOptions = response.data.entity_types.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setEntityTypes(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCompetentAuthorities = async () => {
    try {
      const response = await entityOp.fetchCompetentAuthorities();
      if (response?.data.competent_authorities) {
        const formattedOptions = response.data.competent_authorities.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setCompetentAuthorities(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (idOfSelectedFinancialEntity) {
      fetchFinancialEntitiesById();
    }
    fetchEntityType();
    fetchCompetentAuthorities();
  }, [idOfSelectedFinancialEntity]);

  const addNewRow = () => {
    const newRowTemplate: IEntityMaintainingRegistry = {
      key: generateUniqueId(),
      id: generateUniqueId(),
      lei_entity_maintaining_register_information: '',
      financial_entity_name: '',
      country_id: '',
      entity_type_id: '',
      competent_authority_id: '',
      reporting_date: '',
      isNew: true,
    };
    const newRow = structuredClone(newRowTemplate);

    setData((prevData) => [...prevData, newRow]);
    setChangedData((prevChangedData) => [...prevChangedData, newRow]);
  };

  const handleSubmitEntity = async () => {
    if (JSON.stringify(data) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    const entityData = {
      data_list: data,
      entity_name: 'Financial Entities',
    };

    try {
      const response = await entityOp.fetchFinancialEntity(entityData);
      if (response?.status === 200) {
        fetchFinancialEntitiesById();
        dispatch(set_isSubmitionTrue(true));
      }
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns: EditableColumnType[] = [
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              LEI of the financial entity maintaining the register of
              information
            </p>
            <p>B_01.01.0010</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the financial entity maintaining and updating the register of information using the LEI, 20-character, alpha- numeric code based on the ISO 17442 standard (alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie anhand des 20-stelligen alphanumerischen LEI-Codes gemäß der Norm ISO 17442 das Finanzunternehmen an, das das Informationsregister führt und aktualisiert."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'lei_entity_maintaining_register_information',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Name of Financial entity maintaining the register of information
            </p>
            <p>B_01.01.0020</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Legal name of the financial entity maintaining and updating the register of information (alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Offizielle Bezeichnung des Finanzunternehmens, das das Informationsregister führt und aktualisiert"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'financial_entity_name',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Country of the financial entity</p>
            <p>B_01.01.0030</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the ISO 3166–1 alpha–2 code of the country where the license or the registration of the entity reported in the register of information has been issued"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie nach ISO 3166-1 Alpha-2 den Ländercode des Landes an, in dem die Lizenz oder die Eintragung des im Informationsregister gemeldeten Unternehmens erteilt wurde."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'country_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => (
        <CustomSelect
          options={countries}
          value={record.country_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              country_id: value,
            } as IEntityMaintainingRegistry)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Type of financial entity</p>
            <p>B_01.01.0040</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the type of financial entity using one of the options in the following closed list:
              1.	credit institutions;
              2.	payment institutions, including payment institutions exempted pursuant to Directive
              (EU) 2015/2366 of the European Parliament and of the Council (1);
              3.	account information service providers;
              4.	electronic money institutions, including electronic money institutions exempted pursuant to Directive 2009/110/EC of the European Parliament and of the Council (2);
              5.	investment firms;
              6.	crypto-asset service providers authorised under Regulation (EU) 2023/1114 of the European Parliament and of the Council (3)
              7.	issuers of asset-referenced tokens authorised under Regulation (EU) 2023/1114;
              8.	central securities depositories;
              9.	central counterparties;
              10.	trading venues;
              11.	trade repositories;
              12.	managers of alternative investment funds;
              13.	management companies;
              14.	data reporting service providers;
              15.	insurance and reinsurance undertakings;
              16.	insurance	intermediaries,	reinsurance intermediaries and ancillary insurance intermediaries;
              17.	institutions for occupational retirement provision;
              18.	credit rating agencies;
              19.	administrators of critical benchmarks;
              20.	crowdfunding service providers;
              21.	securitisation repositories.
              22.	other financial entity;
              Where the register of information is maintained at the group level by the parent undertaking, which is not itself subject to the obligation to maintain such register, i.e. it does not fall under the definition of financial entities set out in Article 2 of the Regulation (EU) 2022/2554 (e.g., financial holding company, mixed financial holding company or mixed-activity holding company) ‘Other financial entity’ option shall be chosen.

              (closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Benennen Sie anhand einer der Optionen aus der
              folgenden erschöpfenden Liste die Art des
              Finanzunternehmens:
              1. Kreditinstitute,
              2. Zahlungsinstitute, einschließlich nach der Richtlinie
              (EU) 2015/2366 des Europäischen Parlaments und
              des Rates ausgenommene Zahlungsinstitute (1),
              3. Kontoinformationsdienstleister,
              4. E-Geld-Institute, einschließlich nach der Richtlinie
              2009/110/EG des Europäischen Parlaments und
              des Rates ausgenommene E-Geld-Institute (2),
              5. Wertpapierfirmen,
              6. Anbieter von Krypto-Dienstleistungen, die gemäß
              der Verordnung (EU) 2023/1114 des Europäischen
              Parlaments und des Rates zugelassen sind (3),
              7. Emittenten wertreferenzierter Token, die gemäß der
              Verordnung (EU) 2023/1114 zugelassen sind,
              8. Zentralverwahrer,
              9. zentrale Gegenparteien,
              10. Handelsplätze,
              11. Transaktionsregister,
              12. Verwalter alternativer Investmentfonds,
              13. Verwaltungsgesellschaften,
              14. Datenbereitstellungsdienste,
              15. Versicherungs- und
              Rückversicherungsunternehmen,
              16. Versicherungsvermittler,
              Rückversicherungsvermittler und
              Versicherungsvermittler in Nebentätigkeit,
              17. Einrichtungen der betrieblichen Altersversorgung,
              18. Ratingagenturen,
              19. Administratoren kritischer Referenzwerte,
              20. Schwarmfinanzierungsdienstleister,
              21. Verbriefungsregister,
              22. sonstige Finanzunternehmen.
              Wird das Informationsregister auf Gruppenebene vom
              Mutterunternehmen geführt, das selbst nicht der Pflicht
              zur Führung eines solchen Registers unterliegt, da es
              nicht unter die Definition der Finanzunternehmen
              gemäß Artikel 2 der Verordnung (EU) 2022/2554 fällt
              (z. B. Finanzholdinggesellschaft, gemischte
              Finanzholdinggesellschaft oder gemischte
              Holdinggesellschaft), ist die Option „Sonstiges
              Finanzunternehmen“ zu wählen."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'entity_type_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => {
        return (
          <CustomSelect
            options={entityTypes}
            value={record.entity_type_id}
            onChange={(value: any) => {
              handleSave({
                ...record,
                entity_type_id: value,
              } as IEntityMaintainingRegistry);
            }}
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Competent Authority</p>
            <p>B_01.01.0050</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the competent authority referred to in Article 46 of Regulation (EU) 2022/2554 to which the register of information is reported. (alphanumerical) (mandatory in case of reporting)."
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie die in Artikel 46 der Verordnung
            (EU) 2022/2554 genannte zuständige Behörde an, der
            das Informationsregister gemeldet wird."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'competent_authority_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => (
        <CustomSelect
          options={competentAuthorities}
          value={record.competent_authority_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              competent_authority_id: value,
            } as IEntityMaintainingRegistry)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Date of the reporting</p>
            <p>B_01.01.0060</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of reporting (date) (mandatory in case of reporting)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie das Datum unter Verwendung des
            ISO 8601-Codes (JJJJ–MM–TT) des Meldedatums an"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'reporting_date',
      editable: false,
      className: 'highlighted-column',
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityMaintainingRegistry;
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = date
                ? dayjs(date).format('YYYY-MM-DD')
                : '';
              const updatedRow: IEntityMaintainingRegistry = {
                ...entityRecord,
                reporting_date: formattedDateString,
              };
              handleSave(updatedRow);
            }}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Styled.AreaButton type="primary" onClick={addNewRow}>
        Add Row
      </Styled.AreaButton>
      <Styled.HighlightedTable>
        <CustomTable
          columns={columns}
          dataSource={data}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
      </Styled.HighlightedTable>
      <Styled.AreaButton type="primary" onClick={handleSubmitEntity}>
        Submit
      </Styled.AreaButton>
    </div>
  );
};

export default EntityMaintainingregistry;
