import ApiService from 'utils/ApiCalls';
import { useSelector } from 'react-redux';
import { notification } from 'antd';

const useFetchICTServices = () => {
  const {
    selectedContractForICT,
    selectedEntityForICT,
    selectedFunctionForICT,
    selectedTPPForICT,
  } = useSelector((state: any) => state.ictServices);

  const fetchICTServices = async () => {
    try {
      const response = await ApiService.fetchICTServices(
        selectedContractForICT,
        selectedEntityForICT,
        selectedFunctionForICT,
        selectedTPPForICT,
      );
      return response;
    } catch (error) {
      console.error('Error fetching ICT Services:', error);
      throw error;
    }
  };

  return fetchICTServices;
};

const useFetchICTServicesForData = () => {
  const { selectedEntityForICT } = useSelector(
    (state: any) => state.ictServices,
  );
  const { selectedICTForContractualInformation } = useSelector(
    (state: any) => state.contractualInformation,
  );

  const fetchICTServicesForData = async () => {
    try {
      const response = await ApiService.fetchDataWithICT(
        selectedEntityForICT,
        selectedICTForContractualInformation,
      );
      return response;
    } catch (error) {
      console.error('Error fetching ICT Services for Data:', error);
      throw error;
    }
  };

  return fetchICTServicesForData;
};

const useFetchFunctionsForData = () => {
  const { selectedEntityForICT, selectedFunctionForICT } = useSelector(
    (state: any) => state.ictServices,
  );

  const fetchFunctionsForData = async () => {
    try {
      const response = await ApiService.fetchDataWithFunctions(
        selectedEntityForICT,
        selectedFunctionForICT,
      );
      return response;
    } catch (error) {
      console.error('Error fetching Functions for Data:', error);
      throw error;
    }
  };

  return fetchFunctionsForData;
};

const postICTServices = async (ICTServices: any) => {
  try {
    const response = await ApiService.postICTServices(ICTServices);
    notification.success({
      message: 'Submission Successful',
      description: 'The data was successfully submitted.',
      placement: 'topRight',
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchFunctionsbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postFunctionsbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Functions:', error);
  }
};

const fetchContractualArrangementsbyEntityName = async (
  entityTypeData: any,
) => {
  try {
    const response =
      await ApiService.postContractualArrangementsbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Contractual Arrangements:', error);
  }
};

const fetchICTServiceTypes = async () => {
  try {
    const response = await ApiService.getICTServiceTypes();
    return response;
  } catch (error) {
    console.error('Error fetching the ict service types:', error);
  }
};

const fetchLevelsOfReliance = async () => {
  try {
    const response = await ApiService.getLevelsOfReliance();
    return response;
  } catch (error) {
    console.error('Error fetching the levels of reliance', error);
  }
};

const fetchCriticalityAssessments = async () => {
  try {
    const response = await ApiService.getCriticalityAssessments();
    return response;
  } catch (error) {
    console.error('Error fetching the criticality assessments', error);
  }
};

const fetchDoraCriticality = async () => {
  try {
    const response = await ApiService.getDoraCriticality();
    return response;
  } catch (error) {
    console.error('Error fetching the dora criticality', error);
  }
};

const fetchReintegrationPossibilities = async () => {
  try {
    const response = await ApiService.getReintegrationPossibilities();
    return response;
  } catch (error) {
    console.error('Error fetching the reintegration possibilities', error);
  }
};

const fetchImpactOfDiscontinuingICTServices = async () => {
  try {
    const response = await ApiService.getImpactOfDiscontinuingICTServices();
    return response;
  } catch (error) {
    console.error(
      'Error fetching the impact of discontinuing ICT Services',
      error,
    );
  }
};

const fetchAlternativesExit = async () => {
  try {
    const response = await ApiService.getAlternativesExit();
    return response;
  } catch (error) {
    console.error('Error fetching the alternatives exit', error);
  }
};

const inactivateICTService = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateICTService(dataList);

    const { errors, deleted_ict_services, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach((error: { ict_service_id: number; error: string }) => {
        notification.error({
          message: `Error for ICT Service ID: ${error.ict_service_id}`,
          description: error.error,
          duration: 10,
        });
      });
    } else if (deleted_ict_services && deleted_ict_services.length > 0) {
      notification.success({
        message: 'ICT Service Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate ict service. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate entity');
  }
};

const inactivateICTServiceLevel = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateICTLevel(dataList);

    const { errors, deleted_ict_service_levels, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach(
        (error: { ict_service_level_id: number; error: string }) => {
          if (!error.error.includes('ICTServiceLevel entry not found.')) {
            notification.error({
              message: `Error for ICT service Level ID: ${error.ict_service_level_id}`,
              description: error.error,
              duration: 10,
            });
          }
        },
      );
    } else if (
      deleted_ict_service_levels &&
      deleted_ict_service_levels.length > 0
    ) {
      notification.success({
        message: 'ICT service Level  Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate ICT service Level. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate ICT service Level.');
  }
};

const fetchICTLevels = async () => {
  try {
    const response = await ApiService.getICTLevels();
    return response;
  } catch (error) {
    console.error('Error fetching the ict levels', error);
  }
};

const fetchSupportLevel = async () => {
  try {
    const response = await ApiService.getSupportLevel();
    return response;
  } catch (error) {
    console.error('Error fetching the support level', error);
  }
};

const fetchYesNo = async () => {
  try {
    const response = await ApiService.getYesNo();
    return response;
  } catch (error) {
    console.error('Error fetching the yes/no', error);
  }
};

const fetchICTServicesCriticality = async () => {
  try {
    const response = await ApiService.getICTServicesCriticality();
    return response;
  } catch (error) {
    console.error('Error fetching the ict service criticality', error);
  }
};

const fetchLocalRegulatorCriticality = async () => {
  try {
    const response = await ApiService.getLocalRegulatorCriticality();
    return response;
  } catch (error) {
    console.error('Error fetching the local regulator criticality', error);
  }
};

export default {
  useFetchICTServices,
  fetchFunctionsbyEntityName,
  fetchContractualArrangementsbyEntityName,
  postICTServices,
  fetchICTServiceTypes,
  fetchLevelsOfReliance,
  fetchCriticalityAssessments,
  fetchReintegrationPossibilities,
  fetchImpactOfDiscontinuingICTServices,
  fetchAlternativesExit,
  inactivateICTService,
  inactivateICTServiceLevel,
  fetchICTLevels,
  fetchSupportLevel,
  fetchYesNo,
  fetchICTServicesCriticality,
  fetchLocalRegulatorCriticality,
  fetchDoraCriticality,
  useFetchICTServicesForData,
  useFetchFunctionsForData,
};
