import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, notification } from 'antd';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import useFilters from 'utils/hooks/useFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import { RootState } from 'store/configureStore';
import {
  setSelectedTPP,
  set_isNonSubstReasonAdded,
  set_nonSubstReason,
} from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import { INonSubstReason } from 'store/ducks/DataEntry/ThirdPartyProviders/types';
import * as Styled from '../../DataEntry.styled';
import useTppStatus from 'utils/hooks/useTppStatus';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';

const NonSubstReason: React.FC = () => {
  useTppStatus();
  const dispatch = useDispatch();

  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<INonSubstReason[]>([]);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const { selectedTPP, tpps, nonSubstReason, addRowCountTpp, nameOfTpp } =
    useSelector((state: RootState) => state.tpp);
  const [tppNames, setTppNames] = useState<{ label: string; value: string }[]>(
    [],
  );
  const [selectedTPPID, setSelectedTPPID] = useState<string>('');
  const [combinedData, setCombinedData] = useState<INonSubstReason[]>([]);
  const [initialData, setInitialData] = useState<INonSubstReason[]>([]);
  const [tppName, setTppName] = useState<string>('');
  const isLoggedIn = localStorage.getItem('authToken');

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    setCombinedData([...dataSource, ...nonSubstReason]);
  }, [dataSource, nonSubstReason]);

  const getNonSubstReason = async () => {
    try {
      const response = await tppOp.fetchNonSubstReason(tppName, selectedTPPID);
      const data = response.non_subst_reason;
      const mappedData = data.map((non_subst_reason: any, index: number) => ({
        key: 0,
        id: non_subst_reason.id,
        name: non_subst_reason.name,
        description: non_subst_reason.description,
        isNew: false,
        isEdited: false,
        tpp_name: response.tpp_name,
      }));
      console.log(data, 'data');
      setDataSource(mappedData);
      setInitialData(mappedData);
      setCount(mappedData.length);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const fetchTPPID = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;

      const dataTPP = data.map((item: any, index: number) => ({
        id: item.id,
        third_party_service_provider_name:
          item.third_party_service_provider_name,
        active: item.active,
      }));

      const dataForTable = data.map((item: any) => ({
        label: item?.third_party_service_provider_name || 'Unknown',
        value: item?.id || '',
      }));
      setTppNames(dataForTable);

      const matchedTPP = dataTPP.find(
        (item: INonSubstReason) =>
          item.id.toString() === selectedTPP.toString(),
      );
      if (matchedTPP) {
        setSelectedTPPID(matchedTPP.id);
        setTppName(matchedTPP.third_party_service_provider_name);
        setIsActive(matchedTPP.active);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      if (selectedTPP === '') {
        fetchTPPID();
      } else if (selectedTPP) {
        dispatch(setSelectedTPP(selectedTPP));
      } else if (!selectedTPP) {
        dispatch(setSelectedTPP(''));
        setDataSource([]);
      }
    }
  }, [query.entity]);

  useEffect(() => {
    if (selectedTPP === '' && dataSource.length > 0 && query.entity) {
      setDataSource([]);
      setSelectedTPPID('');
    }
  }, [selectedTPP, dataSource, query.entity]);

  useEffect(() => {
    if (selectedTPP && query.entity) {
      fetchTPPID();
    }
  }, [selectedTPP, query.entity]);

  useEffect(() => {
    if (selectedTPPID && query.entity) {
      getNonSubstReason();
    }
  }, [selectedTPPID, query.entity]);

  // useEffect(() => {
  //   if (addRowCountTpp === 1 && tpps.length > nonSubstReason.length) {
  //     if (nameOfTpp) {
  //       const newRow = {
  //         key: generateUniqueId(),
  //         id: generateUniqueId(),
  //         name: '',
  //         description: '',
  //         tpp_name: nameOfTpp,
  //         isNew: true,
  //         active: true,
  //       };
  //       dispatch(set_nonSubstReason([...nonSubstReason, newRow]));
  //     }

  //   }
  // }, [tpps, query.entity, nameOfTpp]);

  useEffect(() => {
    if (addRowCountTpp === 0) {
      dispatch(set_nonSubstReason([]));
    }
  }, [addRowCountTpp, nonSubstReason.length, dispatch]);

  const handleSave = (row: INonSubstReason) => {
    const isRowInDataSource = dataSource.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = dataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );

      setDataSource(updatedDataSource);
      setCombinedData([...updatedDataSource, ...nonSubstReason]);
    } else {
      const updatedEntityType = nonSubstReason.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setCombinedData([...dataSource, ...updatedEntityType]);
      dispatch(set_nonSubstReason(updatedEntityType));
    }
  };

  const areAllFieldsFilled = (nonSubstReason: INonSubstReason[]) => {
    return nonSubstReason.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };
  useEffect(() => {
    if (addRowCountTpp === 1) {
      const allFieldsFilled = areAllFieldsFilled(nonSubstReason);
      if (allFieldsFilled) {
        dispatch(set_isNonSubstReasonAdded(true));
      } else {
        dispatch(set_isNonSubstReasonAdded(false));
      }
    }
  }, [nonSubstReason, addRowCountTpp]);
  const handleSubmit = async () => {
    if (JSON.stringify(combinedData) === JSON.stringify(initialData)) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited NonSubstReasons to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    // if (!areAllFieldsFilled(combinedData)) {
    //   notification.warning({
    //     message: 'Not submitted',
    //     description: 'Please fill in all the required fields in the data.',
    //     placement: 'topRight',
    //     duration: 20,
    //   });
    //   return;
    // }

    try {
      const newRows = combinedData.filter((row) => row.isNew);
      const updatedRows = combinedData.filter((row) => !row.isNew);

      for (const newRow of newRows) {
        await tppOp.insertNonSubstReason({
          tpp_name: newRow.tpp_name,
          entity_name: query.entity,
          data_list: [newRow],
          token: isLoggedIn,
        });
      }

      if (updatedRows.length > 0) {
        await tppOp.insertNonSubstReason({
          tpp_name: tppName,
          entity_name: query.entity,
          data_list: updatedRows,
          token: isLoggedIn,
        });
      }

      notification.success({
        message: 'Submission Successful',
        description: 'The data has been submitted successfully.',
        placement: 'topRight',
        duration: 20,
      });

      getNonSubstReason();
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: (
        <Styled.TitleComponent>
          Reason where the ICT third- party service provider is considered not
          substitutable or difficult to be substitutable
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the options in the following closed list shall be used:
            1.	The lack of real alternatives, even partial, due to the limited number of ICT third- party service providers active on a specific market, or the market share of the relevant ICT third-party service provider, or the technical complexity or sophistication involved, including in relation to any proprietary technology, or the specific features of the ICT third- party service provider’s organisation or activity;
            2.	Difficulties in relation to a partial or full migration of the relevant data and workloads from the relevant ICT third- party service provider to another ICT third-party service provider or by reintegrating them in the financial entity’s operations, due either to significant financial costs, time or other resources that the migration process may entail, or to an increased ICT risk or other operational risks to which the financial entity might be exposed;
            3.	Both reasons referred to in points 1 and 2.
            (closed set of options)
            (mandatory in case “not substitutable” or “highly complex substitutability” is selected in B_07.01.0041)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'name',
      editable: false,
      width: 500,
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: 'Non Subst Reason Description',
      dataIndex: 'description',
      // editable: true,
      width: 500,
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: (
        <>
          ICT third-party service provider <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'tpp_name',
      editable: false,
      width: 100,
      render: (text: string) => <Input value={text} disabled />,
    },
  ];

  return (
    dataSource && (
      <Styled.StyledWrapper hasEntity={!!query.entity}>
        <CustomTable
          columns={defaultColumns}
          dataSource={combinedData}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        {query.entity && (
          <Styled.AreaButton type="primary" onClick={handleSubmit}>
            Submit
          </Styled.AreaButton>
        )}
      </Styled.StyledWrapper>
    )
  );
};

export default NonSubstReason;
