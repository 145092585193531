import { notification } from 'antd';
import ApiService from 'utils/ApiCalls';

const fetchEntity = async (entityData: any) => {
  try {
    const response = await ApiService.postEntity(entityData);

    notification.success({
      message: response.status,
      description: 'Entity - successfuly saved',
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchEntityType = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postEntityType(entityTypeData);

    notification.success({
      message: response.status,
      description: 'Entity Type- successfuly saved',
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchHierarchyGroup = async (hierarchyGroupData: any) => {
  try {
    const response = await ApiService.postHierarchyGroup(hierarchyGroupData);

    notification.success({
      message: response.status,
      description: 'Hierarchy Group- successfuly saved',
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

const setAllEntities = async () => {
  try {
    const response = await ApiService.getAllEntities();
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchEntityTypeByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postEntityTypeByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Entity Type:', error);
  }
};

const fetchHierarchyGroupByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postHierarchyGroupByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Hierarchy Group:', error);
  }
};

const fetchNatureOfEntityByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postNatureOfEntityByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Nature of Entity:', error);
  }
};

const fetchCompetentAuthorityByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postCompetentAuthorityByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Competent Authority:', error);
  }
};

const fetchCountryByEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postCountryByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Country:', error);
  }
};

const fetchValueOfTotalAssetsByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postValueOfTotalAssetsByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Value of Total Assets:', error);
  }
};

const fetchActivitiesByEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postActivitiesByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Activities:', error);
  }
};

const fetchEntitiesByEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postEntitiesByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Entities:', error);
  }
};

const fetchNatureOfEntity = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postNatureOfEntity(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Nature of Entity:', error);
  }
};

const fetchCompetentAuthority = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postCompetentAuthority(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Competent Authority:', error);
  }
};

const fetchCountry = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postCountry(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Country:', error);
  }
};

const fetchValueOfTotalAssets = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postValueOfTotalAssets(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Value of Total Assets:', error);
  }
};

const fetchActivitiesByTypeEntity = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postActivitiesByTypeEntity(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching Activities:', error);
  }
};

export default {
  fetchEntity,
  fetchEntityType,
  fetchHierarchyGroup,
  setAllEntities,
  fetchEntityTypeByEntityName,
  fetchHierarchyGroupByEntityName,
  fetchNatureOfEntityByEntityName,
  fetchCompetentAuthorityByEntityName,
  fetchCountryByEntityName,
  fetchValueOfTotalAssetsByEntityName,
  fetchActivitiesByEntityName,
  fetchEntitiesByEntityName,
  fetchNatureOfEntity,
  fetchCompetentAuthority,
  fetchCountry,
  fetchValueOfTotalAssets,
  fetchActivitiesByTypeEntity,
};
