import React from 'react';
import BackgroundImage from 'assets/img/image.png';
import * as Styled from './AuthLayout.styled';

interface IAuthLayoutProps {
  children: JSX.Element;
}

const AuthLayout: React.FC<IAuthLayoutProps> = ({ children }) => (
  <Styled.StyledContainer>
    {/* <Styled.BgContainer > */}
    {/* <Styled.BgImage src={BackgroundImage} /> */}
    <Styled.FormContainer>{children}</Styled.FormContainer>
    {/* </Styled.BgContainer> */}
  </Styled.StyledContainer>
);

export default AuthLayout;
