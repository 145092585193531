import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';
import ReactBrightLogo from 'assets/img/Outlook-olknqf1c.svg';

export const StyledNav = styled('div')<IToken>`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => `0px ${props.token.paddingLG}px`};
`;

export const StyledLogo = styled.img`
  width: 136px;
  position: relative;
  top: -23px;
  left: -27px;
`;

export const logoSrc = ReactBrightLogo;

export const DropdownContainer = styled('div')`
  margin-top: 20px;
  display: flex;
  gap: 15px;
`;
