import React, { useState, useEffect } from 'react';
import useBasicFilters from 'utils/hooks/useBasicFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { notification, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import {
  EditableColumnType,
  DataType,
} from 'components/CustomTable/CustomTable';
import { setProviderNames } from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import * as Styled from '../../DataEntry.styled';

interface ITPP {
  key: number;
  id: number;
  ict_service_provider_identification_code: string;
  ict_service_provider_lei: string;
  notice_period: number;
  identification_code_type: string;
  substitutability: boolean;
  non_subst_reason_id: number;
  third_party_service_provider_name: string;
  person_type_id: number;
  headquarters_country: string;
  entity_nature: string;
  ultimate_parent_identification_code: string;
  last_audit_date: string;
  description: string;
  entity_id: number;
  isNew?: boolean;
  isEdited?: boolean;
}

const ThirdPartyServiceProvider: React.FC = () => {
  const dispatch = useDispatch();
  const { query } = useBasicFilters();
  const { selectedTPP } = useSelector((state: RootState) => state.tpp);
  const [dataSource, setDataSource] = useState<ITPP[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<ITPP[]>([]);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const fetchTPPbyEntity = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;

      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        ict_service_provider_identification_code:
          item.ict_service_provider_identification_code,
        id: item.id,
        ict_service_provider_lei: item.ict_service_provider_lei,
        notice_period: item.notice_period,
        identification_code_type: item.identification_code_type,
        substitutability: item.substitutability,
        non_subst_reason_id: item.non_subst_reason_id,
        third_party_service_provider_name:
          item.third_party_service_provider_name,
        person_type_id: item.person_type_id,
        headquarters_country: item.headquarters_country,
        entity_nature: item.entity_nature,
        ultimate_parent_identification_code:
          item.ultimate_parent_identification_code,
        last_audit_date: item.last_audit_date,
        description: item.description,
        entity_id: item.entity_id,
        isNew: false,
        isEdited: false,
      }));

      setDataSource(mappedData);
      console.log(selectedTPP, 'selectedTPP');
      setCount(mappedData.length);
      const providerNames = mappedData.map(
        (item: { third_party_service_provider_name: any }) =>
          item.third_party_service_provider_name,
      );
      dispatch(setProviderNames(providerNames));
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      setDataSource([]);
      fetchTPPbyEntity();
    }
  }, [query.entity]);

  useEffect(() => {
    if (selectedTPP === 'All') {
      setFilteredDataSource(dataSource);
    } else {
      const filteredData = dataSource.filter(
        (item: ITPP) => item.id.toString() === selectedTPP.toString(),
      );
      setFilteredDataSource(filteredData);
    }
  }, [dataSource, selectedTPP]);

  const handleDateChange = (key: number, field: string, dateString: string) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        [field]: dateString,
        isEdited: !item.isNew,
      });
      setDataSource(newData);
    }
  };

  const handleAddRow = (record?: any, index?: number) => {
    const maxId = dataSource.reduce(
      (max, item) => (item.id > max ? item.id : max),
      0,
    );
    const newId = maxId + 1;

    const newData: ITPP = {
      key: newId,
      id: newId,
      ict_service_provider_identification_code: '',
      ict_service_provider_lei: '',
      notice_period: 1,
      identification_code_type: '',
      substitutability: false,
      non_subst_reason_id: 1,
      third_party_service_provider_name: '',
      person_type_id: 1,
      headquarters_country: '',
      entity_nature: '',
      ultimate_parent_identification_code: '',
      last_audit_date: '',
      description: '',
      entity_id: 1,
      isNew: true,
    };

    const insertIndex = dataSource.findIndex(
      (item) => item.key === record?.key,
    );

    const updatedDataSource = [
      ...dataSource.slice(0, insertIndex + 1),
      newData,
      ...dataSource.slice(insertIndex + 1),
    ];

    setDataSource(updatedDataSource);
    setCount(count + 1);
  };

  const handleSave = (row: ITPP) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const handleSubmit = async () => {
    const changedData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );

    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited TPPs to submit.',
        placement: 'topRight',
      });
      return;
    }
    try {
      const response = await tppOp.fetchTPP({
        entity_name: query.entity,
        data_list: changedData,
      });
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      editable: false,
    },
    {
      title: 'ICT Service Provider Identification Code',
      dataIndex: 'ict_service_provider_identification_code',
      editable: true,
    },
    {
      title: 'ICT Service Provider LEI',
      dataIndex: 'ict_service_provider_lei',
      editable: true,
    },
    {
      title: 'Notice Period',
      dataIndex: 'notice_period',
      editable: true,
    },
    {
      title: 'Identification code type',
      dataIndex: 'identification_code_type',
      editable: true,
    },
    {
      title: 'Substitutability',
      dataIndex: 'substitutability',
      editable: true,
    },
    {
      title: 'Non substitutability reason id',
      dataIndex: 'non_subst_reason_id',
      editable: false,
    },
    {
      title: 'Third Party Service Provider Name',
      dataIndex: 'third_party_service_provider_name',
      editable: true,
      fixed: 'left',
    },
    {
      title: 'Person Type ID',
      dataIndex: 'person_type_id',
      editable: false,
    },
    {
      title: 'headquarters Country',
      dataIndex: 'headquarters_country',
      editable: true,
    },
    {
      title: 'Entity Nature',
      dataIndex: 'entity_nature',
      editable: true,
    },
    {
      title: 'Ultimate Parent Identification Code',
      dataIndex: 'ultimate_parent_identification_code',
      editable: true,
    },
    {
      title: 'Last Audit Date',
      dataIndex: 'last_audit_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                handleDateChange(
                  record.key,
                  'last_audit_date',
                  formattedDateString,
                );
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: 'Entity ID',
      dataIndex: 'entity_id',
      editable: false,
    },
    {
      title: 'Create',
      dataIndex: 'add_row',
      key: 'add_row',
      width: '10px',
      fixed: 'right',
      render: (_: any, record: { key: React.Key }, index: number) => (
        <Styled.OperationsRow onClick={() => handleAddRow(record, index)}>
          <PlusOutlined />
        </Styled.OperationsRow>
      ),
    },
  ];

  return (
    dataSource && (
      <div>
        <Styled.AreaButton onClick={handleAddRow} type="primary">
          Add a row
        </Styled.AreaButton>
        <CustomTable
          columns={defaultColumns}
          dataSource={filteredDataSource}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      </div>
    )
  );
};

export default ThirdPartyServiceProvider;
