import React, { useState, useEffect } from 'react';
import { DatePicker, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import useFilters from 'utils/hooks/useFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import dayjs from 'dayjs';
import {
  EditableColumnType,
  DataType,
} from 'components/CustomTable/CustomTable';
import { IEntityTitle } from 'store/ducks/DataEntry/Entities/types';
import * as Styled from '../../DataEntry.styled';

const Entity: React.FC = () => {
  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<IEntityTitle[]>([]);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  let uniqueIdCounter = 0;
  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now();
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId = Number(`${timestampPart}${uniqueIdCounter}${randomPart}`);
    return uniqueId;
  };

  const fetchEntity = async () => {
    try {
      const response = await entityOp.fetchEntitiesByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data;
      const mappedData = [
        {
          key: 0,
          id: data.entity_id,
          lei_entity_maintaining_register_information:
            data.lei_entity_maintaining_register_information || '',
          name_of_entity: data.entity_name || '',
          entity_type_id: data.entity_type_id || 0,
          lei_of_entity: data.lei_of_entity || '',
          hierarchy_in_group_id: data.hierarchy_in_group_id || 0,
          parent_lei: data.parent_lei || '',
          lei_entity_making_use_ICT_service:
            data.lei_entity_making_use_ICT_service || '',
          lei_entity_signing_contractual_arrangement:
            data.lei_entity_signing_contractual_arrangement || '',
          nature_id: data.nature_id || 0,
          integration_date: data.integration_date || '',
          deletion_date: data.deletion_date || '',
          total_assets: data.total_assets || 0,
          lei_financial_entity: data.lei_financial_entity || '',
          description: data.description || '',
          competent_authority_id: data.competent_authority_id || 0,
          country_id: data.country_id || 0,
          date_of_last_update: data.date_of_last_update,
          end_date: data.end_date,
          reporting_date: data.reporting_date,
          start_date: data.start_date,
          currency_id: data.currency_id,
          isNew: false,
          isEdited: false,
        },
      ];

      setDataSource(mappedData);
      setCount(mappedData.length);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchEntity();
    }
  }, [query]);

  const handleAddRow = (record?: any, index?: number) => {
    const maxId = dataSource.reduce(
      (max, item) => (item.id > max ? item.id : max),
      0,
    );
    const newId = maxId + 1;

    const newData: IEntityTitle = {
      key: newId,
      id: newId,
      lei_entity_maintaining_register_information: '',
      name_of_entity: '',
      entity_type_id: generateUniqueId(),
      lei_of_entity: '',
      hierarchy_in_group_id: generateUniqueId(),
      parent_lei: '',
      lei_entity_making_use_ICT_service: '',
      lei_entity_signing_contractual_arrangement: '',
      nature_id: generateUniqueId(),
      integration_date: '',
      deletion_date: '',
      total_assets: '',
      lei_financial_entity: '',
      description: '',
      competent_authority_id: generateUniqueId(),
      country_id: generateUniqueId(),
      date_of_last_update: '',
      end_date: '',
      reporting_date: '',
      start_date: '',
      currency_id: generateUniqueId(),
      isNew: true,
    };

    const insertIndex = dataSource.findIndex(
      (item) => item.key === record?.key,
    );

    const updatedDataSource = [
      ...dataSource.slice(0, insertIndex + 1),
      newData,
      ...dataSource.slice(insertIndex + 1),
    ];

    setDataSource(updatedDataSource);
    setCount(count + 1);
  };

  const handleSave = (row: IEntityTitle) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      total_assets: Number(row.total_assets),
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const handleDateChange = (key: number, field: string, dateString: string) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        [field]: dateString,
        isEdited: !item.isNew,
      });
      setDataSource(newData);
    }
  };

  const handleSubmit = async () => {
    const filteredData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );

    if (filteredData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited entities to submit.',
        placement: 'topRight',
      });
      return;
    }

    try {
      const formattedDataSource = filteredData.map((item) => ({
        ...item,
        integration_date: item.integration_date ? item.integration_date : null,
        deletion_date: item.deletion_date ? item.deletion_date : null,
      }));

      const response = await entityOp.fetchEntity(formattedDataSource);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: (
        <>
          LEI Entity Maintaining Register Information{' '}
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'lei_entity_maintaining_register_information',
      editable: true,
    },
    {
      title: (
        <>
          Entity name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'name_of_entity',
      editable: true,
      fixed: 'left',
    },
    {
      title: (
        <>
          LEI of Entity <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'lei_of_entity',
      editable: true,
    },
    {
      title: (
        <>
          Parent LEI <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'parent_lei',
      editable: true,
    },
    {
      title: (
        <>
          LEI Entity Making Use of ICT Service{' '}
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'lei_entity_making_use_ICT_service',
      editable: true,
    },
    {
      title: (
        <>
          LEI Entity Signing Contractual Arrangement{' '}
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'lei_entity_signing_contractual_arrangement',
      editable: true,
    },
    {
      title: (
        <>
          Integration date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'integration_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                handleDateChange(
                  record.key,
                  'integration_date',
                  formattedDateString,
                );
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Deletion date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'deletion_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = dayjs(date).format('YYYY-MM-DD');
              handleDateChange(
                record.key,
                'deletion_date',
                formattedDateString,
              );
            }}
          />
        );
      },
    },
    {
      title: (
        <>
          Total Assets <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'total_assets',
      editable: true,
    },
    {
      title: (
        <>
          LEI Financial Entity <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'lei_financial_entity',
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: (
        <>
          Date of last update <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'date_of_last_update',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = dayjs(date).format('YYYY-MM-DD');
              handleDateChange(
                record.key,
                'date_of_last_update',
                formattedDateString,
              );
            }}
          />
        );
      },
    },
    {
      title: (
        <>
          End date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'end_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = dayjs(date).format('YYYY-MM-DD');
              handleDateChange(record.key, 'end_date', formattedDateString);
            }}
          />
        );
      },
    },
    {
      title: (
        <>
          Start date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'start_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = dayjs(date).format('YYYY-MM-DD');
              handleDateChange(record.key, 'start_date', formattedDateString);
            }}
          />
        );
      },
    },
    {
      title: (
        <>
          Reporting date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'reporting_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = dayjs(date).format('YYYY-MM-DD');
              handleDateChange(
                record.key,
                'reporting_date',
                formattedDateString,
              );
            }}
          />
        );
      },
    },
    {
      title: 'Create',
      dataIndex: 'add_row',
      key: 'add_row',
      width: '10px',
      fixed: 'right',
      render: (_: any, record: { key: React.Key }, index: number) => (
        <Styled.OperationsRow onClick={() => handleAddRow(record, index)}>
          <PlusOutlined />
        </Styled.OperationsRow>
      ),
    },
  ];

  return (
    dataSource && (
      <div>
       {query.entity && (
          <Styled.AreaButton type="primary" onClick={handleAddRow}>
            Add Row
          </Styled.AreaButton>
        )}
        <CustomTable
          columns={defaultColumns}
          dataSource={dataSource}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        {query.entity && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
        )}
        
      </div>
    )
  );
};

export default Entity;
