import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import CustomTable from 'components/CustomTable/CustomTable';
import useBasicFilters from 'utils/hooks/useBasicFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import * as Styled from '../../DataEntry.styled';

interface IValueOfTotalAssetsDataType {
  key: number;
  id: number;
  value: string;
  entity_id: number;
  description: string;
  branch_id: number;
  entity_name: string;
}

const ValueOfTotalAssets: React.FC = () => {
  const { query } = useBasicFilters();
  const [dataSource, setDataSource] = useState<IValueOfTotalAssetsDataType[]>(
    [],
  );
  const [initialData, setInitialData] = useState<IValueOfTotalAssetsDataType[]>(
    [],
  );

  const handleSave = (row: IValueOfTotalAssetsDataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const fetchValueOfTotalAssets = async () => {
    try {
      const response = await entityOp.fetchValueOfTotalAssetsByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.value_of_total_assets;

      const mappedData = [
        {
          key: 0,
          id: data?.id,
          value: data?.value || '',
          entity_id: data?.entity_id || '',
          description: data?.description || '',
          branch_id: data?.branch_id || '',
          entity_name: response?.data?.entity_name,
        },
      ];

      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchValueOfTotalAssets();
    }
  }, [query]);

  const handleSubmit = async () => {
    if (JSON.stringify(dataSource) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
      });
      return;
    }
    try {
      const payload = {
        entity_name: query.entity,
        data_list: [...dataSource],
      };
      const response = await entityOp.fetchValueOfTotalAssets(payload);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      editable: true,
    },
    {
      title: 'Entity ID',
      dataIndex: 'entity_id',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: 'Branch id',
      dataIndex: 'branch_id',
    },
    {
      title: 'Entity name',
      dataIndex: 'entity_name',
    },
  ];

  return (
    <>
      <Styled.AreaButton type="primary" disabled>
        Add a row
      </Styled.AreaButton>
      <CustomTable
        columns={columns}
        dataSource={dataSource as IValueOfTotalAssetsDataType[]}
        handleSave={handleSave}
      />
      <Styled.AreaButton type="primary" onClick={handleSubmit}>
        Submit
      </Styled.AreaButton>
    </>
  );
};

export default ValueOfTotalAssets;
