import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { Input, notification } from 'antd';
import CustomTable from 'components/CustomTable/CustomTable';
import useFilters from 'utils/hooks/useFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { ICompetentAuthorityDataType } from 'store/ducks/DataEntry/Entities/types';
import {
  set_competentAuthority,
  set_isCompetentAuthorityAdded,
} from 'store/ducks/DataEntry/Entities/entitySlice';
import useEntityStatus from 'utils/hooks/useEntityStatus';
import * as Styled from '../../DataEntry.styled';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';

const CompetentAuthority: React.FC = () => {
  useEntityStatus();
  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<ICompetentAuthorityDataType[]>(
    [],
  );
  const [initialData, setInitialData] = useState<ICompetentAuthorityDataType[]>(
    [],
  );
  const [combinedData, setCombinedData] = useState<
    ICompetentAuthorityDataType[]
  >([]);
  const { competentAuthority, addRowCount, entities, nameOfEntity } =
    useSelector((state: RootState) => state.entities);
  const isLoggedIn = localStorage.getItem('authToken');
  const dispatch = useDispatch();

  useEffect(() => {
    setCombinedData([...competentAuthority, ...dataSource]);
  }, [dataSource, competentAuthority]);

  const handleSave = (row: ICompetentAuthorityDataType) => {
    const isRowInDataSource = dataSource.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = dataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );

      setDataSource(updatedDataSource);
      setCombinedData([...updatedDataSource, ...competentAuthority]);
    } else {
      const updatedEntityType = competentAuthority.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setCombinedData([...dataSource, ...updatedEntityType]);
      dispatch(set_competentAuthority(updatedEntityType));
    }
  };

  const fetchCompetentAuthority = async () => {
    try {
      const response = await entityOp.fetchCompetentAuthorityByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.competent_authority;

      const mappedData = [
        {
          key: 0,
          id: data?.id,
          name: data?.name || '',
          description: data?.description || '',
          entity_name: response?.data?.entity_name || query.entity,
        },
      ];

      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchCompetentAuthority();
    }
  }, [query]);

  useEffect(() => {
    if (addRowCount === 0) {
      dispatch(set_competentAuthority([]));
    }
  }, [addRowCount, competentAuthority.length, dispatch]);

  const areAllFieldsFilled = (
    competentAuthority: ICompetentAuthorityDataType[],
  ) => {
    return competentAuthority.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  useEffect(() => {
    if (addRowCount === 1) {
      const allFieldsFilled = areAllFieldsFilled(competentAuthority);
      if (allFieldsFilled) {
        dispatch(set_isCompetentAuthorityAdded(true));
      } else {
        dispatch(set_isCompetentAuthorityAdded(false));
      }
    }
  }, [competentAuthority, addRowCount]);

  const handleSubmitAuthority = async () => {
    if (JSON.stringify(combinedData) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (competentAuthority.length !== 0) {
      const allFieldsFilled = areAllFieldsFilled(competentAuthority);
      if (!allFieldsFilled) {
        notification.warning({
          message: 'Not submitted',
          description:
            'Please fill in all the required fields in Competent Authority.',
          placement: 'topRight',
          duration: 20,
        });
        return;
      } else {
        dispatch(set_isCompetentAuthorityAdded(true));
      }
    }

    try {
      const payload = {
        entity_name: query.entity,
        data_list: [...combinedData],
        token: isLoggedIn,
      };
      const response = await entityOp.fetchCompetentAuthority(payload);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns = [
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Competent Authority</p>
            <p>B_01.01.0050</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the competent authority referred to in Article 46 of Regulation (EU) 2022/2554 to which the register of information is reported. (alphanumerical) (mandatory in case of reporting)."
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie die in Artikel 46 der Verordnung
            (EU) 2022/2554 genannte zuständige Behörde an, der
            das Informationsregister gemeldet wird"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'name',
      width: '30%',
      editable: false,
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: ' Competent Authority Description',
      dataIndex: 'description',
      editable: false,
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: (
        <>
          Entity Name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'entity_name',
      editable: false,
      render: (text: string) => <Input value={text} disabled />,
    },
  ];

  return (
    <Styled.StyledWrapper
      hasEntity={!!query.entity}
      style={{ marginTop: '64px' }}
    >
      <CustomTable
        columns={columns}
        dataSource={combinedData}
        handleSave={handleSave}
      />
      <Styled.AreaButton type="primary" onClick={handleSubmitAuthority}>
        Submit
      </Styled.AreaButton>
    </Styled.StyledWrapper>
  );
};

export default CompetentAuthority;
