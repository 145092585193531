import React from 'react';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { IBranch, DataType } from 'store/ducks/DataEntry/Branch/types';
import i18nIsoCountries from 'i18n-iso-countries';
import CustomSelect from 'components/CustomSelect';
import { PlusOutlined } from '@ant-design/icons';
import { DatePicker, Button, Input } from 'antd';
import dayjs from 'dayjs';
import * as Styled from '../Tables/DataEntry/DataEntry.styled';

export const getDefaultColumns = (
  handleDateChange: (key: number, field: string, value: string) => void,
  handleSave: (record: IBranch) => void,
  handleAddRow: (record: { key: React.Key }, index: number) => void,
  handleRemoveRow: (key: number) => void,
  handleInactivate: (record: IBranch) => void,
  countries: { value: string; label: string }[],
): EditableColumnType[] => [
  {
    title: (
      <Styled.TitleComponent>
        <div>
          <p>Name of the branch</p>
          <p>B_01.03.0030</p>
        </div>
        <Styled.Asterisx>*</Styled.Asterisx>
        <AdditionalInformation
          color="red"
          description="Identify the name of the branch (alphanumerical)"
        />
        <AdditionalInformation
          color="#FFCD0B"
          description="Geben Sie den Namen der Zweigniederlassung an."
        />
      </Styled.TitleComponent>
    ),
    dataIndex: 'branch_name',
    editable: true,
    fixed: 'left',
    className: 'highlighted-column',
  },
  {
    title: (
      <Styled.TitleComponent>
        <div>
          <p>LEI of the financial entity head office of the branch</p>
          <p>B_01.03.0020</p>
        </div>
        <Styled.Asterisx>*</Styled.Asterisx>
        <AdditionalInformation
          color="red"
          description="As reported in B_01.02.0010
Identify the financial entity head office of the branch, using the LEI, 20-character, alpha- numeric code based on the ISO 17442 standard
(alphanumerical)"
        />
        <AdditionalInformation
          color="#FFCD0B"
          description="Wie in B_01.02.0010 angegeben
          Geben Sie anhand des 20-stelligen alphanumerischen LEI-Codes gemäß der Norm
          ISO 17442 den Hauptsitz des Finanzunternehmens der Zweigniederlassung an."
        />
      </Styled.TitleComponent>
    ),
    dataIndex: 'head_office_lei',
    editable: true,
    className: 'highlighted-column',
  },
  {
    title: (
      <Styled.TitleComponent>
        <div>
          <p>Identification code of the branch</p>
          <p>B_04.01.0040</p>
        </div>
        <Styled.Asterisx>*</Styled.Asterisx>
        <AdditionalInformation
          color="red"
          description="Identify a branch of a financial entity located outside its home country using a unique code for each branch. One of the options in the following closed list shall be used:
            (a)	LEI of the branch if unique for this branch and different from B_01.03.0020;
            (b)	other identification code used by the financial entity to identify the branch (where the LEI of the branch is equivalent to the one in template B_01.03.0020 or equivalent to the LEI of another branch).
            (alphanumerical)"
        />
        <AdditionalInformation
          color="#FFCD0B"
          description="Geben Sie für jede Zweigniederlassung eines Finanzunternehmens, die sich außerhalb
          seines Sitzlandes befindet, einen eindeutigen Code an. Aus der folgenden
          erschöpfenden Liste ist eine Option auszuwählen:
          a) LEI der Zweigniederlassung, wenn sie nur für diese Zweigniederlassung gilt und
          sich von der Angabe in B_01.03.0020 unterscheidet,
          b) ein anderer Identifikationscode, der von dem Finanzunternehmen zur
          Identifizierung der Zweigniederlassung verwendet wird (wenn die LEI der
          Zweigniederlassung derjenigen in Vorlage B_01.03.0020 oder der LEI einer
          anderen Zweigniederlassung entspricht)."
        />
      </Styled.TitleComponent>
    ),
    dataIndex: 'branch_identification_code',
    editable: true,
    className: 'highlighted-column',
  },
  {
    title: (
      <>
        Start Date <Styled.Asterisx>*</Styled.Asterisx>
      </>
    ),
    dataIndex: 'start_date',
    editable: false,
    render: (text: string, record: DataType) => {
      return (
        <div style={{ position: 'relative' }}>
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = date
                ? dayjs(date).format('YYYY-MM-DD')
                : '';
              handleDateChange(record.key, 'start_date', formattedDateString);
            }}
          />
        </div>
      );
    },
  },
  {
    title: (
      <>
        End Date <Styled.Asterisx>*</Styled.Asterisx>
      </>
    ),
    dataIndex: 'end_date',
    editable: false,
    render: (text: string, record: DataType) => {
      return (
        <div style={{ position: 'relative' }}>
          <DatePicker
            value={text ? dayjs(text) : dayjs('9999-01-01')}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = date
                ? dayjs(date).format('YYYY-MM-DD')
                : '9999-01-01';
              handleDateChange(record.key, 'end_date', formattedDateString);
            }}
          />
        </div>
      );
    },
  },
  {
    title: 'Branch description',
    dataIndex: 'description_branch',
    editable: false,
    render: (text: string) => <Input value={text} disabled />,
  },
  {
    title: (
      <Styled.TitleComponent>
        <div>
          <p>Country of the branch</p>
          <p>B_01.03.0040</p>
        </div>
        <Styled.Asterisx>*</Styled.Asterisx>
        <AdditionalInformation
          color="red"
          description="Identify the ISO 3166–1 alpha–2 code of the country where the branch is located (country)"
        />
        <AdditionalInformation
          color="#FFCD0B"
          description="Geben Sie den Code des Landes, in dem die Zweigniederlassung ansässig ist, gemäß ISO
          3166-1 Alpha-2 an."
        />
      </Styled.TitleComponent>
    ),
    dataIndex: 'id_country',
    editable: false,

    render: (_, record) => (
      <CustomSelect
        options={countries}
        value={record.id_country}
        onChange={(value: any) => {
          const selectedCountry = countries.find(
            (country) => country.value === value,
          );

          if (selectedCountry) {
            const countryName = selectedCountry.label;

            const isoCode = i18nIsoCountries.getAlpha2Code(countryName, 'en');

            handleSave({
              ...record,
              id_country: value,
              country_name: selectedCountry?.label || '',
              iso_code: isoCode || '',
            } as IBranch);
          }
        }}
        filterOption={(input: string, option: { label: string }) =>
          option?.label?.toLowerCase().includes(input.toLowerCase())
        }
      />
    ),
  },
  {
    title: <>Iso code</>,
    dataIndex: 'iso_code',
    editable: true,
    className: 'highlighted-column',
    render: (text: string) => <Input value={text} disabled />,
  },
  {
    title: 'Country description',
    dataIndex: 'country_description',
    editable: false,
    render: (text: string) => <Input value={text} disabled />,
  },
  {
    title: 'Create',
    dataIndex: 'add_row',
    key: 'add_row',
    width: '10px',
    fixed: 'right',
    render: (_: any, record: { key: React.Key }, index: number) => (
      <Styled.OperationsRow onClick={() => handleAddRow(record, index)}>
        <PlusOutlined />
      </Styled.OperationsRow>
    ),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (_: any, record) => (
      <>
        {record.isNew ? (
          <Button danger onClick={() => handleRemoveRow(record.key)}>
            Remove
          </Button>
        ) : (
          <Button danger onClick={() => handleInactivate(record as IBranch)}>
            Soft delete
          </Button>
        )}
      </>
    ),
  },
];
