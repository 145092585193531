import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification, Table, Input, Button, Modal } from 'antd';
import {
  PlusOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import {
  ICertificate,
  IICTServices,
  EditedData,
} from 'store/ducks/DataEntry/ICTServices/types';
import useICTSystems from 'utils/hooks/fetchHooks/useICTSystems';
import useCountries from 'utils/hooks/fetchHooks/useCountries';
import useFilters from 'utils/hooks/useFilters';
import DynamicField from 'components/DynamicCalendarField/DynamicField';
import CustomSelect from 'components/CustomSelect';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { generateUniqueId } from 'utils/hooks/generateUniqueId';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import { setSelecedEntity_ICT } from 'store/ducks/DataEntry/ICTServices/ICTServiceSlice';
import * as Styled from '../../DataEntry.styled';

const ICTServices: React.FC = () => {
  const dispatch = useDispatch();
  const { query } = useFilters();

  const [data, setData] = useState<IICTServices[]>([]);
  const [editedData, setEditedData] = useState<EditedData>({});
  const [changedData, setChangedData] = useState<Record<string, any>[]>([]);
  const [ictServicesTypes, setICTServicesType] = useState([]);
  const [levelsOfReliance, setLevelsOfReliance] = useState([]);
  const [criticalityAssessments, setCriticalityAssessments] = useState([]);
  const [localRegulatorCriticality, setLocalRegulatorCriticality] = useState(
    [],
  );
  const {ictSystems } = useICTSystems(query.entityId ?? "", query.entity ?? "");
  const [ictLevel, setICTLevel] = useState([]);
  const { countries } = useCountries();
  const [possibility, setPossibility] = useState([]);
  const [yesNo, setYesNo] = useState<{ label: string; value: string }[]>([]);

  const [functionNames, setFunctionNames] = useState<
    { label: string; value: string }[]
  >([]);

  const { confirm } = Modal;
  const [impact, setImpact] = useState([]);
  const [alternatives, setAlternatives] = useState([]);
  const isLoggedIn = localStorage.getItem('authToken');
  const [isTooltipVisible, setIsTooltipVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const {
    selectedContractForICT,
    selectedEntityForICT,
  } = useSelector((state: any) => state.ictServices);

  const fetchICT = ict_serviceOp.useFetchICTServices();

  useEffect(() => {
    if (!selectedEntityForICT) {
      const selectedEntity = {
        entityId: query.entityId ? Number(query.entityId) : 0,
        entity: query.entity ? String(query.entity) : '',
      };
      dispatch(setSelecedEntity_ICT(selectedEntity));
    }
  }, [query]);

  const fetchICTServices = async () => {
    try {
      const response = await fetchICT();
      const data = response?.data['ICT services'];
      if (data.length === 0) {
        notification.warning({
          message: 'Warning',
          description: response.data.message,
          placement: 'topRight',
          duration: 20,
        });
      }

      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        alternatives_exit_id: item.alternatives_exit.id,
        alternatives_exit_alternative_providers_identified:
          item.alternatives_exit.alternative_providers_identified,
        alternatives_exit_exit_plan_exists: item.exit_plan_exists?.id,
        alternatives_exit_reintegration_possible:
          item.possibility_of_reintegration?.description,
        reintegration_possible_id: item.possibility_of_reintegration?.id,
        alternatives_exit_alternative_ict_tpp_identification:
          item.alternatives_exit.alternative_ict_tpp_identification,
        alternatives_exit_description: item.alternatives_exit.description,
        ictServiceType_id: item.ict_service_type.id,
        ictServiceType_name: item.ict_service_type.name,
        ictServiceType_description: item.ict_service_type.description,
        impactOfDiscontinuingIctServices_id:
          item.impact_of_discontinuing_services.id,
        impactOfDiscontinuingIctServices_description:
          item.impact_of_discontinuing_services.description,
        levelOfRelianceICTService_id: item.level_of_reliance.id,
        levelOfRelianceICTService_name: item.level_of_reliance.name,
        levelOfRelianceICTService_description:
          item.level_of_reliance.description,
        levelofSupportingICTService_id:
          item.level_of_supporting_ict_service?.id,
        levelofSupportingICTService_name:
          item.level_of_supporting_ict_service?.name,
        criticality_assessment_id: item.ict_services_criticality?.id || '',
        criticality_assessment_name: item.ict_services_criticality?.name || '',
        criticality_assessment_description:
          item.ict_services_criticality?.description || '',
        critically_assessment_local_regulator_criticality:
          item.local_regulator_criticality?.name,
        critically_assessment_local_regulator_criticality_id:
          item.local_regulator_criticality?.id,
        ictService_id: item.ict_service_id,
        ictService_ict_service_type_id: item.ict_service_type.id,
        ictService_contractual_arrangement_id: item.contractual_arrangements.id,
        ictService_contractual_arrangement_reference_number:
          item.contractual_arrangements.arrangement_reference_number,
        ictService_description: item.ict_service_description,
        ictService_storage_of_data_id: item.storage_of_data.id,
        ictService_function_id: item.function ? item.function.id : '',
        ictService_country_id: item.country.id,
        ictService_impact_of_discontinuing_ict_services_id:
          item.impact_of_discontinuing_services.id,
        ictService_alternatives_exit_id: item.alternatives_exit.id,
        ictService_level_of_reliance_id: item.level_of_reliance.id,
        ictService_start_date: item.start_date,
        ictService_end_date: item.end_date,
        ict_systems: item.ict_systems
          ? item.ict_systems.map((ict_system: any) => ({
              ict_systems_id: ict_system.id,
              ict_systems_name: ict_system.name,
            }))
          : [],
        certificates: item.certificates
          ? item.certificates.map((certificate: any) => ({
              certificates_id: certificate.id,
              certificates_description: certificate.description,
              certificates_validity: certificate.validity,
              certificates_date_of_issuance: certificate.date_of_issuance,
              certificates_registry: certificate.certificate_registry,
              certificates_date_of_certificate_register:
                certificate.date_of_certificate_register,
              certificates_ict_service_id: item.ictService_id,
              certificates_end_date: certificate.end_date,
              certificates_start_date: certificate.start_date,
            }))
          : [],
        ict_levels: item.ict_service_level
          ? item.ict_service_level.map((level: any) => ({
              ict_level_id: level.id ?? '',
              ict_level_name: level.name,
              ict_level_description: level.description,
            }))
          : [],
        ictService_ict_service_level_id:
          item.ict_service_level?.[0]?.id?.toString() || '',
        ictServiceLevel_id:
          item.ict_levels?.length > 0 ? item.ict_levels[0].id : '',
        ictServiceLevel_description:
          item.ict_levels?.length > 0 ? item.ict_levels[0].description : '',
        ictServiceLevel_name:
          item.ict_levels?.length > 0 ? item.ict_levels[0].name : '',
      }));
      setData(mappedData);
    } catch (error) {
      console.log(error, 'error');
      setData([]);
    }
  };

  const fetchYesNo = async () => {
    try {
      const response = await ict_serviceOp.fetchYesNo();
      if (response?.data.yes_no_statuses) {
        const formattedOptions = response.data.yes_no_statuses.map(
          (reasons: { description: any; id: any }) => ({
            label: reasons.description,
            value: reasons.id,
          }),
        );
        setYesNo(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchICT();
        const timeout = setTimeout(() => {
          if (
            response?.data?.message ===
            'ICT services for the specified criteria do not exist.'
          ) {
            notification.error({
              message: 'No ICT Services',
              description: response?.data.message,
              duration: 20,
            });
          }
        }, 500);
        if (
          response?.data?.message ===
          'ICT services for the specified criteria do not exist.'
        ) {
          setData([]);
          clearTimeout(timeout);
          return;
        }
        await Promise.all([
          fetchICTServices(),
          fetchYesNo(),
          fetchICTServiceTypes(),
          fetchLevelsOfReliance(),
          fetchCriticalityAssessments(),
          fetchLocalRegulatorCriticality(),
          fetchICTLevels(),
          fetchReintegrationPossibilities(),
          fetchImpactOfDiscontinuingICTServices(),
          fetchAlternativesExit(),
          fetchFunctionNames(),
        ]);
      } catch (error) {
        console.error('Error during data fetching:', error);
        notification.error({
          message: 'Data Fetch Error',
          description: 'An error occurred while fetching data.',
          duration: 20,
        });
      }
    };

    const shouldFetch = selectedEntityForICT && selectedContractForICT;

    if (shouldFetch) {
      fetchData();
    }
  }, [
    selectedEntityForICT,
    selectedContractForICT,
    query,
  ]);

  useEffect(() => {
    if (query.entity) {
      fetchICTServiceTypes();
      fetchYesNo();
      fetchLevelsOfReliance();
      fetchCriticalityAssessments();
      fetchLocalRegulatorCriticality();
      fetchICTLevels();
      fetchReintegrationPossibilities();
      fetchImpactOfDiscontinuingICTServices();
      fetchAlternativesExit();
      fetchFunctionNames();
      // fetchCountries();
    }
  }, [query]);

  const addNewCertificate = (rowKey: string | number) => {
    const newCertificate: ICertificate = {
      certificates_id: generateUniqueId(),
      certificates_description: '',
      certificates_validity: '',
      certificates_date_of_issuance: '',
      certificates_date_of_certificate_register: '',
      certificates_ict_service_id: '',
      certificates_registry: '',
      certificates_end_date: '9999-01-01',
      certificates_start_date: '',
    };

    setData((prevData) => {
      return prevData.map((record) => {
        if (record.ictService_id === rowKey) {
          return {
            ...record,
            certificates: [...record.certificates, newCertificate],
          };
        }
        return record;
      });
    });
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    rowKey: string,
  ) => {
    const { value } = e.target;

    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    const updatedRow = data.find(
      (row) => row.ictService_id.toString() == rowKey,
    );

    if (updatedRow) {
      const updatedRowWithChanges: Record<string, any> = {
        ...updatedRow,
        [fieldName]: value,
      };

      if (updatedRow.certificates) {
        const match = fieldName.match(/^(\d+)_newcert_(.+)$/);
        if (match) {
          const idFromFieldName = match[1];
          const actualFieldName = match[2];

          const targetIndex = updatedRow.certificates.findIndex(
            (cert) => cert.certificates_id.toString() === idFromFieldName,
          );

          if (targetIndex !== -1) {
            updatedRowWithChanges.certificates[targetIndex] = {
              ...updatedRow.certificates[targetIndex],
              [actualFieldName]: value,
            };
          } else {
            console.log(
              `No matching certificate found for idFromFieldName (${idFromFieldName}) in certificates`,
            );
          }
        }
      }
      const cleanedUpRow = Object.keys(updatedRowWithChanges).reduce(
        (acc: Record<string, any>, key) => {
          const newKey = key.replace(/^[^a-zA-Z]+/, '');
          acc[newKey] = updatedRowWithChanges[key];
          return acc;
        },
        {},
      );

      setChangedData((prevChangedData) => {
        const newChangedData = [...prevChangedData];
        const existingRowIndex = newChangedData.findIndex(
          (row) => row.ictService_id === cleanedUpRow.ictService_id,
        );

        if (existingRowIndex !== -1) {
          const existingRow = newChangedData[existingRowIndex];
          const mergedRow = { ...existingRow, ...cleanedUpRow };
          if (JSON.stringify(existingRow) !== JSON.stringify(mergedRow)) {
            newChangedData[existingRowIndex] = mergedRow;
          }
        } else {
          newChangedData.push(cleanedUpRow);
        }

        return newChangedData;
      });
    } else {
      console.log('No match found for ictService_id:', rowKey);
    }
  };
  const updateChangedData = (editedData: any, changedData: any) => {
    if (editedData && typeof editedData === 'object') {
      Object.keys(editedData).forEach((key: string) => {
        const keyFromEditedData = parseInt(key.split('_')[0]);
        const matchingChangedRow = changedData.find((dataItem: any) => {
          return dataItem.ictService_id === keyFromEditedData;
        });

        if (matchingChangedRow) {
          const fieldName = key.replace(/^\d+_/, '');
          if (matchingChangedRow.hasOwnProperty(fieldName)) {
            matchingChangedRow[fieldName] = editedData[key];
          }
          if (
            fieldName.includes('certificates_start_date') ||
            fieldName.includes('certificates_end_date')
          ) {
            matchingChangedRow[fieldName] =
              editedData[key] !== undefined
                ? String(editedData[key] ?? '')
                : null;
          }
        }
      });
    } else {
      console.error('editedData is not an object:', editedData);
    }

    setChangedData([...changedData]);
  };

  useEffect(() => {
    updateChangedData(editedData, changedData);
  }, [editedData]);

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.ictService_id,
  }));
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  const addNewRow = () => {
    const newRowTemplate: IICTServices = {
      key: generateUniqueId(),
      isNew: true,
      ictServiceType_id: '',
      ictServiceType_name: '',
      ictServiceType_description: '',
      levelofSupportingICTService_id: '',
      levelofSupportingICTService_name: '',
      ictService_id: generateUniqueId(),
      ictService_ict_service_type_id: '',
      ictService_contractual_arrangement_id: selectedContractForICT?.value,
      ictService_contractual_arrangement_reference_number:
        data[0]?.ictService_contractual_arrangement_reference_number,
      ictService_description: '',
      ictService_storage_of_data_id: '',
      ictService_function_id: '',
      ictService_country_id: '',
      ictService_start_date: '',
      ictService_end_date: '9999-01-01',
      ictService_ict_service_level_id: '',
      ictService_impact_of_discontinuing_ict_services_id: '',
      ictService_alternatives_exit_id: '',
      ictService_level_of_reliance_id: '',
      alternatives_exit_id: '',
      alternatives_exit_alternative_providers_identified: '',
      alternatives_exit_exit_plan_exists: '',
      alternatives_exit_reintegration_possible: '',
      reintegration_possible_id: '',
      alternatives_exit_alternative_ict_tpp_identification: '',
      alternatives_exit_description: '',
      impactOfDiscontinuingIctServices_id: '',
      impactOfDiscontinuingIctServices_description: '',
      levelOfRelianceICTService_id: '',
      levelOfRelianceICTService_name: '',
      levelOfRelianceICTService_description: '',
      criticality_assessment_id: '',
      criticality_assessment_name: '',
      criticality_assessment_description: '',
      critically_assessment_local_regulator_criticality: '',
      critically_assessment_local_regulator_criticality_id: '',
      certificates: [],
      ict_systems: [],
      ict_levels: [
        {
          ict_level_id: '',
          ict_level_name: '',
          ict_level_description: '',
        },
      ],
      data_sensitiveness_name: '',
      data_sensitiveness_description: '',
    };
    const newRow = structuredClone(newRowTemplate);

    setData((prevData) => [...prevData, newRow]);
    setChangedData((prevChangedData) => [...prevChangedData, newRow]);
  };
  const handleOpenTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: true }));
  };

  const handleCloseTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: false }));
  };

  const fetchICTServiceTypes = async () => {
    try {
      const response = await ict_serviceOp.fetchICTServiceTypes();
      if (response?.data.ict_service_types) {
        const formattedOptions = response.data.ict_service_types.map(
          (types: { name: any; id: any }) => ({
            label: types.name,
            value: types.id,
          }),
        );
        setICTServicesType(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLevelsOfReliance = async () => {
    try {
      const response = await ict_serviceOp.fetchLevelsOfReliance();
      if (response?.data.levels_of_reliance) {
        const formattedOptions = response.data.levels_of_reliance.map(
          (levels: { name: any; id: any }) => ({
            label: levels.name,
            value: levels.id,
          }),
        );
        setLevelsOfReliance(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCriticalityAssessments = async () => {
    try {
      const response = await ict_serviceOp.fetchICTServicesCriticality();
      if (response?.data.ict_services_criticality) {
        const formattedOptions = response.data.ict_services_criticality.map(
          (assessments: { name: any; id: any }) => ({
            label: assessments.name,
            value: assessments.id,
          }),
        );
        setCriticalityAssessments(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLocalRegulatorCriticality = async () => {
    try {
      const response = await ict_serviceOp.fetchLocalRegulatorCriticality();
      if (response?.data.local_regulator_criticality) {
        const formattedOptions = response.data.local_regulator_criticality.map(
          (assessments: { name: any; id: any }) => ({
            label: assessments.name,
            value: assessments.id,
          }),
        );
        setLocalRegulatorCriticality(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchICTLevels = async () => {
    try {
      const response = await ict_serviceOp.fetchICTLevels();
      if (response?.data.ict_service_levels) {
        const formattedOptions = response.data.ict_service_levels.map(
          (lvl: { name: any; id: any }) => ({
            label: lvl.name,
            value: lvl.id,
          }),
        );
        setICTLevel(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReintegrationPossibilities = async () => {
    try {
      const response = await ict_serviceOp.fetchReintegrationPossibilities();
      if (response?.data.possibility_of_reintegration) {
        const formattedOptions = response.data.possibility_of_reintegration.map(
          (possibility: { description: any; id: any }) => ({
            label: possibility.description,
            value: possibility.id,
          }),
        );
        setPossibility(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchImpactOfDiscontinuingICTServices = async () => {
    try {
      const response =
        await ict_serviceOp.fetchImpactOfDiscontinuingICTServices();
      if (response?.data.impact_of_discontinuing_ict_services) {
        const formattedOptions =
          response.data.impact_of_discontinuing_ict_services.map(
            (impact: { description: any; id: any }) => ({
              label: impact.description,
              value: impact.id,
            }),
          );
        setImpact(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFunctionNames = async () => {
    try {
      const response = await ict_serviceOp.fetchFunctionsbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      if (response?.data) {
        const formattedOptions = response.data.map(
          (reasons: { function_name: any; id: any }) => ({
            label: reasons.function_name,
            value: reasons.id,
          }),
        );
        setFunctionNames(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = dataWithKey.filter(
          (item) => item.key !== key,
        );
        fetchICTServices();
        setData(updatedDataSource);
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: IICTServices) => {
    if (record.ictService_id === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The ict service ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the ict service with ID ${record.ictService_id}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response = await ict_serviceOp.inactivateICTService([
            record.ictService_id,
          ]);
          fetchICTServices();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };

  const fetchAlternativesExit = async () => {
    try {
      const response = await ict_serviceOp.fetchAlternativesExit();
      if (response?.data.alternatives_exit) {
        const formattedOptions = response.data.alternatives_exit.map(
          (alternatives: {
            alternative_ict_tpp_identification: any;
            id: any;
            exit_plan_exists: boolean;
          }) => ({
            label: alternatives.alternative_ict_tpp_identification,
            exists: alternatives.exit_plan_exists,
            value: alternatives.id,
          }),
        );
        setAlternatives(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited ICT Services to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    const formattedData = {
      contractual_arrangement_id: selectedContractForICT?.value,
      entity_name: selectedEntityForICT?.label,

      ict_service_entries: changedData.map((item) => ({
        id: item.ictService_id,
        ict_service_type_id: item.ictService_ict_service_type_id,
        ictService_contractual_arrangement_id:
          item.ictService_contractual_arrangement_id,
        ictService_contractual_arrangement_reference_number:
          item.ictService_contractual_arrangement_reference_number,
        description: item.ictService_description,
        storage_of_data_id: item.ictService_storage_of_data_id,
        function_id: item.ictService_function_id,
        country_id: item.ictService_country_id,
        ict_service_level_id:
          item.ictServiceLevel_id ||
          (item.ict_levels ? item.ict_levels[0]?.ict_level_id : undefined),
        impact_of_discontinuing_ict_services_id:
          item.ictService_impact_of_discontinuing_ict_services_id,
        alternatives_exit_id: item.ictService_alternatives_exit_id,
        level_of_reliance_id: item.ictService_level_of_reliance_id,
        criticality_assessment_id: item.criticality_assessment_id,
        start_date: item.ictService_start_date,
        end_date: item.ictService_end_date,
        exit_plan_exists_id: item.alternatives_exit_exit_plan_exists,
        possibility_of_reintegration_id: item.reintegration_possible_id,
        ict_services_criticality_id: item.criticality_assessment_id,
        local_regulator_criticality_id:
          item.critically_assessment_local_regulator_criticality_id,
        contractual_arrangement_id: selectedContractForICT?.value,
        alternative_ict_tpp_identification:
          item.alternative_ict_tpp_identification,
          ict_system_ids: item.ict_system_id
            ? item.ict_system_id 
            : item.ict_systems
            ? item.ict_systems.map((ict: { ict_systems_id: string }) => ict.ict_systems_id)
            : [],

      })),

      certificates: changedData
        .map((item) =>
          item.certificates.map((cert: ICertificate) => ({
            id: cert.certificates_id,
            description: cert.certificates_description,
            validity: cert.certificates_validity,
            date_of_issuance: cert.certificates_date_of_issuance,
            certificate_registry: cert.certificates_registry,
            date_of_certificate_register:
              cert.certificates_date_of_certificate_register,
            end_date: cert.certificates_end_date,
            start_date: cert.certificates_start_date,
            ict_service_id: item.ictService_id
          })),
        )
        .flat(),

      token: isLoggedIn,
    };
    try {
      const response = await ict_serviceOp.postICTServices(formattedData);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns: EditableColumnType<IICTServices>[] = [
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> Contractual arrangement reference number</p>
            <p>B.02.03.0010</p>
          </div>
          <AdditionalInformation
            color="red"
            description="Reference number of the contractual arrangement between the entity making use of the ICT service(s) provided and the ICT intra-group service provider.
            The contractual arrangement reference number shall be unique and consistent over time and across all the group.
            (alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Kennnummer der vertraglichen
            Vereinbarung zwischen
            dem
            Unternehmen, das die bereitgestellten
            IK
            T-Dienstleistung(en) in Anspruch nimmt, und dem
            gruppeninternen IK
            T-Dienstleister.
            Die Kennnummer der vertraglichen
            Vereinbarung muss
            eindeutig sein und ist im Zeitverlauf und über die gesamte
            Gruppe hinweg unverändert beizubehalten."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ictService_contractual_arrangement_reference_number',
      editable: true,
      fixed: 'left',
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        return (
          <Input
            value={record.ictService_contractual_arrangement_reference_number}
            disabled
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Function name</p>
            <p>B.06.01.0030</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Function name according to the financial entity’s internal organisation.
            (alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Funktionsbezeichnung entsprechend der
            internen Organisation des
            Finanzunternehmens."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ictService_function_id',
      editable: true,
      fixed: 'left',
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_function_id`;
        const rowKey = record.key;
        return (
          <Styled.ColourReportredFields>
            <CustomSelect
              options={functionNames}
              placeholder="Select an option"
              value={
                editedData.hasOwnProperty(fieldName)
                  ? editedData[fieldName]
                  : record.ictService_function_id
              }
              onChange={(value: string) => {
                const event = {
                  target: {
                    value,
                  },
                } as React.ChangeEvent<HTMLInputElement>;

                handleEditChange(event, fieldName, rowKey);
              }}
              size="large"
              filterOption={(input: string, option: { label: string }) =>
                option?.label?.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Styled.ColourReportredFields>
        );
      },
    },
    {
      title: <Styled.TitleComponent>Country</Styled.TitleComponent>,
      dataIndex: 'ictService_country_id',
      editable: false,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_country_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={countries}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_country_id
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'ictService_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          ICT service level<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ictServiceLevel_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictServiceLevel_name`;
        const fieldId = `${record.ictService_id}_ictServiceLevel_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={ictLevel}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ict_levels[0].ict_level_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const eventName = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(eventName, fieldName, rowKey);
              const eventId = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(eventId, fieldId, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: 'ICT service level - description',
      dataIndex: 'ictServiceLevel_description',
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Type of ICT services</p>
            <p>B.07.01.0040</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the types of ICT services referred to in Annex III (closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Eine der in Anhang III genannten Arten
            von IK
            T-Dienstleistungen"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ictServiceType_name',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictServiceType_name`;
        const fieldNameId = `${record.ictService_id}_ictServiceType_id`;
        const fieldNameId2 = `${record.ictService_id}_ictService_ict_service_type_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={ictServicesTypes}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictServiceType_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const event = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);

              const eventId = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(eventId, fieldNameId, rowKey);
              handleEditChange(eventId, fieldNameId2, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: 'ICT service type - description',
      dataIndex: 'ictServiceType_description',
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Exit plan exists</p>
            <p>B.07.01.0080</p>
          </div>
          <AdditionalInformation
            color="red"
            description="Use this column to report the existence of an exit plan from the ICT third-party service provider in relation to the specific ICT service provided.
             One of the options in the following closed list shall be used:
             1.	Yes;
             2.	No.
            [Yes/No]"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie in dieser Spalte an, ob ein
            Ausstiegsplan für den IK
            T-Drittdienstleister
            in Bezug auf die spezielle erbrachte
            IK
            T-Dienstleistung vorliegt.
            Aus der folgenden erschöpfenden Liste ist
            eine Option auszuwählen:
            1. Ja
            2. Nein"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'alternatives_exit_exit_plan_exists',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_exit_plan_exists`;
        const rowKey = record.key;

        const displayValue = editedData.hasOwnProperty(fieldName)
          ? editedData[fieldName] === 1
            ? 'Yes'
            : editedData[fieldName] === 2
              ? 'No'
              : ''
          : record.alternatives_exit_exit_plan_exists === 1
            ? 'Yes'
            : record.alternatives_exit_exit_plan_exists === 2
              ? 'No'
              : '';
        return (
          <CustomSelect
            options={yesNo}
            placeholder="Select an option"
            value={displayValue}
            onChange={(
              value: string,
              option: { label: string; value: number },
            ) => {
              const selectedValue = option.label === 'Yes' ? 1 : 2;

              const event = {
                target: {
                  value: selectedValue,
                },
              } as unknown as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Possibility of reintegration of the contracted ICT service</p>
            <p>B.07.01.0090</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the options in the following closed list shall be used:
           1.	Easy;
           2.	Difficult;
           3.	Highly complex.
           Use this column where the ICT service is provided by an ICT third-party service provider that is not an ICT intra-group service provider
           (closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Aus der folgenden erschöpfenden Liste ist
            eine Option auszuwählen:
            1. Problemlos
            2. Schwierig
            3. Äußerst kompliziert
            Verwenden Sie diese Spalte, wenn die
            IK
            T-Dienstleistung von einem
            IK
            T-Drittdienstleister erbracht wird, der kein
            gruppeninterner IK
            T-Dienstleister ist."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'alternatives_exit_reintegration_possible',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_reintegration_possible`;
        const fieldId = `${record.ictService_id}_reintegration_possible_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={possibility}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_reintegration_possible
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const event = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
              const eventId = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(eventId, fieldId, rowKey);

              const eventID = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Alternative ICT third-party service provider identification
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the options in the following closed list shall be used:
            1.	Yes;
            2.	No;
            7. Assessment not performed.
            For each ICT third-party service provider supporting a critical or important function, the assessment to identify an alternative service provider shall be performed.
            (closed set of options)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'alternatives_exit_alternative_ict_tpp_identification',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_alternative_ict_tpp_identification`;
        const rowKey = record.key;
        const fieldId = `${record.ictService_id}_alternatives_exit_id`;
        const fieldId1 = `${record.ictService_id}_ictService_alternatives_exit_id`;
        return (
          <CustomSelect
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_alternative_ict_tpp_identification
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
              handleEditChange(event, fieldId, rowKey);
              handleEditChange(event, fieldId1, rowKey);
            }}
            options={alternatives}
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },

    {
      title: 'Alternatives exit - description',
      dataIndex: 'alternatives_exit_description',
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Impact of discontinuing the ICT services </p>
            <p>B.07.01.0100</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Use this column to provide the impact for the financial entity of discontinuing the ICT services provided by the ICT third-party service provider according to the financial entity’s assessment.
              One of the options in the following closed list shall be used:
              1.	Low
              2.	Medium;
              3.	High;
              4.	Assessment not performed.
              (closed set of options))"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="In dieser Spalte ist das
            Ausmaß der
            Auswirkungen anzugeben, die die Einstellung
            der vom IK
            T-Drittdienstleister erbrachten
            IK
            T-Dienstleistungen nach Einschätzung des
            Finanzunternehmens für das
            Finanzunternehmen hätte."
          />
        </Styled.TitleComponent>
      ),

      dataIndex: 'impactOfDiscontinuingIctServices_description',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_impactOfDiscontinuingIctServices_description`;
        const fieldNameID = `${record.ictService_id}_impactOfDiscontinuingIctServices_id`;
        const fieldNameIDICT = `${record.ictService_id}_ictService_impact_of_discontinuing_ict_services_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={impact}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.impactOfDiscontinuingIctServices_description
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldNameID, rowKey);
              handleEditChange(idEvent, fieldNameIDICT, rowKey);
              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <>
          Criticality assessment<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'criticality_assessment_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_criticality_assessment_name`;
        const fieldId = `${record.ictService_id}_criticality_assessment_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={criticalityAssessments}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.criticality_assessment_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldId, rowKey);

              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: 'Criticality assessment - description',
      dataIndex: 'criticality_assessment_description',
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: (
        <>
          Local regulator criticality <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'critically_assessment_local_regulator_criticality',
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_critically_assessment_local_regulator_criticality`;
        const fieldId = `${record.ictService_id}_critically_assessment_local_regulator_criticality_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={localRegulatorCriticality}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_local_regulator_criticality
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const event = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);

              const eventId = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(eventId, fieldId, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <>
         ICT Systems<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ict_system',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ict_system_name`;
        const fieldId = `${record.ictService_id}_ict_system_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={ictSystems}
            mode="multiple"
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ict_systems?.map((ict: { ict_systems_name: any; }) => ict.ict_systems_name) || []
            }
            
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldId, rowKey);
              const eventName = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(eventName, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: <>Certificates</>,

      dataIndex: 'certificates',
      key: 'certificates',
      render: (_, record) => {
        return (
          <div>
            {(record.certificates as ICertificate[]).map(
              (cert: ICertificate) => {
                const idFieldName = `${cert.certificates_id}_newcert_certificates_id`;
                const descriptionFieldName = `${cert.certificates_id}_newcert_certificates_description`;
                const registryFieldName = `${cert.certificates_id}_newcert_certificates_registry`;
                const issuanceDateFieldName = `${cert.certificates_id}_newcert_certificates_date_of_issuance`;
                const validityFieldName = `${cert.certificates_id}_newcert_certificates_validity`;
                const dateOfRegisterFieldName = `${cert.certificates_id}_newcert_certificates_date_of_certificate_register`;
                const startDateFieldName = `${cert.certificates_id}_newcert_certificates_start_date`;
                const endDateFieldName = `${cert.certificates_id}_newcert_certificates_end_date`;
                const fieldNameServices = `${cert.certificates_id}_newcert_certificates_ict_service_id`;
                const rowKey = record.key;
                const handleRemoveCertificates = (
                  certificateKey: number,
                  certificateID: number,
                ) => {
                  Modal.confirm({
                    title: 'Are you sure you want to remove this Certificate?',
                    content: 'This action cannot be undone.',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                      try {
                        const response = await tppOp.inactivateCertificates([
                          certificateID,
                        ]);
                        const updatedCertificates = data.map((certificate) => {
                          if (certificate.key === certificateKey) {
                            return {
                              ...certificate,
                              certificates: certificate.certificates.filter(
                                (certificate: ICertificate) =>
                                  certificate.certificates_id !== certificateID,
                              ),
                            };
                          }
                          return certificate;
                        });
                        setData(updatedCertificates);
                        if (record.isNew) {
                          const updatedCertificates = data.map((item) => {
                            const filteredCertificates =
                              item.certificates.filter(
                                (certificate: ICertificate) =>
                                  certificate.certificates_id !==
                                  cert.certificates_id,
                              );
                            return {
                              ...item,
                              certificates: filteredCertificates,
                            };
                            return item;
                          });

                          setData(updatedCertificates);
                        } else {
                          fetchICTServices();
                        }
                      } catch (error) {
                        console.error('Failed to remove Certificate:', error);
                      }
                    },
                    onCancel: () => {
                      notification.info({
                        message: 'Action Canceled',
                        description:
                          'The Certificate removal has been canceled.',
                        duration: 10,
                      });
                    },
                  });
                };
                return (
                  <Styled.TooltipContainer
                    key={cert.certificates_id}
                    visible={isTooltipVisible[idFieldName]}
                    title={
                      <div>
                        <Styled.TooltipButtonContainer>
                          <Button
                            size="small"
                            type="text"
                            onClick={() => handleCloseTooltip(idFieldName)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                          <Button
                            size="small"
                            type="text"
                            danger
                            onClick={() =>
                              handleRemoveCertificates(
                                record.key,
                                cert.certificates_id,
                              )
                            }
                          >
                            <DeleteOutlined />
                          </Button>
                        </Styled.TooltipButtonContainer>
                        <Styled.InputField>
                          <strong>
                            Register <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(registryFieldName)
                                ? typeof editedData[registryFieldName] ===
                                  'boolean'
                                  ? editedData[registryFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[registryFieldName])
                                : String(cert.certificates_registry)
                            }
                            onChange={(e) =>
                              handleEditChange(e, registryFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Issuance date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(issuanceDateFieldName)
                                ? String(
                                    editedData[issuanceDateFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_issuance ?? '',
                                  )
                            }
                            fieldName={issuanceDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                issuanceDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Validity <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(validityFieldName)
                                ? typeof editedData[validityFieldName] ===
                                  'boolean'
                                  ? editedData[validityFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[validityFieldName])
                                : String(cert.certificates_validity)
                            }
                            onChange={(e) =>
                              handleEditChange(e, validityFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>Certificate Description </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(descriptionFieldName)
                                ? typeof editedData[descriptionFieldName] ===
                                  'boolean'
                                  ? editedData[descriptionFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[descriptionFieldName])
                                : String(cert.certificates_description)
                            }
                            onChange={(e) =>
                              handleEditChange(e, descriptionFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Start date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(startDateFieldName)
                                ? String(editedData[startDateFieldName] ?? '')
                                : String(cert.certificates_start_date ?? '')
                            }
                            fieldName={startDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                startDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            End date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(endDateFieldName)
                                ? String(
                                    editedData[endDateFieldName] ??
                                      '9999-01-01',
                                  )
                                : String(
                                    cert.certificates_end_date ?? '9999-01-01',
                                  )
                            }
                            fieldName={endDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(e, endDateFieldName, record.key)
                            }
                            type="date"
                            useDefaultDate={true}
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Date of register<Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(dateOfRegisterFieldName)
                                ? String(
                                    editedData[dateOfRegisterFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_certificate_register ??
                                      '',
                                  )
                            }
                            fieldName={dateOfRegisterFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                dateOfRegisterFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                      </div>
                    }
                    open={isTooltipVisible[idFieldName] || false}
                    trigger={[]}
                  >
                    <Styled.TagComponent
                      color="blue"
                      onClick={() => handleOpenTooltip(idFieldName)}
                    >
                      <Input
                        value={
                          editedData.hasOwnProperty(descriptionFieldName)
                            ? typeof editedData[descriptionFieldName] ===
                              'boolean'
                              ? editedData[descriptionFieldName]
                                ? 'true'
                                : 'false'
                              : String(editedData[descriptionFieldName])
                            : String(cert.certificates_description)
                        }
                        readOnly
                        addonAfter={<PlusCircleOutlined />}
                      />
                    </Styled.TagComponent>
                    <Styled.GlobalStyle />
                  </Styled.TooltipContainer>
                );
              },
            )}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewCertificate(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> Storage of data </p>
            <p>B.02.02.0140</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Is the ICT service related to (or does it foresee) storage of data (even temporarily)? 
            One of the options provided in the following closed list:
            1.	Yes;
            2.	No.
            [Yes/No]
            (mandatory if the ICT service is supporting a critical or important function)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Ist die IKT-Dienstleistung mit der (vorübergehenden) Speicherung von Daten
            verbunden (oder sieht sie diese vor)?
            Aus der folgenden erschöpfenden Liste ist eine Option auszuwählen:
            1. Ja
            2. Nein"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ictService_storage_of_data_id',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_storage_of_data_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={yesNo}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_storage_of_data_id
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <>
          Start Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ictService_start_date',
      render: (text: string, record) => {
        const fieldName = `${record.ictService_id}_ictService_start_date`;
        const rowKey = record.key;
        return (
          <Styled.InputField>
            <DynamicField
              value={
                editedData.hasOwnProperty(fieldName)
                  ? String(editedData[fieldName] ?? '')
                  : String(record.ictService_start_date ?? '')
              }
              fieldName={fieldName}
              rowKey={record.key}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEditChange(e, fieldName, rowKey)
              }
              type="date"
            />
          </Styled.InputField>
        );
      },
    },
    {
      title: (
        <>
          End Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ictService_end_date',
      render: (text: string, record) => {
        const fieldName = `${record.ictService_id}_ictService_end_date`;
        const rowKey = record.key;
        return (
          <Styled.InputField>
            <DynamicField
              value={
                editedData.hasOwnProperty(fieldName)
                  ? String(editedData[fieldName] ?? '9999-01-01')
                  : String(record.ictService_end_date ?? '9999-01-01')
              }
              fieldName={fieldName}
              rowKey={record.key}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEditChange(e, fieldName, rowKey)
              }
              type="date"
              useDefaultDate={true}
            />
          </Styled.InputField>
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Level of reliance on the ICT service supporting the critical or
              important function.
            </p>
            <p>B.02.02.0180</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the options in the following closed list shall be used:
                1.	Not significant;
                2.	Low reliance: in case of disruption of the services, the supported functions would not be significantly impacted (no interruption, no important damage) or disruption can be resolved quickly and with minimal impact on the functions supported;
                3.	Material reliance: in case of disruption of the services, the supported functions would be significantly impacted if the disruption lasts more than a few minutes/ few hours, and the disruption may cause damages, but is still manageable;
                4.	Full reliance: in case of disruption of the services, the supported functions would be immediately and severely interrupted/damaged, for a long period.
                (closed set of options)
                (mandatory if the ICT service is supporting a critical or important function or material part thereof)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Aus der folgenden erschöpfenden Liste ist eine Option auszuwählen:
              1. Unerheblich
              2. Geringe Abhängigkeit: Bei einer etwaigen Störung der Dienstleistungen werden die
              unterstützten Funktionen nicht wesentlich beeinträchtigt (keine
              Unterbrechung,
              kein erheblicher Schaden) oder die Störung kann schnell und mit minimalen
              Auswirkungen auf die unterstützten Funktionen behoben werden.
              3.
              Wesentliche Abhängigkeit: Bei einer Störung der Dienstleistungen werden die
              unterstützten Funktionen erheblich beeinträchtigt, wenn die Störung länger als
              wenige Minuten/wenige Stunden dauert, und die Störung kann Schäden
              verursachen, ist aber noch beherrschbar.
              4.
              Vollständige Abhängigkeit: Bei einer Störung der Dienstleistungen werden die
              unterstützten Funktionen sofort und über einen längeren Zeitraum hinweg
              massiv unterbrochen/geschädigt."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'levelOfRelianceICTService_name',
      editable: true,
      className: 'highlighted-column',
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_levelOfRelianceICTService_name`;
        const fieldId = `${record.ictService_id}_levelOfRelianceICTService_id`;
        const fieldIdICT = `${record.ictService_id}_ictService_level_of_reliance_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={levelsOfReliance}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.levelOfRelianceICTService_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldId, rowKey);
              handleEditChange(idEvent, fieldIdICT, rowKey);
              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
            }}
            size="large"
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: 'Level of Reliance ICT Service - description',
      dataIndex: 'levelOfRelianceICTService_description',
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record) => (
        <>
          {record.isNew ? (
            <Button danger onClick={() => handleRemoveRow(record.key)}>
              Remove
            </Button>
          ) : (
            <Button
              danger
              onClick={() => handleInactivate(record as IICTServices)}
            >
              Soft delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Styled.ButtonDiv isEmpty={dataWithKey.length === 0}>
        {query.entity && selectedContractForICT && (
          <Styled.AreaButton type="primary" onClick={addNewRow}>
            Add Row
          </Styled.AreaButton>
        )}
      </Styled.ButtonDiv>
      <Styled.TooltipTableContainer>
        <Table
          dataSource={dataWithKey}
          rowKey="key"
          scroll={{ x: 'max-content' }}
          className="coloringForTheReports"
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePaginationChange,
            showSizeChanger: pagination.showSizeChanger,
            pageSizeOptions: pagination.pageSizeOptions,
            position: pagination.position,
          }}
          columns={columns}
        />
      </Styled.TooltipTableContainer>
      {dataWithKey.length > 0 && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      )}
    </>
  );
};

export default ICTServices;
