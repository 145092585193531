import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { notification, Table, Input, Button } from 'antd';
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import {
  IData,
  ICertificate,
  IICTSystem,
  IICTServices,
  EditedData,
} from 'store/ducks/DataEntry/ICTServices/types';
import useFilters from 'utils/hooks/useFilters';
import * as Styled from '../../DataEntry.styled';
import DynamicField from 'components/DynamicCalendarField/DynamicField';

const ICTServices: React.FC = () => {
  const [data, setData] = useState<IICTServices[]>([]);
  const [editedData, setEditedData] = useState<EditedData>({});
  const [changedData, setChangedData] = useState<Record<string, any>[]>([]);
  const [isTooltipVisible, setIsTooltipVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const {
    selectedContractForICT,
    selectedEntityForICT,
    selectedFunctionForICT,
    selectedTPPForICT,
  } = useSelector((state: any) => state.ictServices);
  const { query } = useFilters();
  const fetchICT = ict_serviceOp.useFetchICTServices();
  const fetchICTServices = async () => {
    try {
      const response = await fetchICT();
      const data = response?.data['ICT services'];
      console.log(data, 'data');
      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        alternatives_exit_id: item.alternatives_exit.id,
        alternatives_exit_alternative_providers_identified:
          item.alternatives_exit.alternative_providers_identified,
        alternatives_exit_exit_plan_exists:
          item.alternatives_exit.exit_plan_exists,
        alternatives_exit_reintegration_possible:
          item.alternatives_exit.reintegration_possible,
        alternatives_exit_alternative_ict_tpp_identification:
          item.alternatives_exit.alternative_ict_tpp_identification,
        alternatives_exit_description: item.alternatives_exit.description,
        yesNo_id: item.storage_of_data.id,
        yesNo_status: item.storage_of_data.status,
        yesNo_description: item.storage_of_data.description,
        auswahlServicesDiscount_id: item.auswahl_services_discount.id,
        auswahlServicesDiscount_name: item.auswahl_services_discount.name,
        auswahlServicesDiscount_description:
          item.auswahl_services_discount.description,
        ictServiceLevel_id: item.ict_service_level.id,
        ictServiceLevel_name: item.ict_service_level.name,
        ictServiceLevel_description: item.ict_service_level.description,
        ictServiceType_id: item.ict_service_type.id,
        ictServiceType_name: item.ict_service_type.name,
        ictServiceType_description: item.ict_service_type.description,
        impactOfDiscontinuingFunction_id:
          item.impact_of_discontinuing_function.id,
        impactOfDiscontinuingFunction_description:
          item.impact_of_discontinuing_function.description,
        impactOfDiscontinuingIctServices_id:
          item.impact_of_discontinuing_services.id,
        impactOfDiscontinuingIctServices_description:
          item.impact_of_discontinuing_services.description,
        levelOfRelianceICTService_id: item.level_of_reliance.id,
        levelOfRelianceICTService_name: item.level_of_reliance.name,
        levelOfRelianceICTService_description:
          item.level_of_reliance.description,
        levelofSupportingICTService_id: item.level_of_supporting_ict_service.id,
        levelofSupportingICTService_name:
          item.level_of_supporting_ict_service.name,
        levelofSupportingICTService_description:
          item.level_of_supporting_ict_service.description,
        criticality_assessment_id: item.criticality_assessment?.id || '',
        criticality_assessment_name: item.criticality_assessment?.name || '',
        criticality_assessment_description:
          item.criticality_assessment?.description || '',
        ictService_id: item.ict_service_id,
        ictService_ict_service_type_id: item.ict_service_type.id,
        ictService_third_party_service_provider_id:
          item.third_party_service_provider.id,
        ictService_description: item.ict_service_description,
        ictService_notice_period: item.notice_period,
        ictService_level_of_supporting_ict_service_id:
          item.level_of_supporting_ict_service.id,
        ictService_rank: item.rank,
        ictService_auswahl_services_discount_id:
          item.auswahl_services_discount.id,
        ictService_storage_of_data_id: item.storage_of_data.id,
        ictService_recipient_identification_code:
          item.recipient_identification_code,
        ictService_recipient_identification_type:
          item.recipient_identification_type,
        ictService_function_id: item.function.id,
        ictService_country_id: item.country.id,
        ictService_ict_service_level_id: item.ict_service_level.id,
        ictService_impact_of_discontinuing_function_id:
          item.impact_of_discontinuing_function.id,
        ictService_impact_of_discontinuing_ict_services_id:
          item.impact_of_discontinuing_services.id,
        ictService_alternatives_exit_id: item.alternatives_exit.id,
        ictService_substitutability_of_ict_provider_id:
          item.substitutability.id,
        ictService_level_of_reliance_id: item.level_of_reliance.id,

        ict_systems: item.ict_systems
          ? item.ict_systems.map((ict_system: any) => ({
              ict_systems_id: ict_system.id,
              ict_systems_name: ict_system.name,
              ict_systems_description: ict_system.description,
              ict_systems_start_date: ict_system.start_date,
              ict_systems_end_date: ict_system.end_date,
            }))
          : [],

        certificates: item.certificates
          ? item.certificates.map((certificate: any) => ({
              certificates_id: certificate.id,
              certificates_description: certificate.description,
              certificates_validity: certificate.validity,
              certificates_date_of_issuance: certificate.date_of_issuance,
              certificates_registry: certificate.certificate_registry,
              certificates_date_of_certificate_register:
                certificate.date_of_certificate_register,
              certificates_third_party_service_provider_id:
                certificate.third_party_service_provider_id,
              certificates_function_id: certificate.function_id,
              certificates_ict_service_id: certificate.ict_service_id,
              certificates_end_date: certificate.end_date,
              certificates_start_date: certificate.start_date,
            }))
          : [],
        data: item.data
          ? item.data.map((iData: any) => ({
              sensitivenessOfDataStoredbyICTTPSP_id:
                iData.data_sensitiveness.data_stored_by_ict_tpsp.id,
              sensitivenessOfDataStoredbyICTTPSP_name:
                iData.data_sensitiveness.data_stored_by_ict_tpsp.name,
              sensitivenessOfDataStoredbyICTTPSP_description:
                iData.data_sensitiveness.data_stored_by_ict_tpsp.description,
              dataSensitiveness_id: iData.data_sensitiveness.id,
              dataSensitiveness_sensitiveness_name:
                iData.data_sensitiveness.name,
              dataSensitiveness_description:
                iData.data_sensitiveness.description,
              data_id: iData.id,
              data_location_of_data_at_rest: iData.location_of_data_at_rest,
              data_location_of_data_management:
                iData.location_of_data_management,
              data_sensitiveness_id: iData.data_sensitiveness.id,
              data_description: iData.description,
              data_ict_system_id: iData.ict_system_id,
              data_start_date: iData.start_date,
              data_end_date: iData.end_date,
            }))
          : [],
      }));
      setData(mappedData);
    } catch (error) {
      console.log(error, 'error');
      setData([]);
    }
  };
  const shouldFetch =
    (selectedEntityForICT && selectedContractForICT) ||
    selectedFunctionForICT ||
    selectedTPPForICT;

  useEffect(() => {
    if (shouldFetch) {
      fetchICTServices();
    }
  }, [
    selectedEntityForICT,
    selectedContractForICT,
    selectedFunctionForICT,
    selectedTPPForICT,
    query,
  ]);

  const addNewIctSystem = (rowKey: string | number) => {
    const newIctSystem: IICTSystem = {
      ict_systems_id: generateUniqueId(),
      ict_systems_name: '',
      ict_systems_description: '',
      ict_systems_start_date: '',
      ict_systems_end_date: '',
    };

    setData((prevData) =>
      prevData.map((record) => {
        if (record.ictService_id === rowKey) {
          return {
            ...record,
            ict_systems: [...record.ict_systems, { ...newIctSystem }],
          };
        }
        return record;
      }),
    );
  };
  const addNewCertificate = (rowKey: string | number) => {
    const newCertificate: ICertificate = {
      certificates_id: generateUniqueId(),
      certificates_description: '',
      certificates_validity: '',
      certificates_date_of_issuance: '',
      certificates_date_of_certificate_register: '',
      certificates_third_party_service_provider_id: generateUniqueId(),
      certificates_function_id: generateUniqueId(),
      certificates_ict_service_id: generateUniqueId(),
      certificates_registry: '',
      certificates_end_date: '',
      certificates_start_date: '',
    };

    setData((prevData) => {
      return prevData.map((record) => {
        if (record.ictService_id === rowKey) {
          return {
            ...record,
            certificates: [...record.certificates, newCertificate],
          };
        }
        return record;
      });
    });
  };

  const addNewData = (rowKey: string | number) => {
    const newData: IData = {
      sensitivenessOfDataStoredbyICTTPSP_id: generateUniqueId(),
      sensitivenessOfDataStoredbyICTTPSP_name: '',
      sensitivenessOfDataStoredbyICTTPSP_description: '',
      dataSensitiveness_id: generateUniqueId(),
      dataSensitiveness_location_of_data_at_rest: '',
      dataSensitiveness_location_of_data_management: '',
      dataSensitiveness_sensitiveness_id: generateUniqueId(),
      dataSensitiveness_sensitiveness_name: '',
      dataSensitiveness_description: '',
      dataSensitiveness_ict_system_id: generateUniqueId(),
      data_id: generateUniqueId(),
      data_location_of_data_at_rest: '',
      data_location_of_data_management: '',
      data_sensitiveness_id: generateUniqueId(),
      data_description: '',
      data_ict_system_id: generateUniqueId(),
      data_start_date: '',
      data_end_date: '',
    };

    setData((prevData) => {
      return prevData.map((record) => {
        if (record.ictService_id === rowKey) {
          return {
            ...record,
            data: [...record.data, newData],
          };
        }
        return record;
      });
    });
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    rowKey: string,
  ) => {
    const { value } = e.target;

    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    const updatedRow = data.find(
      (row) => row.ictService_id.toString() == rowKey,
    );

    if (updatedRow) {
      const updatedRowWithChanges: Record<string, any> = {
        ...updatedRow,
        [fieldName]: value,
      };

      if (fieldName === 'ict_systems_id' || 'certificates_id' || 'data_id') {
        if (updatedRow.ict_systems) {
          const match = fieldName.match(/^(\d+)_systems_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];

            const targetIndex = updatedRow.ict_systems.findIndex(
              (system) => system.ict_systems_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.ict_systems[targetIndex] = {
                ...updatedRow.ict_systems[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching system found for idFromFieldName (${idFromFieldName}) in ict_systems`,
              );
            }
          }
        }
        if (updatedRow.certificates) {
          const match = fieldName.match(/^(\d+)_newcert_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];

            const targetIndex = updatedRow.certificates.findIndex(
              (cert) => cert.certificates_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.certificates[targetIndex] = {
                ...updatedRow.certificates[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching certificate found for idFromFieldName (${idFromFieldName}) in certificates`,
              );
            }
          }
        }
        if (updatedRow.data) {
          const match = fieldName.match(/^(\d+)_newItem_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];
            const targetIndex = updatedRow.data.findIndex(
              (item) => item.data_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.data[targetIndex] = {
                ...updatedRow.data[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching data found for idFromFieldName (${idFromFieldName}) in datas`,
              );
            }
          }
        }
      }
      const cleanedUpRow = Object.keys(updatedRowWithChanges).reduce(
        (acc: Record<string, any>, key) => {
          const newKey = key.replace(/^[^a-zA-Z]+/, '');
          acc[newKey] = updatedRowWithChanges[key];
          return acc;
        },
        {},
      );

      setChangedData((prevChangedData) => {
        const newChangedData = [...prevChangedData];
        const existingRowIndex = newChangedData.findIndex(
          (row) => row.ictService_id === cleanedUpRow.ictService_id,
        );

        if (existingRowIndex !== -1) {
          const existingRow = newChangedData[existingRowIndex];
          const mergedRow = { ...existingRow, ...cleanedUpRow };
          if (JSON.stringify(existingRow) !== JSON.stringify(mergedRow)) {
            newChangedData[existingRowIndex] = mergedRow;
          }
        } else {
          newChangedData.push(cleanedUpRow);
        }

        return newChangedData;
      });
    } else {
      console.log('No match found for ictService_id:', rowKey);
    }
  };
  const updateChangedData = (editedData: any, changedData: any) => {
    if (editedData && typeof editedData === 'object') {
      Object.keys(editedData).forEach((key: string) => {
        const keyFromEditedData = parseInt(key.split('_')[0]);
        const matchingChangedRow = changedData.find((dataItem: any) => {
          return dataItem.ictService_id === keyFromEditedData;
        });

        if (matchingChangedRow) {
          const fieldName = key.replace(/^\d+_/, '');
          if (matchingChangedRow.hasOwnProperty(fieldName)) {
            matchingChangedRow[fieldName] = editedData[key];
          }
          if (
            fieldName.includes('certificates_start_date') ||
            fieldName.includes('certificates_end_date')
          ) {
            matchingChangedRow[fieldName] =
              editedData[key] !== undefined
                ? String(editedData[key] ?? '')
                : null;
          }
        }
      });
    } else {
      console.error('editedData is not an object:', editedData);
    }

    setChangedData([...changedData]);
  };

  useEffect(() => {
    updateChangedData(editedData, changedData);
  }, [editedData]);

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.ictService_id,
  }));
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  let uniqueIdCounter = 0;
  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now();
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId = Number(`${timestampPart}${uniqueIdCounter}${randomPart}`);
    return uniqueId;
  };

  const addNewRow = () => {
    const newRowTemplate: IICTServices = {
      key: generateUniqueId(),
      isNew: true,
      yesNo_id: generateUniqueId(),
      yesNo_status: null,
      yesNo_description: '',
      auswahlServicesDiscount_id: generateUniqueId(),
      auswahlServicesDiscount_name: '',
      auswahlServicesDiscount_description: '',
      ictServiceType_id: generateUniqueId(),
      ictServiceType_name: '',
      ictServiceType_description: '',
      levelofSupportingICTService_id: generateUniqueId(),
      levelofSupportingICTService_name: '',
      levelofSupportingICTService_description: '',
      ictService_id: generateUniqueId(),
      ictService_ict_service_type_id: generateUniqueId(),
      ictService_third_party_service_provider_id: generateUniqueId(),
      ictService_description: '',
      ictService_notice_period: '',
      ictService_level_of_supporting_ict_service_id: generateUniqueId(),
      ictService_rank: '',
      ictService_auswahl_services_discount_id: generateUniqueId(),
      ictService_storage_of_data_id: generateUniqueId(),
      ictService_recipient_identification_code: '',
      ictService_recipient_identification_type: '',
      ictService_function_id: generateUniqueId(),
      ictService_country_id: generateUniqueId(),
      ictService_ict_service_level_id: generateUniqueId(),
      ictService_impact_of_discontinuing_function_id: generateUniqueId(),
      ictService_impact_of_discontinuing_ict_services_id: generateUniqueId(),
      ictService_alternatives_exit_id: generateUniqueId(),
      ictService_substitutability_of_ict_provider_id: generateUniqueId(),
      ictService_level_of_reliance_id: generateUniqueId(),
      alternatives_exit_id: generateUniqueId(),
      alternatives_exit_alternative_providers_identified: null,
      alternatives_exit_exit_plan_exists: null,
      alternatives_exit_reintegration_possible: null,
      alternatives_exit_alternative_ict_tpp_identification: '',
      alternatives_exit_description: '',
      impactOfDiscontinuingFunction_id: generateUniqueId(),
      impactOfDiscontinuingFunction_description: '',
      impactOfDiscontinuingIctServices_id: generateUniqueId(),
      impactOfDiscontinuingIctServices_description: '',
      levelOfRelianceICTService_id: generateUniqueId(),
      levelOfRelianceICTService_name: '',
      levelOfRelianceICTService_description: '',
      ictServiceLevel_id: generateUniqueId(),
      ictServiceLevel_name: '',
      ictServiceLevel_description: '',
      criticality_assessment_id: generateUniqueId(),
      criticality_assessment_name: '',
      criticality_assessment_description: '',
      certificates: [
        {
          certificates_id: generateUniqueId(),
          certificates_description: '',
          certificates_validity: '',
          certificates_date_of_issuance: '',
          certificates_date_of_certificate_register: '',
          certificates_third_party_service_provider_id: generateUniqueId(),
          certificates_function_id: generateUniqueId(),
          certificates_ict_service_id: generateUniqueId(),
          certificates_registry: '',
          certificates_end_date: '',
          certificates_start_date: '',
        },
      ],
      ict_systems: [
        {
          ict_systems_id: generateUniqueId(),
          ict_systems_name: '',
          ict_systems_description: '',
          ict_systems_start_date: '',
          ict_systems_end_date: '',
        },
      ],
      data: [
        {
          sensitivenessOfDataStoredbyICTTPSP_id: generateUniqueId(),
          sensitivenessOfDataStoredbyICTTPSP_name: '',
          sensitivenessOfDataStoredbyICTTPSP_description: '',
          dataSensitiveness_id: generateUniqueId(),
          dataSensitiveness_location_of_data_at_rest: '',
          dataSensitiveness_location_of_data_management: '',
          dataSensitiveness_sensitiveness_id: generateUniqueId(),
          dataSensitiveness_sensitiveness_name: '',
          dataSensitiveness_description: '',
          dataSensitiveness_ict_system_id: generateUniqueId(),
          data_id: generateUniqueId(),
          data_location_of_data_at_rest: '',
          data_location_of_data_management: '',
          data_sensitiveness_id: generateUniqueId(),
          data_description: '',
          data_ict_system_id: generateUniqueId(),
          data_start_date: '',
          data_end_date: '',
        },
      ],
      data_sensitiveness_name: '',
      data_sensitiveness_description: '',
    };
    const newRow = structuredClone(newRowTemplate);

    setData((prevData) => [...prevData, newRow]);
    setChangedData((prevChangedData) => [...prevChangedData, newRow]);
  };
  const handleOpenTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: true }));
  };

  const handleCloseTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: false }));
  };

  const handleSubmit = async () => {
    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited ICT Services to submit.',
        placement: 'topRight',
      });
      return;
    }
    const formattedData = {
      tpp_name: selectedTPPForICT?.label,
      function_name: selectedFunctionForICT?.label,
      contractual_arrangement_id: selectedContractForICT?.value,
      entity_name: selectedEntityForICT?.label,

      sensitiveness_of_data_stored_by_ict_tpsp: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.sensitivenessOfDataStoredbyICTTPSP_id,
            name: itemData.sensitivenessOfDataStoredbyICTTPSP_name,
            description:
              itemData.sensitivenessOfDataStoredbyICTTPSP_description,
          })),
        )
        .flat(),

      data_sensitiveness: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.data_sensitiveness_id,
            name: itemData.dataSensitiveness_sensitiveness_name,
            sensitiveness_of_data_stored_by_ict_tpsp_id:
              itemData.sensitivenessOfDataStoredbyICTTPSP_id,
            description: itemData.dataSensitiveness_id,
          })),
        )
        .flat(),

      data_entries: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.data_id,
            location_of_data_at_rest: itemData.data_location_of_data_at_rest,
            location_of_data_management:
              itemData.data_location_of_data_management,
            sensitiveness_id: itemData.dataSensitiveness_id,
            ict_system_id: itemData.data_ict_system_id,
            description: itemData.data_description,
            start_date: itemData.data_start_date,
            end_date: itemData.data_end_date,
          })),
        )
        .flat(),

      yes_no_entries: changedData.map((item) => ({
        id: item.yesNo_id,
        status: item.yesNo_status,
        description: item.yesNo_description,
      })),

      criticality_assessment: changedData.map((item) => ({
        id: item.criticality_assessment_id,
        name: item.criticality_assessment_name,
        description: item.criticality_assessment_description,
      })),

      auswahl_services_discount_entries: changedData.map((item) => ({
        id: item.auswahlServicesDiscount_id,
        name: item.auswahlServicesDiscount_name,
        description: item.auswahlServicesDiscount_description,
      })),

      ict_service_type_entries: changedData.map((item) => ({
        id: item.ictServiceType_id,
        name: item.ictServiceType_name,
        description: item.ictServiceType_description,
      })),

      level_of_supporting_ict_service_entries: changedData.map((item) => ({
        id: item.levelofSupportingICTService_id,
        name: item.levelofSupportingICTService_name,
        description: item.levelofSupportingICTService_description,
      })),

      ict_service_entries: changedData.map((item) => ({
        id: item.ictService_id,
        ict_service_type_id: item.ictService_ict_service_type_id,
        third_party_service_provider_id:
          item.ictService_third_party_service_provider_id,
        description: item.ictService_description,
        notice_period: item.ictService_notice_period,
        level_of_supporting_ict_service_id:
          item.ictService_level_of_supporting_ict_service_id,
        rank: item.ictService_rank,
        auswahl_services_discount_id:
          item.ictService_auswahl_services_discount_id,
        storage_of_data_id: item.ictService_storage_of_data_id,
        recipient_identification_code:
          item.ictService_recipient_identification_code,
        recipient_identification_type:
          item.ictService_recipient_identification_type,
        function_id: item.ictService_function_id,
        country_id: item.ictService_country_id,
        ict_service_level_id: item.ictService_ict_service_level_id,
        impact_of_discontinuing_function_id:
          item.ictService_impact_of_discontinuing_function_id,
        impact_of_discontinuing_ict_services_id:
          item.ictService_impact_of_discontinuing_ict_services_id,
        alternatives_exit_id: item.ictService_alternatives_exit_id,
        substitutability_of_ict_provider_id:
          item.ictService_substitutability_of_ict_provider_id,
        level_of_reliance_id: item.ictService_level_of_reliance_id,
      })),

      ict_systems_entries: changedData
        .map((item) =>
          item.ict_systems.map((ict: IICTSystem) => ({
            id: ict.ict_systems_id,
            name: ict.ict_systems_name,
            description: ict.ict_systems_description,
            start_date: ict.ict_systems_start_date,
            end_date: ict.ict_systems_end_date,
          })),
        )
        .flat(),

      alternatives_exit_entries: changedData.map((item) => ({
        id: item.alternatives_exit_id,
        alternative_providers_identified:
          item.alternatives_exit_alternative_providers_identified,
        exit_plan_exists: item.alternatives_exit_exit_plan_exists,
        reintegration_possible: item.alternatives_exit_reintegration_possible,
        alternative_ict_tpp_identification:
          item.alternatives_exit_alternative_ict_tpp_identification,
        description: item.alternatives_exit_description,
      })),

      impact_of_discontinuing_function_entries: changedData.map((item) => ({
        id: item.impactOfDiscontinuingFunction_id,
        description: item.impactOfDiscontinuingFunction_description,
      })),

      impact_of_discontinuing_ict_services_entries: changedData.map((item) => ({
        id: item.impactOfDiscontinuingIctServices_id,
        description: item.impactOfDiscontinuingIctServices_description,
      })),

      Certificates: changedData
        .map((item) =>
          item.certificates.map((cert: ICertificate) => ({
            id: cert.certificates_id,
            description: cert.certificates_description,
            validity: cert.certificates_validity,
            date_of_issuance: cert.certificates_date_of_issuance,
            certificate_registry: cert.certificates_registry,
            date_of_certificate_register:
              cert.certificates_date_of_certificate_register,
            third_party_service_provider_id:
              cert.certificates_third_party_service_provider_id,
            function_id: cert.certificates_function_id,
            ict_service_id: cert.certificates_ict_service_id,
            end_date: cert.certificates_end_date,
            start_date: cert.certificates_start_date,
          })),
        )
        .flat(),

      ict_service_level: changedData.map((item) => ({
        id: item.ictServiceLevel_id,
        name: item.ictServiceLevel_name,
        description: item.ictServiceLevel_description,
      })),

      level_of_reliance_ict_service: changedData.map((item) => ({
        id: item.levelOfRelianceICTService_id,
        name: item.levelOfRelianceICTService_name,
        description: item.levelOfRelianceICTService_description,
      })),
    };

    try {
      const response = await ict_serviceOp.postICTServices(formattedData);
      notification.success({
        message: 'Submission Successful',
        description: 'The data was successfully submitted.',
        placement: 'topRight',
      });
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const columns: EditableColumnType<IICTServices>[] = [
    {
      title: 'Description',
      dataIndex: 'ictService_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Notice period<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ictService_notice_period',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_notice_period`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_notice_period
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Rank<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ictService_rank',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_rank`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_rank
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Recipient identification code<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ictService_recipient_identification_code',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_recipient_identification_code`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_recipient_identification_code
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Recipient identification type<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ictService_recipient_identification_type',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_recipient_identification_type`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_recipient_identification_type
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Yes/no - status<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'yesNo_status',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_yesNo_status`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.yesNo_status
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Yes/no - description',
      dataIndex: 'yesNo_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_yesNo_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.yesNo_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Auswahl services discount - name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'auswahlServicesDiscount_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_auswahlServicesDiscount_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.auswahlServicesDiscount_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Auswahl services discount - description',
      dataIndex: 'auswahlServicesDiscount_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_auswahlServicesDiscount_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.auswahlServicesDiscount_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          ICT service level - name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ictServiceLevel_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictServiceLevel_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictServiceLevel_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'ICT service level - description',
      dataIndex: 'ictServiceLevel_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictServiceLevel_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictServiceLevel_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          ICT service type - name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ictServiceType_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictServiceType_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictServiceType_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'ICT service type - description',
      dataIndex: 'ictServiceType_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictServiceType_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictServiceType_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Level of supporting ICT service - name
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'levelofSupportingICTService_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_levelofSupportingICTService_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.levelofSupportingICTService_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Level of supporting ICT service - description',
      dataIndex: 'levelofSupportingICTService_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_levelofSupportingICTService_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.levelofSupportingICTService_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Alternatives exit - alternative providers identified
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'alternatives_exit_alternative_providers_identified',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_alternative_providers_identified`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_alternative_providers_identified
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Alternatives exit - exit plan exists
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'alternatives_exit_exit_plan_exists',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_exit_plan_exists`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_exit_plan_exists
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Alternatives exit - reintegration possible
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'alternatives_exit_reintegration_possible',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_reintegration_possible`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_reintegration_possible
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Alternatives exit - alternative ict tpp identification
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'alternatives_exit_alternative_ict_tpp_identification',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_alternative_ict_tpp_identification`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_alternative_ict_tpp_identification
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Alternatives exit - description',
      dataIndex: 'alternatives_exit_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Impact of discontinuing function - description',
      dataIndex: 'impactOfDiscontinuingFunction_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_impactOfDiscontinuingFunction_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.impactOfDiscontinuingFunction_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Impact of discontinuing ICT Services - description',
      dataIndex: 'impactOfDiscontinuingIctServices_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_impactOfDiscontinuingIctServices_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.impactOfDiscontinuingIctServices_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Criticality assessment name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'criticality_assessment_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_criticality_assessment_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.criticality_assessment_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Criticality assessment - description',
      dataIndex: 'criticality_assessment_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_criticality_assessment_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.criticality_assessment_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'ICT Systems',
      dataIndex: 'ict_systems',
      key: 'ict_systems',
      render: (_, record) => {
        return (
          <div>
            {(record.ict_systems as IICTSystem[]).map((system: IICTSystem) => {
              const idFieldName = `${system.ict_systems_id}_systems_ict_systems_id`;
              const nameFieldName = `${system.ict_systems_id}_systems_ict_systems_name`;
              const descriptionFieldName = `${system.ict_systems_id}_systems_ict_systems_description`;
              const startDateFieldName = `${system.ict_systems_id}_systems_ict_systems_start_date`;
              const endDateFieldName = `${system.ict_systems_id}_systems_ict_systems_end_date`;
              const rowKey = record.key;
              return (
                <Styled.TooltipContainer
                  key={system.ict_systems_id}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>
                          Name <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(nameFieldName)
                              ? typeof editedData[nameFieldName] === 'boolean'
                                ? editedData[nameFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[nameFieldName])
                              : String(system.ict_systems_name)
                          }
                          onChange={(e) =>
                            handleEditChange(e, nameFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Start date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(startDateFieldName)
                              ? String(editedData[startDateFieldName] ?? '')
                              : String(system.ict_systems_start_date ?? '')
                          }
                          fieldName={startDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, startDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          End date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(endDateFieldName)
                              ? String(editedData[endDateFieldName] ?? '')
                              : String(system.ict_systems_end_date ?? '')
                          }
                          fieldName={endDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, endDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Description </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(system.ict_systems_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(nameFieldName)
                          ? typeof editedData[nameFieldName] === 'boolean'
                            ? editedData[nameFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[nameFieldName])
                          : String(system.ict_systems_name)
                      }
                      readOnly
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Button
               type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewIctSystem(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: 'Certificates',
      dataIndex: 'certificates',
      key: 'certificates',
      render: (_, record) => {
        return (
          <div>
            {(record.certificates as ICertificate[]).map(
              (cert: ICertificate) => {
                const idFieldName = `${cert.certificates_id}_newcert_certificates_id`;
                const descriptionFieldName = `${cert.certificates_id}_newcert_certificates_description`;
                const registryFieldName = `${cert.certificates_id}_newcert_certificates_registry`;
                const issuanceDateFieldName = `${cert.certificates_id}_newcert_certificates_date_of_issuance`;
                const validityFieldName = `${cert.certificates_id}_newcert_certificates_validity`;
                const dateOfRegisterFieldName = `${cert.certificates_id}_newcert_certificates_date_of_certificate_register`;
                const startDateFieldName = `${cert.certificates_id}_newcert_certificates_start_date`;
                const endDateFieldName = `${cert.certificates_id}_newcert_certificates_end_date`;
                const rowKey = record.key;
                return (
                  <Styled.TooltipContainer
                    key={cert.certificates_id}
                    visible={isTooltipVisible[idFieldName]}
                    title={
                      <div>
                        <Styled.TooltipButtonContainer>
                          <Button
                            size="small"
                            type="text"
                            onClick={() => handleCloseTooltip(idFieldName)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                        </Styled.TooltipButtonContainer>
                        <Styled.InputField>
                          <strong>
                            Registry <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(registryFieldName)
                                ? typeof editedData[registryFieldName] ===
                                  'boolean'
                                  ? editedData[registryFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[registryFieldName])
                                : String(cert.certificates_registry)
                            }
                            onChange={(e) =>
                              handleEditChange(e, registryFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Issuance date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(issuanceDateFieldName)
                                ? String(
                                    editedData[issuanceDateFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_issuance ?? '',
                                  )
                            }
                            fieldName={issuanceDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                issuanceDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Validity <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(validityFieldName)
                                ? typeof editedData[validityFieldName] ===
                                  'boolean'
                                  ? editedData[validityFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[validityFieldName])
                                : String(cert.certificates_validity)
                            }
                            onChange={(e) =>
                              handleEditChange(e, validityFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>Certificate Description </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(descriptionFieldName)
                                ? typeof editedData[descriptionFieldName] ===
                                  'boolean'
                                  ? editedData[descriptionFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[descriptionFieldName])
                                : String(cert.certificates_description)
                            }
                            onChange={(e) =>
                              handleEditChange(e, descriptionFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Start date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(startDateFieldName)
                                ? String(editedData[startDateFieldName] ?? '')
                                : String(cert.certificates_start_date ?? '')
                            }
                            fieldName={startDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                startDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            End date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(endDateFieldName)
                                ? String(editedData[endDateFieldName] ?? '')
                                : String(cert.certificates_end_date ?? '')
                            }
                            fieldName={endDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(e, endDateFieldName, record.key)
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Date of register<Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(dateOfRegisterFieldName)
                                ? String(
                                    editedData[dateOfRegisterFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_certificate_register ??
                                      '',
                                  )
                            }
                            fieldName={dateOfRegisterFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                dateOfRegisterFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                      </div>
                    }
                    open={isTooltipVisible[idFieldName] || false}
                    trigger={[]}
                  >
                    <Styled.TagComponent
                      color="blue"
                      onClick={() => handleOpenTooltip(idFieldName)}
                    >
                      <Input
                        value={
                          editedData.hasOwnProperty(descriptionFieldName)
                            ? typeof editedData[descriptionFieldName] ===
                              'boolean'
                              ? editedData[descriptionFieldName]
                                ? 'true'
                                : 'false'
                              : String(editedData[descriptionFieldName])
                            : String(cert.certificates_description)
                        }
                        readOnly
                      />
                    </Styled.TagComponent>
                    <Styled.GlobalStyle />
                  </Styled.TooltipContainer>
                );
              },
            )}
            <Button
               type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewCertificate(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: 'Data description',
      dataIndex: 'data',
      key: 'data',
      render: (_, record) => {
        return (
          <div>
            {record.data.map((itemData: IData, index: number) => {
              const idFieldName = `${itemData.data_id}_newItem_data_id`;
              const descriptionFieldName = `${itemData.data_id}_newItem_data_description`;
              const restLocationFieldName = `${itemData.data_id}_newItem_data_location_of_data_at_rest`;
              const managementLocationFieldName = `${itemData.data_id}_newItem_data_location_of_data_management`;
              const sensitivenessNameFieldName = `${itemData.data_id}_newItem_dataSensitiveness_sensitiveness_name`;
              const startDateFieldName = `${itemData.data_id}_newItem_data_start_date`;
              const endDateFieldName = `${itemData.data_id}_newItem_data_end_date`;
              const sensitivenessDescriptionFieldName = `${itemData.data_id}_newItem_dataSensitiveness_description`;
              const sensitivenessOfDataStoredByICTTPSPName = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_name`;
              const sensitivenessOfDataStoredByICTTPSPDescription = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_description`;
              const rowKey = record.key;
              return (
                <Styled.TooltipContainer
                  key={itemData.data_id || index}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>
                          Location of Data at Rest{' '}
                          <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(restLocationFieldName)
                              ? typeof editedData[restLocationFieldName] ===
                                'boolean'
                                ? editedData[restLocationFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[restLocationFieldName])
                              : String(itemData.data_location_of_data_at_rest)
                          }
                          onChange={(e) =>
                            handleEditChange(e, restLocationFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Location of Data Management{' '}
                          <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              managementLocationFieldName,
                            )
                              ? typeof editedData[
                                  managementLocationFieldName
                                ] === 'boolean'
                                ? editedData[managementLocationFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[managementLocationFieldName],
                                  )
                              : String(
                                  itemData.data_location_of_data_management,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              managementLocationFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(itemData.data_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Start date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(startDateFieldName)
                              ? String(editedData[startDateFieldName] ?? '')
                              : String(itemData.data_start_date ?? '')
                          }
                          fieldName={startDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, startDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          End date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(endDateFieldName)
                              ? String(editedData[endDateFieldName] ?? '')
                              : String(itemData.data_end_date ?? '')
                          }
                          fieldName={endDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, endDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Sensitiveness Name{' '}
                          <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessNameFieldName,
                            )
                              ? typeof editedData[
                                  sensitivenessNameFieldName
                                ] === 'boolean'
                                ? editedData[sensitivenessNameFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[sensitivenessNameFieldName])
                              : String(
                                  itemData.dataSensitiveness_sensitiveness_name,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessNameFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Sensitiveness Description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessDescriptionFieldName,
                            )
                              ? typeof editedData[
                                  sensitivenessDescriptionFieldName
                                ] === 'boolean'
                                ? editedData[sensitivenessDescriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[
                                      sensitivenessDescriptionFieldName
                                    ],
                                  )
                              : String(itemData.dataSensitiveness_description)
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessDescriptionFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Sensitiveness of data stored by ICT TPSP Name{' '}
                          <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessOfDataStoredByICTTPSPName,
                            )
                              ? typeof editedData[
                                  sensitivenessOfDataStoredByICTTPSPName
                                ] === 'boolean'
                                ? editedData[
                                    sensitivenessOfDataStoredByICTTPSPName
                                  ]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[
                                      sensitivenessOfDataStoredByICTTPSPName
                                    ],
                                  )
                              : String(
                                  itemData.sensitivenessOfDataStoredbyICTTPSP_name,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessOfDataStoredByICTTPSPName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Sensitiveness of data stored by ICT TPSP Description
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessOfDataStoredByICTTPSPDescription,
                            )
                              ? typeof editedData[
                                  sensitivenessOfDataStoredByICTTPSPDescription
                                ] === 'boolean'
                                ? editedData[
                                    sensitivenessOfDataStoredByICTTPSPDescription
                                  ]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[
                                      sensitivenessOfDataStoredByICTTPSPDescription
                                    ],
                                  )
                              : String(
                                  itemData.sensitivenessOfDataStoredbyICTTPSP_description,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessOfDataStoredByICTTPSPDescription,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(descriptionFieldName)
                          ? typeof editedData[descriptionFieldName] ===
                            'boolean'
                            ? editedData[descriptionFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[descriptionFieldName])
                          : String(itemData.data_description)
                      }
                      readOnly
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Button
                type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewData(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Level of Reliance ICT Service - name
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'levelOfRelianceICTService_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_levelOfRelianceICTService_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.levelOfRelianceICTService_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Level of Reliance ICT Service - description',
      dataIndex: 'levelOfRelianceICTService_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_levelOfRelianceICTService_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.levelOfRelianceICTService_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
  ];

  return (
    <>
      {shouldFetch && (
        <Styled.AreaButton type="primary" onClick={addNewRow}>
          Add Row
        </Styled.AreaButton>
      )}
      <Styled.TooltipTableContainer>
        <Table
          dataSource={dataWithKey}
          rowKey="key"
          scroll={{ x: 'max-content' }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePaginationChange,
            showSizeChanger: pagination.showSizeChanger,
            pageSizeOptions: pagination.pageSizeOptions,
            position: pagination.position,
          }}
          columns={columns}
        />
      </Styled.TooltipTableContainer>
      {query.entity && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
        )}
    </>
  );
};

export default ICTServices;
