import React from 'react';
import WorkingArea from 'components/WorkingAreas/WorkingAreas';
import InsourceLayout from 'components/Layout/DataEntryLayout/Layout';
import OutsourceLayout from 'components/Layout/ReportingLayout/Layout';
import EntitySteps from 'pages/EntitySteps/EntitySteps';
import TPPSteps from 'pages/TPPSteps/TPPSteps';
import BranchSteps from 'pages/BranchSteps/BranchSteps';
import FunctionSteps from 'pages/FunctionSteps/FunctionSteps';
import EntityMaintainingregistrySteps from 'pages/EntityMaintainingRegistry/EntityMaintainingRegistry';
import LoginForm from 'pages/Login/Login';
import ForgotPass from 'pages/ForgotPassword/ForgotPassword';
import ICTServiceSteps from 'pages/ICTServiceSteps/ICTServiceSteps';
import DataSteps from 'pages/Data/DataSteps';
import ContractualInformationSteps from 'pages/ContractualInformationSteps/ContractualInformationSteps';
import ChangePassword from 'pages/ChangePassword/ChangePassword';
import SignUp from 'pages/Signup/Signup';
import AdditionalReportingLayout from 'components/Layout/AdditionalReporting/Layout';
import ResetPass from 'pages/ResetPassword/ResetPassword';

const routes = [
  {
    id: 1,
    path: '/',
    private: false,
    component: () => <LoginForm />,
  },
  {
    id: 2,
    path: '/working_area',
    private: true,
    component: () => <WorkingArea />,
  },
  {
    id: 3,
    path: '/data_entry',
    private: true,
    component: () => <InsourceLayout />,
  },
  {
    id: 4,
    path: '/reporting',
    private: true,
    component: () => <OutsourceLayout />,
  },
  {
    id: 5,
    path: '/data_entry/entity',
    private: true,
    component: () => <EntitySteps />,
  },
  {
    id: 6,
    path: '/data_entry/third_party_provider',
    private: true,
    component: () => <TPPSteps />,
  },
  {
    id: 7,
    path: '/data_entry/branch',
    private: true,
    component: () => <BranchSteps />,
  },
  {
    id: 8,
    path: '/data_entry/function',
    private: true,
    component: () => <FunctionSteps />,
  },
  {
    id: 9,
    path: '/forgot_password',
    private: false,
    component: () => <ForgotPass />,
  },
  {
    id: 10,
    path: '/data_entry/ict_service',
    private: true,
    component: () => <ICTServiceSteps />,
  },
  {
    id: 11,
    path: '/data_entry/contractual_information',
    private: true,
    component: () => <ContractualInformationSteps />,
  },
  {
    id: 12,
    path: '/change_pass',
    private: true,
    component: () => <ChangePassword />,
  },
  {
    id: 13,
    path: '/sign_up',
    private: false,
    component: () => <SignUp />,
  },
  {
    id: 14,
    path: '/additional_reporting',
    private: true,
    component: () => <AdditionalReportingLayout />,
  },
  {
    id: 15,
    path: '/reset_password',
    private: false,
    component: () => <ResetPass />,
  },
  {
    id: 16,
    path: '/data_entry/entity_maintaining_registry',
    private: true,
    component: () => <EntityMaintainingregistrySteps />,
  },
  {
    id: 17,
    path: '/data_entry/data',
    private: true,
    component: () => <DataSteps />,
  },
];

export default routes;
