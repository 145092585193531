import ApiService from 'utils/ApiCalls';
import axios from 'axios';
import { notification } from 'antd';

const fetchFunctionbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postFunctionByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Function:', error);
  }
};

const insertFunctions = async (functions: any) => {
  try {
    const response = await ApiService.postFunctions(functions);
    notification.success({
      message: 'Submission Successful',
      description: 'The data was successfully submitted.',
      placement: 'topRight',
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchLicencedActivities = async () => {
  try {
    const response = await ApiService.getLicensedActivities();
    return response;
  } catch (error) {
    console.error('Error fetching the licenced activities:', error);
  }
};

const fetchDataSensitiveness = async () => {
  try {
    const response = await ApiService.getDataSensitiveness();
    return response;
  } catch (error) {
    console.error('Error fetching the data sensitiveness:', error);
  }
};
const fetchFunctionLevels = async () => {
  try {
    const response = await ApiService.getFunctionLevels();
    return response;
  } catch (error) {
    console.error('Error fetching the function levels:', error);
  }
};

const fetchICTSystems = async () => {
  try {
    const response = await ApiService.getICTSystems();
    return response;
  } catch (error) {
    console.error('Error fetching the ict systems:', error);
  }
};

const inactivateFunction = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateFunction(dataList);

    const { errors, deleted_functions, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach((error: { function_id: number; error: string }) => {
        notification.error({
          message: `Error for Function ID: ${error.function_id}`,
          description: error.error,
          duration: 10,
        });
      });
    } else if (deleted_functions && deleted_functions.length > 0) {
      notification.success({
        message: 'Function Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate entity. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate entity');
  }
};

const inactivateFunctionLevel = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateFunctionLevel(dataList);

    const { errors, deleted_function_levels, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach((error: { function_level_id: number; error: string }) => {
        if (!error.error.includes('Function level not found.')) {
          notification.error({
            message: `Error for Function Level  ID: ${error.function_level_id}`,
            description: error.error,
            duration: 10,
          });
        }
      });
    } else if (deleted_function_levels && deleted_function_levels.length > 0) {
      notification.success({
        message: 'Function Level  Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate Function Level. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate Function Level.');
  }
};

const updateRecoveryObjective = async (objectives: any) => {
  try {
    const response = await ApiService.updateRecoveryObjective(objectives);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchFunctionsCriticality = async () => {
  try {
    const response = await ApiService.getFunctionsCriticality();
    return response;
  } catch (error) {
    console.error('Error fetching the function criticality', error);
  }
};

export default {
  fetchFunctionbyEntityName,
  insertFunctions,
  fetchLicencedActivities,
  fetchDataSensitiveness,
  fetchFunctionLevels,
  inactivateFunction,
  fetchICTSystems,
  inactivateFunctionLevel,
  updateRecoveryObjective,
  fetchFunctionsCriticality,
};
