import React from 'react';
import StepComponent from 'components/StepComponent/CustomSteps';
import Data from 'components/Tables/DataEntry/Data/Data/Data';

const steps = [
  {
    title: 'Data',
    content: <Data />,
  },
];

const DataSteps: React.FC = () => {
  return <StepComponent steps={steps} title="Data Steps" />;
};

export default DataSteps;
