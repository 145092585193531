import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';

interface DataType {
  key: React.Key;
  arrangement_reference_number: string;
  linked_contractual_arrangement: string;
  name_of_entity: string;
}

interface RT0203Props {
  entityName: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Contractual arrangement reference number',
    width: 100,
    dataIndex: 'arrangement_reference_number',
    key: 'arrangement_reference_number',
    fixed: 'left',
  },
  {
    title:
      'Contractual arrangement linked to the contractual arrangement referred in RT.02.03.0010',
    width: 100,
    dataIndex: 'linked_contractual_arrangement',
    key: 'linked_contractual_arrangement',
    fixed: 'left',
  },
  {
    title: 'Entity name',
    width: 100,
    dataIndex: 'name_of_entity',
    key: 'name_of_entity',
  },
];

const RT0203: React.FC<RT0203Props> = ({ entityName }) => {
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        entity_name: entityName,
      };
      try {
        const fetchedData =
          await reportingOp.fetchEntityContractualArrangements(params);
        if (Array.isArray(fetchedData)) {
          const formattedData = fetchedData.map((item: any, index: number) => ({
            key: index,
            arrangement_reference_number: item.arrangement_reference_number,
            linked_contractual_arrangement: item.linked_contractual_arrangement,
            name_of_entity: item.name_of_entity,
          }));
          setData(formattedData);
        } else {
          console.error('Fetched data is not an array:', fetchedData);
        }
      } catch (error) {
        console.error('Error fetching entity contractual arrangements:', error);
        setData([]);
      }
    };

    if (entityName) {
      fetchData();
    }
  }, [entityName, dispatch]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default RT0203;
