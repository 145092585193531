import ApiService from 'utils/ApiCalls';
import axios from 'axios';

// const fetchICTServices = async () => {
//   // try {
//   //   const response = await ApiService.fetchICTServices('Amir', 'Meidas');
//   //   console.log(response, 'response');
//   //   return response;
//   // } catch (error) {
//   //   console.error('Error fetching the ICT Services:', error);
//   // }
// };

const fetchICTServices = async () => {
  try {
    const response = await ApiService.fetchICTServices();
    console.log(response, 'Fetched ICT Services');
    return response;
  } catch (error) {
    console.error('Error fetching the ICT Services:', error);
    throw error;
  }
};

const fetchFunctionsbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postFunctionsbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Functions:', error);
  }
};

const fetchContractualArrangementsbyEntityName = async (
  entityTypeData: any,
) => {
  try {
    const response =
      await ApiService.postContractualArrangementsbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Contractual Arrangements:', error);
  }
};
export default {
  fetchICTServices,
  fetchFunctionsbyEntityName,
  fetchContractualArrangementsbyEntityName,
};
