import AxiosInstance from './AxiosInterceptor';
import { IChangePass, IResetPass } from 'store/ducks/globalTypes';

const ApiService = {
  login: (data: { email: string; password: string }) =>
    AxiosInstance({
      url: '/api/login',
      method: 'post',
      data,
    }),

  logout: () =>
    AxiosInstance({
      url: '/api/logout',
      method: 'post',
    }),

  forgotPassword: (email: string) =>
    AxiosInstance({
      url: '/api/forgot-password',
      method: 'post',
      data: { email },
    }),

  resetPassword: (formData: IResetPass) =>
    AxiosInstance({
      url: '/api/reset-password',
      method: 'post',
      data: formData,
    }),

  signup: (data: any) =>
    AxiosInstance({
      url: '/api/signup',
      method: 'post',
      data,
    }),

  changePass: (
    formData: IChangePass,
    token: string | qs.ParsedQs | string[] | qs.ParsedQs[],
  ) =>
    AxiosInstance({
      url: '/api/change-password',
      method: 'post',
      params: { token },
      data: formData,
    }),

  getFilters: () =>
    AxiosInstance({
      url: '/api/entities',
      method: 'get',
    }),

  postEntity: (entityData: any, token: string) =>
    AxiosInstance({
      url: '/api/insert-entity',
      method: 'post',
      params: { token },
      data: entityData,
    }),

  getEntityContractualArrangements: (data: any) => {
    return AxiosInstance({
      url: '/api/entity-contractual-arrangements',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getBranches: (data: any) => {
    return AxiosInstance({
      url: '/api/entity-branches',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getContractualArrangementsLei: (data: any) => {
    return AxiosInstance({
      url: '/api/contractual-arrangements-lei',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getContractualArrangementsICT: (data: any) => {
    return AxiosInstance({
      url: '/api/contractual-arrangements-ict',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getContractualArrangementsICTUse: (data: any) => {
    return AxiosInstance({
      url: '/api/contractual-arrangements-ict-use',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getRT0101: (data: any) => {
    return AxiosInstance({
      url: '/api/RT-01-01',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getRT0102: (data: any) => {
    return AxiosInstance({
      url: '/api/RT-01-02',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getRT0201: (data: any) => {
    return AxiosInstance({
      url: '/api/RT-02-01',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getRT0202: (data: any) => {
    return AxiosInstance({
      url: '/api/RT-02-02',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getRT0302: (data: any) => {
    return AxiosInstance({
      url: '/api/RT-03-02',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getRT0501: (data: any) => {
    return AxiosInstance({
      url: '/api/RT-05-01',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getRT0502: (data: any) => {
    return AxiosInstance({
      url: '/api/RT-05-02',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getRT0601: (data: any) => {
    return AxiosInstance({
      url: '/api/RT-06-01',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getRT0701: (data: any) => {
    return AxiosInstance({
      url: '/api/RT-07-01',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },
  getART0101: (data: any) => {
    return AxiosInstance({
      url: '/api/AR-01-01',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  getB9901: (data: any) => {
    return AxiosInstance({
      url: '/api/report-99',
      method: 'get',
      params: {
        entity_id: data.entity_name,
        date: data.date,
      },
    });
  },

  postEntityType: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-entity-type',
      method: 'post',
      data: entityData,
    }),

  postHierarchyGroup: (hierarchyGroupData: any) =>
    AxiosInstance({
      url: '/api/insert-hierarchy-group',
      method: 'post',
      data: hierarchyGroupData,
    }),

  getAllEntities: () => {
    return AxiosInstance({
      url: '/api/get-all-entities',
      method: 'get',
    });
  },

  postEntityTypeByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-type',
      method: 'post',
      data: entityData,
    }),

  postHierarchyGroupByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-hierarchy',
      method: 'post',
      data: entityData,
    }),

  postNatureOfEntityByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-nature',
      method: 'post',
      data: entityData,
    }),

  postCompetentAuthorityByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-competent-authority',
      method: 'post',
      data: entityData,
    }),

  postCountryByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-country',
      method: 'post',
      data: entityData,
    }),

  postValueOfTotalAssetsByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-value-of-total-assets',
      method: 'post',
      data: entityData,
    }),

  postActivitiesByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-activities-by-entity-type',
      method: 'post',
      data: entityData,
    }),

  postEntitiesByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-by-id',
      method: 'post',
      data: entityData,
    }),

  postTPPbyEntityName: (tpp: any) =>
    AxiosInstance({
      url: '/api/get-entity-third-party-service-provider',
      method: 'post',
      data: tpp,
    }),

  postNatureOfEntity: (natureOfEntity: any) =>
    AxiosInstance({
      url: '/api/insert-nature-of-entity',
      method: 'post',
      data: natureOfEntity,
    }),

  postCompetentAuthority: (competentAuthority: any) =>
    AxiosInstance({
      url: '/api/insert-competent-authority',
      method: 'post',
      data: competentAuthority,
    }),
  postCountry: (country: any) =>
    AxiosInstance({
      url: '/api/insert-country',
      method: 'post',
      data: country,
    }),

  postValueOfTotalAssets: (valueOfTotalAssets: any) =>
    AxiosInstance({
      url: '/api/insert-value-of-total-assets',
      method: 'post',
      data: valueOfTotalAssets,
    }),

  postActivitiesByTypeEntity: (activitiesByTypeEntity: any) =>
    AxiosInstance({
      url: '/api/insert-activities-by-type-entity',
      method: 'post',
      data: activitiesByTypeEntity,
    }),

  getNonSubstReason: () => {
    return AxiosInstance({
      url: '/api/list-non-subst-reasons',
      method: 'get',
    });
  },

  fetchNonSubstReason: (tpp_name: any, tpp_id: string) =>
    AxiosInstance({
      url: '/api/get-non-subst-reason-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),

  fetchPersonType: (tpp_name: any, tpp_id: string) =>
    AxiosInstance({
      url: '/api/get-person-type-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),

  fetchCurrencyAndCosts: (tpp_name: any, tpp_id: string) =>
    AxiosInstance({
      url: '/api/get-costs-and-currency-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),

  fetchCertificates: (tpp_name: string, tpp_id: string) =>
    AxiosInstance({
      url: '/api/filter-certificates-by-tpp',
      method: 'post',
      data: { tpp_name, tpp_id },
    }),
  postTPP: (postTPP: any) =>
    AxiosInstance({
      url: '/api/insert-third-party-service-provider',
      method: 'post',
      data: postTPP,
    }),
  postNonSubstReason: (nonSubstReason: any) =>
    AxiosInstance({
      url: '/api/insert-non-subst-reason',
      method: 'post',
      data: nonSubstReason,
    }),

  postPersonTypes: (personTypes: any) =>
    AxiosInstance({
      url: '/api/insert-person-type',
      method: 'post',
      data: personTypes,
    }),

  postCurrencyAndCosts: (currencyAndCosts: any) =>
    AxiosInstance({
      url: '/api/insert-currency-costs',
      method: 'post',
      data: currencyAndCosts,
    }),
  postCertificates: (certificates: any) =>
    AxiosInstance({
      url: '/api/insert-certificates',
      method: 'post',
      data: certificates,
    }),

  postBranchByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-branch-country-assets-by-entity',
      method: 'post',
      data: entityData,
    }),

  postBranches: (branches: any) =>
    AxiosInstance({
      url: '/api/insert-branch-country-assets',
      method: 'post',
      data: branches,
    }),

  postFunctionByEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/filter-function-details',
      method: 'post',
      data: entityData,
    }),

  postFunctions: (functions: any) =>
    AxiosInstance({
      url: '/api/insert-function-details',
      method: 'post',
      data: functions,
    }),
  postFunctionsbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-entity-functions',
      method: 'post',
      data: entityData,
    }),

  postContractualArrangementsbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-contractual-arrangements-entity',
      method: 'post',
      data: entityData,
    }),

  fetchICTServices: (
    selectedContractForICT: any,
    selectedEntityForICT: any,
    selectedFunctionForICT: any,
    selectedTPPForICT: any,
  ) => {
    return AxiosInstance({
      url: '/api/filter-ict-services',
      method: 'post',
      data: {
        entity_id: selectedEntityForICT ? selectedEntityForICT.entityId : null,
        entity_name: selectedEntityForICT ? selectedEntityForICT.entity : null,
        contractual_arrangements_id: selectedContractForICT
          ? selectedContractForICT.value
          : null,
        tpp_id: selectedTPPForICT ? selectedTPPForICT.value : null,
        tpp_name: selectedTPPForICT ? selectedTPPForICT.label : null,
        function_id: selectedFunctionForICT
          ? selectedFunctionForICT.value
          : null,
        function_name: selectedFunctionForICT
          ? selectedFunctionForICT.label
          : null,
      },
    });
  },

  fetchDataWithICT: (
    selectedEntityForICT: any,
    selectedICTForContractualInformation: any,
  ) => {
    return AxiosInstance({
      url: '/api/filter-ict-service-ict-system-details',
      method: 'post',
      data: {
        entity_id: selectedEntityForICT ? selectedEntityForICT.entityId : null,
        ict_service_id: selectedICTForContractualInformation
          ? selectedICTForContractualInformation.value
          : null,
      },
    });
  },

  fetchDataWithFunctions: (
    selectedEntityForICT: any,
    selectedFunctionForICT: any,
  ) => {
    return AxiosInstance({
      url: '/api/filter-function-ict-system-details',
      method: 'post',
      data: {
        entity_id: selectedEntityForICT ? selectedEntityForICT.entityId : null,
        function_id: selectedFunctionForICT
          ? selectedFunctionForICT.value
          : null,
      },
    });
  },

  postICTServices: (ICTServices: any) =>
    AxiosInstance({
      url: '/api/insert-ict-service-details',
      method: 'post',
      data: ICTServices,
    }),

  postBranchesbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-branches-entity',
      method: 'post',
      data: entityData,
    }),

  postICTServicesbyEntityName: (entityData: any) =>
    AxiosInstance({
      url: '/api/get-ict-services-entity',
      method: 'post',
      data: entityData,
    }),

  fetchContractualInformation: (
    selectedBranchForContractualInformation: any,
    selectedICTForContractualInformation: any,
    selectedTPPForContractualInformation: any,
    selectedEntityForContractualInformation: any,
  ) => {
    return AxiosInstance({
      url: '/api/filter-contractual-arrangements',
      method: 'post',
      data: {
        branch_id: selectedBranchForContractualInformation
          ? selectedBranchForContractualInformation.value
          : null,
        branch_name: selectedBranchForContractualInformation
          ? selectedBranchForContractualInformation.label
          : null,
        entity_id: selectedEntityForContractualInformation
          ? selectedEntityForContractualInformation.entityId
          : null,
        entity_name: selectedEntityForContractualInformation
          ? selectedEntityForContractualInformation.entity
          : null,
        tpp_id: selectedTPPForContractualInformation
          ? selectedTPPForContractualInformation.value
          : null,
        tpp_name: selectedTPPForContractualInformation
          ? selectedTPPForContractualInformation.label
          : null,
        ict_service_id: selectedICTForContractualInformation
          ? selectedICTForContractualInformation.value
          : null,
      },
    });
  },

  postContractualInformation: (contractualInformation: any) =>
    AxiosInstance({
      url: '/api/insert-contractual-details',
      method: 'post',
      data: contractualInformation,
    }),

  fetchUserActivityStatus: (username: string) =>
    AxiosInstance({
      url: '/api/toggle_user_statuss',
      method: 'post',
      data: username,
    }),

  getLicensedActivities: () =>
    AxiosInstance({
      url: '/api/get-licensed-activities',
      method: 'get',
    }),

  getICTServiceTypes: () =>
    AxiosInstance({
      url: '/api/get-ict-service-types',
      method: 'get',
    }),

  getTerminatReasons: () =>
    AxiosInstance({
      url: '/api/get-terminat-reasons',
      method: 'get',
    }),

  getSensitivenessOfData: () =>
    AxiosInstance({
      url: '/api/get-sensitiveness-of-data',
      method: 'get',
    }),

  getLevelsOfReliance: () =>
    AxiosInstance({
      url: '/api/get-levels-of-reliance',
      method: 'get',
    }),

  getCriticalityAssessments: () =>
    AxiosInstance({
      url: '/api/get-criticality-assessments',
      method: 'get',
    }),

  getICTServiceSubstitutability: () =>
    AxiosInstance({
      url: '/api/get-ict-provider-substitutability',
      method: 'get',
    }),

  getReintegrationPossibilities: () =>
    AxiosInstance({
      url: '/api/get-reintegration-possibilities',
      method: 'get',
    }),

  getImpactOfDiscontinuingICTServices: () =>
    AxiosInstance({
      url: '/api/get-impact-of-discontinuing-ict-services',
      method: 'get',
    }),

  getAlternativesExit: () =>
    AxiosInstance({
      url: '/api/get-alternatives-exit',
      method: 'get',
    }),

  getEntityTypes: () =>
    AxiosInstance({
      url: '/api/get-entity-types',
      method: 'get',
    }),

  getHierarchyInGroups: () =>
    AxiosInstance({
      url: '/api/get-hierarchy-groups',
      method: 'get',
    }),

  getNatureID: () =>
    AxiosInstance({
      url: '/api/get-nature-of-entities',
      method: 'get',
    }),

  getCompetentAuthorities: () =>
    AxiosInstance({
      url: '/api/get-competent-authorities',
      method: 'get',
    }),

  getCountries: () =>
    AxiosInstance({
      url: '/api/get-countries',
      method: 'get',
    }),

  getCurrencies: () =>
    AxiosInstance({
      url: '/api/get-currencies',
      method: 'get',
    }),

  getListOfBranches: () =>
    AxiosInstance({
      url: '/api/list-branches',
      method: 'get',
    }),

  getValueOfTotalAssets: () =>
    AxiosInstance({
      url: '/api/get-value-of-total-assets',
      method: 'get',
    }),

  getContractualArrangementTypes: () =>
    AxiosInstance({
      url: '/api/get-contractual-arrangement-types',
      method: 'get',
    }),

  getContractTypes: () =>
    AxiosInstance({
      url: '/api/get-contract-types',
      method: 'get',
    }),

  getContractualArrangements: () =>
    AxiosInstance({
      url: '/api/get-contractual-arrangements',
      method: 'get',
    }),

  getAuswahlFunctionDiscont: () =>
    AxiosInstance({
      url: '/api/get-auswahl-function-discont',
      method: 'get',
    }),

  getFunctionLevels: () =>
    AxiosInstance({
      url: '/api/get-function-levels',
      method: 'get',
    }),

  getFunctions: () =>
    AxiosInstance({
      url: '/api/get-functions',
      method: 'get',
    }),

  getICTServices: () =>
    AxiosInstance({
      url: '/api/get-ict-services',
      method: 'get',
    }),

  getDataSensitiveness: () =>
    AxiosInstance({
      url: '/api/get-data-sensitiveness',
      method: 'get',
    }),

  getCosts: () =>
    AxiosInstance({
      url: '/api/get-costs',
      method: 'get',
    }),

  getNonSubstReasons: () =>
    AxiosInstance({
      url: '/api/get-non-subst-reasons',
      method: 'get',
    }),

  getListPersonTypes: () =>
    AxiosInstance({
      url: '/api/list-person-types',
      method: 'get',
    }),

  inactivateEntity: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-entity',
      method: 'post',
      data: { data_list: dataList },
    }),

  inactivateEntityType: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-entity-type',
      method: 'post',
      data: { data_list: dataList },
    }),

  inactivateTPP: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-third-party-service-provider',
      method: 'post',
      data: { data_list: dataList },
    }),

  inactivateBranch: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-branch',
      method: 'post',
      data: { data_list: dataList },
    }),

  inactivateFunction: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-function',
      method: 'post',
      data: { data_list: dataList },
    }),

  inactivateICTService: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-ict-service',
      method: 'post',
      data: { data_list: dataList },
    }),

  inactivateContractualArrangement: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-contractual-arrangement',
      method: 'post',
      data: { data_list: dataList },
    }),

  inactivateLicencedActivity: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-selection-licenced-act',
      method: 'post',
      data: { data_list: dataList },
    }),

  inactivateCertificates: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-certificates',
      method: 'post',
      data: { data_list: dataList },
    }),

  inactivateICTSystem: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-ict-system',
      method: 'post',
      data: { data_list: dataList },
    }),
  inactivateData: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-data',
      method: 'post',
      data: { data_list: dataList },
    }),
  inactivateFunctionLevel: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-function-level',
      method: 'post',
      data: { data_list: dataList },
    }),
  inactivateICTLevel: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-ict-service-level',
      method: 'post',
      data: { data_list: dataList },
    }),
  inactivateCosts: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-costs',
      method: 'post',
      data: { data_list: dataList },
    }),
  inactivateContract: (dataList: number[]) =>
    AxiosInstance({
      url: '/api/inactivate-contracts',
      method: 'post',
      data: { data_list: dataList },
    }),

  getICTSystems: () =>
    AxiosInstance({
      url: '/api/get-ict-systems',
      method: 'get',
    }),

  getProviderCodeTypes: () =>
    AxiosInstance({
      url: '/api/get-third-party-service-provider-code-types',
      method: 'get',
    }),

  getFinancialEntities: () => {
    return AxiosInstance({
      url: '/api/get-financial-entities',
      method: 'get',
    });
  },

  getFinancialEntityById: (entityId: number | null) =>
    AxiosInstance({
      url: '/api/get-financial-entity-by-id',
      method: 'post',
      data: { financial_entity_id: entityId },
    }),

  updateRecoveryObjective: (recoveryObjective: any) =>
    AxiosInstance({
      url: '/api/update-function-recovery-objectives',
      method: 'post',
      data: recoveryObjective,
    }),

  postFinancialEntity: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-financial-entity-lei',
      method: 'post',
      data: entityData,
    }),

  getICTLevels: () =>
    AxiosInstance({
      url: '/api/get-ict-service-levels',
      method: 'get',
    }),

  getSupportLevel: () =>
    AxiosInstance({
      url: '/api/get-level-of-supporting-ict-service',
      method: 'get',
    }),

  getYesNo: () =>
    AxiosInstance({
      url: '/api/get-yes-no-status',
      method: 'get',
    }),

  getDoraRegulatorCriticality: () =>
    AxiosInstance({
      url: '/api/get-dora-regulator-criticality',
      method: 'get',
    }),

  getFunctionsCriticality: () =>
    AxiosInstance({
      url: '/api/get-functions-criticality',
      method: 'get',
    }),

  getICTServicesCriticality: () =>
    AxiosInstance({
      url: '/api/get-ict-services-criticality',
      method: 'get',
    }),

  getLocalRegulatorCriticality: () =>
    AxiosInstance({
      url: '/api/get-local-regulator-criticality',
      method: 'get',
    }),

  getDoraCriticality: () =>
    AxiosInstance({
      url: '/api/get-dora-criticality',
      method: 'get',
    }),

  postICTSystemsByEntity: (entity: any) =>
    AxiosInstance({
      url: '/api/get-ict-systems-entity',
      method: 'post',
      data: entity,
    }),
};

export default ApiService;
