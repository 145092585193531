export const columnOrderMap: Record<string, string[]> = {
  'B_01.01': [
    'lei_entity',
    'entity_name',
    'iso_code',
    'entity_type',
    'competent_authority',
    'reporting_date',
  ],
  'B_01.02': [
    'lei_entity',
    'entity_name',
    'country_name',
    'entity_type',
    'hierarchy_in_group',
    'parent_lei',
    'date_of_last_update',
    'integration_date',
    'deletion_date',
    'currency',
    'total_assets',
  ],
  'B_01.03': [
    'branch_identification_code',
    'head_office_lei',
    'branch_name',
    'iso_code',
  ],
  'B_02.01': [
    'arrangement_reference_number',
    'contract_type',
    'overarching_reference_number',
    'currency',
    'annual_expense',
  ],
  'B_02.02': [
    'Contractual arrangement ref. number',
    'LEI of the entity using ICT service',
    'Type of ICT service provider code',
    'Type of ICT service provider code',
    'Function identifier',
    'Type of ICT services',
    'Start date',
    'End date',
    'Termination reason',
    'Entity notice period',
    'Provider notice period',
    'Governing law country',
    'Country of ICT services',
    'Storage of data',
    'Data location (storage)',
    'Data location (processing)',
    'Data sensitiveness',
    'Level of reliance',
  ],
  'B_02.03': [
    'arrangement_reference_number',
    'linked_contractual_arrangement',
  ],
  'B_03.01': [
    'arrangement_reference_number',
    'lei_entity_signing_contractual_arrangement',
  ],
  'B_03.02': [
    'Contractual arrangement ref. number',
    'ICT service provider code',
    'Type of ICT service provider code',
  ],
  'B_03.03': [
    'arrangement_reference_number', 
    'lei_of_entity'
  ],
  'B_04.01': [
    'arrangement_reference_number',
    'lei_entity_making_use_ICT_service',
    'Nature of entity',
    'branch_identification_code',
  ],
  'B_05.01': [
    'ICT service provider code',
    'Type of ICT service provider code',
    'Additional identification code of ICT third-party service provi',
    'Type of additional identification code to identify the ICT thir',
    'Legal name of the ICT third- party service provider',
    'Name of ICT service provider',
    'Type of person',
    'Headquarters country',
    'Reported currency',
    'Total annual expense',
    'Ultimate parent code',
    'Type of ultimate parent code',
  ],
  'B_05.02': [
    'Contractual arrangement ref. number',
    'Type of ICT services',
    'ICT service provider code',
    'Type of ICT service provider code',
    'Rank',
    'Recipient ID code',
    'Recipient ID type',
  ],
  'B_06.01': [
    'Function identifier',
    'Licenced activity',
    'Function name',
    'LEI of the financial entity',
    'Criticality assessment',
    'Reasons for criticality',
    'Date of the last assessment of criticality',
    'Recovery time objective of the function',
    'Recovery point objective of the function',
    'Impact of discontinuing the function',
  ],
  'B_07.01': [
    'Arrangement reference number',
    'ICT service provider identification code',
    'Type of ICT service provider code',
    'Type of ICT services',
    'Substitutability of ICT provider',
    'Reason for non-substitutability',
    'Date of last audit',
    'Exit plan exists',
    'Reintegration possible',
    'Impact of discontinuing ICT services',
    'Alternative providers identified',
    'Alternative ICT TPP identification',
  ],
};