import React, { useState, useEffect } from 'react';
import useFilters from 'utils/hooks/useFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import { notification, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { branchOp } from 'store/ducks/DataEntry/Branch';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import { IBranch, DataType } from 'store/ducks/DataEntry/Branch/types';
import * as Styled from '../../DataEntry.styled';

const Branch: React.FC = () => {
  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<IBranch[]>([]);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleDateChange = (key: number, field: string, dateString: string) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        [field]: dateString,
        isEdited: !item.isNew,
      });
      setDataSource(newData);
    }
  };

  const fetchTPPbyEntity = async () => {
    try {
      const response = await branchOp.fetchBranchbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data;
      const mappedData = [
        {
          key: 0,
          branch_identification_code: data.branch.branch_identification_code,
          id_branch: data.branch.id,
          branch_name: data.branch.branch_name,
          competent_authority_id: data.branch.competent_authority_id,
          start_date: data.branch.start_date,
          end_date: data.branch.end_date,
          description_branch: data.branch.description,
          entity_id: data.branch.entity_id,
          head_office_lei: data.branch.head_office_lei,
          id_country: data.country.id,
          country_name: data.country.name,
          iso_code: data.country.iso_code,
          country_description: data.country.description,
          id_valueOfTotalAssets: data.value_of_total_assets.id,
          value: data.value_of_total_assets.value,
          description_valueOfTotalAssets:
            data.value_of_total_assets.description,
          isNew: false,
          isEdited: false,
        },
      ];
      setDataSource(mappedData);
      setCount(mappedData.length);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchTPPbyEntity();
    }
  }, [query]);

  const handleAddRow = (record?: any, index?: number) => {
    let uniqueIdCounter = 0;
    const generateUniqueId = (): number => {
      uniqueIdCounter += 1;
      const timestampPart = Date.now();
      const randomPart = Math.floor(Math.random() * 1000);

      const uniqueId = Number(
        `${timestampPart}${uniqueIdCounter}${randomPart}`,
      );
      return uniqueId;
    };

    const newData: IBranch = {
      key: generateUniqueId(),
      id_branch: generateUniqueId(),
      head_office_lei: '',
      branch_name: '',
      branch_identification_code: '',
      start_date: '',
      end_date: '',
      description_branch: '',
      entity_id: generateUniqueId(),
      competent_authority_id: generateUniqueId(),
      country_description: '',
      id_country: generateUniqueId(),
      country_name: '',
      iso_code: '',
      id_valueOfTotalAssets: generateUniqueId(),
      value: '',
      description_valueOfTotalAssets: '',
      isNew: true,
    };

    const insertIndex = dataSource.findIndex(
      (item) => item.key === record?.key,
    );

    const updatedDataSource = [
      ...dataSource.slice(0, insertIndex + 1),
      newData,
      ...dataSource.slice(insertIndex + 1),
    ];

    setDataSource(updatedDataSource);
    setCount(count + 1);
  };

  const handleSave = (row: IBranch) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const handleSubmit = async () => {
    const changedData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );

    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited Branches to submit.',
        placement: 'topRight',
      });
      return;
    }

    const formattedData = {
      entity_name: query.entity,
      data_list: [
        {
          Branch: changedData.map((item) => ({
            id: item.id_branch,
            head_office_lei: item.head_office_lei,
            branch_name: item.branch_name,
            branch_identification_code: item.branch_identification_code,
            description: item.description_branch,
            entity_id: item.entity_id,
            competent_authority_id: item.competent_authority_id,
          })),
        },
        {
          Country: changedData.map((item) => ({
            id: item.id_country,
            country_name: item.country_name,
            iso_code: item.iso_code,
            description: item.country_description,
          })),
        },
        {
          ValueOfTotalAssets: changedData.map((item) => ({
            id: item.id_valueOfTotalAssets,
            value: item.value,
            description: item.description_valueOfTotalAssets,
          })),
        },
      ],
    };

    try {
      const response = await branchOp.insertBranches(formattedData);
      notification.success({
        message: 'Submission Successful',
        description: 'The data was successfully submitted.',
        placement: 'topRight',
      });
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: (
        <>
          Head office LEI<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'head_office_lei',
      editable: true,
    },
    {
      title: (
        <>
          Branch name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'branch_name',
      editable: true,
    },
    {
      title: (
        <>
          Branch identification code<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'branch_identification_code',
      editable: true,
    },
    {
      title: (
        <>
          Start Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'start_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                handleDateChange(record.key, 'start_date', formattedDateString);
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          End Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'end_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                handleDateChange(record.key, 'end_date', formattedDateString);
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Branch description',
      dataIndex: 'description_branch',
      editable: true,
    },
    {
      title: (
        <>
          Country name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'country_name',
      editable: true,
    },
    {
      title: (
        <>
          Iso code<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'iso_code',
      editable: true,
    },
    {
      title: 'Country description',
      dataIndex: 'country_description',
      editable: true,
    },
    {
      title: (
        <>
          Value<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'value',
      editable: true,
    },
    {
      title: 'Description for value of total assets',
      dataIndex: 'description_valueOfTotalAssets',
      editable: true,
    },
    {
      title: 'Create',
      dataIndex: 'add_row',
      key: 'add_row',
      width: '10px',
      fixed: 'right',
      render: (_: any, record: { key: React.Key }, index: number) => (
        <Styled.OperationsRow onClick={() => handleAddRow(record, index)}>
          <PlusOutlined />
        </Styled.OperationsRow>
      ),
    },
  ];

  return (
    dataSource && (
      <div>
         {query.entity && (
          <Styled.AreaButton onClick={handleAddRow} type="primary">
            Add a row
          </Styled.AreaButton>
        )}
        <CustomTable
          columns={defaultColumns}
          dataSource={dataSource}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
         {query.entity && (
          <Styled.AreaButton type="primary" onClick={handleSubmit}>
            Submit
          </Styled.AreaButton>
        )}
      </div>
    )
  );
};

export default Branch;
