import { Button, Form } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';

export const StyledFormItem = styled(Form.Item)<IToken>`
  margin-bottom: ${(props) => `${props.token.margin}px`};

  .ant-input,
  .ant-input-affix-wrapper {
    padding: 16px 14px;
    border-radius: ${(props) => `${props.token.borderRadiusSM}px`};
  }

  .ant-input-prefix,
  .ant-input-suffix,
  .ant-input-suffix span {
    font-size: 20px;
    color: #757474 !important;
  }

  .ant-col {
    min-width: 100%;
  }
`;

export const ItemsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-row.ant-form-item-row {
    min-width: 100%;
  }

  .ant-form-item .ant-form-item-label {
    text-align: initial;
  }
`;

export const BottomSection = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  > button {
    align-self: center;
  }
`;

export const StyledButton = styled(Button)`
  min-width: 150px;
`;

export const StyledLink = styled(Link)`
  margin-bottom: 15px;
  color: #ff4d4f;
  display: flex;
  flex-direction: column;

  &:hover {
    color: #ff7875;
  }

  span {
    text-align: center;
  }
`;

export const StyledDisabledButton = styled(Button)`
  margin-bottom: 15px;
  min-width: 205px;
  border-radius: 15px;
`;
