import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGlobalState } from './types';
import initialGlobalState from './initialState';
import { IFilters, ThemeList } from '../globalTypes';
import { ITableFilters } from './types';

const globalSlice = createSlice({
  name: 'global',
  initialState: initialGlobalState(),
  reducers: {
    set_applied_filters_result(state: any, action: PayloadAction<any[]>) {
      state.appliedFiltersResult = Object.values(action.payload);
      state.error = false;
      state.loading = false;
    },
    set_filters_data(state: any, action: PayloadAction<IFilters[]>) {
      state.filtersData = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_table_filters(state: any, action: PayloadAction<ITableFilters>) {
      state.tableFilters = { ...state.tableFilters, ...action.payload };
      state.error = false;
      state.loading = false;
    },
    set_loading(state: IGlobalState) {
      state.loading = true;
    },
    set_error(state: IGlobalState, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    set_theme(state: IGlobalState, action: PayloadAction<ThemeList>) {
      state.scheme = action.payload;
      state.loading = false;
    },
    // set_search_params(state: IGlobalState, action: PayloadAction<string>) {
    //   state.searchParams = action.payload;
    //   state.loading = false;
    // },
  },
});

export default globalSlice;
