import { useEffect } from 'react';
import { useQueryParams, withDefault, ArrayParam } from 'use-query-params';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/configureStore';
import { globalOp } from 'store/ducks/Global';
import { IFilters } from 'store/ducks/globalTypes';

const useAdvancedFilters = () => {
  const dispatch: AppDispatch = useDispatch();
  const FiltersParam = withDefault(ArrayParam, []);
  const [query, setQuery] = useQueryParams({
    entity: FiltersParam,
    tpp: FiltersParam,
    function: FiltersParam,
    contractual_arrangement: FiltersParam,
  });

  const { filtersData } = useSelector((state: RootState) => ({
    filtersData: state.global.filtersData as IFilters[],
  }));

  const handleFilterChange = (key: string, value: string[]) => {
    setQuery((latestQuery) => ({
      ...latestQuery,
      [key]: value,
    }));
  };

  const getOptionsForFilter = (filterName: string) => {
    const filterItem = filtersData.find((item) => item.filter === filterName);
    if (filterItem && Array.isArray(filterItem.options)) {
      return filterItem.options.map((option) => ({
        label: option.name_of_entity,
        value: option.value,
        key: option.id,
      }));
    }
    return [];
  };

  const entityOptions = getOptionsForFilter('entity');
  const tppOptions = getOptionsForFilter('tpp');
  const functionOptions = getOptionsForFilter('function');
  const contractualArrangementOptions = getOptionsForFilter(
    'contractual_arrangement',
  );

  useEffect(() => {
    dispatch(globalOp.setFilters());
  }, [dispatch]);

  return {
    query,
    handleFilterChange,
    entityOptions,
    tppOptions,
    functionOptions,
    contractualArrangementOptions,
  };
};

export default useAdvancedFilters;
