import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import * as Styled from './ThemeSelector.styled';
import { ThemeList } from 'store/ducks/globalTypes';

export interface IThemeSelectorProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  theme: ThemeList;
}

const ThemeSelector: React.FC<IThemeSelectorProps> = ({ children }) => {
  const { scheme } = useSelector((state: RootState) => state.global);

  return (
    <Styled.ThemeContainer theme={scheme as unknown as ThemeList}>
      {children}
    </Styled.ThemeContainer>
  );
};

export default ThemeSelector;
