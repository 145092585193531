import { useState, useEffect } from "react";
import { functionOp } from "store/ducks/DataEntry/Functions";

interface LicensedActivity {
  label: string;
  value: any;
}

const useLicensedActivities = () => {
  const [licensedActivities, setLicensedActivities] = useState<LicensedActivity[]>([]);

  const fetchLicensedActivities = async () => {
    try {
      const response = await functionOp.fetchLicencedActivities();
      if (response?.data.licensed_activities) {
        const formattedOptions = response.data.licensed_activities.map(
          (activity: { name: string; id: any }) => ({
            label: activity.name,
            value: activity.id,
          }),
        );
        setLicensedActivities(formattedOptions);
      }
    } catch (error) {
      console.error("Error fetching licensed activities:", error);
    }
  };

  useEffect(() => {
    fetchLicensedActivities();
  }, []);

  return { licensedActivities, refetch: fetchLicensedActivities };
};

export default useLicensedActivities;
