import styled, { createGlobalStyle } from 'styled-components';
import { Button, Tooltip, Tag } from 'antd';

export const TypeComponent = styled('div')`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 0;
    cursor: pointer;
    color: black !important;
    border: none;
    border-radius: 2px;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  .ant-table-cell a {
    &:hover {
      color: black;
    }
  }
  .ant-picker-dropdown {
    z-index: 1050;
  }
  .ant-table-cell-row-hover .ant-picker {
    pointer-events: auto;
  }
  .ant-picker-input {
    color: black !important;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    // margin: 0 0 16px 0;
    margin: 16px;
  }

  .ant-pagination {
    position: absolute;
    top: -62px;
    right: 0;
  }
`;

export const OperationsRow = styled.a`
  color: black;

  span svg {
    font-size: 20px;
  }
`;

export const AreaButton = styled(Button)`
  margin: 16px;
  z-index: 1;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    .ant-tooltip-inner {
      width: 430px;
      div {
        border-bottom: 1px solid;
        padding: 1px 0;
        input {
          margin-left: 20px;
        }
        strong, span{
          font-size: 16px;
          width: -webkit-fill-available;
        }
      }
    }
  }
`;
export const InputField = styled.div`
  display: flex;

  .ant-picker .ant-picker-input input {
    width: auto;
    display: flex;
    flex: none;
  }
`;
export const TooltipContainer = styled(Tooltip)`
  cursor: pointer;
`;

export const AddButton = styled(Button)`
  &.ant-btn {
    color: black !important;
  }
  .ant-btn-icon > svg {
    fill: black;
  }
`;

export const TooltipButtonContainer = styled('div')`
  text-align: end;

  button {
    color: white;
  }
`;

export const TagComponent = styled(Tag)`
  padding: 0;
  border: none;

  input {
    background: #e6f4ff;
  }

  .ant-input-group-addon {
    cursor: pointer;
    color: #fff !important;
  }
`;

export const TooltipTableContainer = styled('div')`
  .ant-table-wrapper {
    // position: relative;
    // top: -64px;

    .ant-pagination {
      margin: 16px;
      position: absolute;
      top: -64px;
      right: 0;
    }
    .ant-picker-input {
      color: black !important;
    }
  }

  .highlighted-column {
    background-color: #ffcd0b4a;
  }

  }
`;

export const HighlightedTable = styled('div')`
  .highlighted-column {
    background-color: #ffcd0b4a;
  }
`;

export const Asterisx = styled.span`
  color: red;
`;

export const StyledWrapper = styled.div<{ hasEntity?: boolean }>`
  margin-top: ${({ hasEntity }) => (hasEntity ? '60px' : '0')};
`;

export const OpenTooltipBtn = styled(Button)`
  background: #e6f4ff;
  color: black !important;
  padding: 0;
  justify-content: flex-start;
  margin-right: 10px;

  .ant-input-group-addon {
    color: #fff;
    cursor: pointer;
  }
  &: hover {
    background: #e6f4ff !important;
  }
`;

export const ButtonDiv = styled.div<{ isEmpty: boolean }>`
  ${({ isEmpty }) =>
    isEmpty &&
    `
    position: relative;
    top: -67px;
  `}
`;

export const TitleComponent = styled('div')`
  display: flex;

  p {
    margin: 0;
  }
`;

export const ColourReportredFields = styled('div')`
  .ant-select {
    background-color: #ffcd0b4a;
  }
`;

export const IctSystem = styled('div')`
  display: flex;
  gap: 10px;
`;
