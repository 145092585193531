import Images from 'constants/images';

const dataEntries = () => {
  const entries = [
    {
      area: 'entity',
      title: 'Entity',
      infoText: 'info about entity',
      imgSrc: Images.dataEntryAreas,
      href: '/data-entry/entity',
    },
    {
      area: 'third-party-provider',
      title: 'Third Party Provider',
      infoText: 'info about Third party provider',
      imgSrc: Images.dataEntryAreas,
      href: '/data-entry/third-party-provider',
    },
    {
      area: 'function',
      title: 'Function',
      infoText: 'info about Function',
      imgSrc: Images.dataEntryAreas,
      href: '/data-entry/function',
    },
    {
      area: 'ict-service',
      title: 'ICT Service',
      infoText: 'info about ICT_service',
      imgSrc: Images.dataEntryAreas,
      href: '/data-entry/ict-service',
    },
    {
      area: 'contractual-information',
      title: 'Contractual Information',
      infoText: 'info about Contractual information',
      imgSrc: Images.dataEntryAreas,
      href: '/data-entry/contractual-information',
    },
    {
      area: 'branch',
      title: 'Branch',
      infoText: 'info about Branch',
      imgSrc: Images.dataEntryAreas,
      href: '/data-entry/branch',
    },
  ];
  return entries;
};

export default dataEntries;
