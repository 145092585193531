import styled from 'styled-components';
import Background from 'assets/img/image.png';

export const StyledContainer = styled('div')`
  overflow-y: hidden;
  min-height: 100vh;
  background-image: ${() => `url(${Background})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
`;

// export const BgContainer = styled('div')`
//   position: relative;
//   width: 100vw;
//   height: 100vh;
//   overflow: hidden;
// `

// export const BgImage = styled('img')`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   max-width: 100%;
//   max-height: 100%;
// `

export const FormContainer = styled('div')`
  margin-left: 200px;
  margin-top: 180px;
  width: 450px;
  display: flex;
  flex-direction: column;
`;
