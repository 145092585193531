import React, { useState, useEffect } from 'react';
import useFilters from 'utils/hooks/useFilters';
import i18nIsoCountries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import CustomTable from 'components/CustomTable/CustomTable';
import useCountries from 'utils/hooks/fetchHooks/useCountries';
import { notification, Modal } from 'antd';
import { branchOp } from 'store/ducks/DataEntry/Branch';
import { IBranch } from 'store/ducks/DataEntry/Branch/types';
import { generateUniqueId } from 'utils/hooks/generateUniqueId';
import { getDefaultColumns } from 'components/Columns/BranchTableColumns';
import * as Styled from '../../DataEntry.styled';

const Branch: React.FC = () => {
  const { query } = useFilters();
  const { confirm } = Modal;
  const [dataSource, setDataSource] = useState<IBranch[]>([]);
  const { countries } = useCountries();
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  i18nIsoCountries.registerLocale(enLocale);
  const isLoggedIn = localStorage.getItem('authToken');

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleDateChange = (key: number, field: string, dateString: string) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        [field]: dateString,
        isEdited: !item.isNew,
      });
      setDataSource(newData);
    }
  };

  const fetchBranchbyEntity = async () => {
    try {
      const response = await branchOp.fetchBranchbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data;
      const mappedData = [
        {
          key: 0,
          branch_identification_code: data.branch.branch_identification_code,
          id_branch: data.branch.id,
          branch_name: data.branch.branch_name,
          start_date: data.branch.start_date,
          end_date: data.branch.end_date,
          description_branch: data.branch.description,
          entity_id: data.branch.entity_id,
          head_office_lei: data.branch.head_office_lei,
          id_country: data.country.id,
          country_name: data.country.name,
          iso_code: data.country.iso_code,
          country_description: data.country.description,
          isNew: false,
          isEdited: false,
        },
      ];
      setDataSource(mappedData);
      setCount(mappedData.length);
    } catch (error) {
      setDataSource([]);
      console.log(error, 'error');
    }
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = dataSource.filter((item) => item.key !== key);
        setDataSource(updatedDataSource);
        fetchBranchbyEntity();
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: IBranch) => {
    if (record.id_branch === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The branch ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the branch with ID ${record.id_branch}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response = await branchOp.inactivateBranch([record.id_branch]);
          fetchBranchbyEntity();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };

  useEffect(() => {
    if (query.entity) {
      fetchBranchbyEntity();
    }
  }, [query]);

  const handleAddRow = (record?: any, index?: number) => {
    const newData: IBranch = {
      key: generateUniqueId(),
      id_branch: generateUniqueId(),
      head_office_lei: '',
      branch_name: '',
      branch_identification_code: '',
      start_date: '',
      end_date: '9999-01-01',
      description_branch: '',
      entity_id: query.entityId,
      country_description: '',
      id_country: '',
      country_name: '',
      iso_code: '',
      isNew: true,
    };
    const insertIndex = dataSource.findIndex(
      (item) => item.key === record?.key,
    );

    const updatedDataSource = [
      ...dataSource.slice(0, insertIndex + 1),
      newData,
      ...dataSource.slice(insertIndex + 1),
    ];

    setDataSource(updatedDataSource);
    setCount(count + 1);
  };

  const handleSave = (row: IBranch) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const handleSubmit = async () => {
    const changedData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );

    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited Branches to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    const formattedData = {
      entity_name: query.entity,
      data_list: [
        {
          Branch: changedData.map((item) => ({
            id: item.id_branch,
            head_office_lei: item.head_office_lei,
            branch_name: item.branch_name,
            branch_identification_code: item.branch_identification_code,
            description: item.description_branch,
            entity_id: item.entity_id,
            country_id: item.id_country,
            start_date: item.start_date,
            end_date: item.end_date,
          })),
        },
      ],
      token: isLoggedIn,
    };

    try {
      const response = await branchOp.insertBranches(formattedData);
      if (response?.status === 200) {
        notification.success({
          message: 'Submission Successful',
          description: 'The data was successfully submitted.',
          placement: 'topRight',
          duration: 20,
        });
      }
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };
  const columns = getDefaultColumns(
    handleDateChange,
    handleSave,
    handleAddRow,
    handleRemoveRow,
    handleInactivate,
    countries,
  );

  return (
    dataSource && (
      <div>
        {query.entity && (
          <Styled.AreaButton onClick={handleAddRow} type="primary">
            Add a row
          </Styled.AreaButton>
        )}
        <Styled.HighlightedTable>
          <CustomTable
            columns={columns}
            dataSource={dataSource}
            handleSave={handleSave}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </Styled.HighlightedTable>

        {dataSource.length > 0 && (
          <Styled.AreaButton type="primary" onClick={handleSubmit}>
            Submit
          </Styled.AreaButton>
        )}
      </div>
    )
  );
};

export default Branch;
