import ApiService from 'utils/ApiCalls';
import axios from 'axios';

const fetchBranchbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postBranchByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Branch:', error);
  }
};

const insertBranches = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postBranches(entityTypeData);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching Branches:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

export default {
  fetchBranchbyEntityName,
  insertBranches,
};
