export const columnData = [
  {
    code: 'RT.02.01.0020',
    name: 'Type of contractual arrangements',
  },
  {
    code: 'RT.02.01.0020',
    name: 'Type of contractual arrangements',
  },
  {
    code: 'RT.02.01.0020',
    name: 'Type of contractual arrangements',
  },
  {
    code: 'RT.02.01.0020',
    name: 'Type of contractual arrangements',
  },
  {
    code: 'RT.02.01.0020',
    name: 'Type of contractual arrangements',
  },
  {
    code: 'RT.02.01.0170',
    name: 'Impact of discontinuing the function',
  },
  {
    code: 'RT.02.01.0170',
    name: 'Impact of discontinuing the function',
  },
  {
    code: 'RT.02.01.0170',
    name: 'Impact of discontinuing the function',
  },
  {
    code: 'RT.02.01.0170',
    name: 'Impact of discontinuing the function',
  },
  {
    code: 'RT.02.01.0170',
    name: 'Impact of discontinuing the function',
  },
  {
    code: 'RT.02.01.0170',
    name: 'Impact of discontinuing the function',
  },
  {
    code: 'RT.06.01.0110',
    name: 'Impact of discontinuing the ICT services',
  },
  {
    code: 'RT.06.01.0110',
    name: 'Impact of discontinuing the ICT services',
  },
  {
    code: 'RT.06.01.0110',
    name: 'Impact of discontinuing the ICT services',
  },
  {
    code: 'RT.06.01.0110',
    name: 'Impact of discontinuing the ICT services',
  },
  {
    code: 'RT.06.01.0110',
    name: 'Impact of discontinuing the ICT services',
  },
  {
    code: 'RT.06.01.0110',
    name: 'Impact of discontinuing the ICT services',
  },
  {
    code: 'RT.07.01.0050',
    name: 'Possibility of reintegration of the contracted ICT service',
  },
  {
    code: 'RT.07.01.0050',
    name: 'Possibility of reintegration of the contracted ICT service',
  },
  {
    code: 'RT.07.01.0050',
    name: 'Possibility of reintegration of the contracted ICT service',
  },
  {
    code: 'RT.07.01.0090',
    name: 'Sensitiveness of the data stored by the ICT third-party service provider',
  },
  {
    code: 'RT.07.01.0090',
    name: 'Sensitiveness of the data stored by the ICT third-party service provider',
  },
  {
    code: 'RT.07.01.0090',
    name: 'Sensitiveness of the data stored by the ICT third-party service provider',
  },
  {
    code: 'RT.07.01.0090',
    name: 'Sensitiveness of the data stored by the ICT third-party service provider',
  },
  {
    code: 'RT.07.01.0090',
    name: 'Sensitiveness of the data stored by the ICT third-party service provider',
  },
  {
    code: 'RT.07.01.0090',
    name: 'Sensitiveness of the data stored by the ICT third-party service provider',
  },
  {
    code: 'RT.07.01.0090',
    name: 'Sensitiveness of the data stored by the ICT third-party service provider',
  },
  {
    code: 'RT.07.01.0100',
    name: 'Substitutability of the ICT third-party service provider',
  },
  {
    code: 'RT.07.01.0100',
    name: 'Substitutability of the ICT third-party service provider',
  },
  {
    code: 'RT.07.01.0100',
    name: 'Substitutability of the ICT third-party service provider',
  },
  {
    code: 'RT.07.01.0100',
    name: 'Substitutability of the ICT third-party service provider',
  },
  {
    code: 'RT.07.01.0100',
    name: 'Substitutability of the ICT third-party service provider',
  },
];
