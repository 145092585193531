import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from 'auth/PrivateRoute';
import routes from 'constants/routes';

interface IRouteProps {
  id: number;
  path: string;
  private: boolean;
  component: () => JSX.Element;
}

const RouterOutlet = () => (
  <Routes>
    {routes.map((route: IRouteProps) => (
      <Route
        key={route.id}
        path={route.path}
        element={
          route.private ? (
            <PrivateRoute>{route.component()}</PrivateRoute>
          ) : (
            route.component()
          )
        }
      />
    ))}
  </Routes>
);

export default RouterOutlet;
