import React from 'react';
import { Input, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

interface DynamicFieldProps {
  value: string | null;
  fieldName: string;
  rowKey: string | number;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    rowKey: string | number,
    fieldName: string,
  ) => void;
  type?: 'input' | 'date';
  format?: string;
  useDefaultDate?: boolean;
}

const DynamicField: React.FC<DynamicFieldProps> = ({
  value,
  fieldName,
  rowKey,
  onChange,
  type = 'input',
  format = 'YYYY-MM-DD',
  useDefaultDate = false,
}) => {
  const parsedDate =
    useDefaultDate && (!value || !dayjs(value).isValid())
      ? dayjs('9999-01-01')
      : value && dayjs(value).isValid()
        ? dayjs(value)
        : null;

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e, rowKey, fieldName);
  };

  if (type === 'date') {
    return (
      <DatePicker
        value={parsedDate}
        format={format}
        onChange={(date: Dayjs | null) => {
          const formattedDate = date
            ? date.format(format)
            : useDefaultDate
              ? '9999-01-01'
              : '';
          handleFieldChange({
            target: { value: formattedDate },
          } as React.ChangeEvent<HTMLInputElement>);
        }}
      />
    );
  }

  return <Input value={value || ''} onChange={handleFieldChange} />;
};

export default DynamicField;
