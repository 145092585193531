import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18nIsoCountries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { RootState } from 'store/configureStore';
import { Input, notification } from 'antd';
import CustomTable, {
  EditableColumnType,
} from 'components/CustomTable/CustomTable';
import useFilters from 'utils/hooks/useFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { ICountryComponentDataType } from 'store/ducks/DataEntry/Entities/types';
import {
  set_countryComponent,
  set_isCountryAdded,
} from 'store/ducks/DataEntry/Entities/entitySlice';
import useCountries from 'utils/hooks/fetchHooks/useCountries';
import useEntityStatus from 'utils/hooks/useEntityStatus';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import CustomSelect from 'components/CustomSelect';
import * as Styled from '../../DataEntry.styled';

const CountryComponent: React.FC = () => {
  useEntityStatus();
  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<ICountryComponentDataType[]>([]);
  const [initialData, setInitialData] = useState<ICountryComponentDataType[]>(
    [],
  );
  const [combinedData, setCombinedData] = useState<ICountryComponentDataType[]>(
    [],
  );
  const { countries } = useCountries();
  const { countryComponent, addRowCount, entities, nameOfEntity } = useSelector(
    (state: RootState) => state.entities,
  );
  const dispatch = useDispatch();
  i18nIsoCountries.registerLocale(enLocale);
  const isLoggedIn = localStorage.getItem('authToken');

  useEffect(() => {
    setCombinedData([...countryComponent, ...dataSource]);
  }, [dataSource, countryComponent]);

  const handleSave = (row: ICountryComponentDataType) => {
    const isRowInDataSource = dataSource.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = dataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );

      setDataSource(updatedDataSource);
      setCombinedData([...updatedDataSource, ...countryComponent]);
    } else {
      const updatedEntityType = countryComponent.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setCombinedData([...dataSource, ...updatedEntityType]);
      dispatch(set_countryComponent(updatedEntityType));
    }
  };

  const fetchCountry = async () => {
    try {
      const response = await entityOp.fetchCountryByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.country;
      const mappedData = [
        {
          key: data?.id,
          id: data?.id,
          country_name: data?.country_name || '',
          iso_code: data?.iso_code || '',
          description: data?.description || '',
          entity_name: response?.data?.entity_name || query.entity,
        },
      ];

      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchCountry();
    }
  }, [query]);

  useEffect(() => {
    if (addRowCount === 0) {
      dispatch(set_countryComponent([]));
    }
  }, [addRowCount, countryComponent.length, dispatch]);

  const areAllFieldsFilled = (
    countryComponent: ICountryComponentDataType[],
  ) => {
    return countryComponent.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  useEffect(() => {
    if (addRowCount === 1) {
      const allFieldsFilled = areAllFieldsFilled(countryComponent);
      if (allFieldsFilled) {
        dispatch(set_isCountryAdded(true));
      } else {
        dispatch(set_isCountryAdded(false));
      }
    }
  }, [countryComponent, addRowCount]);

  const handleSubmitCountry = async () => {
    if (JSON.stringify(combinedData) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (countryComponent.length !== 0) {
      const allFieldsFilled = areAllFieldsFilled(countryComponent);
      if (!allFieldsFilled) {
        notification.warning({
          message: 'Not submitted',
          description:
            'Please fill in all the required fields in Country Component.',
          placement: 'topRight',
          duration: 20,
        });
        return;
      } else {
        dispatch(set_isCountryAdded(true));
      }
    }

    try {
      const payload = {
        entity_name: query.entity,
        data_list: [...combinedData],
        token: isLoggedIn,
      };
      const response = await entityOp.fetchCountry(payload);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns: EditableColumnType[] = [
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Country of the financial entity</p>
            <p>B_01.02.0030</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the country of the financial entity using the ISO 3166–1 alpha–2 code. (country)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie nach ISO 3166-1 Alpha-2 den Ländercode des Landes an, in dem die Lizenz
            oder die Eintragung des im Informationsregister gemeldeten Finanzunternehmens
            erteilt wurde."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'country_name',
      width: '30%',
      editable: false,
      render: (text) => <Input value={text} disabled />,
    },
    {
      title: (
        <Styled.TitleComponent>
          Country ISO code
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the ISO 3166–1 alpha–2 code of the country where the license or the registration of the entity reported in the register of information has been issued."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'iso_code',
      editable: false,
      render: (text) => <Input value={text} disabled />,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: false,
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: (
        <>
          Entity Name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'entity_name',
      editable: false,
      render: (text) => <Input value={text} disabled />,
    },
  ];

  return (
    <Styled.StyledWrapper
      hasEntity={!!query.entity}
      style={{ marginTop: '64px' }}
    >
      <CustomTable
        columns={columns}
        dataSource={combinedData}
        handleSave={handleSave}
      />
      <Styled.AreaButton type="primary" onClick={handleSubmitCountry}>
        Submit
      </Styled.AreaButton>
    </Styled.StyledWrapper>
  );
};

export default CountryComponent;
