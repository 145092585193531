import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, message, Steps, Layout, theme } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { TitleList, TitleDataEntry } from 'store/ducks/globalTypes';
import { RootState } from 'store/configureStore';
import { setSelectedTPP } from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import {
  setSelectedContract_ICT,
  setSelectedFunction_ICT,
  setSelectedTPP_ICT,
  setSelecedEntity_ICT,
} from 'store/ducks/DataEntry/ICTServices/ICTServiceSlice';
import {
  setSelecedEntity_Contractual_Information,
  setSelectedBranch_Contractual_Information,
  setSelectedICT_Contractual_Information,
  setSelectedTPP_Contractual_Information,
} from 'store/ducks/DataEntry/ContractualInformation/contractualInformationSlice';
import GoBackButton from 'components/GoBackButton';
import TitleBar from 'components/TitleBar/TitleBar';
import useFilters from 'utils/hooks/useFilters';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';
import { contractual_informationOp } from 'store/ducks/DataEntry/ContractualInformation';
import * as Styled from 'components/Layout/Layout.styled';
import { ISelectedValueContract } from 'store/ducks/DataEntry/ICTServices/types';
import { ISelectedValueICT } from 'store/ducks/DataEntry/ContractualInformation/types';

interface Step {
  title: string;
  content: React.ReactNode;
}

interface StepComponentProps {
  steps: Step[];
  title: string;
}
const StepComponent: React.FC<StepComponentProps> = ({ steps }) => {
  const { token } = theme.useToken();
  const dispatch = useDispatch();
  const location = useLocation();

  const [current, setCurrent] = useState(0);
  const [open, setOpen] = useState(false);
  const [all, setAll] = useState(false);
  const [pathNameTPP, setPathNameTPP] = useState(false);
  const [selectedTPPForICT, setSelectedTPPForICT] = useState<
    { value: string; label: string } | undefined
  >(undefined);
  const [selectedFunctionForICT, setSelectedFunctionForICT] = useState<
    { value: string; label: string } | undefined
  >(undefined);
  const [selectedContractForICT, setSelectedContractForICT] = useState<
    string | undefined
  >(undefined);
  const [
    selectedBranchForContractualInformation,
    setSelectedBranchForContractualInformation,
  ] = useState<{ value: string; label: string } | undefined>(undefined);
  const [
    selectedICTForContractualInformation,
    setSelectedICTForContractualInformation,
  ] = useState<string | undefined>(undefined);

  const [tppdData, setTPPData] = useState<any[]>([]);
  const [functionData, setFunctionData] = useState<any[]>([]);
  const [contractsData, setContractsData] = useState<any[]>([]);
  const [branchData, setBranchData] = useState<any[]>([]);
  const [ictData, setICTData] = useState<any[]>([]);

  const { query, handleFilterChange, extractedOptions } = useFilters();
  const { scheme } = useSelector((state: RootState) => state.global);
  const { selectedTPP } = useSelector((state: RootState) => state.tpp);
  const { Sider } = Layout;
  const showDrawer = () => setOpen(true);
  const next = () => setCurrent((prev) => prev + 1);
  const prev = () => setCurrent((prev) => prev - 1);
  const onClose = () => setOpen(false);
  const onStepChange = (stepIndex: number) => setCurrent(stepIndex);

  const applyFilters = () => {
    setOpen(false);
    message.success('Filters applied');
  };
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#aaa',
    backgroundColor: '#f0f2f5',
    borderRadius: '8px',
    border: '1px solid #d9d9d9',
    marginTop: 16,
  };

  useEffect(() => {
    setAll(steps[current]?.title === 'ThirdPartyServiceProvider');
  }, [current, steps]);

  useEffect(() => {
    if (
      steps[current]?.title !== 'ThirdPartyServiceProvider' &&
      selectedTPP === 'All'
    ) {
      dispatch(setSelectedTPP(''));
      message.info('Please select a valid TPP.');
    }
  }, [current, steps]);

  const fetchTPPbyEntity = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;
      if (data.length > 0) {
        const updatedTPPData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          third_party_service_provider_name:
            item.third_party_service_provider_name,
        }));
        setTPPData(updatedTPPData);
      } else {
        setTPPData([]);
      }
      setSelectedTPPForICT(undefined);
      dispatch(setSelectedTPP(''));
    } catch (error) {
      console.log(error, 'error');
      setTPPData([]);
    }
  };

  const fetchFunctionsByEntity = async () => {
    try {
      const response = await ict_serviceOp.fetchFunctionsbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data;
      if (data.length > 0) {
        const updatedFunctionsData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          function_name: item.function_name,
        }));
        setFunctionData(updatedFunctionsData);
      } else {
        setFunctionData([]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchContractsByEntity = async () => {
    try {
      const response =
        await ict_serviceOp.fetchContractualArrangementsbyEntityName({
          entity_id: query.entityId,
          entity_name: query.entity,
        });
      const data = response?.data;

      if (data.length > 0) {
        const updatedContractsData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          description: item.description,
        }));
        setContractsData(updatedContractsData);
      } else {
        setContractsData([]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchBranchesByEntity = async () => {
    try {
      const response =
        await contractual_informationOp.fetchBranchesbyEntityName({
          entity_id: query.entityId,
          entity_name: query.entity,
        });
      const data = response?.data;
      if (data.length > 0) {
        const updatedBranchesData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          branch_name: item.branch_name,
        }));
        setBranchData(updatedBranchesData);
      } else {
        setBranchData([]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchICTServicesByEntity = async () => {
    try {
      const response =
        await contractual_informationOp.fetchICTServicesbyEntityName({
          entity_id: query.entityId,
          entity_name: query.entity,
        });
      const data = response?.data;
      if (data.length > 0) {
        const updatedICTServicedData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          ict_services_name: item.ict_services_name,
        }));
        setICTData(updatedICTServicedData);
      } else {
        setICTData([]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchTPPbyEntity();
      fetchFunctionsByEntity();
      fetchBranchesByEntity();
      fetchContractsByEntity();
      fetchICTServicesByEntity();
    }
  }, [query.entity]);

  const handleTPPForICTChange = (value: string, option: { label: string }) => {
    setSelectedTPPForICT({ value, label: option.label });
    dispatch(setSelectedTPP(value));

    if (value) {
      const selectedTPP = {
        value: value,
        label: option.label,
      };
      dispatch(setSelectedTPP_ICT(selectedTPP));
      dispatch(setSelectedTPP_Contractual_Information(selectedTPP));
      setSelectedFunctionForICT(undefined);
      setSelectedContractForICT(undefined);
      setSelectedBranchForContractualInformation(undefined);
      setSelectedICTForContractualInformation(undefined);
    }
  };

  const isTPPDisabled =
    selectedFunctionForICT || selectedContractForICT || selectedBranchForContractualInformation || selectedICTForContractualInformation!== undefined;
  const isFunctionDisabled =
    selectedContractForICT || selectedTPPForICT !== undefined;
  const isContractDisabled =
    selectedTPPForICT || selectedFunctionForICT !== undefined;

  const isBranchDisabled =
    selectedICTForContractualInformation || selectedTPPForICT !== undefined;
  const isICTDisabled =  selectedBranchForContractualInformation || selectedTPPForICT !== undefined;

  useEffect(() => {
    if (isTPPDisabled) {
      console.log(selectedTPP, 'selTpp'); 
      console.log(selectedTPPForICT, 'f ict');
      console.log(setSelectedTPP_Contractual_Information, 'contr');
      console.log(setSelectedTPP_ICT, '_ict');
      dispatch(setSelectedTPP_Contractual_Information(null));
      // setSelectedTPP(null);
      // setSelectedTPP_Contractual_Information(null);
      setSelectedTPP_ICT(null);
      setSelectedTPPForICT(undefined);
      dispatch(setSelectedTPP_ICT(null));
    }
    if (isFunctionDisabled) {
      setSelectedFunctionForICT(undefined);
      dispatch(setSelectedFunction_ICT(null));
    }
    if (isContractDisabled) {
      setSelectedContractForICT(undefined);
      dispatch(setSelectedContract_ICT(null));
    }
    if (isBranchDisabled) {
      console.log(isBranchDisabled, 'isBranchDisabled');
     setSelectedBranchForContractualInformation(undefined);
     dispatch(setSelectedBranch_Contractual_Information(null));
    }
    if (isICTDisabled) {
      console.log(isICTDisabled, 'isICTDisabled');
      setSelectedICTForContractualInformation(undefined);
      dispatch(setSelectedICT_Contractual_Information(null));
    }
  }, [
    isTPPDisabled,
    isFunctionDisabled,
    isContractDisabled,
    isBranchDisabled,
    isICTDisabled,
    setSelectedTPPForICT,
    setSelectedFunctionForICT,
    setSelectedContractForICT,
    setSelectedBranchForContractualInformation,
    setSelectedICTForContractualInformation,
  ]);
  const handleChange = (selectedLabel: string) => {
    const selectedOption = extractedOptions.find(
      (option: { label: string }) => option.label === selectedLabel,
    );
    if (selectedOption) {
      const selectedEntity = {
        entityId: selectedOption.key,
        entity: selectedOption.label,
      };
      dispatch(setSelecedEntity_ICT(selectedEntity));
      dispatch(setSelecedEntity_Contractual_Information(selectedEntity));

      handleFilterChange(selectedOption.label, selectedOption.key);
      setSelectedTPPForICT(undefined);
      setSelectedFunctionForICT(undefined);
      setSelectedBranchForContractualInformation(undefined);
      setSelectedContractForICT(undefined);
      setSelectedICTForContractualInformation(undefined);
    } else {
      console.log('No matching option found for label:', selectedLabel);
    }
  };

  const handleContractForICTChange = (value: string | undefined) => {
    setSelectedContractForICT(value);

    if (value) {
      const contractPayload: ISelectedValueContract = { value };
      dispatch(setSelectedContract_ICT(contractPayload));
    } else {
      dispatch(setSelectedContract_ICT(null));
    }

    setSelectedTPPForICT(undefined);
    setSelectedFunctionForICT(undefined);
  };
  const handleICTServicesForICTChange = (value: string | undefined) => {
    setSelectedICTForContractualInformation(value);

    if (value) {
      const ictPayload: ISelectedValueICT = { value };
      dispatch(setSelectedICT_Contractual_Information(ictPayload));
    } else {
      dispatch(setSelectedICT_Contractual_Information(null));
    }

    setSelectedTPPForICT(undefined);
    setSelectedFunctionForICT(undefined);
  };

  const handleRefresh = () => {
    setSelectedTPPForICT(undefined);
    setSelectedFunctionForICT(undefined);
    setSelectedBranchForContractualInformation(undefined);
    setSelectedContractForICT(undefined);
    setSelectedICTForContractualInformation(undefined);
  };

  const titleMap: Record<string, string> = {
    '/data_entry/third_party_provider': TitleDataEntry.THIRD_PARTY_PROVIDER,
    '/data_entry/entity': TitleDataEntry.ENTITY,
    '/data_entry/function': TitleDataEntry.FUNCTION,
    '/data_entry/ict_service': TitleDataEntry.ICT_SERVICES,
    '/data_entry/contractual_information':
      TitleDataEntry.CONTRACTUAL_INFORMATION,
    '/data_entry/branch': TitleDataEntry.BRANCH,
  };

  const title = titleMap[location.pathname];

  useEffect(() => {
    if (location.pathname === '/data_entry/third_party_provider') {
      setPathNameTPP(true);
    }
  }, [location]);

  const handleFunctionForICTChange = (
    value: string | undefined,
    option: { label: string },
  ) => {
    const selectedOption = functionData.find((item) => item.id === value);
    const selectedFunction = selectedOption
      ? { value: selectedOption.id, label: selectedOption.function_name }
      : undefined;

    setSelectedFunctionForICT(selectedFunction);

    if (selectedFunction) {
      dispatch(setSelectedFunction_ICT(selectedFunction));

      setSelectedTPPForICT(undefined);
      setSelectedContractForICT(undefined);
    } else {
      console.log('No matching function found for value:', value);
    }
  };

  const handleBranchForContractualInformationChange = (
    value: string | undefined,
    option: { label: string },
  ) => {
    const selectedOption = branchData.find((item) => item.id === value);
    const selectedBranch = selectedOption
      ? { value: selectedOption.id, label: selectedOption.branch_name }
      : undefined;

    setSelectedBranchForContractualInformation(selectedBranch);

    if (selectedBranch) {
      dispatch(setSelectedBranch_Contractual_Information(selectedBranch));

      setSelectedTPPForICT(undefined);
      setSelectedICTForContractualInformation(undefined);
    } else {
      console.log('No matching branch found for value:', value);
    }
  };

  return (
    <Styled.StyledLayout token={token}>
      <TitleBar title={TitleList.DATA_ENTRY} />
      <Styled.WorkingAreaButton>
        <div>
          <GoBackButton to="/" text="Working areas" />
          <GoBackButton to={`/data_entry${location.search}`} text="Data Entry" />
        </div>
        
        <Button
          type="primary"
          onClick={showDrawer}
          icon={open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        ></Button>
      </Styled.WorkingAreaButton>
      <Styled.HorizontalLayout theme={scheme}>
        <Styled.StepsComponent>
          <Styled.AreaTitle>
            <Styled.TitleContainer theme={scheme}>
          <div>
            <Styled.SubtitleText>
              {title ? (
                <Styled.DataEntryTitle>{title}</Styled.DataEntryTitle>
              ) : null}
            </Styled.SubtitleText>
          </div>
        </Styled.TitleContainer>
            </Styled.AreaTitle>
            <Styled.SelectedFilters>
              {query.entity && <h2>Entity: {query.entity}</h2>}
              {selectedTPPForICT?.label && <h2>TPP: {selectedTPPForICT.label}</h2>}
              {selectedBranchForContractualInformation?.label && (
                <h2>Branch: {selectedBranchForContractualInformation.label}</h2>
              )}
              {selectedICTForContractualInformation && (
                <h2>ICT Service: {selectedICTForContractualInformation}</h2>
              )}
              {selectedFunctionForICT?.label && (
                <h2>Function: {selectedFunctionForICT.label}</h2>
              )}
              {selectedContractForICT && (
                <h2>Contractual Arrangement: {selectedContractForICT}</h2>
              )}
              </Styled.SelectedFilters>
          {!query.entity && (
            <Styled.AlertInfo
              description="In order to retrieve the data, it would be necessary to filter the entity first."
              type="info"
              showIcon
              closable
            />
          )}
          <Steps current={current} items={items} onChange={onStepChange} />
          <Styled.SelectionOfTPP>
            {pathNameTPP && (
              <Styled.SelectFilter
                placeholder="TPP"
                options={
                  steps[current]?.title === 'ThirdPartyServiceProvider'
                    ? [
                        { value: 'All', label: 'All', key: 'all' },
                        ...tppdData
                          .filter(
                            (item) =>
                              item &&
                              item.id &&
                              item.third_party_service_provider_name,
                          )
                          .map((item) => ({
                            value: item.id,
                            label: item.third_party_service_provider_name,
                            key: item.key,
                          })),
                      ]
                    : tppdData
                        .filter(
                          (item) =>
                            item &&
                            item.id &&
                            item.third_party_service_provider_name,
                        )
                        .map((item) => ({
                          value: item.id,
                          label: item.third_party_service_provider_name,
                          key: item.key,
                        }))
                }
                onChange={handleTPPForICTChange}
                value={selectedTPPForICT}
              />
            )}
          </Styled.SelectionOfTPP>
          {steps[current]?.title !== 'Branch' &&
            steps[current]?.title !== 'Function' &&
            steps[current]?.title !== 'ICTService' &&
            steps[current]?.title !== 'ContractualInformation' &&
            query.entity && (
              <Styled.StepButtons>
                <div>
                  <Button
                    onClick={prev}
                    disabled={
                      steps[current]?.title === 'ThirdPartyServiceProvider' ||
                      steps[current]?.title === 'Entity'
                    }
                  >
                    Previous
                  </Button>
                  {current < steps.length - 1 && (
                    <Button type="primary" onClick={next}>
                      Next
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={() => message.success('Processing complete!')}
                    >
                      Done
                    </Button>
                  )}
                </div>
              </Styled.StepButtons>
            )}

          <div style={contentStyle}>{steps[current].content}</div>
        </Styled.StepsComponent>
      </Styled.HorizontalLayout>
      <Styled.StyledLayout token={token}>
        <Sider>
          <Styled.AntDrawer title="Filters" onClose={onClose} open={open}>
            {steps[current]?.title !== 'ICTService' &&
              steps[current]?.title !== 'ContractualInformation' && (
                <Styled.SelectFilter
                  placeholder="Entity"
                  options={extractedOptions.map(
                    (option: { label: any; key: any }) => ({
                      value: option.label,
                      key: option.key,
                      label: option.label,
                    }),
                  )}
                  value={query.entity}
                  onChange={handleChange}
                />
              )}

            {steps[current]?.title === 'ICTService' && (
              <>
                <Styled.SelectFilter
                  placeholder="Entity"
                  options={extractedOptions.map(
                    (option: { label: any; key: any }) => ({
                      value: option.label,
                      key: option.key,
                      label: option.label,
                    }),
                  )}
                  value={query.entity}
                  onChange={handleChange}
                />
                <Styled.SelectFilter
                  placeholder="TPP"
                  options={tppdData.map((item) => ({
                    value: item.id,
                    label: item.third_party_service_provider_name,
                    key: item.key,
                  }))}
                  onChange={handleTPPForICTChange}
                  value={selectedTPPForICT}
                  disabled={isTPPDisabled}
                />
                <Styled.SelectFilter
                  placeholder="Functions"
                  options={functionData.map((item) => ({
                    value: item.id,
                    label: item.function_name,
                    key: item.key,
                  }))}
                  onChange={(value: string | undefined, option: any) =>
                    handleFunctionForICTChange(value, option)
                  }
                  value={selectedFunctionForICT}
                  disabled={isFunctionDisabled}
                />
                <Styled.SelectFilter
                  placeholder="Contractual arrangements"
                  options={contractsData.map((item) => ({
                    value: item.id,
                    label: item.description,
                    key: item.key,
                  }))}
                  onChange={(value: string | undefined) =>
                    handleContractForICTChange(value)
                  }
                  value={selectedContractForICT}
                  disabled={isContractDisabled}
                />
                <Styled.StyledButton onClick={handleRefresh}>
                  <ReloadOutlined />
                </Styled.StyledButton>
              </>
            )}

            {steps[current]?.title === 'ContractualInformation' && (
              <>
                <Styled.SelectFilter
                  placeholder="Entity"
                  options={extractedOptions.map(
                    (option: { label: any; key: any }) => ({
                      value: option.label,
                      key: option.key,
                      label: option.label,
                    }),
                  )}
                  value={query.entity}
                  onChange={handleChange}
                />
                <Styled.SelectFilter
                  placeholder="TPP"
                  options={tppdData.map((item) => ({
                    value: item.id,
                    label: item.third_party_service_provider_name,
                    key: item.key,
                  }))}
                  onChange={handleTPPForICTChange}
                  value={selectedTPPForICT}
                  disabled={isTPPDisabled}
                />
                <Styled.SelectFilter
                  placeholder="Branches"
                  options={branchData.map((item) => ({
                    value: item.id,
                    label: item.function_name,
                    key: item.key,
                  }))}
                  onChange={(value: string | undefined, option: any) =>
                    handleBranchForContractualInformationChange(value, option)
                  }
                  value={selectedBranchForContractualInformation}
                  disabled={isBranchDisabled}
                />
                <Styled.SelectFilter
                  placeholder="ICT Services"
                  options={ictData.map((item) => ({
                    value: item.id,
                    label: item.description,
                    key: item.key,
                  }))}
                  onChange={(value: string | undefined) =>
                    handleICTServicesForICTChange(value)
                  }
                  value={selectedICTForContractualInformation}
                  disabled={isICTDisabled}
                />

                <Styled.StyledButton onClick={handleRefresh}>
                  <ReloadOutlined />
                </Styled.StyledButton>
              </>
            )}
            <Styled.StyledButton type="primary" onClick={applyFilters}>
              Display
            </Styled.StyledButton>
          </Styled.AntDrawer>
        </Sider>
      </Styled.StyledLayout>
    </Styled.StyledLayout>
  );
};

export default StepComponent;
