import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { Button, DatePicker, notification, Modal } from 'antd';
import CustomTable, {
  DataType,
  EditableColumnType,
} from 'components/CustomTable/CustomTable';
import useFilters from 'utils/hooks/useFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { IEntityTitle } from 'store/ducks/DataEntry/Entities/types';
import {
  set_addRowCount,
  set_entities,
  set_isAddRow,
  set_isEntityAdded,
  set_nameOfEntity,
  set_idOfEntity,
} from 'store/ducks/DataEntry/Entities/entitySlice';
import { generateUniqueId } from 'utils/hooks/generateUniqueId';
import useCountries from 'utils/hooks/fetchHooks/useCountries';
import dayjs from 'dayjs';
import useEntityStatus from 'utils/hooks/useEntityStatus';
import CustomSelect from 'components/CustomSelect';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import useRole from 'utils/hooks/useRoles';
import * as Styled from '../../DataEntry.styled';
import { globalOp } from 'store/ducks/Global';

const Entity: React.FC = () => {
  useEntityStatus();
  const { query } = useFilters();
  const { confirm } = Modal;
  const [dataSource, setDataSource] = useState<IEntityTitle[]>([]);
  const [initialData, setInitialData] = useState<IEntityTitle[]>([]);
  const [combinedData, setCombinedData] = useState<IEntityTitle[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [entityTypes, setEntityTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [hierarchyInGroups, setHierarchyInGroups] = useState<
    { label: string; value: string }[]
  >([]);
  const [natures, setNatures] = useState<{ label: string; value: string }[]>(
    [],
  );
  const [competentAuthorities, setCompetentAuthorities] = useState<
    { label: string; value: string }[]
  >([]);
  const [currencies, setCurrencies] = useState<
    { label: string; value: string }[]
  >([]);
  const [entityMaintaining, setEntityMaintaining] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const { countries } = useCountries();
  const { addRowCount, entities = [] } = useSelector(
    (state: RootState) => state.entities,
  );
  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem('authToken');

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    let combined: IEntityTitle[] = [];

    if (
      entities.length > 0 &&
      dataSource.length > 0 &&
      query.entity !== undefined
    ) {
      combined = [...entities, ...dataSource];
    } else if (entities.length < 1 && dataSource.length > 0) {
      combined = [...dataSource];
    } else if (dataSource.length < 1 && entities.length > 0) {
      combined = [...entities];
    }

    combined = combined.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.id === value.id),
    );

    setCombinedData(combined);
  }, [dataSource, entities, query.entity]);

  useEffect(() => {
    if (entities && entities.length > 0) {
      dispatch(set_entities(entities));
      dispatch(set_addRowCount(1));
    }
  }, [entities]);

  const handleSave = (row: IEntityTitle) => {
    const isRowInDataSource = dataSource.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = dataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setDataSource(updatedDataSource);
      entities.length < 1
        ? setCombinedData(updatedDataSource)
        : setCombinedData([...entities]);
    } else {
      const updatedEntity = entities.map((item) => {
        if (item.key === row.key) {
          if (row.isNew) {
            dispatch(set_idOfEntity(row.id));
            dispatch(set_nameOfEntity(row.name_of_entity));
            // dispatch(set_nameOfEntityType(row.name_of_entity));
            // dispatch(set_nameOfEntity(row.name_of_entity));
            // dispatch(set_nameOfEntity(row.name_of_entity));
            // dispatch(set_nameOfEntity(row.name_of_entity));
            // dispatch(set_nameOfEntity(row.name_of_entity));
          }
          return { ...item, ...row };
        }
        return item;
      });
      entities.length < 1
        ? setCombinedData([...dataSource])
        : setCombinedData([...updatedEntity]);

      dispatch(set_entities(updatedEntity));
    }
  };

  const fetchEntityType = async () => {
    try {
      const response = await entityOp.fetchEntityTypes();
      if (response?.data.entity_types) {
        const formattedOptions = response.data.entity_types.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setEntityTypes(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEntityMaintaining = async () => {
    try {
      const response = await entityOp.fetchAllFinancialEntities();
      const data = response?.data;
      if (data.length > 0) {
        const updatedTPPData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          financial_entity_name: item.financial_entity_name,
        }));
        setEntityMaintaining(updatedTPPData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHierarchyInGroups = async () => {
    try {
      const response = await entityOp.fetchHierarchyInGroup();
      if (response?.data.hierarchy_groups) {
        const formattedOptions = response.data.hierarchy_groups.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setHierarchyInGroups(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNatures = async () => {
    try {
      const response = await entityOp.fetchNatureID();
      if (response?.data.nature_of_entities) {
        const formattedOptions = response.data.nature_of_entities.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setNatures(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCompetentAuthorities = async () => {
    try {
      const response = await entityOp.fetchCompetentAuthorities();
      if (response?.data.competent_authorities) {
        const formattedOptions = response.data.competent_authorities.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setCompetentAuthorities(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await entityOp.fetchCurrencies();
      if (response?.data.currencies) {
        const formattedOptions = response.data.currencies.map(
          (reasons: { currency_name: any; id: any }) => ({
            label: reasons.currency_name,
            value: reasons.id,
          }),
        );
        setCurrencies(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRow = (record?: any, index?: number) => {
    dispatch(set_isAddRow(true));

    const newData: IEntityTitle = {
      key: generateUniqueId(),
      id: generateUniqueId(),
      lei_entity_maintaining_register_information: '',
      name_of_entity: '',
      entity_type_id: '',
      lei_of_entity: '',
      hierarchy_in_group_id: '',
      parent_lei: '',
      lei_entity_making_use_ICT_service: '',
      lei_entity_signing_contractual_arrangement: '',
      nature_id: '',
      integration_date: '',
      deletion_date: '',
      total_assets: 0,
      description: '',
      competent_authority_id: '',
      country_id: '',
      date_of_last_update: '',
      end_date: '9999-01-01',
      financial_entity_lei_id: '',
      reporting_date: '',
      start_date: '',
      currency_id: '',
      name_of_financial_entity: '',
      name_of_entity_maintaining_register_information: '',
      isNew: true,
    };
    const savedDataSource =
      entities.length > 0 ? [...entities] : [...dataSource];

    let updatedDataSource = [...savedDataSource];
    if (record) {
      const insertIndex = updatedDataSource.findIndex(
        (item) => item.key === record.key,
      );
      updatedDataSource = [
        ...updatedDataSource.slice(0, insertIndex + 1),
        newData,
        ...updatedDataSource.slice(insertIndex + 1),
      ];
    } else {
      updatedDataSource.push(newData);
    }

    setDataSource(updatedDataSource);
    dispatch(set_entities(updatedDataSource));

  };

  const fetchEntity = async () => {
    try {
      const response = await entityOp.fetchEntitiesByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data;
      const mappedData = [
        {
          key: data.entity_id,
          id: data.entity_id,
          active: data.active,
          lei_entity_maintaining_register_information:
            data.lei_entity_maintaining_register_information || '',
          name_of_entity: data.entity_name || '',
          entity_type_id: data.entity_type_id || undefined,
          lei_of_entity: data.lei_of_entity || '',
          hierarchy_in_group_id: data.hierarchy_in_group_id || undefined,
          parent_lei: data.parent_lei || '',
          lei_entity_making_use_ICT_service:
            data.lei_entity_making_use_ICT_service || '',
          lei_entity_signing_contractual_arrangement:
            data.lei_entity_signing_contractual_arrangement || '',
          nature_id: data.nature_id || undefined,
          integration_date: data.integration_date || '',
          deletion_date: data.deletion_date || '',
          total_assets: data.total_assets || 0,
          description: data.description || '',
          competent_authority_id: data.competent_authority_id || undefined,
          country_id: data.country_id || undefined,
          date_of_last_update: data.date_of_last_update || '',
          end_date: data.end_date || '',
          financial_entity_lei_id: data.financial_entity_lei_id || '',
          // lei_financial_entity: data.lei_financial_entity || '',
          reporting_date: data.reporting_date || '',
          start_date: data.start_date || '',
          currency_id: data.currency_id || undefined,
          name_of_financial_entity: data.name_of_financial_entity || '',
          name_of_entity_maintaining_register_information:
            data.name_of_entity_maintaining_register_information || '',
          isNew: false,
          isEdited: false,
        },
      ];
      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchEntity();
      fetchEntityType();
      fetchHierarchyInGroups();
      fetchEntityMaintaining();
      fetchNatures();
      fetchCompetentAuthorities();
      fetchCurrencies();
    } else {
      if (entities) {
        // dispatch(set_entities([]));
        fetchEntityType();
        fetchHierarchyInGroups();
        fetchEntityMaintaining();
        fetchNatures();
        fetchCompetentAuthorities();
        fetchCurrencies();
        dispatch(set_addRowCount(0));
      }
    }
  }, [query]);

  const areAllFieldsFilled = (entities: IEntityTitle[]) => {
    return entities.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = entities.filter((item) => item.key !== key);
        setDataSource(updatedDataSource);
        dispatch(set_entities(updatedDataSource));
        dispatch(set_addRowCount(0));
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: IEntityTitle) => {
    if (record.id === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The entity ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the entity with ID ${record.id}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response = await entityOp.inactivateEntity([record.id]);
          fetchEntity();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };

  useEffect(() => {
    if (addRowCount === 1) {
      const allFieldsFilled = areAllFieldsFilled(entities);
      if (allFieldsFilled) {
        dispatch(set_isEntityAdded(true));
      } else {
        dispatch(set_isEntityAdded(false));
      }
    }
  }, [entities, addRowCount]);

  const handleSubmitEntity = async () => {
    const updatedData = dataSource.map((item) => ({
      ...item,
      total_assets: Number(item.total_assets),
    }));
    if (JSON.stringify(updatedData) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (entities.length !== 0) {
      const allFieldsFilled = areAllFieldsFilled(entities);
      if (!allFieldsFilled) {
        // notification.warning({
        //   message: 'Not submitted',
        //   description: 'Please fill in all the required fields in Entity.',
        //   placement: 'topRight',
        //   duration: 20,
        // });
        // return;
      } else {
        dispatch(set_isEntityAdded(true));
      }
    } else {
      //  setCanAddRow(true);
    }

    try {
      const response = await entityOp.fetchEntity(
        [...updatedData],
        isLoggedIn ?? '',
      );
      dispatch(set_entities([]));
      if (response?.status === 200) {
        fetchEntity();
      }
      dispatch(globalOp.setFilters() as any);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns: EditableColumnType[] = [
    {
      title: (
        <>
          Entity name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'name_of_entity',
      editable: true,
      fixed: 'left',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Entity Maintaining Register Of Information</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
        </Styled.TitleComponent>
      ),
      dataIndex: 'financial_entity_lei_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => (
        <CustomSelect
          options={entityMaintaining.map(
            (option: {
              id: number;
              key: any;
              financial_entity_name: string;
            }) => ({
              value: option.id,
              key: option.key,
              label: option.financial_entity_name,
            }),
          )}
          value={record.financial_entity_lei_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              financial_entity_lei_id: value,
            } as IEntityTitle)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>LEI of the financial entity</p>
            <p>B_01.02.0010</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="As reported in B_04.01.0020
Identify the financial entity using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard
(alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie anhand des 20-stelligen alphanumerischen LEI-Codes gemäß der Norm
ISO 17442 das Finanzunternehmen an, das im Informationsregister gemeldet wird."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'lei_of_entity',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>LEI of the direct parent undertaking of the financial entity</p>
            <p>B_01.02.0060</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the direct parent undertaking of the financial entity reported in the register of information using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard
(alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie das im Informationsregister gemeldete direkte Mutterunternehmen des
Finanzunternehmens unter Verwendung des 20-stelligen alphanumerischen LEI-Codes
gemäß der Norm ISO 17442 an."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'parent_lei',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> LEI of the financial entity making use of the ICT service(s)</p>
            <p>B_02.02.0020</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the financial entity making use of the ICT service(s) using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard
            (alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Wie in B_04.01.0020 angegeben
            Geben Sie anhand des 20-stelligen alphanumerischen LEI-Codes gemäß der Norm
            ISO 17442 das Finanzunternehmen an, das die IKT-Dienstleistung(en) in Anspruch
            nimmt."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'lei_entity_making_use_ICT_service',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>LEI of the entity signing the contractual arrangement</p>
            <p>B_03.01.0020</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the undertaking signing the contractual arrangement using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard or the EUID.
(alphanumerical)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie anhand des 20-stelligen alphanumerischen
            LEI-Codes gemäß der Norm ISO 17442 oder anhand der
            EUID das Unternehmen an, das die vertragliche
            Vereinbarung unterzeichnet."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'lei_entity_signing_contractual_arrangement',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> Date of integration in the register of information</p>
            <p>B_01.02.0080</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of integration of the financial entity into the register of information
(date)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie das Datum unter Verwendung des ISO 8601-Codes (JJJJ–MM–TT) des
Datums der Aufnahme des Finanzunternehmens in das Informationsregister an."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'integration_date',
      editable: false,
      className: 'highlighted-column',
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = date
                  ? dayjs(date).format('YYYY-MM-DD')
                  : '';
                const updatedRow: IEntityTitle = {
                  ...entityRecord,
                  integration_date: formattedDateString,
                };
                handleSave(updatedRow);
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Date of deletion in the register of information</p>
            <p>B_01.02.0090</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of deletion of the financial entity from the register of information.
Where the financial entity has not been deleted, ‘9999-12-31’ shall be reported.
(date)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie das Datum unter Verwendung des ISO 8601-Codes (JJJJ–MM–TT) des
Datums der Löschung des Finanzunternehmens aus dem Informationsregister an.
Wurde das Finanzunternehmen nicht gelöscht, ist „9999-12-31“ anzugeben."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'deletion_date',
      editable: false,
      className: 'highlighted-column',
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = date
                ? dayjs(date).format('YYYY-MM-DD')
                : '';
              const updatedRow: IEntityTitle = {
                ...entityRecord,
                deletion_date: formattedDateString,
              };
              handleSave(updatedRow);
            }}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> Value of total assets of the financial entity</p>
            <p> B_01.02.0110 </p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Monetary value of total assets of the financial entity as reported in the financial entity’s annual financial statement of the year before the date of the last update of the register of information. Monetary value shall be reported in units.
            Refer to Annex IV for the filling in this column.
            (monetary)
            (mandatory if the entity is a financial entity)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Monetärer Gesamtwert der Aktiva des Finanzunternehmens; maßgeblich ist der im
Jahresabschluss des Finanzunternehmens für das Jahr vor dem Datum der letzten
Aktualisierung des Informationsregisters ausgewiesene Wert. Der monetäre Wert ist in
Einheiten anzugeben.
Für das Ausfüllen dieser Spalte wird auf Anhang IV verwiesen."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'total_assets',
      editable: true,
      className: 'highlighted-column',
    },
    {
      title: <>Entity maintaining register information</>,
      dataIndex: 'name_of_entity_maintaining_register_information',
      editable: true,
      render: (text: string) => <span className="custom-value">{text}</span>,
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Date of last update</p>
            <p>B_01.02.0070</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of the last update or modification made in the register of information in relation to the financial entity.
(date)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie das Datum unter Verwendung des ISO 8601-Codes (JJJJ–MM–TT) des
Datums der letzten Aktualisierung oder Änderung des Informationsregisters in Bezug
auf das Finanzunternehmen an."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'date_of_last_update',
      editable: false,
      className: 'highlighted-column',
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = date
                ? dayjs(date).format('YYYY-MM-DD')
                : '';
              const updatedRow: IEntityTitle = {
                ...entityRecord,
                date_of_last_update: formattedDateString,
              };
              handleSave(updatedRow);
            }}
          />
        );
      },
    },
    {
      title: (
        <>
          Start date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'start_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = date
                ? dayjs(date).format('YYYY-MM-DD')
                : '';
              const updatedRow: IEntityTitle = {
                ...entityRecord,
                start_date: formattedDateString,
              };
              handleSave(updatedRow);
            }}
          />
        );
      },
    },
    {
      title: (
        <>
          End date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'end_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <DatePicker
            value={text ? dayjs(text) : dayjs('9999-01-01')}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = date
                ? dayjs(date).format('YYYY-MM-DD')
                : '';
              const updatedRow: IEntityTitle = {
                ...entityRecord,
                end_date: formattedDateString,
              };
              handleSave(updatedRow);
            }}
          />
        );
      },
    },

    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Date of the reporting</p>
            <p>B_01.01.0060</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of reporting (date) (mandatory in case of reporting)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie das Datum unter Verwendung des
ISO 8601-Codes (JJJJ–MM–TT) des Meldedatums an."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'reporting_date',
      editable: false,
      className: 'highlighted-column',
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = date
                ? dayjs(date).format('YYYY-MM-DD')
                : '';
              const updatedRow: IEntityTitle = {
                ...entityRecord,
                reporting_date: formattedDateString,
              };
              handleSave(updatedRow);
            }}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p> Type of financial entity </p>
            <p>B_01.02.0040</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the type of financial entity using one of the options in the following closed list:
              1.	credit institutions;
              2.	payment institutions, including payment institutions exempted pursuant to Directive
              (EU) 2015/2366 of the European Parliament and of the Council (1);
              3.	account information service providers;
              4.	electronic money institutions, including electronic money institutions exempted pursuant to Directive 2009/110/EC of the European Parliament and of the Council (2);
              5.	investment firms;
              6.	crypto-asset service providers authorised under Regulation (EU) 2023/1114 of the European Parliament and of the Council (3)
              7.	issuers of asset-referenced tokens authorised under Regulation (EU) 2023/1114;
              8.	central securities depositories;
              9.	central counterparties;
              10.	trading venues;
              11.	trade repositories;
              12.	managers of alternative investment funds;
              13.	management companies;
              14.	data reporting service providers;
              15.	insurance and reinsurance undertakings;
              16.	insurance	intermediaries,	reinsurance intermediaries and ancillary insurance intermediaries;
              17.	institutions for occupational retirement provision;
              18.	credit rating agencies;
              19.	administrators of critical benchmarks;
              20.	crowdfunding service providers;
              21.	securitisation repositories.
              22.	other financial entity;
              Where the register of information is maintained at the group level by the parent undertaking, which is not itself subject to the obligation to maintain such register, i.e. it does not fall under the definition of financial entities set out in Article 2 of the Regulation (EU) 2022/2554 (e.g., financial holding company, mixed financial holding company or mixed-activity holding company) ‘Other financial entity’ option shall be chosen.

              (closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Benennen Sie anhand einer der Optionen aus der folgenden erschöpfenden Liste die Art
des Finanzunternehmens:
1. Kreditinstitute,
2. Zahlungsinstitute, einschließlich nach der Richtlinie (EU) 2015/2366
ausgenommene Zahlungsinstitute,
3. Kontoinformationsdienstleister,
4. E-Geld-Institute, einschließlich nach der Richtlinie 2009/110/EG ausgenommene
E-Geld-Institute,
5. Wertpapierfirmen,
6. Anbieter von Krypto-Dienstleistungen, die gemäß der Verordnung
(EU) 2023/1114 zugelassen sind,
7. Emittenten wertreferenzierter Token, die gemäß der Verordnung (EU) 2023/1114
zugelassen sind,
8. Zentralverwahrer,
9. zentrale Gegenparteien,
10. Handelsplätze,
11. Transaktionsregister,
12. Verwalter alternativer Investmentfonds,
13. Verwaltungsgesellschaften,
14. Datenbereitstellungsdienste,
15. Versicherungs- und Rückversicherungsunternehmen,
16. Versicherungsvermittler, Rückversicherungsvermittler und
Versicherungsvermittler in Nebentätigkeit,
17. Einrichtungen der betrieblichen Altersversorgung,
18. Ratingagenturen,
19. Administratoren kritischer Referenzwerte,
20. Schwarmfinanzierungsdienstleister,
21. Verbriefungsregister,
22. sonstige Finanzunternehmen,
23. Nichtfinanzunternehmen: gruppeninterne IKT-Dienstleister,
24. Nichtfinanzunternehmen: Sonstiges."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'entity_type_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => {
        return (
          <CustomSelect
            options={entityTypes}
            value={record.entity_type_id}
            onChange={(value: any) => {
              handleSave({
                ...record,
                entity_type_id: value,
              } as IEntityTitle);
            }}
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Hierarchy of the financial entity within the group</p>
            <p>B_01.02.0050</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Determine the hierarchy of the financial entity in the consolidation using one of the options in the following closed list:
1.	The financial entity is the ultimate parent undertaking in the consolidation;
2.	The financial entity is the parent undertaking of a sub-consolidated part in the consolidation;
3.	The financial entity is a subsidiary in the consolidation and is not a parent undertaking of a sub-consolidated part;
4.	The financial entity is not part of a group;
5.	The financial entity is a service provider to which the financial entity (or the third- party service provider acting on its behalf) is outsourcing all its operational activities.
Where an entity fulfils more than one options from the closed list above, the higher- level option applicable to this entity shall be selected.
(closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Benennen Sie anhand einer der Optionen aus der folgenden erschöpfenden Liste die
Hierarchie des Finanzunternehmens im Konsolidierungskreis:
1. Das Finanzunternehmen ist das oberste Mutterunternehmen in der
Konsolidierung.
2. Das Finanzunternehmen ist das Mutterunternehmen eines teilkonsolidierten
Bereichs der Konsolidierung.
3. Das Finanzunternehmen ist ein in der Konsolidierung befindliches
Tochterunternehmen und kein Mutterunternehmen eines teilkonsolidierten
Bereichs.
4. Das Finanzunternehmen gehört keiner Gruppe an.
5. Das Finanzunternehmen ist ein Dienstleister, an den das Finanzunternehmen (oder
der in seinem Namen handelnde Drittdienstleister) alle seine operationellen
Tätigkeiten auslagert.
Trifft auf ein Unternehmen mehr als eine Option der oben angegebenen erschöpfenden
Liste zu, so ist die auf das Unternehmen zutreffende höherrangige Option
auszuwählen."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'hierarchy_in_group_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => (
        <CustomSelect
          options={hierarchyInGroups}
          value={record.hierarchy_in_group_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              hierarchy_in_group_id: value,
            } as IEntityTitle)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>
              Nature of the financial entity making use of the ICT service(s)
            </p>
            <p> B_04.01.0030</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="One of the options in the following closed list shall be used:
              1.	The financial entity making use of the ICT service(s) is a branch of a financial entity
              2.	The financial entity making use of the ICT service(s) is not a branch
              (closed set of options)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Aus der folgenden erschöpfenden Liste ist
eine Option auszuwählen:
1. Das Finanzunternehmen, das die
IKT-Dienstleistung(en) in Anspruch
nimmt, ist eine Zweigniederlassung
eines Finanzunternehmens.
2. Das Finanzunternehmen, das die
IKT-Dienstleistung(en) in Anspruch
nimmt, ist keine Zweigniederlassung."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'nature_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => (
        <CustomSelect
          options={natures}
          value={record.nature_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              nature_id: value,
            } as IEntityTitle)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Competent Authority</p>
            <p>B_01.01.0050</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the competent authority referred to in Article 46 of Regulation (EU) 2022/2554 to which the register of information is reported. (alphanumerical) (mandatory in case of reporting)."
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie die in Artikel 46 der Verordnung
(EU) 2022/2554 genannte zuständige Behörde an, der
das Informationsregister gemeldet wird."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'competent_authority_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => (
        <CustomSelect
          options={competentAuthorities}
          value={record.competent_authority_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              competent_authority_id: value,
            } as IEntityTitle)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Currency</p>
            <p>B_01.02.0100</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the ISO 4217 alphabetic code of the currency used for the preparation of the financial entity’s financial statements.
The currency reported shall be the same currency used by the financial entity for the preparation of the financial statements at entity, sub-consolidated or consolidated level, as applicable.
(currency)
(mandatory only if B_01.02.0110 is reported)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie den alphabetischen ISO 4217-Code der Währung an, die für die Aufstellung
des Jahresabschlusses des Finanzunternehmens verwendet wird.
Die Beträge sind in der Währung auszuweisen, die das Finanzunternehmen bei der
Aufstellung des Jahresabschlusses je nach Sachlage auf Unternehmensebene,
teilkonsolidierter oder konsolidierter Ebene verwendet."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'currency_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => (
        <CustomSelect
          options={currencies}
          value={record.currency_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              currency_id: value,
            } as IEntityTitle)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          <div>
            <p>Country of the financial entity</p>
            <p>B_01.02.0030</p>
          </div>
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            color="red"
            description="Identify the ISO 3166–1 alpha–2 code of the country where the license or the registration of the financial entity reported in the register of information has been issued.
            (country)"
          />
          <AdditionalInformation
            color="#FFCD0B"
            description="Geben Sie nach ISO 3166-1 Alpha-2 den Ländercode des Landes an, in dem die Lizenz
oder die Eintragung des im Informationsregister gemeldeten Finanzunternehmens
erteilt wurde."
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'country_id',
      editable: false,
      className: 'highlighted-column',
      render: (_, record) => (
        <CustomSelect
          options={countries}
          value={record.country_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              country_id: value,
            } as IEntityTitle)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record) => (
        <>
          {record.isNew ? (
            <Button danger onClick={() => handleRemoveRow(record.key)}>
              Remove
            </Button>
          ) : (
            <Button
              danger
              onClick={() => handleInactivate(record as IEntityTitle)}
            >
              Soft delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    dataSource && (
      <div>
        <Styled.AreaButton type="primary" onClick={handleAddRow}>
          Add Row
        </Styled.AreaButton>
        <Styled.HighlightedTable>
          <CustomTable
            columns={columns}
            dataSource={dataSource}
            handleSave={handleSave}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </Styled.HighlightedTable>
        <Styled.AreaButton type="primary" onClick={handleSubmitEntity}>
          Submit
        </Styled.AreaButton>
        {/* {canCreate() &&  <Styled.AreaButton type="primary" onClick={handleSubmitEntity}>
            Submit
          </Styled.AreaButton>} */}
      </div>
    )
  );
};

export default Entity;
