import styled from 'styled-components';
import { Button, Form, Row } from 'antd';
import { Link } from 'react-router-dom';
import { IToken } from 'store/ducks/globalTypes';
import Background from 'assets/img/login_build.png';

export const StyledFormItem = styled(Form.Item)<IToken>`
  margin-bottom: ${(props) => `${props.token.margin}px`};

  .ant-input,
  .ant-input-affix-wrapper {
    padding: 16px 14px;
    border-radius: ${(props) => `${props.token.borderRadiusSM}px`};
  }

  .ant-input-prefix,
  .ant-input-suffix,
  .ant-input-suffix span {
    font-size: 20px;
    color: #757474 !important;
  }

  .ant-col {
    min-width: 100%;
  }
`;

export const ItemsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-row.ant-form-item-row {
    min-width: 100%;
  }

  .ant-form-item .ant-form-item-label {
    text-align: initial;
  }
`;

export const BottomSection = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  > button {
    align-self: center;
  }
`;

export const StyledButton = styled(Button)`
  min-width: 150px;
`;

export const StyledLink = styled(Link)`
  margin-bottom: 15px;
  color: #ff4d4f;
  display: flex;
  flex-direction: column;

  &:hover {
    color: #ff7875;
  }

  span {
    text-align: center;
  }
`;

export const StyledDisabledButton = styled(Button)`
  margin-bottom: 15px;
  min-width: 205px;
  border-radius: 15px;
`;

export const NavbarLogin = styled(Row)`
    justify-content: space-between;
  .ant-col {
    text-align: center;
    align-content: center;

    h1 {
      text-align: center;
      font-size: 2.5rem;
      font-weight: bold;
      text-shadow: rgba(0, 0, 0, 0.4) 2px 2px 4px;
      margin: 0px;
      letter-spacing: 1.5px;
      line-height: 2 !important;
    }
    p {
      text-align: center;
      font-size: 1.5rem;
      text-shadow: rgba(0, 0, 0, 0.4) 2px 2px 4px;
      margin: 0px;
      letter-spacing: 1px;
      line-height: 2 !important;
    }
  }
  img {
    width: 136px;
    position: relative;
    top: -23px;
    left: -42px;
  }
`
export const LoginContainer = styled.div`
  overflow-y: hidden;
  min-height: 100vh;
  background-image: ${() => `url(${Background})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
`

export const FormContainer = styled.div`
  margin-left: 200px;
  margin-top: 60px;
  width: 315px;;
  display: flex;
  flex-direction: column;
`