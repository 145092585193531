import React from 'react';
import { TableColumnsType } from 'antd';
import { set_headers } from 'store/ducks/Reportings/reportingSlice';
import { Dispatch } from 'redux';

export const extractTableHeaders = <T,>(
  columns: TableColumnsType<T>,
  dispatch: Dispatch,
) => {
  const extractedHeaders = columns.map((col) => {
    if (typeof col.title === 'string') {
      return col.title;
    } else if (React.isValidElement(col.title)) {
      const paragraph = col.title.props.children.find(
        (child: any) => child.type === 'p',
      );
      return paragraph?.props.children || 'Unknown Column';
    }
    return 'Unknown Column';
  });

  dispatch(set_headers(extractedHeaders));

  console.log(extractedHeaders, 'extractedHeaders');
};
