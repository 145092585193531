import React from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { theme, Button, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CustomTitle from '../CustomTitle';
import CustomSelect from '../CustomSelect';
import { ThemeList, TitleList } from 'store/ducks/globalTypes';
import { globalOp } from 'store/ducks/Global';
import ColorPalettes from 'constants/colorPalettes';
import * as Styled from './TitleBar.styled';

const { useToken } = theme;

interface ITitleBarProps {
  title: TitleList;
}

const TitleBar: React.FC<ITitleBarProps> = ({ title }) => {
  const { token } = useToken();
  const dispatch = useDispatch();
  const colorPalettes = ColorPalettes();
  const navigate = useNavigate();
  const location = useLocation();
  const handleThemeChange = (value: ThemeList) => {
    localStorage.setItem('theme', value);
    dispatch(globalOp.setTheme(value) as any);
  };

  const handleLogOut = () => {
    Modal.confirm({
      title: 'Are you sure you want to log out?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will be logged out and redirected to the login page.',
      okText: 'Yes, Log Out',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          const response = await globalOp.logout();
          localStorage.removeItem('authToken');
          localStorage.removeItem('role');
          navigate('/');
        } catch (error) {
          console.error('Error during logout:', error);
        }
      },
    });
  };

  return (
    <Styled.StyledNav token={token}>
      <Link to="/working_area">
        <Styled.StyledLogo src={Styled.logoSrc} alt="logo" />
      </Link>
      <CustomTitle title={title} level={2} />
      <Styled.DropdownContainer>
        <CustomSelect
          size="lg"
          options={colorPalettes}
          defaultValue={localStorage.getItem('theme') || ThemeList.MAIN_THEME}
          onChange={handleThemeChange}
        />
        <Styled.ChangeButton>
          <Button type="primary" onClick={handleLogOut}>
            Logout
          </Button>
        </Styled.ChangeButton>
      </Styled.DropdownContainer>
    </Styled.StyledNav>
  );
};

export default TitleBar;
