import { useState, useEffect } from "react";
import { entityOp } from "store/ducks/DataEntry/Entities";

interface Country {
  label: string;
  value: any;
}

const useCountries = () => {
  const [countries, setCountries] = useState<Country[]>([]);

  const fetchCountries = async () => {
    try {
      const response = await entityOp.fetchCountries();
      if (response?.data.countries) {
        const formattedOptions = response.data.countries.map(
          (country: { country_name: any; id: any }) => ({
            label: country.country_name,
            value: country.id,
          })
        );
        setCountries(formattedOptions);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return { countries, refetch: fetchCountries };
};

export default useCountries;
