import ApiService from 'utils/ApiCalls';
import { useSelector } from 'react-redux';
import { AnyARecord } from 'dns';

const useFetchContractualInformation = () => {
  const {
    selectedBranchForContractualInformation,
    selectedICTForContractualInformation,
    selectedTPPForContractualInformation,
    selectedEntityForContractualInformation,
  } = useSelector((state: any) => state.contractualInformation);

  const fetchContractualInformation = async () => {
    try {
      const response = await ApiService.fetchContractualInformation(
        selectedBranchForContractualInformation,
        selectedICTForContractualInformation,
        selectedTPPForContractualInformation,
        selectedEntityForContractualInformation,
      );
      return response;
    } catch (error) {
      console.error('Error fetching Contractual Information:', error);
      throw error;
    }
  };

  return fetchContractualInformation;
};

const postContractualInformation = async (data: any) => {
  try {
    const response = await ApiService.postContractualInformation();
    return response;
  } catch (error) {
    console.error('Error posting Contractual Information:', error);
  }
};

const fetchBranchesbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postBranchesbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Branches:', error);
  }
};

const fetchICTServicesbyEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postICTServicesbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the ICT services:', error);
  }
};

export default {
  useFetchContractualInformation,
  fetchBranchesbyEntityName,
  fetchICTServicesbyEntityName,
  postContractualInformation,
};
