import * as XLSX from 'xlsx';

export const tableData = [
  {
    title: 'RT.01.01',
    description: 'Entity maintaining the register of information',
  },
  {
    title: 'RT.01.02',
    description:
      'List of entities within the scope of the register of information',
  },
  { title: 'RT.01.03', description: 'List of branches' },
  {
    title: 'RT.02.01',
    description: 'Contractual arrangements – General Information',
  },
  {
    title: 'RT.02.02',
    description: 'Contractual arrangements – Specific information',
  },
  {
    title: 'RT.02.03',
    description: 'List of intra-group contractual arrangements',
  },
  {
    title: 'RT.03.01',
    description:
      'Entities signing the Contractual arrangements for receiving ICT service(s) or on behalf of the entities making use of the ICT service(s)',
  },
  {
    title: 'RT.03.02',
    description:
      ' ICT third-party service providers signing the Contractual arrangements for providing ICT service(s)',
  },
  {
    title: 'RT.03.03',
    description:
      'Entities signing the Contractual arrangements for providing ICT service(s) to other entity within the scope of consolidation.',
  },
  { title: 'RT.04.01', description: 'Entities making use of the ICT services' },
  { title: 'RT.05.01', description: 'ICT third-party service providers' },
  { title: 'RT.05.02', description: 'ICT service supply chains' },
  { title: 'RT.06.01', description: 'Functions identification' },
  { title: 'RT.07.01', description: 'Assessment of the ICT services' },
  { title: 'RT.99.01', description: 'Description for RT.99.01' },
];

export const handleTableClick = (tableTitle: string, fetchedData: any[]) => {
  const { wb, title } = generateExcelData(fetchedData, tableTitle);

  XLSX.writeFile(wb, `${title}.xlsx`);
};

export const generateExcelData = (fetchedData: any[], tableTitle: string) => {
  if (!fetchedData || fetchedData.length === 0) {
    throw new Error('No data available');
  }

  const headers = Object.keys(fetchedData[0]);

  const data = [
    headers,
    ...fetchedData.map((item) => headers.map((key) => item[key] || '')),
  ];

  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, ws, tableTitle);

  return { wb, title: tableTitle };
};
