import React, { useEffect, useState, useMemo } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import {
  set_headers,
  set_isDisplayed,
} from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { extractTableHeaders } from 'utils/hooks/reportTableUtils';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  arrangement_reference_number: string;
  linked_contractual_arrangement: string;
}

interface B0203Props {
  entityName: string | null | undefined;
}

const B0203: React.FC<B0203Props> = ({ entityName }) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: (
        <Styled.AddttionalInfoContainer>
          <p>
            Contractual arrangement reference number - <span>0010</span>
          </p>
          <AdditionalInformation
            description="Reference number of the contractual arrangement between the entity making use of the ICT service(s) provided and the ICT intra-group service provider.
  The contractual arrangement reference number shall be unique and consistent over time and across all the group.
  (alphanumerical)"
          />
        </Styled.AddttionalInfoContainer>
      ),
      width: 100,
      dataIndex: 'arrangement_reference_number',
      key: 'arrangement_reference_number',
      fixed: 'left',
    },
    {
      title: (
        <Styled.AddttionalInfoContainer>
          <p>
            Contractual arrangement linked to the contractual arrangement
            referred in B.02.03.0010 - <span>0020</span>
          </p>
          <AdditionalInformation
            description="Contractual arrangement reference number of the contractual arrangement between the ICT intra-group service provider of the contractual arrangement in B_02.03.0010 and its direct ICT third-party service provider.
(alphanumerical)"
          />
        </Styled.AddttionalInfoContainer>
      ),
      width: 100,
      dataIndex: 'linked_contractual_arrangement',
      key: 'linked_contractual_arrangement',
      fixed: 'left',
    },
  ];

  const { date, is_displayed, headers } = useSelector(
    (state: RootState) => state.reportings,
  );

  const [data, setData] = useState<DataType[]>([]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData =
        await reportingOp.fetchEntityContractualArrangements(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          arrangement_reference_number: item.arrangement_reference_number,
          linked_contractual_arrangement: item.linked_contractual_arrangement,
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching entity contractual arrangements:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed]);

  // useEffect(() => {
  //   const extractedHeaders = columns.map(col => {
  //     if (typeof col.title === "string") {
  //       return col.title;
  //     } else if (React.isValidElement(col.title)) {
  //       const paragraph = col.title.props.children.find(
  //         (child: any) => child.type === "p"
  //       );
  //       return paragraph?.props.children || "Unknown Column";
  //     }
  //     return "Unknown Column";
  //   });

  //   dispatch(set_headers(extractedHeaders));
  //   // extractTableHeaders(memoizedColumns, dispatch);
  // }, []);

  // const convertToCSV = (headers: string[], data: Record<string, any>[]) => {
  //   const processedHeaders = headers.map((header: any) => {
  //     if (Array.isArray(header)) {
  //       return header[0] + (header[1]?.props?.children || "");
  //     }
  //     return header || "Unknown Column";
  //   });

  //   const formattedHeaders = processedHeaders.join(",");
  //   const rows = data
  //     .map(row => [row.arrangement_reference_number, row.linked_contractual_arrangement].join(","))
  //     .join("\n");

  //   return `${formattedHeaders}\n${rows}`;
  // };

  // const downloadCSV = (componentName: string) => {
  //   if (!headers || !data.length) {
  //     console.error("No data or headers available for CSV download.");
  //     return;
  //   }

  //   const csvContent = convertToCSV(headers, data);
  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  //   const url = URL.createObjectURL(blob);

  //   const fileName = `${componentName || "data"}.csv`;

  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", fileName);
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(url);
  // };

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
      {/* <button onClick={() => downloadCSV('B0203')}>Download CSV</button> */}
    </>
  );
};

export default B0203;
