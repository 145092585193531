import { Dispatch } from '@reduxjs/toolkit';
import globalSlice from './globalSlice';
import { notification } from 'antd';
import { IChangePass, IFilters, IResetPass, ThemeList } from '../globalTypes';
import { ITableFilters } from './types';
import ApiService from 'utils/ApiCalls';
import Helpers from 'utils/Helpers';

const {
  set_theme,
  set_loading,
  set_applied_filters_result,
  set_table_filters,
  set_filters_data,
} = globalSlice.actions;

const setTheme = (theme: ThemeList) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_theme(theme));
  } catch (error) {
    console.error(error);
  }
};

const setAppliedFilersResult = (data: any[]) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_applied_filters_result(data));
  } catch (error) {
    console.error(error);
  }
};

const setTableFilter = (filter: ITableFilters) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_table_filters(filter));
  } catch (error) {
    console.error(error);
  }
};

const setFilters = () => async (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    const response = await ApiService.getFilters();
    let fetchedFilters: IFilters[] = [];

    if (response.data && !Helpers.isObjEmpty(response.data)) {
      fetchedFilters = Object.values(
        response.data as { id: number; name_of_entity: string }[],
      ).map((entity) => {
        return {
          filter: entity.name_of_entity,
          options: [
            {
              id: entity.id,
              name_of_entity: entity.name_of_entity,
              label: entity.name_of_entity,
              value: entity.id.toString(),
            },
          ],
        };
      });
    }

    dispatch(set_filters_data(fetchedFilters));
  } catch (error) {
    console.error('Error fetching the Entities:', error);
  }
};

const login = async (username: string, password: string) => {
  try {
    const response = await ApiService.login({
      username,
      password,
    });

    return response.data;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

const changePass = async (
  formData: IChangePass,
  linkToken: string | qs.ParsedQs | string[] | qs.ParsedQs[],
) => {
  try {
    const response = await ApiService.changePass(formData, linkToken);

    notification.success({
      message: response.status,
      description: 'Your password has been updated.',
    });
  } catch (error) {
    console.error(error);
  }
};

const logout = async () => {
  try {
    const response = await ApiService.logout();
    return response.data;
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
};

const signup = async (data: any) => {
  try {
    const response = await ApiService.signup(data);
    return response.data;
  } catch (error) {
    console.error('Signup failed:', error);
    throw error;
  }
};

const forgotPassword = async (email: string) => {
  try {
    const response = await ApiService.forgotPassword(email);
    return response.data;
  } catch (error) {
    console.error('Forgot password request failed:', error);
    throw error;
  }
};

const resetPassword = async (
  formData: IResetPass,
  linkToken: string | qs.ParsedQs | string[] | qs.ParsedQs[],
) => {
  try {
    const response = await ApiService.resetPassword(formData, linkToken);

    notification.success({
      message: response.status,
      description: 'Your password has been reseted successfully.',
    });
  } catch (error) {
    console.error(error);
  }
};

const userActivityStatus = async (username: string) => {
  try {
    const response = await ApiService.fetchUserActivityStatus(username);
    return response.data;
  } catch (error) {
    console.error('Activity status request failed:', error);
    throw error;
  }
};

export default {
  setTheme,
  setAppliedFilersResult,
  setTableFilter,
  setFilters,
  login,
  changePass,
  logout,
  signup,
  forgotPassword,
  resetPassword,
  userActivityStatus
};
