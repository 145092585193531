import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { notification } from 'antd';
import qs from 'qs';
import { environment } from 'environments/environment';
import Helpers from 'utils/Helpers';

// const token = Helpers.getToken();

const AxiosInstance = axios.create({
  baseURL: environment.serverUrl,
  headers: {
    // Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

AxiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config.paramsSerializer = (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    };

    return config;
  },
  async (error) => {
    notification.error({
      message: 'error.message',
      description: 'In Request Error',
    });

    return Promise.reject(error);
  },
);

AxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // if (!token && response.data.token) {
    //   localStorage.setItem("user", JSON.stringify(response.data));
    // }

    return response;
  },
  (error) => {
    const { status, statusText, data } = error.response;

    if (status === 400) {
      notification.error({
        message: status,
        description: data.message || 'Something went wrong',
      });
    }

    // if (status === 401 && statusText === "UNAUTHORIZED") {
    //   if (token) {
    //     localStorage.removeItem("user");
    //     window.location.href = "/";
    //   }

    //   notification.error({
    //     message: `${status} ${statusText}`,
    //     description: data.message,
    //   });
    // }

    // if (status === 404) {
    //   notification.error({
    //     message: 'Wrong Api URL',
    //     description: 'Please Check Api  URL',
    //   });
    // }

    // if (status === 500) {
    //   if (token) {
    //     localStorage.removeItem("user");
    //     window.location.href = "/";
    //   }

    //   notification.error({
    //     message: "Server is facing issues",
    //     description: "Please contact backend team",
    //   });
    // }

    return Promise.reject(error);
  },
);

export default AxiosInstance;
