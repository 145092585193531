import { IReportingState } from './types';

const initialReportingState = (): IReportingState => ({
  loading: false,
  date: '',
  is_displayed: false,
  is_additional_displayed: false,
  headers: [] as string[],
});

export default initialReportingState;
