import ApiService from 'utils/ApiCalls';
import axios from 'axios';

const fetchTPPbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postTPPbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the TPP:', error);
  }
};

const setNonSubstReason = async () => {
  try {
    const response = await ApiService.getNonSubstReason();
    return response;
  } catch (error) {
    console.error('Error setting the Value of Non Subst Reason:', error);
  }
};

const fetchNonSubstReason = async (tpp_name: string, tpp_id: string) => {
  try {
    const response = await ApiService.fetchNonSubstReason(tpp_name, tpp_id);
    if (response.data) {
      return response.data;
    } else {
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    console.error('Error fetching the Value of Non Subst Reason:', error);
  }
};

const setPersonType = async (tpp_name: string, tpp_id: string) => {
  try {
    const response = await ApiService.fetchPersonType(tpp_name, tpp_id);

    if (response.data) {
      return response.data;
    } else {
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    console.error('Error fetching the Value of Person Type:', error);
  }
};

const setCurrencyAndCosts = async (tpp_name: string, tpp_id: string) => {
  try {
    const response = await ApiService.fetchCurrencyAndCosts(tpp_name, tpp_id);

    if (response.data) {
      return response.data;
    } else {
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    console.error('Error fetching the Currency and Costs:', error);
  }
};

const setCertificates = async (tpp_name: string, tpp_id: string) => {
  try {
    const response = await ApiService.fetchCertificates(tpp_name, tpp_id);

    if (response.data) {
      return response.data;
    } else {
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    console.error('Error fetching the Value of Certificates:', error);
  }
};

const fetchTPP = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postTPP(entityTypeData);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching TPP:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

const insertNonSubstReason = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postNonSubstReason(entityTypeData);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching NonSubstReason:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

const insertPersonTypes = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postPersonTypes(entityTypeData);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching person types:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

const insertCurrencyAndCosts = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postCurrencyAndCosts(entityTypeData);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching Currency and Costs:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

const insertCertificates = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postCertificates(entityTypeData);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching Certificates:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

export default {
  fetchTPPbyEntityName,
  setNonSubstReason,
  fetchNonSubstReason,
  setPersonType,
  setCurrencyAndCosts,
  setCertificates,
  fetchTPP,
  insertNonSubstReason,
  insertPersonTypes,
  insertCurrencyAndCosts,
  insertCertificates,
};
