import React from 'react';
import { RollbackOutlined } from '@ant-design/icons';
import * as Styled from './GoBackButton.styled';

interface GoBackButtonProps {
  to: string;
  text: string;
}

const GoBackButton: React.FC<GoBackButtonProps> = ({ to, text }) => {
  return (
    <Styled.StyledLink to={to}>
      <Styled.StyledGoBackButton type="primary">
        {text}
        <RollbackOutlined />
      </Styled.StyledGoBackButton>
    </Styled.StyledLink>
  );
};

export default GoBackButton;
