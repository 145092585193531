import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { theme, Card, Avatar, Button, Modal } from 'antd';
import { RootState } from 'store/configureStore';
import { TitleList } from 'store/ducks/globalTypes';
import TitleBar from 'components/TitleBar';
import GoBackButton from 'components/GoBackButton';
import dataEntries from 'constants/dataEntries';
import * as Styled from '../Layout.styled';

const { useToken } = theme;

const DataEntryLayout: React.FC = () => {
  const { token } = useToken();
  const { scheme } = useSelector((state: RootState) => state.global);
  const entries = dataEntries();
  const navigate = useNavigate();
  const location = useLocation();
  const [isEnglishModalVisible, setEnglishModalVisible] = useState(false);
  const [isGermanModalVisible, setGermanModalVisible] = useState(false);

  const showEnglishModal = () => setEnglishModalVisible(true);
  const showGermanModal = () => setGermanModalVisible(true);
  const handleEnglishOk = () => setEnglishModalVisible(false);
  const handleGermanOk = () => setGermanModalVisible(false);
  const handleEnglishCancel = () => setEnglishModalVisible(false);
  const handleGermanCancel = () => setGermanModalVisible(false);

  const handleCardClick = (area: string) => {
    navigate(`/data_entry/${area}${location.search}`);
  };

  // const handleCardClick = (area: string, title: string) => {
  //   const params = new URLSearchParams(location.search);
  //   params.set('entity', title);
  //   params.set('entityId', '1');

  //   navigate(`/data_entry?${params.toString()}`);
  // };

  return (
    <Styled.StyledLayout token={token}>
      <TitleBar title={TitleList.DATA_ENTRY} />
      <h4>Register of Information</h4>
      <Styled.WorkingAreaButtonData>
        <div>
          <GoBackButton to="/working_area" text="Working areas" />
        </div>
        <Button type="primary" onClick={showEnglishModal}>
          Instructions
        </Button>
        <Modal
          title="Instructions"
          open={isEnglishModalVisible}
          onOk={handleEnglishOk}
          onCancel={handleEnglishCancel}
        >
          <ul>
            <li>
              <strong>Follow the order:</strong> Enter data in the sequence of
              the data entry icons (Roman numerals).
            </li>
            <li>
              <strong>Mandatory fields:</strong> Marked with a red asterisk (*).
            </li>
            <li>
              <strong>Regulatory instructions:</strong> Click the red question
              mark in the title box for guidance.
            </li>
            <li>
              <strong>Filters:</strong> Identified by the filter icon.
            </li>
            <li>
              <strong>Additional fields:</strong>
            </li>
            <ul>
              <li>
                Click the plus sign in a circle to open extra pop-up fields.
              </li>
              <li>
                Click the regular plus sign to display additional data entry
                boxes for further information.
              </li>
            </ul>
            <li>
              <strong>Corrections:</strong> Values can be edited and
              resubmitted.
            </li>
            <li>
              <strong>No autosave:</strong> Ensure you save your progress
              manually.
            </li>
            <li>
              <strong>Deleting rows:</strong> Click Soft Delete (right side of
              the row) to remove an entire erroneous row.
            </li>
          </ul>
        </Modal>

        <Button type="primary" onClick={showGermanModal}>
          Anweisungen
        </Button>
        <Modal
          title="Anweisungen"
          open={isGermanModalVisible}
          onOk={handleGermanOk}
          onCancel={handleGermanCancel}
        >
          <ul>
            <li>
              <strong>Reihenfolge beachten:</strong> Die Dateneingabe erfolgt in
              der Reihenfolge der Dateneingabe-Symbole (römische Zahlen).
            </li>
            <li>
              <strong>Pflichtfelder:</strong> Mit einem roten Sternchen (*)
              gekennzeichnet.
            </li>
            <li>
              <strong>Regulatorische Anweisungen:</strong> Durch Klicken auf das
              rote Fragezeichen im Titelfeld abrufbar.
            </li>
            <li>
              <strong>Filter:</strong> Über das Filter-Symbol erkennbar.
            </li>
            <li>
              <strong>Zusätzliche Felder:</strong>
            </li>
            <ul>
              <li>
                Durch Klicken auf das Pluszeichen in einem Kreis öffnen sich
                zusätzliche Pop-up-Felder.
              </li>
              <li>
                Durch Klicken auf das reguläre Pluszeichen erscheinen weitere
                Dateneingabefelder zur Erfassung zusätzlicher Informationen.
              </li>
            </ul>
            <li>
              <strong>Korrekturen:</strong> Werte können bearbeitet und erneut
              übermittelt werden.
            </li>
            <li>
              <strong>Kein automatisches Speichern:</strong> Speichern Sie Ihre
              Eingaben manuell.
            </li>
            <li>
              <strong>Zeilen löschen:</strong> Klicken Sie auf Soft Delete
              (rechts neben der Dateneingabezeile), um eine fehlerhafte Zeile zu
              entfernen.
            </li>
          </ul>
        </Modal>
      </Styled.WorkingAreaButtonData>
      <Styled.HorizontalLayout theme={scheme}>
        <Styled.Container>
          <Styled.GridContainer>
            {entries.map((item: any) => (
              <Card
                key={item.area}
                onClick={() => handleCardClick(item.area)}
                style={{ minWidth: 300 }}
              >
                <Card.Meta
                  key={item.area}
                  avatar={
                    <Avatar
                      src={item.imgSrc}
                      style={{ width: 70, height: 70 }}
                    />
                  }
                  title={item.title}
                  description={
                    <>
                      <p>{item.infoText}</p>
                    </>
                  }
                />
              </Card>
            ))}
          </Styled.GridContainer>
        </Styled.Container>
      </Styled.HorizontalLayout>
    </Styled.StyledLayout>
  );
};

export default DataEntryLayout;
