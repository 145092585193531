import ApiService from 'utils/ApiCalls';
import { notification } from 'antd';

const fetchBranchbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postBranchByEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Branch:', error);
  }
};

const insertBranches = async (branches: any) => {
  try {
    const response = await ApiService.postBranches(branches);

    if (response && response.status === 200) {
      notification.success({
        message: 'Success',
        description: response.statusText,
        duration: 20,
      });
    } else if (response.status === 207) {
      notification.success({
        message: response.data.message,
        description: response.data.errors[0].error,
        duration: 20,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Error',
      description: 'An unexpected error occurred. Please try again.',
      duration: 20,
    });
  }
};

const inactivateBranch = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateBranch(dataList);

    const { errors, deleted_branches, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach((error: { branch_id: number; error: string }) => {
        notification.error({
          message: `Error for Branch ID: ${error.branch_id}`,
          description: error.error,
          duration: 10,
        });
      });
    } else if (deleted_branches && deleted_branches.length > 0) {
      notification.success({
        message: 'Branch Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate branch. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate branch');
  }
};

export default {
  fetchBranchbyEntityName,
  insertBranches,
  inactivateBranch,
};
