import styled from 'styled-components';
import { Button, Form } from 'antd';
import { IToken } from 'store/ducks/globalTypes';
import Background from 'assets/img/login_build.png';

export const StyledContainer = styled('div')`
  overflow-y: hidden;
  min-height: 100vh;
  background-image: ${() => `url(${Background})`};
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  .ant-pagination {
    margin: 10px 0;
  }
`;

export const StyledLinkButton = styled(Button)<IToken>`
  padding: unset;
  line-height: inherit;
  margin-right: ${(props) => `${props.token.marginXXS}px`};

  > span {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const StyledButton = styled(Button)<IToken>`
  min-width: 100%;
  margin-top: ${(props) => `${props.token.marginXS}px`};
  border-radius: ${(props) => `${props.token.borderRadiusSM}px !important`};
`;

export const StyledFormItem = styled(Form.Item)<IToken>`
  margin-bottom: ${(props) => `${props.token.margin}px`};

  .ant-input,
  .ant-input-affix-wrapper {
    padding: 8px 10px;
    border-radius: ${(props) => `${props.token.borderRadiusSM}px`};
  }

  .ant-col {
    min-width: 100%;
  }
`;

export const FormContainer = styled('div')<IToken>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 200px;
  margin-top: 10px;
  width: 450px;

  > .ant-typography {
    margin: 0;
  }

  .ant-row {
    justify-content: space-between;

    a span {
      color: black;
    }
  }

  > .ant-form {
    min-width: 315px;
    margin-top: ${(props) => `${props.token.marginLG}px`};
  }
`;

export const IconContainer = styled('div')<IToken>`
  background: ${(props) => props.token.colorPrimary};
  padding: ${(props) => `${props.token.paddingSM}px`};
  border-radius: 50%;

  > span {
    font-size: ${(props) => `${props.token.fontSizeXL}px`};
    color: ${(props) => props.token.colorPrimaryText};
  }
`;

export const InputForm = styled('div')`
 position: relative,
 margin-bottom: 20px
`;
