import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import CustomTable from 'components/CustomTable/CustomTable';
import useFilters from 'utils/hooks/useFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { ICountryComponentDataType } from 'store/ducks/DataEntry/Entities/types';
import * as Styled from '../../DataEntry.styled';

const CountryComponent: React.FC = () => {
  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<ICountryComponentDataType[]>([]);
  const [initialData, setInitialData] = useState<ICountryComponentDataType[]>(
    [],
  );

  const handleSave = (row: ICountryComponentDataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const fetchCountry = async () => {
    try {
      const response = await entityOp.fetchCountryByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.country;
      const mappedData = [
        {
          key: 0,
          id: data?.id,
          country_name: data?.country_name || '',
          iso_code: data?.iso_code || '',
          description: data?.description || '',
          entity_name: response?.data?.entity_name,
        },
      ];

      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchCountry();
    }
  }, [query]);

  const handleSubmit = async () => {
    if (JSON.stringify(dataSource) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
      });
      return;
    }
    try {
      const payload = {
        entity_name: query.entity,
        data_list: [...dataSource],
      };
      const response = await entityOp.fetchCountry(payload);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const columns = [
    {
      title: (
        <>
          Name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'country_name',
      width: '30%',
      editable: true,
    },
    {
      title: (
        <>
          ISO code <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'iso_code',
      editable: true,
    },
    { title: 'Description', dataIndex: 'description', editable: true },
    { title: 'Entity name', dataIndex: 'entity_name', editable: false },
  ];

  return (
    <Styled.StyledWrapper hasEntity={!!query.entity}>
      <CustomTable
        columns={columns}
        dataSource={dataSource as ICountryComponentDataType[]}
        handleSave={handleSave}
      />
       {query.entity && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
        )}
    </Styled.StyledWrapper>
  );
};

export default CountryComponent;
