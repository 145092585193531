import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { Form, Input, notification, Pagination, theme, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UserAddOutlined } from '@ant-design/icons';
import signUpFormItems from 'constants/signUpFormItems';
import CustomTitle from 'components/CustomTitle';
import { globalOp } from 'store/ducks/Global';
import * as Styled from './Signup.styled';

const { useToken } = theme;

export const SignUp: React.FC = () => {
  const { token } = useToken();
  const [userForms, setUserForms] = useState([0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState<Record<number, Record<string, any>>>(
    {},
  );
  const pageSize = 1;

  const addUserForm = () => {
    if (userForms.length < 20) {
      const newFormIndex = userForms.length;
      setUserForms([...userForms, newFormIndex]);
      setFormData({
        ...formData,
        [newFormIndex]: {},
      });
      setCurrentPage(newFormIndex + 1);
    } else {
      notification.warning({
        message: 'Limit Reached',
        description: 'You can only add up to 20 users.',
        duration: 20,
      });
    }
  };

  const removeUserForm = (formIndex: number) => {
    if (userForms.length > 1) {
      const newUserForms = userForms.filter((index) => index !== formIndex);
      const newFormData = { ...formData };
      delete newFormData[formIndex];
      setUserForms(newUserForms);
      setFormData(newFormData);
      setCurrentPage((prev) => Math.min(prev, newUserForms.length));
    } else {
      notification.warning({
        message: 'Minimum Form Required',
        description: 'You must have at least one user form.',
        duration: 20,
      });
    }
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    const formIndex = Object.keys(changedValues)[0];
    if (formIndex) {
      setFormData({
        ...formData,
        [formIndex]: allValues[formIndex],
      });
    }
  };

  const handleSubmit = async () => {
    const usersData = userForms.map((formIndex) => {
      const userFormData = formData[formIndex];
      if (!userFormData) {
        return {};
      }
      return {
        username: userFormData.username,
        password: userFormData.password,
        email: userFormData.email,
        first_name: userFormData.firstName,
        last_name: userFormData.lastName,
        deactivation_date: userFormData.deactivation_date || null,
      };
    });

    try {
      const response = await globalOp.signup(usersData);
      if (response.results[0].status === 'success') {
        notification.success({
          message: 'Users Created',
          description: 'All users have been successfully created.',
          duration: 20,
        });
      } else {
        const errorMessage =
          response.results?.[0]?.error ||
          'An error occurred while creating users.';
        notification.error({
          message: 'Registration Failed',
          description: errorMessage,
          duration: 20,
        });
      }
    } catch (error: unknown) {
      let errorMessage = 'An unexpected error occurred.';

      if (error instanceof AxiosError) {
        if (
          error.response &&
          error.response.data &&
          typeof error.response.data.error === 'string'
        ) {
          errorMessage = error.response.data.error;
        } else if (error.message) {
          errorMessage = error.message;
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      notification.error({
        message: 'Registration Failed',
        description: errorMessage,
        duration: 20,
      });
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Styled.StyledContainer>
      <Styled.FormContainer token={token}>
        <Styled.IconContainer token={token}>
          <UserAddOutlined />
        </Styled.IconContainer>
        <CustomTitle title="Sign up" level={3} />
        <Form
          name="userForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
          onValuesChange={handleFormChange}
        >
          {userForms
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map((formIndex, index) => (
              <Styled.InputForm key={formIndex}>
                <CustomTitle title={`User ${formIndex + 1}`} level={4} />
                {signUpFormItems.map((item) => (
                  <Styled.StyledFormItem
                    token={token}
                    key={`${item.name}_${formIndex}`}
                    label={item.label}
                    name={[formIndex, item.name]}
                    rules={item.rules}
                    initialValue={formData[formIndex]?.[item.name]}
                  >
                    {item.type === 'custom' && item.render ? (
                      item.render()
                    ) : (
                      <Input type={item.type} />
                    )}
                  </Styled.StyledFormItem>
                ))}
                <Styled.StyledButton
                  token={token}
                  type="primary"
                  size="large"
                  icon={<MinusCircleOutlined />}
                  onClick={() => removeUserForm(formIndex)}
                  disabled={formIndex === 0}
                >
                  Remove User
                </Styled.StyledButton>
              </Styled.InputForm>
            ))}
          <Row>
            <Col>
              <Styled.StyledButton
                token={token}
                type="primary"
                size="large"
                onClick={addUserForm}
                icon={<PlusOutlined />}
              >
                Add User
              </Styled.StyledButton>
            </Col>
            <Col>
              <Pagination
                current={currentPage}
                total={userForms.length}
                pageSize={pageSize}
                onChange={handlePageChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Styled.StyledButton
                token={token}
                type="primary"
                size="large"
                htmlType="submit"
              >
                Submit
              </Styled.StyledButton>
            </Col>
            <Col>
              <Styled.StyledButton
                token={token}
                size="large"
                type="link"
                href="/working_area"
              >
                Back to Working Area
              </Styled.StyledButton>
            </Col>
          </Row>
        </Form>
      </Styled.FormContainer>
    </Styled.StyledContainer>
  );
};

export default SignUp;
