import { ReactNode } from 'react';
import { DatePicker } from 'antd';

type SignUpFormItem = {
  type: 'text' | 'email' | 'password' | 'custom';
  label: string;
  name: string;
  rules?: Array<{ required: boolean }>;
  render?: () => ReactNode;
};

const signUpFormItems: SignUpFormItem[] = [
  {
    type: 'text',
    label: 'First Name',
    name: 'firstName',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    type: 'text',
    label: 'Last Name',
    name: 'lastName',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    type: 'text',
    label: 'Username',
    name: 'username',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    type: 'email',
    label: 'Email Address',
    name: 'email',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    type: 'password',
    label: 'Password',
    name: 'password',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    type: 'custom',
    label: 'Deactivation Date',
    name: 'deactivation_date',
    render: () => <DatePicker />,
  },
];

export default signUpFormItems;
