import React, { useState } from 'react';
import { Input, Form, theme, notification } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import AuthLayout from 'components/Layout/AuthLayout/AuthLayout';
import * as Styled from './ForgotPassword.styled';
import { globalOp } from 'store/ducks/Global';

const { useToken } = theme;

const ForgotPass: React.FC = () => {
  const { token } = useToken();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async (e: any) => {
    setLoading(true);
    try {
      const data = await globalOp.forgotPassword(email);
      console.log(data, 'data');
      notification.success({
        message: 'Success',
        description: 'Email was sent successfully!',
      });
    } catch (error: any) {
      console.error('Forgot password request failed:', error);

      notification.error({
        message: 'Error',
        description:
          error.response?.data?.error ||
          'There was an issue while sending your email. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <>
        <Styled.InfoContainer>
          <InfoCircleTwoTone />
          Please enter you email to reset password
        </Styled.InfoContainer>
        <Form form={form} name="forgot_password" onFinish={handleSubmit}>
          <Styled.StyledFormItem
            token={token}
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input
              type="email"
              onChange={(event) => setEmail(event.target.value)}
            />
          </Styled.StyledFormItem>
          <Styled.ButtonContainer>
            <Styled.StyledButton htmlType="submit">Submit</Styled.StyledButton>
          </Styled.ButtonContainer>
        </Form>
      </>
    </AuthLayout>
  );
};

export default ForgotPass;
