import React from 'react';
import StepComponent from 'components/StepComponent/CustomSteps';
import Branch from 'components/Tables/DataEntry/Branch/Branches/Branch';

const steps = [
  {
    title: 'Branch',
    content: <Branch />,
  },
];

const BranchSteps: React.FC = () => {
  return <StepComponent steps={steps} title="Branch Steps" />;
};

export default BranchSteps;
