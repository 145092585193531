import Images from 'constants/images';

const workingAreas = () => {
  const areas = [
    {
      area: 'Dateneingabe',
      infoText: 'data entry Information Details',
      imgSrc: Images.dataEntry,
      href: '/data-entry',
    },
    {
      area: 'Reporting',
      infoText: 'Reporting Information Details',
      imgSrc: Images.reporting,
      href: '/reporting',
    },
  ];
  return areas;
};

export default workingAreas;
