import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialGlobalState from './initialState';

const branchSlice = createSlice({
  name: 'branch',
  initialState: initialGlobalState(),
  reducers: {},
});

export const {} = branchSlice.actions;
export default branchSlice;
