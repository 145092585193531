import React, { useContext, useRef, useState } from 'react';
import { Form, Input, Table, FormInstance, InputRef } from 'antd';
import { ColumnType } from 'antd/es/table';
import * as Styled from '../Tables/DataEntry/DataEntry.styled';

export interface DataType {
  [key: string]: any;
  key: number;
  active?: boolean;
}

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof DataType;
  record: DataType;
  handleSave: (record: DataType) => void;
}

type FixedType = 'left' | 'right' | undefined;

export interface EditableColumnType<T = DataType> extends ColumnType<T> {
  editable?: boolean;
  fixed?: FixedType;
  render?: (value: any, record: T, index: number) => React.ReactNode;
  children?: EditableColumnType<T>[];
  type?: string;
}

type ColumnTypes = EditableColumnType[];

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    if (!editing) {
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  // if (editable) {
  //   if (typeof record[dataIndex] === 'boolean') {
  //     childNode = editing ? (
  //       <Form.Item style={{ margin: 0 }} name={dataIndex}>
  //         <Select
  //           disabled={!record.active}
  //           onChange={(value) => {
  //             handleSave({
  //               ...record,
  //               [dataIndex]: value,
  //             });
  //           }}
  //           onBlur={save}
  //         >
  //           <Select.Option value={true}>True</Select.Option>
  //           <Select.Option value={false}>False</Select.Option>
  //         </Select>
  //       </Form.Item>
  //     ) : (
  //       <div className="editable-cell-value-wrap" onClick={toggleEdit}>
  //         {record[dataIndex] ? 'True' : 'False'}
  //       </div>
  //     );
  //   } else {
  //     childNode = editing ? (
  //       <Form.Item style={{ margin: 0 }} name={dataIndex}>
  //         <Input ref={inputRef} onPressEnter={save} onBlur={save} />
  //       </Form.Item>
  //     ) : (
  //       <div className="editable-cell-value-wrap" onClick={toggleEdit}>
  //         {children}
  //       </div>
  //     );
  //   }
  // }

  if (editable) {
    if (typeof record[dataIndex] === 'boolean') {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          valuePropName="checked"
        >
          <Input
            type="checkbox"
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={save}
          />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {record[dataIndex] ? 'True' : 'False'}
        </div>
      );
    } else {
      childNode = editing ? (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

const CustomTable = <T extends DataType>({
  columns,
  dataSource,
  handleSave,
}: {
  columns: ColumnTypes;
  dataSource: T[];
  handleSave: (row: T) => void;
  pagination?: {
    current: number;
    pageSize: number;
    onChange: (page: number, pageSize: number) => void;
    showSizeChanger: boolean;
  };
}) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const onPaginationChange = (page: number, pageSize: number) => {
    setPagination({ ...pagination, current: page, pageSize });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const processedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: T) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <Styled.TypeComponent>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={processedColumns as ColumnTypes}
        pagination={{
          ...pagination,
          onChange: onPaginationChange,
        }}
        scroll={{ x: 'max-content' }}
      />
    </Styled.TypeComponent>
  );
};

export default CustomTable;
