import { Button, Form } from 'antd';
import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';

export const StyledFormItem = styled(Form.Item)<IToken>`
  margin-bottom: ${(props) => `${props.token.margin}px`};

  .ant-input,
  .ant-input-affix-wrapper {
    padding: 16px 14px;
    border-radius: ${(props) => `${props.token.borderRadiusSM}px`};
  }

  .ant-col {
    display: flex;
    min-width: 100%;
  }
`;

export const InfoContainer = styled('div')`
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  display: flex;
  gap: 5px;
`;

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  min-width: 150px;
`;
