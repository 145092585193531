import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import useBasicFilters from 'utils/hooks/useBasicFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import { setSelectedTPP } from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import * as Styled from '../../DataEntry.styled';

interface ICurrencyAndCosts {
  key: number;
  currency_id: number;
  currency_name: string;
  currency_description: string;
  costs_id: number;
  currency_amount_reported: number;
  annual_expense: number;
  total_annual_expense: number;
  cost_description: string;
  id: number;
  contractual_arrangement_id: number;
  date_id: number;
  third_party_service_provider_id: number;
  tpp_name: string;
  isNew?: boolean;
  isEdited?: boolean;
  type?: 'currency' | 'cost';
}

const CurrencyAndCosts: React.FC = () => {
  const dispatch = useDispatch();

  const { query } = useBasicFilters();
  const [dataSource, setDataSource] = useState<ICurrencyAndCosts[]>([]);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const { selectedTPP } = useSelector((state: RootState) => state.tpp);
  const [selectedTPPID, setSelectedTPPID] = useState<string>('');

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const getCurrencyAndCosts = async () => {
    try {
      const response = await tppOp.setCurrencyAndCosts(
        selectedTPP,
        selectedTPPID,
      );
      const mappedData = [
        {
          key: 0,
          currency_id: response.costs.currency_id,
          currency_name: response.currency.name,
          currency_description: response.currency.description,
          costs_id: response.costs.id,
          currency_amount_reported: response.costs.currency_amount_reported,
          annual_expense: response.costs.annual_expense,
          total_annual_expense: response.costs.total_annual_expense,
          cost_description: response.costs.description,
          id: response.currency.id,
          contractual_arrangement_id: response.costs.contractual_arrangement_id,
          date_id: response.costs.date_id,
          third_party_service_provider_id:
            response.costs.third_party_service_provider_id,
          isNew: false,
          isEdited: false,
          tpp_name: response.tpp_name,
        },
      ];
      setDataSource(mappedData);
      setCount(mappedData.length);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchTPPID = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;

      const dataTPP = data.map((item: any, index: number) => ({
        id: item.id,
        third_party_service_provider_name:
          item.third_party_service_provider_name,
      }));

      const matchedTPP = dataTPP.find(
        (item: ICurrencyAndCosts) =>
          item.id.toString() === selectedTPP.toString(),
      );

      if (matchedTPP) {
        setSelectedTPPID(matchedTPP.id);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      if (selectedTPP === '') {
        fetchTPPID();
      } else if (selectedTPP) {
        dispatch(setSelectedTPP(selectedTPP));
      } else if (!selectedTPP) {
        dispatch(setSelectedTPP(''));
        setDataSource([]);
      }
    }
  }, [query.entity]);

  useEffect(() => {
    if (selectedTPP === '' && dataSource.length > 0 && query.entity) {
      setDataSource([]);
      setSelectedTPPID('');
    }
  }, [selectedTPP, dataSource, query.entity]);

  useEffect(() => {
    if (selectedTPP && query.entity) {
      fetchTPPID();
    }
  }, [selectedTPP, query.entity]);

  useEffect(() => {
    if (selectedTPPID && query.entity) {
      getCurrencyAndCosts();
    }
  }, [selectedTPPID, query.entity]);

  const handleAddRow = (record?: any, index?: number) => {
    const maxId = dataSource.reduce(
      (max, item) => (item.id > max ? item.id : max),
      0,
    );
    const newId = maxId + 1;

    const newData: ICurrencyAndCosts = {
      key: newId,
      id: newId,
      currency_id: 0,
      currency_name: '',
      currency_description: '',
      costs_id: 0,
      currency_amount_reported: 0,
      annual_expense: 0,
      total_annual_expense: 0,
      cost_description: '',
      contractual_arrangement_id: 0,
      date_id: 0,
      third_party_service_provider_id: 0,
      tpp_name: selectedTPP,
      isNew: true,
    };

    const insertIndex = dataSource.findIndex(
      (item) => item.key === record?.key,
    );

    const updatedDataSource = [
      ...dataSource.slice(0, insertIndex + 1),
      newData,
      ...dataSource.slice(insertIndex + 1),
    ];

    setDataSource(updatedDataSource);
    setCount(count + 1);
  };

  const handleSave = (row: ICurrencyAndCosts) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const handleSubmit = async () => {
    const filteredData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );

    if (filteredData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited records to submit.',
        placement: 'topRight',
      });
      return;
    }

    const currencies_list = filteredData.map((item) => ({
      id: item.id,
      currency_name: item.currency_name,
      currency_description: item.currency_description,
    }));

    const costs_list = filteredData.map((item) => ({
      id: item.costs_id,
      currency_amount_reported: item.currency_amount_reported,
      annual_expense: item.annual_expense,
      total_annual_expense: item.total_annual_expense,
      cost_description: item.cost_description,
      currency_id: item.currency_id,
      contractual_arrangement_id: item.contractual_arrangement_id,
      date_id: item.date_id,
      third_party_service_provider_id: item.third_party_service_provider_id,
    }));

    const payload = {
      tpp_name: selectedTPP,
      entity_name: query.entity,
      currencies_list,
      costs_list,
    };

    try {
      await tppOp.insertCurrencyAndCosts(payload);
      notification.success({
        message: 'Submission Success',
        description: 'Data successfully submitted.',
        placement: 'topRight',
      });

      await getCurrencyAndCosts();
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description:
          'There was an error submitting the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: 'id',
      dataIndex: 'id',
      editable: false,
      type: 'currency',
    },
    {
      title: 'currency_name',
      dataIndex: 'currency_name',
      editable: true,
      type: 'currency',
    },
    {
      title: 'currency_description',
      dataIndex: 'currency_description',
      editable: true,
      type: 'currency',
    },
    {
      title: 'costs_id',
      dataIndex: 'costs_id',
      editable: false,
      type: 'cost',
    },
    {
      title: 'currency_id',
      dataIndex: 'currency_id',
      editable: false,
      type: 'cost',
    },
    {
      title: 'currency_amount_reported',
      dataIndex: 'currency_amount_reported',
      editable: true,
      type: 'cost',
    },
    {
      title: 'annual_expense',
      dataIndex: 'annual_expense',
      editable: true,
      type: 'cost',
    },
    {
      title: 'total_annual_expense',
      dataIndex: 'total_annual_expense',
      editable: false,
      type: 'cost',
    },
    {
      title: 'cost_description',
      dataIndex: 'cost_description',
      editable: true,
      type: 'cost',
    },
    {
      title: 'contractual_arrangement_id',
      dataIndex: 'contractual_arrangement_id',
      editable: false,
      type: 'cost',
    },
    {
      title: 'date_id',
      dataIndex: 'date_id',
      editable: false,
      type: 'cost',
    },
    {
      title: 'third_party_service_provider_id',
      dataIndex: 'third_party_service_provider_id',
      editable: false,
      type: 'cost',
    },
    {
      title: 'TPP name',
      dataIndex: 'tpp_name',
      editable: false,
      fixed: 'right',
    },
    {
      title: 'Create',
      dataIndex: 'add_row',
      key: 'add_row',
      width: '10px',
      fixed: 'right',
      render: (_: any, record: { key: React.Key }, index: number) => (
        <Styled.OperationsRow onClick={() => handleAddRow(record, index)}>
          <PlusOutlined />
        </Styled.OperationsRow>
      ),
    },
  ];

  return (
    dataSource && (
      <div>
        <Styled.AreaButton onClick={handleAddRow} type="primary">
          Add a row
        </Styled.AreaButton>
        <CustomTable
          columns={defaultColumns}
          dataSource={dataSource}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      </div>
    )
  );
};

export default CurrencyAndCosts;
