import Images from 'constants/images';

const dataEntries = () => {
  const entries = [
    {
      area: 'entity',
      title: 'Entity',
      infoText: 'info about entity',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/entity',
    },
    {
      area: 'third_party_provider',
      title: 'Third Party Provider',
      infoText: 'info about Third party provider',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/third_party_provider',
    },
    {
      area: 'function',
      title: 'Function',
      infoText: 'info about Function',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/function',
    },
    {
      area: 'ict_service',
      title: 'ICT Service',
      infoText: 'info about ICT_service',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/ict_service',
    },
    {
      area: 'contractual_information',
      title: 'Contractual Information',
      infoText: 'info about Contractual information',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/contractual_information',
    },
    {
      area: 'branch',
      title: 'Branch',
      infoText: 'info about Branch',
      imgSrc: Images.dataEntryAreas,
      href: '/data_entry/branch',
    },
  ];
  return entries;
};

export default dataEntries;
